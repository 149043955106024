import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { Draw } from "ol/interaction";

export default function DrawInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    type: undefined,
    clickTolerance: undefined,
    features: undefined,
    source: undefined,
    dragVertexDelay: undefined,
    snapTolerance: undefined,
    stopClick: undefined,
    maxPoints: undefined,
    minPoints: undefined,
    finishCondition: undefined,
    style: undefined,
    geometryFunction: undefined,
    geometryName: undefined,
    condition: undefined,
    freehand: undefined,
    freehandCondition: undefined,
    wrapX: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    drawend: undefined,
    drawstart: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Draw, props, options, events),
    []
  );

  return null;
}
