import React, { Fragment } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

export const getHeader = (field, t) => {
	const headerString = field.ttoken ? t(field.ttoken) : field.title;
	const Component = field.type === "numeric" ? 
		<div style={{ textAlign: "end" }}>{headerString}</div>
		: <div>{headerString}</div>;

	if (field.header_ttip) {
		return (
			<Tooltip placement="bottom" title={t(field.header_ttip)}>
				{Component}
			</Tooltip>
		);
	} else {
		return Component;
	}
}