import React, { useContext, useEffect} from "react";
import OlLayerVector from "ol/layer/Vector";
import OlSourceOSM from "ol/source/OSM";

import Util from '../Util';
import { MapContext } from "../MapContext";
import { GroupLayerContext } from "./GroupLayer/GroupLayerContext";



export default function VectorLayer(props) {

  const mapContext = useContext(MapContext);
  const parentLayerContext = useContext(GroupLayerContext);

  let layer = undefined;

  const options = {
    className: undefined,
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    renderOrder: undefined,
    rednerBuffer: undefined,
    source: undefined,
    map: undefined,
    declutter: undefined,
    style: undefined,
    updateWhileAnimating: undefined,
    updateWhileInteracting: undefined
  };

  const idKey = "id";
  const titleKey = "title";

  const events = {
    'change': undefined,
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    'error': undefined,
    'postrender': undefined,
    'prerender': undefined,
    'propertychange': undefined
  };

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    layer = new OlLayerVector(definedOptions);
    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if(props.zIndex){
      layer.setZIndex(props.zIndex);
    }

    if (parentLayerContext && parentLayerContext.exists) {
      //console.log('*** parent layer')
      parentLayerContext.childLayers.push(layer);

    } else if (mapContext.map) {
      const mapLayers = mapContext.map.getLayers();
      const mapLayer = mapLayers.getArray().find(x => x instanceof OlLayerVector && x.get(idKey) === props.id);

      if (mapLayer) {
        //console.log('--remove mapLayer', mapLayer);
        mapContext.map.removeLayer(mapLayer);
      }
      //console.log('++add Layer', layer);
      mapContext.map.addLayer(layer);
    } else {
      //console.log('push init layer', layer);
      mapContext.initOptions.layers.push(layer)
    }

    let olEvents = Util.getEvents(events, props);
    for(let eventName in olEvents) {
      layer.on(eventName, olEvents[eventName]);
    }

    layer.changed();

    //console.log('layer Vector mounted', layer);
  }, [
    props.className,
    props.opacity,
    props.visible,
    props.extent,
    props.zIndex,
    props.minResolution,
    props.maxResolution,
    props.renderOrder,
    props.rednerBuffer,
    props.source,
    props.map,
    props.declutter,
    props.style,
    props.updateWhileAnimating,
    props.updateWhileInteracting
  ]);

  return(null);

}
