import React, { useContext } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from "@material-ui/core/Button";

//Custom Components
import UserContext from "Components/UserContext/UserContext";

const customButtonStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.black,
    marginLeft: 10,
    marginRight: 0,
    minWidth: "fit-content"
  }
}));

function TableHeaderButton(props) {
  const userContext = useContext(UserContext);
  const { onClick, needRole, ...otherProps } = props;
  const classes = customButtonStyles();

  const handleClick = function (evt) {
    onClick(evt);
  };

  return needRole && !userContext.hasAnyRole(needRole) ? null : (
    <Button classes={classes} color="secondary" size="small" {...otherProps} onClick={handleClick}>
      {props.children}
    </Button>
  );
}

TableHeaderButton.propTypes = {
  needRole: PropTypes.array
};

export default TableHeaderButton;
