//import patchedevent from 'Libs/patchedevent';
import moment from "moment";
import { formats } from "Lib/formats";
import api from "Lib/api";

import model from "Components/Scheduler/schedulerModel";
import dataController from "Lib/dataController";

//class SchedulerModel extends patchedevent {
class schedulerDataController {
  constructor(start_date) {
    //super();

    const interval = this.calcInterval(start_date, 2);
    this.left_edge = interval.from;
    this.right_edge = interval.to;

    // just filtering remains!!!
    // this.filters = {
    // 	vozaci: false,
    // 	vozila: true
    // };
    // this.filter_fnc = this.filter_general.bind(this);

    this.fields = [];
    this.records = [];
    // this.visible = [];
    this.groups = [];
    // this.isolations = [];
    this.group_parents = [];
    this.mouse_hold = [];
    this.tr_conflicts = {};
    this.conflictIntervalsTotal = [];

    this.dc = new dataController(model);
    this.api = new api();

    this.GetData = this.GetData.bind(this);
    this.parseRecord = this.parseRecord.bind(this);
  }

  parseData(data) {
    const records = data;
    const fields = this.dc.getFormFieldsNames("default");

    if (!this.fields.length) {
      this.fields = fields;
      // const requriedFieldNames = ["lbl", "ts1", "ts2", "st", "opis", "hexcolor", "link_t", "trjob", "linkid"];
      // const brokenField = requriedFieldNames.find((key) => this.fields.indexOf(key) === -1);
      // if (brokenField) {
      //   console.error("Invalid data structure provided from mw, cannot continue", brokenField);
      //   throw "Expected field not found in resultset";
      // }
    }
    this.group_parents = [];
    this.conflictIntervalsTotal = [];
    this.tr_conflicts = {};
    this.records = records ? records.map(this.parseRecord) : [];
    console.log(this.tr_conflicts);
    // this.apply_filters();
  }

  parseRecord(r, pos) {
    r.day1 = moment(r.day1 + "T00:00");
    r.day2 = moment(r.day2 + "T23:59");
    r.groupActive = true;
    // if (!r.link_id && this.groups.indexOf(r.trjob) === -1) {
    //   this.groups.push(r.trjob);
    // }

    // r.isolationSelected = (r.linkid && this.isolations.indexOf( r.trjob ) > -1);
    // Maybe in isolation property as well!?

    // if (r.linkid && this.group_parents.indexOf(r.trjob) == -1) {
    //   this.group_parents.push(r.trjob);
    // }

    // ["vozac1", "vozac2", "vozac3", "vozilo"].forEach((item) => {
    //   if (!r[item]) {
    //     return;
    //   }

    //   if (this.tr_conflicts[r[item]]) {
    //     let inconflict = false;
    //     let tempInterval;
    //     let ro = { from: r.ts1, to: r.ts2 };

    //     this.tr_conflicts[r[item]].ranges.forEach((rc) => {
    //       if (r.tip >= 220 && r.tip < 230 && r.trjob === rc.trjob) {
    //         inconflict = false;
    //       } else {
    //         inconflict |=
    //           (rc.from <= ro.from && ro.from < rc.to) ||
    //           (rc.from < ro.to && ro.to <= rc.to) ||
    //           (rc.from >= ro.from && rc.to <= ro.to);
    //         if (inconflict) {
    //           tempInterval = { from: rc.from, to: rc.to, trjob: rc.trjob };
    //         }
    //       }
    //     });

    //     if (inconflict) {
    //       // console.log( 'conflicts!!!!' );
    //       this.tr_conflicts[r[item]].conflicts++;

    //       if (this.tr_conflicts[r[item]].conflictInterval.length === 0) {
    //         this.tr_conflicts[r[item]].conflictInterval.push(tempInterval);
    //         this.conflictIntervalsTotal.push(tempInterval);
    //       }

    //       this.tr_conflicts[r[item]].conflictInterval.push({
    //         from: r.ts1,
    //         to: r.ts2,
    //         trjob: r.linkid ? null : r.trjob
    //       });
    //       this.conflictIntervalsTotal.push({ from: r.ts1, to: r.ts2 });
    //     }

    //     this.tr_conflicts[r[item]].ranges.push({ from: ro.from, to: ro.to, trjob: r.linkid ? null : r.trjob });
    //   } else {
    //     this.tr_conflicts[r[item]] = {
    //       conflicts: 0,
    //       ranges: [{ from: r.ts1, to: r.ts2, trjob: r.linkid ? null : r.trjob }],
    //       conflictInterval: [],
    //       type: item === "vozilo" ? "vozilo" : "vozac"
    //     };
    //   }
    // });

    return r;
  }

  calcInterval(startDate, res_h) {
    const n_days = this.get_n_days(res_h);

    const half = Math.ceil(n_days * 0.5);
    const bufferLeft = half * -1;
    const bufferRight = n_days + half;

    const from = startDate.clone().add(bufferLeft, "days");
    const to = startDate.clone().add(bufferRight, "days");

    return { from: from, to: to };
  }

  get_n_days(res_h) {
    switch (res_h) {
      case 1:
      case 2:
        return 7;
      case 3:
        return 14;
      case 4:
        return 21;
      case 6:
        return 28;
      case 8:
        return 35;
      case 12:
        return 56;
      case 24:
        return 98;
      default:
        return 7;
    }
  }

  ChangeGroupState(item_id, state) {
    this.records[item_id].groupActive = state;
    let pos = this.groups.indexOf(this.records[item_id].trjob);
    if (pos > -1 && !state) {
      this.groups.splice(pos, 1);
    } else if (pos == -1 && state) {
      this.groups.push(this.records[item_id].trjob);
    }
    // this.apply_filters();
  }

  // ChangeIsolationState(item_id, state){
  //     let select_value = this.records[item_id].trjob;
  //     for( let i=0; i < this.records.length; i++ ){
  //         if( this.records[i].trjob == select_value ){
  //             this.records[i].isolationSelected = state;
  //         }
  //     }
  //     let pos = this.isolations.indexOf( select_value );
  //     if( pos > -1 && !state ){
  //         this.isolations.splice( pos, 1 );
  //     }
  //     else if( pos == -1 && state ){
  //         this.isolations.push( select_value );
  //     }
  //     // TODO: when isolations empty, change scheduler state!!!
  //     this.apply_filters();
  // }

  // SetIsolation(state){
  //     if( state ){
  //         this.filter_fnc = this.filter_isolation;
  //     } else {
  //         this.filter_fnc = this.filter_general;
  //     }
  //     this.apply_filters();
  // }

  // ResetIsolation(){
  // 	this.filter_fnc = this.filter_general;
  //     this.isolations = [];
  //     for( let  i=0; i < this.records.length; i++ ){
  //         this.records[i].isolationSelected = false;
  //     }
  //     this.apply_filters();
  // }

  // ToggleFilter(name){
  // 	if( name in this.filters ){
  // 		this.filters[name] = !this.filters[name];
  // 		this.apply_filters();
  // 	}
  // }

  // filter_isolation(r, i) {
  //     return r.linkid && this.isolations.indexOf( r.trjob ) > -1 ? i : null;
  // }

  // filter_general(r, i, item_id = -1) {

  // // if( !this.filters.vozaci && r.link_t == 4 ){
  // // 	return null;
  // // }
  // // if( !this.filters.vozila && r.link_t == 3 ){
  // // 	return null;
  // // }
  //   if( r.linkid && this.groups.indexOf( r.trjob ) > -1 ){
  //   	return null;
  //   }
  //   // if( this.records[item_id] && r.trjob === item_id || item_id === -10 && r.tip >= 220 && r.tip < 230 ){
  //   //   return item_id !== -10 || this.records[item_id] && this.records[item_id].groupActive ? i : null
  //   // }

  // return i;
  // }

  // apply_filters(item_id = -1) {

  // 	// this.visible = this.records
  //  // //   // .map( (r, i) => this.filter_fnc(r, i, item_id ) )
  //  //       						   .filter( (v) => { return v != null; } );

  //    this.scheduler.forceUpdate();
  // }

  CheckData(startDate, res_h, force = false) {
    //console.log("Check data", startDate.format(formats.datetime), force);

    const currentDateDiff = this.right_edge.diff(this.left_edge, "days");
    if (
      force ||
      startDate.diff(this.left_edge, "days") < currentDateDiff / 4 ||
      this.right_edge.diff(startDate, "days") < currentDateDiff / 4
    ) {
      return this.GetData(startDate, res_h);
    } else {
      return Promise.resolve(false);
    }
  }

  GetData(startDate, res_h) {
    //console.log("GetData", startDate, res_h);

    const interval = this.calcInterval(startDate, res_h);
    const interval_start = interval.from.format(formats.date_iso);
    const interval_end = interval.to.format(formats.date_iso);

    const url = `kalendar/interval/${interval_start}/${interval_end}`;

    //console.log('Getting data', interval_start, interval_end);
    return this.api.Call(url, "get").then((resp) => {
      if (resp.success) {
        this.left_edge = interval.from;
        this.right_edge = interval.to;
        this.parseData(resp.data);
        return Promise.resolve({ success: true });
      } else {
        return Promise.resolve({ success: false });
      }
    });
  }

  checkInterval(interval, intervalList, id) {
    let thereIsConflict = false;
    intervalList.conflictInterval.forEach((item) => {
      if (id && id !== item.trjob) {
        return false;
      }
      thereIsConflict |=
        (item.from <= interval.from && interval.from < item.to) ||
        (item.from < interval.to && interval.to <= item.to) ||
        (item.from >= interval.from && item.to <= interval.to);
    });
    return thereIsConflict;
  }

  GetRows(start_date, end_date, res, cell_width, r_edge, mouse_hold, userFilters, userShow, hideTime, sortType) {
    if (!mouse_hold) {
      this.mouse_hold = [];
    }

    let rows = [];
    // let total_n = this.visible.length;
    let total_n = this.records.length;

    let isGroupVisibile = true;

    for (let i = 0; i < total_n; i++) {
      // let r = this.records[ this.visible[i] ];
      let r = this.records[i];

      if (r.linkid && this.groups.indexOf(r.trjob) > -1) {
        continue;
      }

      let userShowTrue = true;
      let recordId = null;
      // if (r.tip === 0 || r.tip === null) {
      //   userShowTrue = userShow.ostalo;
      //   recordId = r.linkid;
      // } else if (r.tip >= 20 && r.tip <= 29) {
      //   userShowTrue = userShow.voznje;
      //   recordId = r.trjob;
      // } else if (r.tip >= 30 && r.tip <= 39) {
      //   userShowTrue = userShow.vozila;
      //   recordId = r.linkid;
      // } else if (r.tip >= 40 && r.tip <= 49) {
      //   userShowTrue = userShow.vozaci;
      //   recordId = r.linkid;
      // } else if (r.tip >= 220 && r.tip <= 229) {
      //   userShowTrue = userShow.voznje;
      //   recordId = r.linkid;
      // }

      if (!userShowTrue) {
        continue;
      }

      let filterKeys = Object.keys(userFilters);
      const linkIdKey = "link_id";
      if (filterKeys.length > 0) {
        let includeRecord = false;
        let includeSummary = {};

        filterKeys.forEach((source) => {
          const filterByValues = userFilters[source];
          if (filterByValues === undefined || filterByValues === null) {
            return;
          }
          const okValues = filterByValues.map((x) => x.value);

          //all filters must be met
          switch (source) {
            case "klijent_id":
            case "ponuda_id":
            case "ugovor_id":
              if (!includeSummary.hasOwnProperty(linkIdKey)) {
                includeSummary[linkIdKey] = false;
              }
              break;
            default:
              includeSummary[source] = false;
          }

          switch (source) {
            case "djelatnik_id":
            case "oprema_id":
              const indv = okValues.indexOf(r[source]);
              if (indv >= 0) {
                includeSummary[source] = true;
              }
              break;
            case "klijent_id":
            case "ponuda_id":
            case "ugovor_id":
              const indv2 = okValues.indexOf(r[linkIdKey]);
              if (indv2 >= 0) {
                includeSummary[linkIdKey] = true;
              }
              break;
            // case "search":
            //   const recordValue = r["opis"] ? r["opis"].toLowerCase() : "";
            //   const searchValue = okValues[0].toLowerCase();
            //   const inds = recordValue.indexOf(searchValue);
            //   if (inds >= 0) {
            //     includeSummary[source] = true;
            //   }
            //   break;
          }
        });

        //all includeSummary keys must be true to render
        const notOk = Object.keys(includeSummary).find((key) => includeSummary[key] === false);
        if (notOk) {
          continue;
        }
      }

      let isGroupRow = r.tip >= 220 && r.tip <= 229;

      // let d = moment.duration(r.day1.utc().diff(start_date.utc()));
      let d = moment.duration(r.day1.diff(start_date));
      let offset = Math.floor(d.asMinutes() * (cell_width / res)) / 60;

      //let w = moment.duration(r.day2.utc().diff(r.day1.utc()));
      let w = moment.duration(r.day2.diff(r.day1));
      let width = w.asHours() * (cell_width / res);

      // if( mouse_hold && this.mouse_hold.indexOf( this.visible[i] ) == -1 ){
      if (mouse_hold && this.mouse_hold.indexOf(i) == -1) {
        continue;
      } else if (!mouse_hold) {
        // filter out those not visible on the screen
        if (offset + width < 0 || offset > r_edge) {
          if (isGroupRow) {
            isGroupVisibile = false;
            continue;
          }

          if (!isGroupRow && isGroupVisibile === false) {
            continue;
          }
          continue;
        }
        // this.mouse_hold.push( this.visible[i] );
        this.mouse_hold.push(i);
      }

      // check for vozaci / vozila
      let action = null;
      if (r.linkid) {
        action = "check";
      } else if (r.trjob) {
        if (this.group_parents.indexOf(r.trjob) == -1) {
          action = "notgroup";
        } else {
          action = r.groupActive ? "ungroup" : "group";
        }
      }

      let conflictRecords = [];

      // ["vozac1", "vozac2", "vozac3", "vozilo"].forEach((item) => {
      //   const recordInterval = { from: r.day1, to: r.day2 };
      //   if (
      //     r[item] &&
      //     Object.keys(this.tr_conflicts).indexOf(r[item].toString()) > -1 &&
      //     this.tr_conflicts[r[item].toString()].conflicts > 0 &&
      //     this.checkInterval(recordInterval, this.tr_conflicts[r[item].toString()], r.linkid ? null : r.trjob)
      //   ) {
      //     recordInterval.type = item === "vozilo" ? "vozilo" : "vozac";
      //     recordInterval.conflictIntervals = this.tr_conflicts[r[item].toString()].conflictInterval;
      //     conflictRecords.push(recordInterval);
      //   }
      // });
      // How to getRecordObject for vozac and vozilo?? Here are numbers only

      const hasDjelatnik = r.djelatnik_id ? true : false;
      const hasOprema = r.oprema_id ? true : false;
      const label = `${hasDjelatnik ? "[" + r.dj_tag + "] " : ""}${hasOprema ? "[" + r.os_tag + "] " : ""}${r.opis}`;
      let robj = {
        id: r.id,
        day1: r.day1,
        day2: r.day2,
        link_id: r.link_id,
        djelatnik_id: r.djelatnik_id,
        oprema_id: r.oprema_id,

        icon: action,
        label: label,
        content: {
          registration: { label: r.vozilo_tag, id: r.vozilo },
          driver: [
            { label: r.vozac1_tag, id: r.vozac1 },
            { label: r.vozac2_tag, id: r.vozac2 },
            { label: r.vozac3_tag, id: r.vozac3 }
          ],
          description: { label: r.opis, id: r.linkid ? r.linkid : r.trjob },
          cooperant: { label: r.kooperant_tag, id: r.kooperant }
        },
        class: r.st,
        offset: offset,
        width: width,
        timeSpan: { from: r.day1, to: r.day2 },
        color: r.hexcolor,
        tooltip: this.getTooltipLines(r, hideTime),
        conflict: conflictRecords,
        //subjobs: r.sub_jobs ? JSON.parse(r.sub_jobs) : [],
        subjobs: r.sub_jobs ? r.sub_jobs : [],
        recordId: recordId,
        trjobId: r.trjob
      };
      rows.push(robj);
    }

    const sortFunc = this.getSortFunction(sortType);
    const sortedRows = rows.sort(sortFunc);

    return [sortedRows, this.tr_conflicts];
    // return [ rows, this.conflictIntervalsTotal ];
  }

  getSortFunction(sortType) {
    switch (sortType) {
      case 1:
        return (a, b) => {
          if (a.day1 < b.day1) {
            return -1;
          } else if (a.day1 > b.day1) {
            return 1;
          } else {
            if (a.djelatnik_id < b.djelatnik_id) {
              return -1;
            } else if (a.djelatnik_id > b.djelatnik_id) {
              return 1;
            } else {
              if (a.oprema_id < b.oprema_id) {
                return -1;
              } else if (a.oprema_id > b.oprema_id) {
                return 1;
              } else {
                return a.id < b.id ? -1 : 1;
              }
            }
          }
        };
      case 2:
        return (a, b) => {
          if (a.link_id < b.link_id) {
            return -1;
          } else if (a.link_id > b.link_id) {
            return 1;
          } else {
            if (a.day1 < b.day1) {
              return -1;
            } else if (a.day1 > b.day1) {
              return 1;
            } else {
              if (a.oprema_id < b.oprema_id) {
                return -1;
              } else if (a.oprema_id > b.oprema_id) {
                return 1;
              } else {
                return a.id < b.id ? -1 : 1;
              }
            }
          }
        };
      case 3:
        return (a, b) => {
          if (a.djelatnik_id < b.djelatnik_id) {
            return -1;
          } else if (a.djelatnik_id > b.djelatnik_id) {
            return 1;
          } else {
            if (a.day1 < b.day1) {
              return -1;
            } else if (a.day1 > b.day1) {
              return 1;
            } else {
              if (a.oprema_id < b.oprema_id) {
                return -1;
              } else if (a.oprema_id > b.oprema_id) {
                return 1;
              } else {
                return a.id < b.id ? -1 : 1;
              }
            }
          }
        };
      case 4:
        return (a, b) => {
          if (a.oprema_id < b.oprema_id) {
            return -1;
          } else if (a.oprema_id > b.oprema_id) {
            return 1;
          } else {
            if (a.day1 < b.day1) {
              return -1;
            } else if (a.day1 > b.day1) {
              return 1;
            } else {
              if (a.djelatnik_id < b.djelatnik_id) {
                return -1;
              } else if (a.djelatnik_id > b.djelatnik_id) {
                return 1;
              } else {
                return a.id < b.id ? -1 : 1;
              }
            }
          }
        };
      default:
        return (a, b) => {
          return a.id < b.id ? -1 : 1;
        };
    }
  }

  getTooltipLines(r, hideTime) {
    const format = hideTime ? formats.date : formats.datetime;
    const rows = [];
    rows.push(`Interval od ${r.day1.format(format)} do ${r.day2.format(format)}`);
    if (r.link_id) {
      rows.push(`[${r.link_id}] ${r.link_opis}`);
    }
    if (r.djelatnik_id) {
      rows.push(`[${r.djelatnik_id}] DJ: ${r.dj_tag}`);
    }
    if (r.oprema_id) {
      rows.push(`[${r.oprema_id}] OS: ${r.os_tag}`);
    }
    rows.push(r.opis);
    return rows;
  }
}

export default schedulerDataController;
