import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";

import AppPage from "UI/AppPage/AppPage";
import PonudeTable from "Views/PonudePage/PonudeTable";

function PonudePage(props) {
  const { t } = useTranslation();

  return (
    <AppPage>
      <Box m={0} style={{height: "100%"}}>
        <PonudeTable/>
      </Box>
    </AppPage>
  );
}

export default PonudePage;
