import React, { useCallback } from "react";
import ButtonControl from "../OpenLayersExt/ButtonControl";
import shpwrite from "shp-write";

import OlGeoJSON from "ol/format/GeoJSON";

export default function DownloadShapeControl(props) {
  const { tooltip, featureSource, featureProjection, targetProjection, target, className } = props;

  const handleDownload = useCallback(() => {
    const features = featureSource.getFeatures();
    console.log('download shape', features);

    const featProjCode = featureProjection.getCode();
    const targetProjCode = targetProjection.getCode();


    const geoJSONformat = new OlGeoJSON({
      dataProjection: targetProjCode,
      featureProjection: featProjCode
    });
    const encodedFeatures = geoJSONformat.writeFeatures(features, {
      decimals: 7
    });
    const geoJSON = JSON.parse(encodedFeatures)
    geoJSON.crs =  {
      "type": "name",
      "properties": {
        "name": targetProjCode
        }
      }
    console.log(geoJSON);

    shpwrite.download(geoJSON, {
      folder: 'opazanja',
        types: {
          point: 'opazanja-points',
          polygon: 'mypolygons',
          line: 'mylines'
        }
    });

  }, [props.featureSource]);

  return (
    <ButtonControl
      className={className}
      title={tooltip}
      html='<i class="fas fa-download"></i>'
      handleClick={handleDownload}
      target={target}
    />
  );
}
