import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { LayerSwitcherImageControl } from "Components/Map/Controls";
import { mapService } from "Services/mapService";
import { MapContext } from "Components/Map/MapContext";

function GeoPortalBaseLayerSwitcher(props) {
  const { t } = useTranslation();
  const [baselayersGroup, setBaselayersGroup] = useState(undefined);
  const context = useContext(MapContext);

  useEffect(() => {
    if (context.map) {
      mapService.getBaseLayers(t).then((olGroup)=> {
        if (olGroup) {
          const mapLayers = context.map.getLayers();
          mapLayers.insertAt(0, olGroup);
        }

        setBaselayersGroup(olGroup);
      });
    }
  }, [context.map]);

  const showBaseLayerSwitcher =
    context.map &&
    baselayersGroup && baselayersGroup.getLayers && baselayersGroup.getLayers().getArray().length > 0 ? true : false;

  return showBaseLayerSwitcher ? (
    <LayerSwitcherImageControl id="base-layer-switcher" layerGroup={baselayersGroup} className={props.cl}/>
  ) : null;
}

export default GeoPortalBaseLayerSwitcher;
