import React from "react";
import MapConfigContext from "./MapConfigContext";

const maxSelVrsta = process.env.REACT_APP_MAP_MAXSELVRSTA ? process.env.REACT_APP_MAP_MAXSELVRSTA : 0;

class MapConfigProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setFilteredData = this.setFilteredData.bind(this);
    this.getFilteredData = this.getFilteredData.bind(this);

    this.state = {
      filteredData: null,
      setFilteredData: this.setFilteredData,
      getFilteredData: this.getFilteredData
    };
  }

  // sr is set/reset flag => if false set filteredPonude to null (filter is reset)
  setFilteredData(records, sr = true) {
    if (!sr || records === undefined || records === null) {
      this.setState({ filteredData: null });
      return;
    }
    const filteredData = [];
    records.forEach(r => {
      const { id, id2 } = r;
      // I find this to be more readable, correct me if I'm wrong
      // the argument is: a < x < b if x is greater than a and lesser than b
      // or "between" a and b
      if (100000 < id && id < 200000 || 200000 < id && id < 300000) {
        filteredData.push(id);
      } else if (100000 < id2 && id2 < 200000 || 200000 < id && id < 300000) {
        filteredData.push(id2);
      }
    });
    this.setState({
      filteredData: filteredData
    })
  }

  getFilteredData() {
    const { filteredData } = this.state;
    return filteredData === null ? filteredData : Array.from(filteredData);
  }

  render() {
    return (
      <MapConfigContext.Provider value={this.state}>
        {this.props.children}
      </MapConfigContext.Provider>
    );
  }
}

export default MapConfigProvider;
