import React from 'react'
import { withTranslation } from 'react-i18next';
import moment from 'moment';

//Material-UI Core Components
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Box from "@material-ui/core/Box";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

//Material-UI Icons
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

//Custom Components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import DialogConsumer from 'UI/DialogContext/DialogConsumer';

import DraggableDialog from 'UI/Dialog/DraggableDialog';
import DialogHeader from 'UI/Dialog/DialogHeader';
import DialogToolbarHeading from 'UI/Dialog/DialogToolbarHeading';
import DialogToolbarButton from 'UI/Dialog/DialogToolbarButton';
import DialogToolbarFillContent from 'UI/Dialog/DialogToolbarFillContent';
import DialogBody from 'UI/Dialog/DialogBody';
import DialogActionButton from "UI/Dialog/DialogActionButton";

import TextControl from "Controls/TextControl";
import RadioControl from "Controls/RadioControl";
import PickerControl from "Controls/PickerControl";

import picker from "Models/submodels/picker.json";
import api from "Lib/api";
import dataControllerSubModel from "Lib/dataControllerSubModel";

class MapAddLayerDialog extends React.Component {
  constructor(props) {
    super(props);

    this.api = new api();

    this.state = {
      activeStep: 0,
      validation: {},
      layerType: {},
      layerUrl: "",
      layerPicker: {},
      layerUserGivenName: "",
      subModels: null
    }

    this.steps = ["map.layer_type", "map.layer_url", "map.layer_pick", "map.layer_name"];

    this.nextStep = this.nextStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.layerUrl = {
      "title": "url",
      "source": "layerUrl",
      "ttoken": "map.layer_url"
    }

    this.layerType = {
      "title": "layerType",
      "source": "layerType",
      "ttoken": "map.layer_type",
      "items": {
        "labels": ["WMS", "WFS"],
        "values": [1, 2],
        "default": 1
      }
    }

    this.layerPicker = {
      "title": "layer picker",
      "source": "layerPicker",
      "ttoken": "map.layer_pick",
      "type": "picker",
      "subModel": {
        "format": "{label}"
      }
    }

    this.layerUserGivenName = {
      "title": "layer_userGivenName",
      "source": "layerUserGivenName",
      "ttoken": "map.layer_name"
    }
  }

  componentDidMount() {
  }

  handleChange(value, source, hideDialog = null) {
    if (source === "layerUserGivenName") {
      if (value.keyCode === 13 && hideDialog) {
        this.handleSubmit(null, hideDialog);
        return;
      }
    }

    if (source === "layerUrl") {
      if (value.keyCode === 13) {
        this.nextStep();
        return;
      }
    }

    this.setState({
      [source]: value
    })
  }

  handleClose(evt, hideDialog) {
    this.close({ success: false}, hideDialog);
  }

  nextStep() {
    const { activeStep } = this.state;
    this.validate(activeStep).then(ret => {
      if (ret) {
        this.setState({
          activeStep: activeStep + 1
        });
      }
    })
  }

  handleSubmit(evt, hideDialog) {
    const { activeStep } = this.state;
    const { layerType, layerUrl, layerPicker, layerUserGivenName } = this.state;
    const result = {
      success: true,
      data: {
        type: layerType.label.toLowerCase(),
        url: layerUrl,
        name: layerPicker.value,
        userGivenName: layerUserGivenName
      }
    }
    this.validate(activeStep).then(ret => {
      if (ret) {
        this.close(result, hideDialog);
      }
    });
  }

  close(result, hideDialog) {
    const { onClose } = this.props;
    if (onClose) {
      onClose(result);
    }
    if (hideDialog) {
      hideDialog();
    }
  }

  validate(activeStep) {
    const { t } = this.props;
    const { layerType, layerUrl, layerPicker, layerUserGivenName } = this.state;

    if (activeStep === 0) {
      if (layerType === null || layerType === undefined || Object.keys(layerType).length === 0) {
        const validation = {
          layerType: {
            valid: false,
            msg: t("validation.required")
          }
        };

        this.setState({
          validation: validation
        });

        return Promise.resolve(false);
      } else {
        return Promise.resolve(true);
      }
    } else if (activeStep === 1) {
      if (layerUrl === null || layerUrl === undefined || layerUrl === "") {
        const validation = {
          layerUrl: {
            valid: false,
            msg: t("validation.required")
          }
        };

        this.setState({
          validation: validation
        });

        return Promise.resolve(false);
      } else {
        // vanja how do we check if the url is valid?
        const regexUrl = /^https?:\/\/.*/gi;
        const validUrl = layerUrl.match(regexUrl);
        if (validUrl) {
          const apiPath = "geo/external/layers/?url=" + encodeURIComponent(layerUrl);

          Object.assign(this.layerPicker.subModel, {apiPath: apiPath});
          return Promise.all([this.layerPicker]).then(fModels => {
            const subModels = {};
            fModels.forEach(f => {
              const modelType = f.type === 'picker' ? picker : null;
              if (modelType !== null) {
                Object.assign(subModels, {[f.source]: new dataControllerSubModel(modelType, f)})
              }
            })
            this.setState({
              subModels: subModels
            })
            return true;
          })
          .catch(err => {
            console.error("Error catching submodels", err);
          });
        } else {
          const validation = {
            layerUrl: {
              valid: false,
              msg: t("validation.urlformat")
            }
          };

          this.setState({
            validation: validation
          });
          return Promise.resolve(false);
        }
      }
    } else if (activeStep === 2) {
      if (layerPicker === null || layerPicker === undefined || Object.keys(layerPicker).length === 0) {
        const validation = {
          layerPicker: {
            valid: false,
            msg: t("validation.required")
          }
        }

        this.setState({
          validation: validation
        });

        return Promise.resolve(false);
      } else {
        return Promise.resolve(true);
      }
    } else if (activeStep === 3) {
      if (layerUserGivenName === null || layerUserGivenName === undefined || layerUserGivenName === "") {
        const validation = {
          layerUserGivenName: {
            valid: false,
            msg: t("validation.required")
          }
        }

        this.setState({
          validation: validation
        });
        return Promise.resolve(false);
      } else {
        return Promise.resolve(true);
      }
    }
  }

  render() {
    const { t } = this.props;
    const { activeStep, layerType, layerUrl, layerPicker, layerUserGivenName, validation, subModels } = this.state;

    const layerPickerModel = subModels && subModels.hasOwnProperty("layerPicker") ? subModels["layerPicker"] : undefined;

    return (
      <DialogConsumer>
        {({ hideDialog }) => (
          <DraggableDialog
            open={true}
            maxWidth={"sm"}
            onClose={(evt) => this.handleClose(evt, hideDialog)}>
            <DialogHeader>
              <Toolbar variant="dense" disableGutters={true}>
                <DialogToolbarHeading>
                  {t('Dodavanje sloja')}
                </DialogToolbarHeading>
                <DialogToolbarFillContent />
                <DialogToolbarButton onClick={(evt) => this.handleClose(evt, hideDialog)}>
                  <CloseIcon />
                </DialogToolbarButton>
              </Toolbar>
            </DialogHeader>
            <DialogBody>
              <Stepper activeStep={activeStep} alternativeLabel>
                {this.steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{t(label)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box m={2}>
                {
                  activeStep === 0 ?
                  <RadioControl
                    field={this.layerType}
                    formMode="form"
                    controlMode="edit"
                    value={layerType}
                    validation={validation["layerType"]}
                    onChange={this.handleChange}
                  />
                  :
                  activeStep === 1 ?
                  <TextControl
                    field={this.layerUrl}
                    formMode="form"
                    controlMode="edit"
                    value={layerUrl}
                    validation={validation["layerUrl"]}
                    onChange={this.handleChange}
                  />
                  :
                  activeStep === 2 ?
                  <PickerControl
                    field={this.layerPicker}
                    formMode="form"
                    controlMode="edit"
                    value={layerPicker}
                    validation={validation["layerPicker"]}
                    isLoading={subModels ? false : true}
                    onChange={this.handleChange}
                    model={layerPickerModel}
                  />
                  :
                  activeStep === 3 ?
                  <TextControl
                    field={this.layerUserGivenName}
                    formMode="form"
                    controlMode="edit"
                    value={layerUserGivenName}
                    validation={validation["layerUserGivenName"]}
                    onChange={(value, source) => this.handleChange(value, source, hideDialog)}
                  />
                  : null
                }
              </Box>
            </DialogBody>
            <DialogActions>
              <DialogActionButton
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={(evt) => this.handleClose(evt, hideDialog)}
              >
                {t('buttons.cancel')}
              </DialogActionButton>
              {
                activeStep !== (this.steps.length - 1) ?
                <DialogActionButton
                  variant="contained"
                  startIcon={<NavigateNextIcon />}
                  onClick={this.nextStep}
                >
                  {t('buttons.next')}
                </DialogActionButton>
                :
                <DialogActionButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={(evt) => this.handleSubmit(evt, hideDialog)}
                >
                  {t('buttons.add_layer')}
                </DialogActionButton>
              }
            </DialogActions>
          </DraggableDialog>
        )}
      </DialogConsumer>
    );
  }

}


export default withTranslation()(MapAddLayerDialog);