import React, { useContext, useEffect } from "react";
import { SidebarControlContext } from "./SidebarControlContext";

export default function SidebarTabListItem(props) {
  const { id, icon, disabled, title } = props;

  // const context = useContext(SidebarControlContext);

  // const li = document.createElement("li");
  // if (disabled) {
  //   li.className = "disabled";
  // }

  // const a = document.createElement("a");
  // a.setAttribute("href", "#" + id);
  // a.setAttribute("role", "tab");

  // const i = document.createElement("i");
  // i.className = "fas " + icon;

  // a.appendChild(i);
  // li.appendChild(a);

  // useEffect(() => {
  //   if (context.tabList) {
  //     context.tabList.appendChild(li);
  //   }

  // }, []);

  // return null;


  return (
    <li className={disabled ? "disabled" : ''} title={title}>
      <a href={"#" + id} role="tab">
        {icon}
        {/* <i class="fa fa-bars"></i> */}
      </a>
    </li>
  );
}
