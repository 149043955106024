import React, { useRef } from "react";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

function SchedulerGridHeader(props) {

  const dateelementsRef = useRef();

  const { day_offset, day_cells, row_height, res_h } = props;

  const theme = useTheme();

  return (
    <div className="scheduler-row scheduler-header" style={{ height: row_height }}>
      <div className="scheduler-grid" ref={dateelementsRef} style={{ transform: "translateX(" + day_offset + "px)" }}>
        {day_cells.map((d, di) => (
          <div className={d.day_class} style={{ width: d.width + "px" }} key={di}>
            <div align="center" className="scheduler-header-wrapper">
              <div className="scheduler-date">{d.day_lbl}</div>
              <div style={{ display: "block", marginRight: "auto", marginLeft: "auto" }}>
                <Badge
                  color="error"
                  badgeContent={d.tooltip}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "top"
                  }}
                  component={"div"}
                  showZero={false}
                  variant={res_h >= 8 ? "dot" : "standard"}
                >
                  <div className="scheduler-date-name" style={{ color: d.conflict ? theme.palette.error.dark : null }}>
                    {d.name}
                  </div>
                </Badge>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SchedulerGridHeader;
