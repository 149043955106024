import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import { OverviewMap } from "ol/control";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOSM from "ol/source/OSM";

export default function OverviewMapControl(props) {
  const context = useContext(MapContext);

  const options = {
    className: undefined,
    collapsed: undefined,
    collapseLabel: undefined,
    collapsible: undefined,
    label: undefined,
    layers: [new OlLayerTile({ source: new OlSourceOSM()})],
    render: undefined,
    rotateWithView: undefined,
    target: undefined,
    tipLabel: undefined,
    view: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, OverviewMap, props, options, events), [
    props.className,
    props.collapsed,
    props.collapseLabel,
    props.collapsible,
    props.label,
    props.layers,
    props.render,
    props.rotateWithView,
    props.target,
    props.tipLabel,
    props.view
  ]);

  return null;
}
