import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { DragBox } from "ol/interaction";

export default function DragBoxInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    className: undefined,
    condition: undefined,
    minArea: undefined,
    boxEndCondition: undefined,
    onBoxEnd: undefined
  };

  const events = {
    boxdrag: undefined,
    boxend: undefined,
    boxstart: undefined,
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, DragBox, props, options, events),
    []
  );

  return null;
}
