import React from "react";
import { withTranslation } from 'react-i18next';

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

class SnackbarProvider extends React.Component {
  constructor(props) {
    super(props);

    this.showNotification = this.showNotification.bind(this);
    this.hideNotification = this.hideNotification.bind(this);

    this.state = {
      status: {},
      showNotification: this.showNotification,
      hideNotification: this.hideNotification
    };
  }

  showNotification(message, type, translationArgs = null) {
    const { t } = this.props;

    let msg = t("notifications.action");
    switch (message) {
      case "update":
        msg = t("notifications.update");
        break;
      case "insert":
        msg = t("notifications.insert");
        break;
      case "delete":
        msg = t("notifications.delete");
        break;
      case "copy":
        msg = t("notifications.copy");
        break;
      default:
        if (message && message.length > 0) {
          msg = translationArgs === null ? t(message) : t(message, translationArgs);
        }
        break;
    }

    let newVariant = status.variant;
    switch (type) {
      case "success":
      case "info":
      case "error":
      case "warning":
        newVariant = type;
    }

    this.setState({
      status: { show: true, message: msg, variant: newVariant }
    });
  }

  hideNotification() {
    this.setState({
      status: { show: false, message: "", variant: "" }
    });
  }

  render() {
    return (
      <SnackbarContext.Provider value={this.state}>
        {this.props.children}
      </SnackbarContext.Provider>
    );
  }
}

export default withTranslation()(SnackbarProvider);
