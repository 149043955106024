import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

//Custom Components
import withFormController from "Components/withFormController";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarHeadingSub from "UI/Dialog/DialogToolbarHeadingSub";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import UserSetPasswordDialog from "Views/UsersPage/UserSetPasswordDialog";
import picker from "Models/submodels/picker";
import dataControllerSubModel from "Lib/dataControllerSubModel"

const userRoleField = {
  "subModel": {
    "apiPath": "user-roles/"
  }
}

function UserDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [dataChanged, setDataChanged] = useState(false);
  const [userRole, setUserRole] = useState({});
  const [userPermissions, setUserPermissions] = useState([]);
  const roleDC = new dataControllerSubModel(picker, userRoleField);

  const { model, mode, form, record, validation, fields, subModels } = props; //HOC withFormController
  const { doInsert, doUpdate, doClose, doDelete } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const fUserRole = "user_role";
  const fPermissions = "user_permissions";

  useEffect(() => {
    roleDC.GetData().then(resp => {
      if (resp && resp.success) {
        if (resp.data && Array.isArray(resp.data)) {
          const roleObj = {};
          resp.data.forEach(role => {
            Object.assign(roleObj, {[role.id]: role.app_permissions});
          });
          setUserRole(roleObj);
        }
      }
    })
  }, []);

  useEffect(() => {
    const { user_permissions } = record;
    setUserPermissions(user_permissions)
  }, [record]);

  const handleFieldChange = (value, source, userPermissions) => {
    if (source === fUserRole && value !== null) {
      const appPermissions = userRole[value.value] === null ? userPermissions : userRole[value.value];
      if (onFieldChange) {
        onFieldChange(appPermissions, fPermissions);
      }
    }

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
        } else if (result.error) {
          const msg = result.errorCode && result.errorCode === 23505 ?
            ("messages.duplicate") :
            ("Greška prilikom dodavanja novog zapisa! " + result.error);
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg = result.errorCode && result.errorCode === 23505 ?
            ("messages.duplicate_user") :
            ("Greška prilikom dodavanja novog zapisa! " + result.error);
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const handleSetPassword = () => {
    dialogContext.showDialog(UserSetPasswordDialog, {
      model: model,
      mode: "update",
      form: "password_set",
      recordId: record.id
    }, 2);
  }

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          const msg = "Greška prilikom brisanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const fieldUserRole = fields.find((x) => x.source === fUserRole);
  const fieldPermissions = fields.find((x) => x.source === fPermissions);

  let isCustomRole = false;

  if (record && record[fUserRole]) {
    if (record[fUserRole] === 990 || record[fUserRole].value === 990) {
      isCustomRole = true;
      fieldPermissions.readonly = false;
    } else {
      isCustomRole = false;
      fieldPermissions.readonly = true;
    }
  }

  const insertFieldNames = ["status", "username", "password", "password_confirm", "email", "first_name", "last_name"]
  const updateFieldNames = ["status", "username", "email", "first_name", "last_name"]
  const fieldNames = form === "insert" ? insertFieldNames : updateFieldNames;

  return (
    <DraggableDialog open={true} maxWidth={"md"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="user" icon={<AccountCircleIcon />} />
          <DialogToolbarHeading>{t("titles.user")}</DialogToolbarHeading>
          {record.id ? <DialogToolbarHeadingSub>(ID = {record.id})</DialogToolbarHeadingSub> : null}
          <DialogToolbarFillContent />
          {mode === "update" ? (
            <DialogToolbarMenuButton>
              <DialogToolbarMenuItem
                onClick={handleSetPassword}
                label={t("buttons.set_password")}
                icon={<VpnKeyIcon />}
              />
              <DialogToolbarMenuItem
                onClick={handleDelete}
                label={t("buttons.delete")}
                icon={<DeleteIcon/>}
              />
            </DialogToolbarMenuButton>
          ) : null}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <GridContainer>
                <FormContent
                  fieldNames={fieldNames}
                  record={record}
                  validation={validation}
                  fields={fields}
                  onFieldChange={handleFieldChange}
                  mode={mode}
                  columns={1}
                  subModels={subModels}
                />
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <GridContainer>
                <FormContent
                  fieldNames={["user_role", "user_permissions"]}
                  record={record}
                  validation={validation}
                  fields={fields}
                  onFieldChange={(value, source) => handleFieldChange(value, source, userPermissions)}
                  mode={mode}
                  columns={1}
                  subModels={subModels}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButton variant="outlined" startIcon={<CloseIcon />} onClick={handleClose}>
          {t("buttons.close")}
        </DialogActionButton>
        {mode === "insert" ? (
          <DialogActionButton variant="contained" startIcon={<AddIcon />} onClick={handleInsert}>
            {t("buttons.add")}
          </DialogActionButton>
        ) : mode === "update" ? (
          <DialogActionButton variant="contained" startIcon={<SaveIcon />} onClick={handleUpdate}>
            {t("buttons.save")}
          </DialogActionButton>
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormController(UserDialog);
