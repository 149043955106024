import React, { useContext, useEffect, useRef } from "react";
import { SidebarControlContext } from "./SidebarControlContext";

export default function SidebarTabList(props) {
  // const context = useContext(SidebarControlContext);
  // const tabListRef = useRef(null);

  // const element = document.createElement("ul");
  // element.setAttribute("role", "tablist");

  // tabListRef.current = element;

  // useEffect(() => {
  //   if (context.tabs) {
  //     context.tabs.appendChild(tabListRef.current);
  //   }
  // }, []);

  // return (
  //   <SidebarControlContext.Provider
  //     value={{
  //       sidebar: context.sidebar,
  //       tabs: context.tabs,
  //       tabList: tabListRef.current
  //     }}
  //   >
  //     {props.children}
  //   </SidebarControlContext.Provider>
  // );

  return <ul role="tablist">{props.children}</ul>;
}
