import React from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

//Custom Components
import PublicPage from "UI/PublicPage/PublicPage";
import RegistrationForm from "Views/Auth/RegistrationForm";

import model from "Models/user";
import dataController from "Lib/dataController";

function RegistrationPage() {

  const dc = new dataController(model)

  return (
    <PublicPage>
      <Box m={2}>
        <Container maxWidth={"sm"}>
          <RegistrationForm model={dc} form="register" />
        </Container>
      </Box>
    </PublicPage>
  )
}
export default RegistrationPage;
