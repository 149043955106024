import React from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const makeCustomStyles = makeStyles((theme) => ({
  toolbarHeading: {
    marginLeft: 10,
    marginRight: 10
  }
}));

function DialogToolbarHeading(props) {
  const { lowercase, children } = props;
  const classes = makeCustomStyles();

  return (
    <Typography
      align="left"
      variant="subtitle1"
      className={"nodrag " + classes.toolbarHeading}
      style={lowercase ? { textTransform: "none" } : null}
    >
      {children}
    </Typography>
  );
}

DialogToolbarHeading.defaultProps = {
  lowercase: false
};

DialogToolbarHeading.propTypes = {
  lowercase: PropTypes.bool
};

export default DialogToolbarHeading;
