import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

//Material-UI Icons
import LockIcon from '@material-ui/icons/Lock';


class BoolControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.getLabel = this.getLabel.bind(this);
    this.getHelperText = this.getHelperText.bind(this);
    this.getSelectedIndex = this.getSelectedIndex.bind(this);
  }

  handleChange(evt) {
    const { field } = this.props;
    const checked = evt.currentTarget.checked;
    const ind = checked ? 1 : 0;
    const value = field.items.values[ind];
    if (this.props.onChange) {
      this.props.onChange(value, field.source);
    }
  }

  getLabel() {
    const { field, label, t } = this.props;

    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  }

  getHelperText() {
    const { field, helperText} = this.props;

    return field && field.tooltip ? field.tooltip : (helperText ? helperText : " ");
  }

  getSelectedIndex() {
    const { value, field } = this.props;

    let ind = field.items.values.indexOf(value);
    if (ind === -1) {
      ind = field.items.values.indexOf(field.items.default);
      if (ind === -1) {
        ind = 0;
      }
    }
    return ind;
  }

  render() {
    const { t } = this.props;
    const { field, value, validation, formMode, controlMode } = this.props;

    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || field === undefined || field.readonly || field.type === "categorization";
    const isRequired = field && field.validation && field.validation.required;

    const selectedIndex = this.getSelectedIndex();
    const label = this.getLabel();
    const helperText = this.getHelperText();
    const lbl = field.items.labels[selectedIndex]
    const valueLabel = field.translate ? t(lbl) : lbl;
    const checked = selectedIndex === 1 ? true : false;

    return (
      <TextField
        margin="none"
        fullWidth
        required={isRequired}
        error={hasError}
        label={ formMode === "form" ? label : null}
        value={valueLabel}
        InputProps={{
          readOnly: true,
          endAdornment:
            <Fragment>
              { isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" />
              : <Switch checked={checked} onChange={this.handleChange} disabled={isReadOnly} color="secondary" />
              }
            </Fragment>
        }}
        variant = {formMode === "form" ? "filled" : "standard"}
        helperText = {hasError ? validation.msg : helperText }
      />
    );
  }
}

BoolControl.defaultProps = {
  formMode: "form",
  controlMode: "edit"
}

BoolControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  // field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
}

export default withTranslation()(BoolControl);