import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";

//Material-UI Core Components
import Hidden from '@material-ui/core/Hidden';
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

//Material-UI Icons
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import CropFreeIcon from '@material-ui/icons/CropFree';

//Custom Components
import Map from "Components/Map/Map";
import { DefaultInteractions, Interactions, DrawInteraction, ModifyInteraction } from "Components/Map/Interactions";
import { Layers, TileLayer, VectorLayer } from "Components/Map/Layers";
import {
  Controls,
  ZoomControl,
  ScaleControl,
  ScaleLineControl,
  ZoomToExtentControl,
  RotateControl
} from "Components/Map/Controls";
import GeoPortalBaseLayerSwitcher from "Views/MapPage/GeoPortalBaseLayerSwitcher";
import { recordStyle, selectedStyle, drawStyle } from "Components/mapStyles";
import TagTableButton from "Components/TaggingControl/TagTableButton";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import TextControl from "Controls/TextControl";

//Openlayers Components
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection } from "ol/proj";
import OlFormatWKT from "ol/format/WKT";
import OlSourceVector from "ol/source/Vector";
import withStyles from "@material-ui/core/styles/withStyles";
import OlFeature from "ol/Feature";
import { buffer as OlBuffer } from "ol/extent";
import {fromExtent} from 'ol/geom/Polygon'
import Point from 'ol/geom/Point'

const MapGrid = withStyles(() => ({
  root: {
    minHeight: "250px",
    height: "100%",
    borderRadius: "0.8rem"
  }
}))(GridItem);

const fPolygon = {
  source: "tag_poly",
  ttoken: "Poligon/točka (WKT)",
  type: "text"
};

const iconButtonStyle = {
  backgroundColor: "#b7c800",
  borderRadius: "3px",
  margin: "0 5px",
  padding: "3px",
}

class TagsMap extends React.Component {
  constructor(props) {
    super(props);

    proj4.defs(
      "EPSG:3765",
      "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
    );
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    proj4.defs(
      "EPSG:3857",
      "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
    );
    OlRegister(proj4);
    this.htrs96 = OlGetProjection("EPSG:3765");
    this.wgs84 = OlGetProjection("EPSG:4326");
    this.wgs84PM = OlGetProjection("EPSG:3857");

    this.wkt = new OlFormatWKT();
    this.defaultViewCenter = [1731757, 5581737];
    this.defaultExtent = [1042452.65, 5167448.19, 2626808.03, 5851603.54];

    this.recordPointsSource = new OlSourceVector({});
    this.recordPolysSource = new OlSourceVector({});
    this.selectedGeomsSource = new OlSourceVector({});
    this.editingSource = new OlSourceVector({});
    this.allWktsSource = new OlSourceVector({});

    //this.generateSource = this.generateSource.bind(this);

    this.handleDrawStart = this.handleDrawStart.bind(this);
    this.handleDrawEnd = this.handleDrawEnd.bind(this);
    this.handleDrawChange = this.handleDrawChange.bind(this);
    this.editingSource.on('changefeature', this.handleDrawChange);
    this.editingSource.on('addfeature', this.handleDrawChange);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.getZoomExtent = this.getZoomExtent.bind(this);

    this.swapPointPolygon = this.swapPointPolygon.bind(this);
    this.handleChangePoly = this.handleChangePoly.bind(this);
    this.validate = this.validate.bind(this);
    this.getMap = this.getMap.bind(this);

    this.zoom = this.zoom.bind(this);

    this.state = {
      viewOptions: {
        center: this.defaultViewCenter,
        zoom: 8,
        minZoom: 7,
        maxZoom: 17
      },
      zoomToExtentView: null,
      recordExtent: this.defaultExtent,
      editingFeature: this.genFeature(this.props.editingGeom),
      polyValue: "",
      polyValidated: false,
      polyValidation: {
        valid: true,
        msg: ""
      }
    };
  }

  componentDidMount() {
    this.mounted = true;

    this.fillSource(this.editingSource, this.props.editingGeom);
    this.fillSource(this.recordPointsSource, this.props.geomPoints);
    this.fillSource(this.recordPolysSource, this.props.geomPolys);
    this.fillSource(this.selectedGeomsSource, this.props.selectedGeoms);
    this.fillSource(this.allWktsSource, this.props.allGeoms);
    this.getZoomExtent(this.allWktsSource);
    
    this.zoom(this.recordPointsSource);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectionCnt !== prevProps.selectionCnt) {
      this.editingSource.clear();
      this.setState({ 
        polyValue: this.props.editingGeom,
        editingFeature: null
      })
    }
    if (prevProps.editingGeom != this.props.editingGeom) {
      this.setState({
        editingFeature: this.genFeature(this.props.editingGeom),
        polyValue: this.props.editingGeom
      });
    }



    // if (prevProps.canEdit !== this.props.canEdit) {
    //   this.generateSource();
    // }
    // else if (!prevProps.geoms && this.props.geoms) {
    //   this.generateSource();
    // }
    // else if (prevProps.geoms && this.props.geoms && prevProps.geoms.join(';') !== this.props.geoms.join(';')) {
    //   this.generateSource();
    // }

    if (prevProps.editingGeom != this.props.editingGeom) {
      this.fillSource(this.editingSource, this.props.editingGeom);
    }

    if (this.hashGeoms(prevProps.geomPoints) !== this.hashGeoms(this.props.geomPoints)) {
      this.fillSource(this.recordPointsSource, this.props.geomPoints);
    }
    if (this.hashGeoms(prevProps.geomPolys) !== this.hashGeoms(this.props.geomPolys)) {
      this.fillSource(this.recordPolysSource, this.props.geomPolys);
    }

    if (this.hashGeoms(prevProps.selectedGeoms) !== this.hashGeoms(this.props.selectedGeoms)) {
      this.fillSource(this.selectedGeomsSource, this.props.selectedGeoms);
      this.getZoomExtent(this.allWktsSource)
    }

    if (this.hashGeoms(prevProps.allGeoms) !== this.hashGeoms(this.props.allGeoms)) {
      this.fillSource(this.allWktsSource, this.props.allGeoms);
      this.getZoomExtent(this.allWktsSource)
    }
  }

  isValidWKT(wkt) {
    return wkt.startsWith("POINT") || wkt.startsWith("POLYGON");
  }

  getMap(map) {
    this.setState({ map: map });
  }

  validate(submit = false) {
    const { polyValue, polyValidation, polyValidated } = this.state;
    if (!submit) {
      return polyValidation;
    }

    const isValid = polyValue !== null && polyValue !== undefined && polyValue.length >= 0 && this.isValidWKT(polyValue);

    const newPolyValidation = {};

    if (!isValid) {
      newPolyValidation.valid = false;
      newPolyValidation.msg = "Neipravna vrijednost";
      this.setState({
        polyValidated: true,
        polyValidation: newPolyValidation
      });
    } else {
      newPolyValidation.valid = true;
      newPolyValidation.msg = "";
      this.setState({
        polyValidated: true,
        polyValidation: newPolyValidation
      });
    }
    return newPolyValidation;
  }

  handleChangePoly(value, source) {
    const { polyValidated } = this.state;
    if (polyValidated) {
      this.validate(true);
    }
    this.setState({
      polyValue: value ? value.toUpperCase() : value
    });
  }

  genPolygonFromPoint(midX, midY, dx, dy) {
    const points = [];
    const last = []
    for (let i = 0; i < 2; ++i) {
      for (let j = 0; j < 2; ++j) {
        const currP = "" + Math.round(midX + (i ? dx : -dx)) + " " + Math.round(midY + (!(j ^ i)? dy : -dy));
        if (i == 0 && j == 0) {
          last.push(currP);
        }
        points.push(currP);
      }
    }
    return "POLYGON((" + [...points, ...last].join(",") + "))";
  }

  swapPointPolygon() {
    const { editingFeature, zoomExtentCalc, map } = this.state;
    const hasGeom = editingFeature !== undefined && editingFeature !== null;

    if (hasGeom) {
      const geometry = editingFeature.getGeometry();
      const type = geometry.getType();

      if (type === "Polygon") {
        this.editingSource.clear();
        const [x1, y1, x2, y2] = geometry.getExtent();
        const midX = (x1 + x2) / 2;
        const midY = (y1 + y2) / 2;
        const point = new Point([midX, midY], "XY");
        const feature = new OlFeature({
          geometry: point
        });
        const wkt = this.wkt.writeGeometry(feature.getGeometry(), {
          featureProjection: this.wgs84PM,
          dataProjection: this.htrs96,
          decimals: 0
        });
        this.setState({
          polyValue: wkt,
          editingFeature: feature
        });
        this.editingSource.addFeature(feature);
      } else {
        // its point
        this.editingSource.clear();
        const [px1, py1, px2, py2] = geometry.getExtent();
        const [x1, y1, x2, y2] = map ? map.getView().calculateExtent(map.getSize()) : zoomExtentCalc;
        const midX = (px1 + px2) / 2;
        const midY = (py1 + py2) / 2;
        const dx = Math.max(40, 0.05 * Math.abs(x2 - x1));
        const dy = Math.max(40, 0.05 * Math.abs(y2 - y1));
        const polygon = fromExtent([midX - dx, midY - dy, midX + dx, midY + dy])
        const feature = new OlFeature({
          geometry: polygon
        });
        const wkt = this.wkt.writeGeometry(feature.getGeometry(), {
          featureProjection: this.wgs84PM,
          dataProjection: this.htrs96,
          decimals: 0
        });
        this.setState({
          polyValue: wkt,
          editingFeature: feature
        });
        this.editingSource.addFeature(feature);
      }
      
    } else {
      const [x1, y1, x2, y2] = map ? map.getView().calculateExtent(map.getSize()) : zoomExtentCalc;
      const midX = (x1 + x2) / 2;
      const midY = (y1 + y2) / 2;
      const dx = Math.max(40, 0.05 * Math.abs(x2 - x1));
      const dy = Math.max(40, 0.05 * Math.abs(y2 - y1));

      const polygon = fromExtent([midX - dx, midY - dy, midX + dx, midY + dy])
      const feature = new OlFeature({
        geometry: polygon
      });
      const wkt = this.wkt.writeGeometry(feature.getGeometry(), {
        featureProjection: this.wgs84PM,
        dataProjection: this.htrs96,
        decimals: 0
      });
      this.setState({
        polyValue: wkt,
        editingFeature: feature
      });
      this.editingSource.addFeature(feature);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  hashGeoms(geoms) {
    return geoms ? geoms.join(";") : null;
  }

  genFeature(wkt) {
    if (wkt) {
      return new OlFeature({
        geometry: this.wkt.readGeometry(wkt, {
          dataProjection: this.htrs96,
          featureProjection: this.wgs84PM
        })
      });
    } else {
      return null;
    }
  }

  generateSource() {
    const { geomPoints, geomPolys, editingGeom, canEdit } = this.props;
    const { editingFeature } = this.state;
    // if (record && record.geom) {

    this.recordPointsSource.clear();
    this.recordPolysSource.clear();
    this.editingSource.clear();

    if (canEdit) {
      if (editingFeature) {
        this.editingSource.addFeature(editingFeature);
      } else if (editingGeom) {
        let f = this.genFeature(editingGeom);
        this.editingSource.addFeature(f);
      }
    } else if (geomPoints || geomPolys) {
      if (geomPoints) {
        geomPoints.forEach((geom) => {
          let f = this.genFeature(geom);
          this.recordPointsSource.addFeature(f);
        });
      }
      if (geomPolys) {
        geomPolys.forEach((geom) => {
          let f = this.genFeature(geom);
          this.recordPolysSource.addFeature(f);
        });
      }
    }

    this.zoom(this.recordPointsSource);
  }

  fillSource(source, wkts) {
    source.clear();
    if (Array.isArray(wkts)) {
      wkts.forEach((wkt) => {
        let f = this.genFeature(wkt);
        source.addFeature(f);
      });
    } else if (wkts) {
      let f = this.genFeature(wkts);
      source.addFeature(f);
    }
  }

  zoom(source) {
    if (source.getFeatures().length > 0) {
      const extent = source.getExtent();
      if (this.mounted && extent) {
        this.setState({
          zoomToExtentView: extent,
          recordExtent: extent
        });
      }
    }
  }
// TODO: Piero
// REFACTOR
  getZoomExtent(mainSource, secSource) {

    if (mainSource.getFeatures().length > 0) {
      
      const extent = mainSource.getExtent();
      if (extent) {
        const lrDist = Math.abs(extent[0] - extent[2]);
        const udDist = Math.abs(extent[1] - extent[3])
        const newExtent = extent.map((ex, i) => {
          return ex + (i < 2 ? -0.17 : 0.17) * (i % 2 === 0 ? lrDist : udDist);
        });
        this.setState({
          zoomExtentCalc: newExtent,
          recordExtent: newExtent
        });
      }
      return extent;
    }
  }

// TODO: Piero
// REFACTOR
  getZoomExtent2(source) {
    if (source.getFeatures().length > 0) {
      const extent = source.getExtent();
      if (extent) {
        const lrDist = Math.abs(extent[0] - extent[2]);
        const udDist = Math.abs(extent[1] - extent[3])
        return extent.map((ex, i) => {
          return ex + (i < 2 ? -0.17 : 0.17) * (i % 2 === 0 ? lrDist : udDist);
        });
      }
      return extent;
    }
  }

// TODO: Piero
// REFACTOR
  getZoomPadding(source) {
    if (source.getFeatures().length > 0) {
      const extent = source.getExtent();
      if (extent) {
        const lrDist = Math.abs(extent[0] - extent[2]);
        const udDist = Math.abs(extent[1] - extent[3])
        return extent.map((ex, i) => {
          //return ex + (i < 2 ? -0.17 : 0.17) * (i % 2 === 0 ? lrDist : udDist);
          return Math.abs(ex - 0.17*ex);
        })
      }
    } 
    return [50,50,50,50];
  }

  handleDrawStart(evt) {}

  handleDrawEnd(evt) {}

  handleDrawChange(evt) {
    const { feature } = evt;
    if (feature) {
      const wkt = this.wkt.writeGeometry(feature.getGeometry(), {
        featureProjection: this.wgs84PM,
        dataProjection: this.htrs96,
        decimals: 0
      });
      if (wkt)
      this.setState({
        polyValue: wkt,
        editingFeature: feature
      })
    }
  }

  handleSubmit(evt) {
    const { onEditSubmit } = this.props;
    const { polyValue } = this.state;

    const features = this.editingSource.getFeatures();

    if (polyValue && polyValue.length) {
      const validation = this.validate(true);
      if (validation.valid) {
        if (polyValue.startsWith("POINT")) {
          onEditSubmit(polyValue);
        } else {
          onEditSubmit(null, polyValue);
        }
      } else {
        return;
      }
    } else if (features.length > 0) {
      const ft = features[0];
      const wkt = this.wkt.writeGeometry(ft.getGeometry(), {
        featureProjection: this.wgs84PM,
        dataProjection: this.htrs96,
        decimals: 0
      });
      if (wkt.startsWith("POINT")) {
        onEditSubmit(wkt);
      } else {
        onEditSubmit(null, wkt);
      }
    } else {
      onEditSubmit(null);
    }

    this.editingSource.clear();

    this.setState({
      editingFeature: null,
      polyValue: null,
      polyValidated: false,
      polyValidation: {
        valid: true,
        msg: ""
      }
    });
  }

  handleCancel(evt) {
    const { onEditCancel } = this.props;

    this.editingSource.clear();
    this.setState({
      editingFeature: null,
      polyValue: null
    });
    if (onEditCancel) {
      onEditCancel();
    }
  }

  handleDelete(evt) {
    this.editingSource.clear();
    this.setState({
      editingFeature: null,
      polyValue: null
    }, () => this.handleSubmit());
  }

  render() {
    const { t, zoomToExtentCreate, canEdit } = this.props;
    const { viewOptions, zoomToExtentView, recordExtent, editingFeature, zoomExtentCalc, polyValue, polyValidation } = this.state;

    const zoomToExtent = zoomToExtentView ? zoomToExtentView : zoomToExtentCreate;
    const hasGeom = editingFeature !== undefined && editingFeature !== null;


    const selectedExtent = this.selectedGeomsSource.getFeatures().length > 0 ? this.selectedGeomsSource.getExtent() : null;
    // TODO: Piero
    // REFACTOR
    //const zoomExtent = this.getZoomExtent(this.recordSource, this.selectedSource);
    const zoomExtent = this.getZoomExtent2(this.selectedGeomsSource, this.recordSource);
    const mapPadding = zoomExtentCalc ? (Math.max(Math.abs(zoomExtentCalc[0] - zoomExtentCalc[2]), Math.abs(zoomExtentCalc[1] - zoomExtentCalc[3])) < 1000 ? 1000 : 100) : 100;
    const recordPadding = zoomExtent ? (Math.max(Math.abs(zoomExtent[0] - zoomExtent[2]), Math.abs(zoomExtent[1] - zoomExtent[3])) < 1000 ? 1000 : 100) : 100;

    return (
      <MapGrid xs={12} sm={12}>
        <Map id="map-record" view={viewOptions} zoomToExtent={zoomExtentCalc ? zoomExtentCalc.map((z, i) => z + (i < 2 ? -mapPadding : mapPadding)) : recordExtent} zoomToExtentPadding={[]} changeZoom={!canEdit} getMap={this.getMap}>
          <Controls>
            <ZoomControl zoomInTipLabel={t("map:controls.zoom_in")} zoomOutTipLabel={t("map:controls.zoom_out")} className="ol-zoom-special"/>
            <ZoomToExtentControl
              id="zoom-extent-records"
              extent={OlBuffer(zoomExtentCalc ? zoomExtentCalc : recordExtent, mapPadding)}
              tipLabel={t("map:controls.zoom_to_extent")}
              className="ol-zoom-extent ol-sidebar-sticky ol-zoom-extent-special"
            />
            {selectedExtent !== null ? (
              <ZoomToExtentControl
                id="zoom-extent-selected"
                extent={OlBuffer(zoomExtent ? zoomExtent : selectedExtent, recordPadding)}
                tipLabel={t("map:controls.zoom_to_extent")}
                className="ol-zoom-extent-selected ol-sidebar-sticky ol-zoom-extent-special-2"
              />
            ) : null}
            {/* <ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} /> */}
            <ScaleLineControl className="ol-control ol-scale-ratio ol-sidebar-sticky ol-scale-line-special ol-scale-line"/>
            {/* <RotateControl /> */}
            <GeoPortalBaseLayerSwitcher cl="ol-layerswitcher-special"/>
          </Controls>
          <Layers>
            <VectorLayer id="records" source={this.recordPointsSource} style={recordStyle} zIndex={5} visible={true} />
            <VectorLayer id="records" source={this.recordPolysSource} style={recordStyle} zIndex={5} visible={true} />
            <VectorLayer
              id="selected"
              source={this.selectedGeomsSource}
              style={selectedStyle}
              zIndex={10}
              visible={!canEdit}
            />
            <VectorLayer id="editing" source={this.editingSource} style={drawStyle} zIndex={15} visible={true} />
          </Layers>
          <Interactions>
            <DefaultInteractions dragPan={true} />
            {canEdit ? (
              <Fragment>
                {hasGeom ? (
                  <ModifyInteraction
                    source={this.editingSource}
                    type={"Point"}
                    style={drawStyle}
                    onChange={this.handleDrawChange}
                    onDrawstart={this.handleDrawStart}
                    onDrawend={this.handleDrawEnd}
                  />
                ) : (
                  <DrawInteraction
                    source={this.editingSource}
                    type={"Point"}
                    style={drawStyle}
                    onChange={this.handleDrawChange}
                    onDrawstart={this.handleDrawStart}
                    onDrawend={this.handleDrawEnd}
                  />
                )}
              </Fragment>
            ) : null}
          </Interactions>
        </Map>
        {canEdit ? (
          <Toolbar disableGutters>
            <GridContainer>
              <GridItem xs={12} style={{ marginTop: "8px" }}>
                <TagTableButton variant="outlined" startIcon={<DeleteIcon />} onClick={this.handleDelete}>
                  {t("buttons.delete")}
                </TagTableButton>
                <TagTableButton variant="outlined" startIcon={<CancelIcon />} onClick={this.handleCancel}>
                  {t("buttons.cancel")}
                </TagTableButton>
                <TagTableButton variant="contained" startIcon={<CheckIcon />} onClick={this.handleSubmit}>
                  {t("buttons.submit")}
                </TagTableButton>
                <IconButton size="small" variant="contained" onClick={this.swapPointPolygon} style={iconButtonStyle}>
                  <CropFreeIcon />
                </IconButton>
              </GridItem>
              <GridItem xs={12} style={{ padding: 0 }}>
                <TextControl field={fPolygon} value={polyValue} multiline={true} rowsMax={5} validation={polyValidation} onChange={this.handleChangePoly} />
              </GridItem>
            </GridContainer>
          </Toolbar>
        ) : null}
      </MapGrid>
    );
  }
}

export default withTranslation()(TagsMap);
