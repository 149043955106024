import { createContext } from 'react';

const UserContext = createContext({
  username: null,
  user: null,
  roleIds: [],
  roleNames: null,
  setUserData: () => {},
  hasAnyRole: () => {}
});
UserContext.displayName = 'UserContext';

export default UserContext