import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Material UI Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Custom Components
import FormButton from "UI/Form/FormButton";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import UserContext from "Components/UserContext/UserContext";
import VanjskiCVDialog from "Views/VanjskiCVPage/VanjskiCVDialog";

import model from "Models/vanjski_cv";
import dataController from "Lib/dataController";

const tagsByLinkKeys = ["klj_sektor", "klj_podrucje_vanjski"];

function VanjskiCVTable(props) {
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const { level = 1, ugovorId } = props;
  const [records, setRecords ] = useState([]);
  const [notAuth, setNotAuth] = useState(false);
  const [tableControls, setTableControls] = useState(true);
  const [loading, setLoading] = useState(false);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  useEffect(() => {
    refreshRecords();
  }, [userContext.gotData]);

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }

  const handleAdd = (evt) => {
    dialogContext.showDialog(VanjskiCVDialog, {
      model: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose,
    }, level);
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;

    dialogContext.showDialog(VanjskiCVDialog, {
      level: props.level,
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose,
    }, level);
  };

  const handleTableEdit = (recordId) => {
    dialogContext.showDialog(VanjskiCVDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose,
    }, level);
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords(true);
      if (result.action === "insert" && result.id) {
        handleTableEdit(result.id);
      }
    }
  };

  const mapRecords = (recs) => {
    const tags = userContext.fetchTags();
    return recs.map(r => {
      r.tags = "";
      if (tags[r.id]) {
        tagsByLinkKeys.forEach(k => {
          if (tags[r.id][k]) {
            r.tags = r.tags.length === 0 ? tags[r.id][k] : r.tags + ';' + tags[r.id][k];
          }
        });
      }
      return r;
    })
  }

  const refreshRecords = () => {
    // dummyRecords();
    setLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success) {
        setRecords(mapRecords(resp.data))
      } else {
        const { error } = resp;
        if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
          setNotAuth(true);
        } 
      }
      setLoading(false);
    });
  };
  
  return (
    <ModelTable records={records || []} dc={dc} 
      allowSelection="one" 
      allowFilter={true} 
      allowExport={true} 
      disableControls={!tableControls} 
      inlineEdit={true} 
      onEdit={handleTableEdit} 
      initialPageSize={50} 
      notAuth={notAuth}
      isLoading={loading}
      onAdd={handleAdd}
      title="titles.vanjski_cv"
      addGender="m"
    />
  );
}

export default VanjskiCVTable;
