import React from "react";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Icon } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import ErrorIcon from "@material-ui/icons/Error";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

function SchedulerGridBar(props) {
  const { r, day_offset, grid_width } = props;
  const { onClick, onRangeMove, onCellLeave } = props;
  const theme = useTheme();

  let croppedLeft = false;
  let croppedRight = false;

  let translateX = r.offset + day_offset;
  let w = translateX < 0 ? r.width + translateX : r.width;
  if (translateX < 0) {
    croppedLeft = true;
    translateX = 0;
  }

  if (w + translateX > grid_width) {
    croppedRight = true;
    w = grid_width - translateX;
  }

  const getBarStyle = (r) => {
    // const transform = "translateX(" + (r.offset + day_offset + divisions * (cell_width - 1)) + "px )";
    const transform = "translateX(" + translateX + "px )";
    const width = w + "px";
    const height = "32px";

    let backgroundColor = theme.palette.common.white;

    if (r.tip >= 20 && r.tip <= 29) {
      //backgroundColor = theme.palette.trjobstatus[r.tip];

      return {
        transform: transform,
        width: width,
        height: height,
        backgroundColor: backgroundColor
      };
    } else if (r.tip >= 220 && r.tip <= 229) {
      const statusVoznje = r.tip - 200;
      //const color1 = theme.palette.trjobstatus[statusVoznje];
      const color1 = theme.palette.common.black;
      const color2 = theme.palette.common.white;
      const background =
        "repeating-linear-gradient( 45deg, " +
        color1 +
        ", " +
        color1 +
        " 6px, " +
        color2 +
        " 6px, " +
        color2 +
        " 12px)";

      return {
        transform: transform,
        width: width,
        height: height,
        background: background
      };
    } else if ((r.tip >= 30 && r.tip <= 39) || (r.tip >= 40 && r.tip <= 49)) {
      const borderColor = theme.palette.primary.main;

      return {
        transform: transform,
        width: width,
        height: height,
        border: "solid 2px " + r.color
      };
    }

    return {
      transform: transform,
      width: width,
      height: height,
      border: "2px solid " + theme.palette.primary.main,
      backgroundColor: theme.palette.common.white
    };
  };

  return (
    <div className="scheduler-record">
      <Tooltip
        title={r.tooltip.map((ln, lni) => (
          <div key={lni}>{ln}</div>
        ))}
        key={r.id}
        placement="top-start"
        arrow={true}
      >
        <div
          data-tip
          data-for={"datatip" + r.id}
          className={r.class + " scheduler-span"}
          style={getBarStyle(r)}
          onClick={() => onClick(r.id)}
          onMouseMove={onRangeMove}
          onMouseLeave={onCellLeave}
        >
          <div className="scheduler-span-leftstop"></div>
          <div className="scheduler-span-lbl">
            {/*<div className="scheduler-span-lbl-value" style={{height: "24px"}}>&nbsp;{}</div>*/}
            <div className="scheduler-span-lbl-value" style={{ height: "24px" }}>
              {r.conflict.length !== 0 && r.icon === "ungroup" ? (
                <ErrorIcon
                  style={{
                    color: theme.palette.error.dark,
                    float: "left",
                    diplay: "inline",
                    marginRight: "5px"
                  }}
                  fontSize="small"
                />
              ) : null}
              {croppedLeft ? <Icon color="primary" fontSize="inherit">chevron_left</Icon> : null}
              {r.label}
              {croppedRight ? <Icon color="primary" fontSize="inherit">chevron_right</Icon> : null}
              {/* {!(r.tip >= 220 && r.tip < 230) ? r.content : " "} */}
            </div>
            <div className="scheduler-span-lbl-hover"></div>
          </div>
          <div className="scheduler-span-rightstop"></div>
        </div>
      </Tooltip>
      {/* <div
        data-tip
        data-for={"datatip" + r.id}
        style={{
          transform: `translateX(${r.offset + day_offset + divisions * (cell_width - 1) + 5}px)`,
          height: "32px"
        }}
        className="scheduler-span-extra"
        onMouseMove={this.range_move.bind(this)}
        onMouseLeave={onCellLeave}
        // onClick={() => this.item_click(r.id)}
      > */}
      {/* <DisplayIcons
          // content={{ show: (r.tip >= 220 && r.tip < 230), data: r.content }}
          conflicts={r.conflict}
          timeSpan={r.timeSpan}
          icon={r.icon}
          id={r.id}
          handleHide={this.item_group_state.bind(this)}
          showDetails={{
            registration: { state: registration, content: r.content.registration },
            driver: { state: driver, content: r.content.driver },
            description: {
              state: description && r.tip >= 220 && r.tip < 230,
              content: r.content.description
            },
            cooperant: { state: true, content: r.content.cooperant }
          }}
          subjobs={r.tip >= 20 && r.tip < 30 ? r.subjobs : []}
          onIconClick={this.handleIconClick}
          onTextClick={null}
          allConflicts={conflicts}
          modelVozilo={modelVozilo}
          modelVozac={modelVozac}
          modelRecord={r.tip >= 20 && r.tip <= 29 ? modelTrJob : modelTrSubJob}
          recordId={r.recordId}
          trjobId={r.trjobId}
          conflictsActive={conflictsActive}
          styleBasedConflict={this.getBarStyle(r, divisions).boxShadow !== null}
        /> */}
      {/* <DisplayIcons
          // vozilo={r.other.vozilo}
          // vozaci={r.other.vozaci}
          content={{ show: r.tip >= 220 && r.tip < 230, data: r.content }}
          conflicts={r.conflict}
          icon={r.icon}
          id={r.id}
          handleHide={onToggleDisplay}
        /> */}
      {/* </div> */}
    </div>
  );
}

export default SchedulerGridBar;
