import React from 'react'

//Material-UI Core Components
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const style = {
  toolbarHeading: {
    marginLeft: 10,
    marginRight: 20
  }
}

class ToolbarHeading extends React.Component {
  render() {
    const { children, classes } = this.props;

    return (
      <Typography align="left" variant="h6" className={classes.toolbarHeading}>
        {children}
      </Typography>
    );
  }
}

export default withStyles(style)(ToolbarHeading);