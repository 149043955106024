import React, { useContext } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import IconButton from "@material-ui/core/IconButton";

//Custom Components
import UserContext from "Components/UserContext/UserContext";

function CardActionButton(props) {
  const userContext = useContext(UserContext);

  const { needRole, ...rest } = props;

  return needRole && !userContext.hasAnyRole(needRole) ? null : (
    <IconButton color="primary" {...rest}>
      {props.children}
    </IconButton>
  );
}

CardActionButton.propTypes = {
  needRole: PropTypes.array
};

export default CardActionButton;
