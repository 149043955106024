import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";

import model from "Models/zivotopis";
import dataController from "Lib/dataController";

function ZivotopisTable(props) {
  const { t } = useTranslation();
  const { level = 1, djelatnikId, refreshData } = props;

  const [records, setRecords ] = useState([]);
  const [tableControls, setTableControls] = useState(true);
  const [notAuth, setNotAuth] = useState(false);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }


  const refreshRecords = () => {
    // dummyRecords();
    const customPath = "usluge-tim/cv/" + djelatnikId;
    dc.GetData(customPath).then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      } else {
        const { error } = resp;
        if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
          setNotAuth(true);
        } 
      }
    });
  };

  return (
    <ModelTable 
      dc={dc} 
      records={records || []} 
      allowSelection="one" 
      allowFilter={true} 
      allowExport={true} 
      disableControls={!tableControls} 
      initialPageSize={100} 
      notAuth={notAuth}
      allowAdd={false}
      title="titles.zivotopis"
    />
  );
}

export default ZivotopisTable;
