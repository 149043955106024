import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ExploreIcon from "@material-ui/icons/Explore";
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

//Custom Components
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";
import TableButton from "UI/Table/TableButton";
import DialogContext from "UI/DialogContext/DialogContext";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import Loader from "UI/Loader/Loader";

import UserContext from "Components/UserContext/UserContext";
import TagTableButton from "Components/TaggingControl/TagTableButton";
import TagsMap from "Components/TaggingControl/TagsMap";
import TagDialog from "Components/TaggingControl/TagDialog";

import TaggingAddControl from "Components/TaggingControl/TaggingAddControl";

import model from "Models/tagByLink";
import dataController from "Lib/dataController";

import { tagsService } from "Services/tagsService";

function TaggingControl(props) {
  const { isRequired, title, linkId, noMap, parentHasGeom, groupsDenied = [], groupsAllowed = [], mode, groupsFilterId = -1 } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [wktPoints, setWKTPoints] = useState(undefined);
  const [wktPolys, setWKTPolys] = useState(undefined);
  const [selectedTags, setSelectedTags] = useState([]);
  const [addMode, setAddMode] = useState(false);
  const [editingTagLocation, setEditingTagLocation] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { t } = useTranslation();
  const dc = new dataController(model);
  const hasError = false;

  useEffect(() => {
    setShowMap(!parentHasGeom);
  }, [parentHasGeom]);

  useEffect(() => {
    refreshData();
  }, [linkId]);

  const refreshData = () => {
    if (linkId) {
      setIsLoading(true);
      tagsService
        .getTags(linkId)
        .then((grouped_tags) => {
          const wktPoints = grouped_tags
            .map((g) => g.tags)
            .flat()
            .filter((t) => t.tag_xy && t.tag_xy !== "");
          const wktPolys = grouped_tags
            .map((g) => g.tags)
            .flat()
            .filter((t) => t.tag_poly && t.tag_poly !== "");
            //.map((x) => x.tag_xy);
          setTags(grouped_tags);
          setSelectedTags([]);
          setWKTPoints(wktPoints);
          setWKTPolys(wktPolys);
        })
        .finally(() => {
          setIsLoading(false);
        });
      if (linkId > 200000 && linkId < 300000) {
        userContext.getDataTags(linkId);
      }
    }
  };

  const handleSelectChip = (evt, tag) => {
    /*if (selectedTag && tag && selectedTag.id === tag.id) {
      setSelectedTag(null);
    } else {
      setSelectedTag(tag);
    }*/
    if (tag) {
      setSelectedTags((prevState) => {
        const index = prevState.findIndex((x) => x.id === tag.id);
        if (index > -1) {
          return prevState.filter((x, i) => i !== index);
        } else {
          return prevState.concat(tag);
        }
      });
    } else setSelectedTags([]);
    setEditingTagLocation(false);
  };

  const handleTagAddMode = (evt) => {
    setAddMode(true);
  };

  const handleTagAdd = (evt) => {
    dialogContext.showDialog(TagDialog, {
      model: dc,
      mode: "insert",
      form: "insert",
      onClose: handleCloseTagDialog
    });
  };

  const handleTagEdit = (evt) => {
    dialogContext.showDialog(TagDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: selectedTag.id,
      onClose: handleCloseTagDialog
    });
  };

  const handleTagsDelete = (evt) => {
    //alert("handleTagsDelete");
    if (linkId) {
      const ids = selectedTags.map((x) => x.id);
      tagsService.delTags(linkId, ids).then(() => {
        refreshData();
      });
    }
    setSelectedTags([]);
  };

  const handleCloseTagDialog = (evt, refresh) => {
    //alert("handleCloseTagDialog refresh="+refresh);
    if (refresh) refreshData();
    setAddMode(false);
  };

  const handleEditLocation = (evt) => {
    setEditingTagLocation(true);
  };

  const handleLocationChange = (wktPoint, wktPoly) => {
    if (selectedTags && selectedTags.length === 1) {
      const tag = selectedTags[0];
      if (tag) {
        tagsService.updTag(tag, wktPoint, wktPoly).then((result) => {
          if (result.success) {
            refreshData();
          }
        });
      }
    }

    setEditingTagLocation(false);
  };

  const handleLocationCancel = () => {
    setEditingTagLocation(false);
  };

  const renderSwitch = noMap && parentHasGeom;

  const showDelete = true;
  const showEdit = false;

  const selectedTagsLen = selectedTags.length;
  const oneSelected = selectedTagsLen === 1;
  const selectedTag = oneSelected ? selectedTags[0] : [];
  const selectedIds = selectedTags.map(t => t.id);

  const selected_wkts = selectedTags.filter((t) => t.tag_xy && t.tag_xy !== "" || t.tag_poly && t.tag_poly !== "").map((x) => x.tag_xy || x.tag_poly);
  const selectionCnt = selectedTags ? selectedTags.length : 0;
  const record_wktPoints = wktPoints ? wktPoints.filter(w => selectedIds.indexOf(w.id) === -1).map(x => x.tag_xy) : [];
  const record_wktPolys = wktPolys ? wktPolys.filter(w => selectedIds.indexOf(w.id) === -1).map(x => x.tag_poly) : [];
  const all_wkts = [...selected_wkts, ...record_wktPolys, ...record_wktPoints];
  const hasGeoms = editingTagLocation || all_wkts && all_wkts.length;

  return (
    <FormControl fullWidth={true} error={hasError} style={editingTagLocation ? {marginBottom: "150px"} : {}}>
      <FormLabel required={isRequired}>
        {title}
        {
          renderSwitch ?
          <Switch 
            checked={showMap}
            onChange={() => setShowMap(!showMap)}
          >
          </Switch>
          : null
        }
      </FormLabel>
      <GridContainer>
        <GridItem sm={12} md={noMap && !showMap || !hasGeoms ? 12 : 8}>
          <Table>
            <TableBody>
              {tags.map((group, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{group.label}</TableCell>
                    <TableCell>
                      {group.tags.map((tag, j) => {
                        const selected = selectedTags.findIndex((x) => x.id === tag.id) > -1;
                        const hasPoint = tag.tag_xy && tag.tag_xy !== "";
                        const hasPoly = tag.tag_poly && tag.tag_poly !== "";
                        return (
                          <Chip
                            disabled={addMode ? true : false}
                            color="primary"
                            variant={!selected ? "outlined" : "default"}
                            icon={hasPoint ? <LocationOnOutlinedIcon /> : hasPoly ? <ChangeHistoryIcon /> : undefined}
                            key={j}
                            label={tag.tag}
                            clickable={true}
                            onClick={(evt) => handleSelectChip(evt, tag)}
                          />
                        );
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Toolbar>
            {!showDelete ? null : (
              <TagTableButton
                disabled={addMode || selectedTagsLen < 1 ? true : false}
                variant={"outlined"}
                startIcon={<DeleteIcon />}
                onClick={handleTagsDelete}
              >
                {t("buttons.delete") + (selectedTagsLen < 1 ? "" : " " + selectedTagsLen)}
              </TagTableButton>
            )}

            {!showEdit ? null : (
              <TagTableButton
                variant="contained"
                startIcon={<EditIcon />}
                onClick={handleTagEdit}
                disabled={oneSelected ? false : true}
              >
                {t("buttons.edit")}
              </TagTableButton>
            )}

            <TagTableButton
              disabled={mode === "insert" || addMode || selectedTagsLen !== 0 ? true : false}
              variant={addMode || selectedTagsLen !== 0 ? "outlined" : "contained"}
              startIcon={<AddIcon />}
              onClick={handleTagAddMode}
            >
              {t("buttons.add")}
            </TagTableButton>
            {/*<ToolbarFillContent />*/}
            {
              noMap && !showMap ?
                null :
                <TagTableButton
                  variant={oneSelected && !editingTagLocation ? "contained" : "outlined"}
                  startIcon={<LocationOnOutlinedIcon />}
                  onClick={handleEditLocation}
                  disabled={!oneSelected || editingTagLocation}
                >
                  {t("buttons.edit_location")}
                </TagTableButton>
            }
          </Toolbar>
        </GridItem>
        {
          noMap && !showMap ? 
            null :
            hasGeoms ? (
              <GridItem sm={12} md={4}>
                <TagsMap
                  geomPoints={record_wktPoints}
                  geomPolys={record_wktPolys}
                  selectedGeoms={selected_wkts}
                  editingGeom={selectedTag ? selectedTag.tag_xy || selectedTag.tag_poly : undefined}
                  allGeoms={all_wkts}
                  canEdit={editingTagLocation}
                  onEditSubmit={handleLocationChange}
                  onEditCancel={handleLocationCancel}
                  selectionCnt={selectionCnt}
                />
              </GridItem>
            ) : null
        }
      </GridContainer>

      {!addMode ? null : <TaggingAddControl linkId={-1} add_linkId={linkId} onClose={handleCloseTagDialog} groupsDenied={groupsDenied} groupsAllowed={groupsAllowed} groupsFilterId={groupsFilterId}/>}
      <Loader open={isLoading} />
    </FormControl>
  );
}

export default TaggingControl;

/*
POLYGON ((457972 5078858, 459899 5078097, 459895 5079771, 457972 5078858))
*/