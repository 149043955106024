import green from "@material-ui/core/colors/lightGreen";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";

import OlStyle from "ol/style/Style";
import OlStroke from "ol/style/Stroke";
import OlCircle from "ol/style/Circle";
import OlFill from "ol/style/Fill";
import OlIcon from "ol/style/Icon";
import OlText from "ol/style/Text";

export { 
  recordStyle
, zahvatStyle
, selectedStyle
, drawStyle
, drawnAlarmStyle 
, tagsStyle
};

const recordStyle = (f) => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(26, 114, 158, 0.79)"
        //color: "rgba(255, 0, 0, 0.90)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "rgba(26, 114, 158, 0.79)",
      width: 3.25,
      radius: 5
    })
  });
  return style;
};

const zahvatStyle = (f) => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(26, 114, 158, 0.79)"
        //color: "rgba(255, 0, 0, 0.90)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#ff1f1f",
      width: 3.25,
      radius: 5
    })
  });
  return style;
};

const selectedStyle = (f) => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(255, 0, 0, 0.70)"
        //color: "rgba(183, 200, 0, 0.79)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#ff1f1f",
      width: 3.25,
      radius: 5
    })
  });
  return style;
};

const tagsStyle = new OlStyle({
  image: new OlCircle({
    radius: 5,
    stroke: new OlStroke({
      color: "rgba(255, 255, 255, 1)"
    }),
    fill: new OlFill({
      color: "rgba(200, 3, 3, 1)"
    })
  }),
  fill: new OlFill({
    color: "rgba(255,255,255,0.4)"
  }),
  stroke: new OlStroke({
    color: "#3399CC",
    width: 3.25,
    radius: 5
  })
});

const drawStyle = new OlStyle({
  image: new OlCircle({
    radius: 7,
    stroke: new OlStroke({
      color: "rgba(255, 255, 255, 0.79)"
    }),
    fill: new OlFill({
      color: "rgba(255, 0, 0, 0.90)"
    })
  }),
  fill: new OlFill({
    color: "rgba(255,255,255,0.2)"
  }),
  stroke: new OlStroke({
    color: "rgba(255, 0, 0, 0.90)",
    width: 3.25,
    radius: 5
  })
});

const drawnAlarmStyle = (f) => {
  let style = new OlStyle({
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#ff1f1f",
      width: 3.25,
      radius: 5
    })
  });
  return style;
};
