import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//Custom Components
import GridContainer from "UI/Grid/GridContainer";
import Form from "Components/Form";
import FormContent from "Components/FormContent";
import withFormAuthController from "Components/withFormAuthController";

import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogContext from "UI/DialogContext/DialogContext";

function PasswordChangeDialog(props) {
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const { t } = useTranslation();
  const { onFieldChange } = props; //HOC withFormAuthController
  const { doChangePassword } = props; //HOC withFormAuthController
  const { onClose } = props;
  const { record, validation, fields, form } = props; //HOC withFormAuthController

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleChange = (evt) => {
    if (doChangePassword) {
      doChangePassword().then((result) => {
        if (result.success) {
          close({ success: true });
        } else if (result.error) {
          snackbarContext.showNotification(result.error);
        } else if (result.validationPass === false) {
          console.log(result.validation);
        }
      });
    }
  };

  const handleClose = (evt) => {
    close({ success: false });
  };

  const close = (result) => {
    if (onClose) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  return (
    <DraggableDialog open={true} maxWidth={"xs"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.password_change")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={["password", "password_new", "password_new_confirm"]}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode="insert"
              columns={1}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        {form === "view" ? (
          <DialogActionButton variant="contained" onClick={handleClose} startIcon={<CloseIcon />}>
            {t("buttons.close")}
          </DialogActionButton>
        ) : (
          <div>
            <DialogActionButton variant="outlined" onClick={handleClose} startIcon={<CloseIcon />}>
              {t("buttons.cancel")}
            </DialogActionButton>
            <DialogActionButton variant="contained" onClick={handleChange} startIcon={<SaveIcon />}>
              {t("buttons.change")}
            </DialogActionButton>
          </div>
        )}
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormAuthController(PasswordChangeDialog);
