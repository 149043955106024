import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";

//Custom Components
import CardAvatar from "UI/Card/CardAvatar";
import { formats } from "Lib/formats";
import CardActionButtonClose from "UI/Card/CardActionButtonClose";
import DialogContext from "UI/DialogContext/DialogContext";
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: "350px"
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

function GSInfoCard(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { featureData, onClose } = props;

  const { id, naziv_zahvata, datum_studije, utjecaji_path, mjere_path, narucitelj } = featureData.feature.properties;

  const textVariant = "body2"

  return (
    <Fragment>
      <Card className={classes.root}>
        <CardHeader
          classes={{ root: classes.header }}
          title={`Zahvat (${t("common.id")}: ${id})`}
          avatar={<CardAvatar ariaLabel="info" icon={<i className={"fas fa-info "}></i>} />}
          action={<CardActionButtonClose onClick={onClose} />}
        />
        <CardContent>
          <GridContainer spacing={1}>
          <GridItem xs={12}>
              <Typography variant={textVariant} component="p">
                {t("common.narucitelj")}: {narucitelj}
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant={textVariant} component="p">
                {t("zahvati.naziv_zahvata")}: {naziv_zahvata}
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant={textVariant} component="p">
                {t("zahvati.datum_studije")}: {moment(datum_studije).format(formats.date)}
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant={textVariant} component="p">
                {t("zahvati.utjecaji_path")}:{" "}
                <a href={utjecaji_path} target="_blank">
                  <i className="fas fa-link"></i>
                </a>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Typography variant={textVariant} component="p">
                {t("zahvati.mjere_path")}:{" "}
                <a href={mjere_path} target="_blank">
                  <i className="fas fa-link"></i>
                </a>
              </Typography>
            </GridItem>
          </GridContainer>
        </CardContent>
      </Card>
    </Fragment>
  );
}

export default GSInfoCard;
