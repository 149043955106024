import api from "Lib/api";
import { authHeader } from 'Services/helpers/authHeader'
import { standardHeadersWithAuth } from 'Services/helpers/standardHeadersWithAuth';
import { handleResponse } from 'Services/helpers/handleResponse';

export const userService = {
  whoAmI,
  changePassword,
  logout,
  setUserSettings,
  setPassword
};

const userApiUrl = process.env.REACT_APP_APIPATH + 'users/';

function changePassword(data) {

  const requestOptions = {
    method: 'PUT',
    headers: standardHeadersWithAuth(),
    body: JSON.stringify({
      'password': data.password,
      'passwordNew': data.password_new
    }),
  };

  const url = userApiUrl + 'password-change';

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function whoAmI(){
  const requestOptions = {
    method: 'GET',
    headers: standardHeadersWithAuth()
  };

  const url = userApiUrl + 'who-am-i';

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    })
    .catch(error => {
      return error;

    })
}

function logout() {
  const requestOptions = {
    method: 'PUT',
    headers: standardHeadersWithAuth()
  };

  const url = userApiUrl + 'logout';

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(data => {
      localStorage.removeItem('auth_token');
      return data;
    });
}

function setUserSettings(data) {
  //TODO: implement call on backend
  return Promise.resolve({success: true});
  // const apiInstance = new api();
  // return apiInstance.Call("user-settings/", "patch", { user_settings: data});
}

function setPassword(data) {
  const apiInstance = new api();
  return apiInstance.Call("users/" + data.userid + "/set-password", "patch", { password_new: data.password_new });
}