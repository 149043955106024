import React, { useCallback } from "react";
import ButtonControl from "../OpenLayersExt/ButtonControl";

export default function GeoLocateControl(props) {
  const { tooltip, handleClick } = props;

  const handleGeoLocate = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        handleClick({
          success: true,
          timestamp: pos.timestamp,
          coords:pos.coords
        });
      });
    } else {
      handleClick({
        success: false
      });
    }
  }, [props.handleClick]);

  return (
    <ButtonControl
      className="ol-geolocate"
      title={tooltip}
      html='<i class="fas fa-bullseye"></i>'
      handleClick={handleGeoLocate}
    />
  );
}
