import React, { Fragment} from 'react'
import PropTypes from 'prop-types';

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import Menu from '@material-ui/core/Menu';
import Popper from '@material-ui/core/Popper';
import Icon from "@material-ui/core/Icon";
import Typography from '@material-ui/core/Typography';

//Material-UI Icons
import MenuIcon from '@material-ui/icons/Menu';

//Custom Components
import HeaderMenuItem from 'UI/Header/HeaderMenuItem';


const customStyles =  makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    color: theme.palette.common.white
  }
}));


function HeaderMenuButton(props) {

  const [ anchorEl, setAnchorEl] = React.useState(null);
  const classes = customStyles();

  const handleClick = evt => {
    setAnchorEl(evt.currentTarget);
  }

  const handleClose = (evt, reason) => {
    setAnchorEl(null);
  }

  const hookAction = action => {
    setAnchorEl(null);
    action();
  }

  const buildMenuItem = (node, index) => {
    const { onClick, label, icon } = node.props;
    return <HeaderMenuItem onClick={() => hookAction(onClick)} label={label} icon={icon} key={index}/>;
  }

  let menuItems = [];

  if (Array.isArray(props.children)) {
    props.children[1].forEach((child,i) => {
      if (child !== null) {
        menuItems.push(buildMenuItem(child, i));
      }
    })
  } else {
    if (props.children !== null) {
      menuItems.push(buildMenuItem(props.children, 1));
    }
  }

  return (
    <div onMouseLeave={handleClose}>
      <div onMouseOver={handleClick}>
        {props.children[0]}
      </div>
      {anchorEl ?
        <Popper
          id="dialogt-toolbar-menu"
          anchorEl={anchorEl}
          onMouseLeave={handleClose}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={classes}
          placement="bottom-start"
          style={{
            zIndex: 99999
          }}
        >
         {menuItems}
        </Popper>
        : null
      }
    </div>
  );
}

HeaderMenuButton.propTypes = {
  icon: PropTypes.string
}

export default withTheme(HeaderMenuButton);