import Controls from "./Controls";
import DefaultControls from "./DefaultControls";

//OpenLayers Control Wrappers
import AttributionControl from "./OpenLayers/AttributionControl";
import FullScreenControl from "./OpenLayers/FullScreenControl";
import MousePositionControl from "./OpenLayers/MousePositionControl";
import OverviewMapControl from "./OpenLayers/OverviewMapControl";
import RotateControl from "./OpenLayers/RotateControl";
import ScaleLineControl from "./OpenLayers/ScaleLineControl";
import ZoomSliderControl from "./OpenLayers/ZoomSliderControl";
import ZoomToExtentControl from "./OpenLayers/ZoomToExtentControl";
import ZoomControl from "./OpenLayers/ZoomControl";

//OpenLayers-Ext Control Wrappers
import LayerSwitcherImageControl from "./OpenLayersExt/LayerSwitcherImageControl";
// import LayerSwitcherControl from "./OpenLayersExt/LayerSwitcherControl";
import ScaleControl from "./OpenLayersExt/ScaleControl";
import ButtonControl from "./OpenLayersExt/ButtonControl";
import BarControl from "./OpenLayersExt/Bar/BarControl";
import SearchControl from "./OpenLayersExt/SearchControl";

//Sidebar
import SidebarControl from "./Sidebar/SidebarControl";

//Custom Controls
import GeoLocateControl from "./Custom/GeoLocateControl";
import LayerSwitcher from "./Custom/LayerSwitcher/LayerSwitcher";
import MeasureControls from "./Custom/MeasureControls";
import DownloadShapeControl from "./Custom/DownloadShapeControl";
import ViewHistoryControl from "./Custom/ViewHistoryControl";
import ScaleRatioControl from "./ScaleRatio/ScaleRatioControl";

export {
  Controls,
  DefaultControls,

  AttributionControl,
  FullScreenControl,
  MousePositionControl,
  OverviewMapControl,
  RotateControl,
  ScaleLineControl,
  ZoomSliderControl,
  ZoomToExtentControl,
  ZoomControl,

  LayerSwitcherImageControl,
  ScaleControl,
  ScaleRatioControl,
  ButtonControl,
  BarControl,
  SearchControl,

  SidebarControl,

  GeoLocateControl,
  LayerSwitcher,
  MeasureControls,
  DownloadShapeControl,
  ViewHistoryControl
};
