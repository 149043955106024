import React, { useContext, useEffect, useRef } from "react";
import { SidebarControlContext } from "./SidebarControlContext";
import Sidebar from "./ol5-sidebar";

export default function SidebarPane(props) {
  const context = useContext(SidebarControlContext);
  const paneRef = useRef(null);

  const { id } = props;

  const element = document.createElement("div");
  element.className = "sidebar-pane";
  element.id = id;
  paneRef.current = element;

  useEffect(() => {
    if (context.container) {
      context.container.appendChild(element);
    }
  }, []);

  // return (
  //   <SidebarControlContext.Provider
  //     value={{
  //       sidebar: context.sidebar,
  //       container: context.container,
  //       pane: paneRef.current,
  //     }}
  //   >
  //     {props.children}
  //   </SidebarControlContext.Provider>
  // );

  return (
    <div className="sidebar-pane" id={id}>
      {props.children}
    </div>
  )
}
