import React from "react";
import { useTranslation } from "react-i18next";

import AppPage from "UI/AppPage/AppPage";
import DjelatniciTable from "Views/DjelatniciPage/DjelatniciTable";

import Box from "@material-ui/core/Box";

function DjelatniciPage(props) {
  const { t } = useTranslation();

  return (
    <AppPage>
      <Box m={0} style={{height: "100%"}}>
        <DjelatniciTable />
      </Box>
    </AppPage>
  );
}

export default DjelatniciPage;
