import { handleResponse } from "Services/helpers/handleResponse";
import { standardHeadersWithAuth } from 'Services/helpers/standardHeadersWithAuth';
import { Role } from "Lib/role";

// The authentication service is used to login and logout of the application
export const authService = {
  register,
  check,
  activate,
  login,
  logout,
  resetPasswordRequest,
  resetPassword,
  whoAmI,
  changePassword
};

const authApiUrl = process.env.REACT_APP_APIPATH + "auth/";

function register(userData) {
  const tip = userData.tip.value ? userData.tip.value : userData.tip;

  let bodyData = {
    tip: tip,
    ime: userData.ime,
    prezime: userData.prezime,
    email: userData.email,
    oib: userData.oib,
    telefon: userData.telefon,
    adresa: userData.adresa
  };

  if (tip === "P") {
    bodyData["p_naziv"] = userData.p_naziv;
    bodyData["p_odgovornaosoba"] = userData.p_odgovornaosoba;
    bodyData["p_opisdjelatnosti"] = userData.p_opisdjelatnosti;
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify(bodyData),
  };

  const url = authApiUrl + "register";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function check(token) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };

  const url = authApiUrl + "activate-check/" + token;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function activate(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      email: data.email,
      activationToken: data.activation_token,
      password: data.password,
      passwordNew: data.password_new
    }),
  };

  const url = authApiUrl + "activate";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.auth_token) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        localStorage.setItem("auth_token", data.access_token);
      }
      return data;
    });
}

function resetPasswordRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      email: data.email
    }),
  };

  const url = authApiUrl + "password-reset-request";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function resetPassword(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      email: data.email,
      passwordreset_token: data.passwordreset_token,
      password: data.password_new
    }),
  };

  const url = authApiUrl + "password-reset";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function wait(ms){
  return new Promise(resolve => setTimeout(resolve, ms));
}

function dummyLoginDelay() {
  return wait(1000).then(() =>{
    return dummyLogin();
  })
}

function changePassword(data) {

  const requestOptions = {
    method: 'PUT',
    headers: standardHeadersWithAuth(),
    body: JSON.stringify({
      'password': data.password,
      'password_new': data.password_new
    }),
  };

  const url = authApiUrl + 'password-change';

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function dummyLogin(username,passowrd) {
  localStorage.setItem("auth_token", "123");
  //return Promise.resolve({});
  return {
    code: 0,
    access_token: "123",
    token_type: "bearer",
    username: "vanja",
    data: {
      username: "vanja",
      email: "vanja@prehnit.hr",
      firs_name: "Vanja",
      last_name: "Suhina",
      role_codes: [90],
    }};
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' },
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      username: username,
      password: password
    }),
  };

  const url = authApiUrl + "login";

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      console.log(data);
      //login successful if there's a user in the response
      if (data.access_token) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        localStorage.setItem("auth_token", data.access_token);
      }
      return data;
    });
}

function logout() {
  localStorage.removeItem("auth_token");
}

function whoAmI(){
  const requestOptions = {
    method: 'GET',
    headers: standardHeadersWithAuth()
  };

  const url = authApiUrl + 'who-am-i';

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    })
    .catch(error => {
      return error;
    })
}
