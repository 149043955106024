import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";

import AppPage from "UI/AppPage/AppPage";
import MainMap from "Views/MapPage/MainMap";

function MapPage(props) {
  const { t } = useTranslation();

  return (
    <AppPage noPadding>
      <MainMap />
    </AppPage>
  );
}

export default MapPage;
