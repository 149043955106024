import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import UslugaTimDialog from "Views/UslugeTimPage/UslugaTimDialog";

import model from "Models/usluge_tim";
import dataController from "Lib/dataController";

function UslugeTimTable(props) {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();
  const { level = 1, ugovorId } = props;

  const [records, setRecords ] = useState([]);
  const [tableControls, setTableControls] = useState(false);
  const [notAuth, setNotAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }

  const handleAdd = (evt) => {
    dialogContext.showDialog(UslugaTimDialog, {
      model: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose,
      level: level,
      submodelDefaultValues: {
        ugovor_id: ugovorId
      }
    }, level);
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;

    dialogContext.showDialog(UslugaTimDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose,
      level: level,
      submodelDefaultValues: {
        ugovor_id: ugovorId
      }
    }, level);
  };

  const handleTableEdit = (recordId) => {
    dialogContext.showDialog(UslugaTimDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose,
      level: level,
      submodelDefaultValues: {
        ugovor_id: ugovorId
      }
    }, level);
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    // dummyRecords();
    setLoading(true);
    const customPath = "usluge-tim/ug/" + ugovorId;
    dc.GetData(customPath).then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      } else {
        const { error } = resp;
        if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
          setNotAuth(true);
        } 
      }
      setLoading(false);
    });
  };

  return (
    <ModelTable records={records || []} dc={dc} 
      allowSelection="one" 
      allowFilter={true} 
      allowExport={true} 
      disableControls={!tableControls} 
      inlineEdit={true} 
      onEdit={handleTableEdit} 
      initialPageSize={100} 
      notAuth={notAuth}
      isLoading={loading}
      onAdd={handleAdd}
      title="titles.usluga_tim"
      addGender="m"
    />
  );
}

export default UslugeTimTable;
