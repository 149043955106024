import { useContext, useEffect, useRef } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import LayerSwitcherImage from "ol-ext/control/LayerSwitcherImage";
import "ol-ext/control/LayerSwitcherImage.css";

export default function LayerSwitcherImageControl(props) {
  const context = useContext(MapContext);

  const options = {
    displayInLayerSwitcher: undefined,
    show_progress: undefined,
    mouseover: undefined,
    reordering: undefined,
    trash: undefined,
    extent: undefined,
    drawDelay: undefined,
    collapsed: undefined,
    layerGroup: undefined
  };

  const events = {
    info: undefined,
    extent: undefined
  };

  const layerGroupRevision = props.layerGroup ? props.layerGroup.getRevision() : undefined;
  const layerGroupCount = (props.layerGroup && props.layerGroup.getLayers()) ? props.layerGroup.getLayers().getLength() : 0;

  // //debug
  // const usePrevious = (value, initialValue) => {
  //   const ref = useRef(initialValue);
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // };


  // const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  //   const previousDeps = usePrevious(dependencies, []);

  //   const changedDeps = dependencies.reduce((accum, dependency, index) => {
  //     if (dependency !== previousDeps[index]) {
  //       const keyName = dependencyNames[index] || index;
  //       return {
  //         ...accum,
  //         [keyName]: {
  //           before: previousDeps[index],
  //           after: dependency
  //         }
  //       };
  //     }

  //     return accum;
  //   }, {});

  //   if (Object.keys(changedDeps).length) {
  //     console.log('[use-effect-debugger] ', changedDeps);
  //   }

  //   useEffect(effectHook, dependencies);
  // };
  // //end debug

  useEffect(() => {
    // console.log('LayerSwitcher effect called');
    return registerOlControl(context, LayerSwitcherImage, props, options, events)
  }, [
    props.displayInLayerSwitcher,
    props.show_progress,
    props.mouseover,
    props.reordering,
    props.trash,
    props.extent,
    props.drawDelay,
    props.collapsed,
    props.className,
    //props.layerGroup,
    //layerGroupRevision,
    layerGroupCount
  ]);

  return null;
}
