import React, { useContext, useEffect} from "react";
import OlLayerGroup from "ol/layer/Group";
import OlLayerImage from "ol/layer/Image";

import OlSourceOSM from "ol/source/OSM";
import OlSourceStamen from "ol/source/Stamen";
import OlSourceImageWMS from 'ol/source/ImageWMS';

import Util from '../Util';
import { MapContext } from "../MapContext";
import { GroupLayerContext } from "./GroupLayer/GroupLayerContext";

const idKey = "id";
const gsLayerKey = "layer";
const titleKey = "title";
const typeKey = "type";
const queryKey = "query";

export default function ImageLayer(props) {

  const mapContext = useContext(MapContext);
  const parentLayerContext = useContext(GroupLayerContext);

  const { id, title, visible, query, gsLayer } = props;

  let layer = undefined;

  const options = {
    className: undefined,
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    minZoom: undefined,
    maxZoom: undefined,
    map: undefined,
    source: undefined
  };

  const wmsOptions = {
    attributions: undefined,
    cacheSize: undefined,
    crossOrigin: undefined,
    params: undefined,
    gutter: undefined,
    hidpi: undefined,
    projection: undefined,
    reprojectionErrorThreshold: undefined,
    tileClass: undefined,
    tileGrid: undefined,
    serverType: undefined,
    tileLoadFunction: undefined,
    url: undefined,
    urls: undefined,
    wrapX: undefined,
    transition: 0
  }

  const events = {
    'change': undefined,
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    'error': undefined,
    'postrender': undefined,
    'prerender': undefined,
    'propertychange': undefined
  };

  const findLayer = (id) => {
    let layer = null;
    if (mapContext.map) {
      const mapRootLayers = mapContext.map.getLayers().getArray();
      const mapLayer = mapRootLayers.find(x => x instanceof OlLayerImage && x.get(idKey) === id);
      if (mapLayer) {
        return mapLayer;
      }

      mapRootLayers.filter( x => x instanceof OlLayerGroup).forEach(groupLayer => {
        const mapLayer = groupLayer.getLayers().getArray().find(x => x instanceof OlLayerImage && x.get(idKey) === id);
        if (mapLayer) {
          layer = mapLayer;
        }
      });
    }

    return layer;
  }

  const getSource = () => {
    if (props.source) {
      return props.source;
    }
    else if (props.wms) {
      let allWMSOptions = Object.assign(wmsOptions, props.wms);
      let definedWMSOptions = Util.getDefinedOptions(allWMSOptions);

      return new OlSourceImageWMS(definedWMSOptions);
    }
    else if (props.stamen) {
      return new OlSourceStamen({
        layer: props.stamen
      })
    } else {
      return new OlSourceOSM();
    }
  }

  // console.log('render layer', layer, props);

  useEffect(() => {
    //console.log('tile layer effect called');
    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    definedOptions.source = definedOptions.source || getSource();

    layer = new OlLayerImage(definedOptions);
    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if (props.type) {
      layer.set(typeKey, props.type);
    }
    if(props.zIndex){
      layer.setZIndex(props.zIndex);
    }
    if (props.query) {
      layer.set(queryKey, props.query)
    }
    // if (props.gsLayer) {
    //   layer.set(gsLayerKey, props.gsLayer);
    // }

    if (parentLayerContext && parentLayerContext.exists) {
      parentLayerContext.childLayers.push(layer);

    } else if (mapContext.map) {
      // const mapLayers = mapContext.map.getLayers();
      // mapLayers.getArray().find(x => x instanceof OlLayerImage && x.get(idKey) === props.id);
      const mapLayer = findLayer(props.id);

      if (mapLayer) {
        // context.updateMap({type: "removeLayer", layer: mapLayer});
        //console.log('remove layer', mapLayer);
        mapContext.map.removeLayer(mapLayer);
      }
      // context.updateMap({type: "addLayer", layer: layer});
      //console.log('add layer', layer);
      mapContext.map.addLayer(layer);

    } else {
      mapContext.initOptions.layers.push(layer)
    }

    let olEvents = Util.getEvents(events, props);
    for(let eventName in olEvents) {
      layer.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (mapContext.map) {
        const mapLayer = findLayer(props.id);
        if (mapLayer) {
          //console.log('unmounting ImageLayer, removing mapLayer', props.id, mapLayer)
          mapContext.map.removeLayer(mapLayer);
        }
      }
    }

    // console.log('layer Tile mounted', layer);
  }, []);

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(props.id);
      if (mapLayer) {
        mapLayer.set(titleKey, title);
      }
    }
  }, [title])

  useEffect(() => {
    //console.log('eff change visible', id, visible);
    if (mapContext.map) {

    }
  }, [visible])

  return(null);
}
