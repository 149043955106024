import React from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Core Components
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from '@material-ui/core/Typography';

export default function TableRowNotAuthorized(props) {
	const { cols } = props;
	const { t } = useTranslation();

	return (
		<TableRow>
			<TableCell colSpan={cols} align="center">
				<Typography color="error" variant="h6">
					{t("messages.not_authorized")}
				</Typography>
			</TableCell>
		</TableRow>
	);

}