import React from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 150,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper
  }
}));

function SchedulerDrawer(props) {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
}

SchedulerDrawer.propTypes = {
  style: PropTypes.object
};

export default SchedulerDrawer;
