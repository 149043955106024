import React, { Fragment } from 'react';

//Material-UI Core Components
import withStyles from "@material-ui/core/styles/withStyles";

//Custom Components
import PublicHeader from 'UI/Header/PublicHeader';
import Footer from 'UI/Footer/Footer';

const style = (theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "48px !important",
    marginTop: "20px"
  },
  content: {
    flexGrow: 1,
    //padding: theme.spacing(3),
  },
});


class PublicPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <PublicHeader />
        <main id="content-wrapper" className={classes.content}>
          <div className={classes.toolbar}/>
          <div id="content">
            {this.props.children}
          </div>
        </main>
      </Fragment>
    );
  }
}

export default withStyles(style)(PublicPage);