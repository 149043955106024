import React from 'react'

//Custom Components
import PickerControl from 'Controls/PickerControl';
import TextControl from 'Controls/TextControl';
import WholeNumberControl from 'Controls/WholeNumberControl';
import FloatNumberControl from 'Controls/FloatNumberControl';
import DateTimeControl from 'Controls/DateTimeControl';
import BoolControl from 'Controls/BoolControl';
import CurrencyControl from 'Controls/CurrencyControl';
import RichTextControl from 'Controls/RichTextControl';
import CheckboxControl from 'Controls/CheckboxControl';
import RadioControl from 'Controls/RadioControl';
import FileControl from 'Controls/FileControl';
import LinkControl from "Controls/LinkControl";

let formGenerator = function () {

}

formGenerator.prototype.generateFormControls = function(fields, formMode, controlMode, record, validation, onChangeHandler, subModels, showDialog) {
  let rec = {}
  if (fields) {
    fields.forEach(field => {
      if (field) {
        const defaultValue = record && field && field.source && record.hasOwnProperty(field.source) ? record[field.source] : null;
        const fieldValidation = validation && validation.hasOwnProperty(field.source) ? validation[field.source] : null;
        const control = this.generateFieldControl(field, formMode, controlMode, defaultValue, fieldValidation, onChangeHandler, subModels, showDialog);

        rec[field.source] = control;
      }
    });
  }
  return rec;
}

formGenerator.prototype.generateFieldControl = function(field, formMode, controlMode, value, validation, onChangeHandler, subModels, showDialog) {

  let model = null;
  if (field && field.subModel && subModels) {
    model = subModels[field.source];
  }

  switch (field.type) {
    case ('numeric'):
      return <FloatNumberControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('wholenum'):
      return <WholeNumberControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('date'):
      return <DateTimeControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} showTime={false} showTimeLeft={this.should_show_timeleft(field)} />
    case ('datetime'):
      return <DateTimeControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} showTimeLeft={this.should_show_timeleft(field)} />
    case ('pickergroup'):
      return <PickerControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} isLoading={subModels ? false : true} model={model} onChange={onChangeHandler} />
    case ('picker'):
      return <PickerControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} isLoading={subModels ? false : true} model={model} onChange={onChangeHandler} />
    case ('multiline'):
      return <TextControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} multiline={true} rows={field.rows || 1} rowsMax={field.rowsMax} onChange={onChangeHandler} />
    case 'currency':
        return <CurrencyControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('boolean'):
      return <BoolControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('password'):
      return <TextControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} type="password"/>
    case ('richtext'):
      return <RichTextControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />;
    case ('checkbox'):
      return <CheckboxControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} dc={model}/>;
    case ('radio'):
      return <RadioControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} model={model}/>
    case ('images'):
    //case ('cmsimages'):
      return <FileControl accept={'image/jpeg,image/png'} field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('dokumenti'):
      return <FileControl accept={'*'} field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ("link"):
    case ("mail"):
      return <LinkControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} linkType={field.type}/>
    default:
      return <TextControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
  }
}

formGenerator.prototype.should_show_timeleft = function(attr) {
  return attr.flags ? attr.flags.indexOf('showTimeLeft') > -1 : false;
}

export default formGenerator;