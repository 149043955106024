import React, { useContext } from 'react';
import PropTypes from "prop-types";

//Custom Components 
import UserContext from "Components/UserContext/UserContext";

//Material-UI Core components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';

const customTabStyles = makeStyles(theme => ({
  root: {
    minWidth: 100,
    paddingLeft: 8,
    paddingRight: 8
  }}));

const errorTabStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.error.dark,
    backgroundColor: "#FFF",
    minWidth: 100,
    paddingLeft: 8,
    paddingRight: 8
  }}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function DialogTab(props) {
  const { value, label, icon, fields, validation, needRole, ...rest } = props;

  let hasError = false;
  if (fields && validation && fields.find(x => validation.hasOwnProperty(x) && validation[x].valid === false)) {
    hasError = true;
  }

  const userContext = useContext(UserContext);

  return(
    needRole && !userContext.hasAnyRole(needRole) ? 
    <Tab style={{minWidth: 0, padding: 0}}/> :
    <Tab value={value} classes={hasError ? errorTabStyles() : customTabStyles()} label={label} icon={icon} {...a11yProps(value)} {...rest} />
  )
}

DialogTab.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  icon: PropTypes.node,
  fields: PropTypes.array,
  validation: PropTypes.object
}

export default DialogTab;