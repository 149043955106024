import MapPage from "Views/MapPage/MapPage";
import HomePage from "Views/HomePage/HomePage";
import DashboardPage from "Views/HomePage/DashboardPage";
import UsersPage from "Views/UsersPage/UsersPage";
import ProfilePage from "Views/ProfilePage/ProfilePage";
import PonudePage from "Views/PonudePage/PonudePage";
import TagsPage from "Views/TagsPage/TagsPage";
import DjelatniciPage from "Views/DjelatniciPage/DjelatniciPage";
import KlijentiPage from "Views/KlijentiPage/KlijentiPage";
import UgovoriPage from "Views/UgovoriPage/UgovoriPage";
import ZahvatiPage from "Views/ZahvatiPage/ZahvatiPage";
import VanjskiZahvatiPage from "Views/VanjskiZahvatiPage/VanjskiZahvatiPage";
import CalendarPage from "Views/CalendarPage/CalendarPage";
import OpremaPage from "Views/OpremaPage/OpremaPage";
import VanjskiCVPage from "Views/VanjskiCVPage/VanjskiCVPage";

const privateRoutes = [
  { path: "/users", name: "Users", component: UsersPage },
  { path: "/djelatnici", name: "Djelatnici", component: DjelatniciPage },
  { path: "/vanjski-cv", name: "Vanjski CV", component: VanjskiCVPage },
  { path: "/oprema", name: "Oprema", component: OpremaPage },
  { path: "/klijenti", name: "Klijenti", component: KlijentiPage },
  { path: "/map", name: "Map", component: MapPage },
  { path: "/profile", name: "Profile", component: ProfilePage },
  { path: "/ponude", name: "Ponude", component: PonudePage },
  { path: "/tags", name: "Tags", component: TagsPage },
  { path: "/ugovori", name: "Ugovori", component: UgovoriPage },
  // { path: "/zahvati", name: "Zahvati", component: ZahvatiPage },
  { path: "/vanjski-zahvati", name: "Vanjski zahvati", component: VanjskiZahvatiPage },
  { path: "/dashboard", name: "Dashboard", component: DashboardPage },
  { path: "/kalendar", name: "Kalendar", component: CalendarPage },
  { path: "/", name: "Home", component: HomePage }
];

export default privateRoutes
