import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { Modify } from "ol/interaction";

export default function ModifyInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    condition: undefined,
    deleteCondition: undefined,
    insertVertexCondition: undefined,
    pixelTolerance: undefined,
    style: undefined,
    source: undefined,
    features: undefined,
    wrapX: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    modifyend: undefined,
    modifystart: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Modify, props, options, events),
    []
  );

  return null;
}
