import React from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
//  https://react-dropzone-uploader.js.org/docs/props
import { withTranslation } from 'react-i18next';
import FileSaver from 'file-saver';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faFilePdf, faFileWord, faFile } from '@fortawesome/free-solid-svg-icons';
import api from "Lib/api";
import arrayBufferToBase64 from "Lib/arrayBufferToBase64";

// Materil UI Icons
import CloseIcon from '@material-ui/icons/Close';

// Material UI Core
import withTheme from '@material-ui/core/styles/withTheme';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';

const customStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  fontFamily: "Helvetica, sans-serif",
  fontSize: "20px",
  fontWeight: 600,
  cursor: "pointer",
  backgroundColor: '#FFF',
  margin: 0
}

class FileControl extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      fileIds: [],
      fileNumber : 0,
      firstEntry: false,
      hovered: false,
      activeImage: null,
      fileValidation: null
    }

    this.api = new api();

    this.getLabel = this.getLabel.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleOpenFile = this.handleOpenFile.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.deactivateImage = this.deactivateImage.bind(this);
  }

  componentDidMount() {
    const { value } = this.props;
    const fileIds = [];
    let firstEntry = false;
    if (Array.isArray(value)) {
      value.forEach(val => {
        if (val.uuid) {
          fileIds.push(val.uuid);
        }
      })
      firstEntry = true;
    } else if (value && value.uuid) {
      fileIds.push(value.uuid);
      firstEntry = true;
    }

    this.setState({
      fileIds: fileIds,
      firstEntry: firstEntry
    })
  }

  componentDidUpdate(prevProps) {
    const { firstEntry, fileIds } = this.state;
    const { value } = this.props;

    if (prevProps.value !== value && !firstEntry) {
      if (Array.isArray(value)) {
        value.forEach(val => {
          if (val.uuid) {
            fileIds.push( val.uuid )
          }
          this.setState({
            fileIds: fileIds,
            firstEntry: true
          })
        })
      } else if (value && typeof value === 'object' && value.hasOwnProperty('uuid')) {
        fileIds.push( value.uuid );
        this.setState({
          fileIds: fileIds,
          firstEntry: true
        })
      }
    }

    return null
  }

  handleOpenFile(file) {
    console.log('handle open file', file);
    const { field } = this.props;

    if (field.type === 'images' || field.type === 'cmsimages') {
      if (file.hasOwnProperty('image')) {
        this.setState({
          activeImage: file
        });
      } else if (file.hasOwnProperty('uuid')) {

        this.api.Download(field.type, file.uuid).then(resp => {
          resp.arrayBuffer().then(data => {

            const imageB64 = arrayBufferToBase64(data);
            const imageObj = Object.assign({}, file);
            Object.assign(imageObj, {image : imageB64});

            this.setState({
              activeImage: imageObj
            });
          })
        })
      }
    } else if(file.type.indexOf('image') >= 0 && file.hasOwnProperty('uuid')) {
      this.api.Download(field.type, file.uuid).then(resp => {
        resp.arrayBuffer().then(data => {

          const imageB64 = arrayBufferToBase64(data);
          const imageObj = Object.assign({}, file);
          Object.assign(imageObj, {image : imageB64});

          this.setState({
            activeImage: imageObj
          });
        })
      })
    } else {
      if (file.uuid) {
        const path = field.cms ? "cms/dokumenti" : "dokumenti";
        this.api.Download(path, file.uuid).then(resp => {

          resp.blob().then(data => {
            const fileBlob = new Blob([data], { type: file.type }, file.name);
            FileSaver.saveAs(fileBlob, file.name);
          });
        });
      }
    }
  }

  handleRemoveFile(file) {
    const { onChange, field, value } = this.props;
    const { fileIds, fileNumber, activeImage } = this.state;

    const fileId = file.uuid || file.id;
    console.log('handle remove file', fileId);
    const pos = fileIds.indexOf(fileId);
    const fileList = value ? value.filter(val => (val.uuid || val.id) !== fileId) : [];

    if (pos >= 0) {
      fileIds.splice(pos, 1);
      this.setState({
        fileIds: fileIds
      })
    }

    if (activeImage && fileId === (activeImage.id || activeImage.uuid)) {
      this.setState({
        activeImage: null
      })
    }

    if (onChange) {
      onChange(fileList, field.source);
    }
  }

  handleChangeStatus({meta}, status, files){
    const { onChange, field, value, t } = this.props;
    const { fileIds, fileNumber, firstEntry } = this.state; // array of Ids if the files that were added to dropbox
    const pos = fileIds.indexOf( meta.id ); // position of the id in the fileIds array
    let fileList = [];

/*
** if status is 'removed' id is spliced from the array
** this has to be done because ( not sure about this part ) of the asinchronism of the removal of the file from the dropbox
** if you remove the file from the dropbox it will not be removed from the files array from some reason
**
** the idea is that it is faster to search array of 15-ish long strings than the array of 24kB big b64 strings
*/
    if (status === 'error_file_size') {
      files.pop();
      this.setState({
        fileValidation: t('validation.file_too_big')
      })
    } else if(status === 'rejected_file_type') {
      files.pop();
      this.setState({
        fileValidation: t('validation.rejected_file_type')
      })
    } else {
      this.setState({
        fileValidation: null
      })
    }

    if (status === 'done' && !firstEntry) {
      this.setState({
        firstEntry: true
      })
    }

    if (pos >= 0 && status == 'removed'){
      fileIds.splice(pos, 1);
      this.setState({
        fileIds: fileIds,
        fileNumber: fileNumber - 1
      })
    } else if (status === 'done'){
      fileIds.push(meta.id);
      this.setState({
        fileIds: fileIds,
        fileNumber: fileNumber + 1
      })
    }

    // if more files is uploaded at once
    const diff = fileIds.length - (Array.isArray(value) ? value.length : 0);

    // if status is removed then the id of the image will not be in fileIds and this piece of code ensures it is not in the fileList
    if (Array.isArray(value)) {
      fileList = value.filter(val => fileIds.indexOf( (val.id || val.uuid) ) >= 0 )
    } else if (status === 'removed') {
      fileList = [];
    }

    // if status is done the last item in files is the latest image added
    if (status === 'done' && files.length === (fileNumber + 1)) {
      // files.pop() would be better but then you remove it from the dropbox
      const newImages = [];
      for (let i = 1; i <= diff; i++) {
        newImages.push(files[ files.length - i ]);
      }

      const filePromise = newImages.map(newImage => new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => {
          const newFileB64 = arrayBufferToBase64(reader.result);
          const fileObj = {
            content: newFileB64,
            name:  newImage.meta.name,
            size:  newImage.meta.size,
            type:  newImage.meta.type,
            id:    newImage.meta.id
          };
          fileList.push(fileObj);
        }
        reader.onerror = (e) => {
          console.log("ERROR ===>");
          console.error(e);
        }
        reader.onloadend = () => {resolve()}
        reader.readAsArrayBuffer(newImage.file);
      }));

      Promise.all(filePromise).then(() => {
        if (onChange) {
          onChange(fileList, field.source);
        }
      })
    } else if (status === 'removed') {
      // if (onChange) {
      //   onChange(fileList, value)
      // }
    }
  }

  handleHover(hovered) {
    this.setState({
      hovered: hovered
    });
  }

  deactivateImage(){
    this.setState({
      activeImage: null
    })
  }

  handleSubmit(files, allFiles){

    console.log(files.map(f => f.meta))
    // allFiles.forEach(f => f.remove())
  }

  getLabel() {
    const { field, label, t } = this.props;

    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  }

  render(){
    // accept is a string with allowed input MIME types (comma separated)
    const { accept, field, controlMode, value, validation, t, theme } = this.props;
    const { fileNumber, activeImage, hovered, fileValidation } = this.state;

    const isReadOnly = controlMode === "view" || (field && field.readonly)
    const isRequired = field.validation && field.validation.required;
    const hasError = validation && validation.valid === false;
    const hasValue = value && Array.isArray(value) ? true : false;

    const hasMaxFiles = field.hasOwnProperty('maxFiles') ? true : false;
    const maxFiles = hasMaxFiles ? field.maxFiles : 3
    const valueLength = hasValue ? value.length : 0;
    const maxFilesCalculated =  fileNumber + maxFiles - valueLength;

    const label = this.getLabel();
    const newStyle = Object.assign({color: theme.palette.primary.dark}, customStyle);

    const valueArrays = Array.isArray(value) && value.length > 5 ? [value.slice(0, 5), value.slice(5)] : [value]
    return (
      <FormControl margin="none" fullWidth required={isRequired} error={hasError}>
        <FormLabel>
          {
            `${label} (${t('titles.max')} ${maxFiles} ${maxFiles === 1 ?
              t('titles.files_sg')
              : maxFiles % 10 >= 2 && maxFiles % 10 < 5 ?
              t('titles.files_pl2') : t('titles.files_pl1')})`
          }
        </FormLabel>
        <div style={{display: "flex"}}>
          <Dropzone
            onChangeStatus={this.handleChangeStatus}
            onSubmit={this.handleSubmit}
            styles={{
              dropzone: {minHeight: 56, overflow: "auto", width: '50%'} ,
              preview: {display: "none"},
              inputLabel: newStyle,
              inputLabelWithFiles: newStyle
            }}
            inputWithFilesContent={t("components.dropzone.msg")}
            inputContent={t('components.dropzone.msg')}
            SubmitButtonComponent={null}
            accept={accept}
            maxFiles={maxFilesCalculated}
            multiple
            maxSizeBytes={5 * 1024 * 1024} // 1024*1024 is 1MB
            disabled={isReadOnly}
          />
          <Box
            style={{
              width: '50%',
              border: "2px solid #d9d9d9"
            }}
          >
            <p style={{position: "absolute", top: 0, right: 0, padding: "4px"}}>
              {`${valueLength}/${maxFiles}`}
            </p>
            {
              Array.isArray(valueArrays) ?
                valueArrays.map((arr, j) => {
                  return(
                    <div
                      key={j}
                      style={{
                        display: "flex"
                      }}
                    >
                    { Array.isArray(arr) ?
                        arr.map((val, i) => {
                          let type = val && val.type ? val.type : '';
                          const name = val && val.name ? (val.name.length > 13 ? val.name.substring(0, 14) + '...' : val.name) : '';
                          // if (type === undefined || type === null)  {
                          //   type = '';
                          // }
                          const icon =
                            type.indexOf('pdf') >= 0 ?
                              faFilePdf
                            :
                            type.indexOf('image') >= 0 ?
                              faImage
                            :
                            type.indexOf('docx') >= 0 ?
                              faFileWord
                            : faFile;
                          return (
                            <span
                              key={'div' + i}
                              style={{margin: 5, textAlign: "center"}}
                            >
                              <FontAwesomeIcon
                                icon={icon}
                                color="grey"
                                size="2x"
                                key={'icon' + i}
                                style={{margin: "auto"}}
                                onClick={() => this.handleOpenFile(val)}
                              />
                              { !isReadOnly ?
                              <Badge
                                key={'badge' + i}
                                badgeContent={<CloseIcon fontSize="inherit"/>}
                                color="secondary"
                                onClick={() => this.handleRemoveFile(val)}
                                anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                              />
                              : null }
                              <label key={'label' + i} style={{display: "block"}}>{name}</label>
                            </span>
                          )
                        })
                      : null
                    }
                    </div>
                  )
                })
                : null
            }

          </Box>
        </div>
        {
          activeImage && activeImage.hasOwnProperty('image') ?
          <Card
            onMouseEnter={() => this.handleHover(true)}
            onMouseLeave={() => this.handleHover(false)}
          >
              { hovered ?
                <Fab
                  size="small"
                  style={{position: "absolute", backgroundColor: "#E52E2E", bottom: 0}}
                  onClick={this.deactivateImage}
                >
                  <CloseIcon />
                </Fab>
                : null
              }
            <img src={`data:image/jpeg;base64, ${activeImage.image}`} style={{display: 'block', maxWidth: '100%', maxHeight: '100%'}} />
          </Card>
          : null
        }
        <FormHelperText id={field.source + "-helper"} error={hasError || fileValidation ? true : false}>
          { hasError ? validation.msg : ''}
          { fileValidation ? fileValidation : ''}
        </FormHelperText>
      </FormControl>
    )
  }
}

export default withTranslation()(withTheme(FileControl));