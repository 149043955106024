import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import Toolbar from '@material-ui/core/Toolbar';
import DialogActions from '@material-ui/core/DialogActions';

//Material UI Icons
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

//Service
// import userService from '/src/services/userService';

//Custom Components
import withFormController from "Components/withFormController";
import withSnackbar from "Components/withSnackbar";
import GridContainer from "UI/Grid/GridContainer";
import DialogConsumer from 'UI/DialogContext/DialogConsumer';

import FormContent from "Components/FormContent";
import DraggableDialog from 'UI/Dialog/DraggableDialog';
import DialogHeader from 'UI/Dialog/DialogHeader';
import DialogToolbarHeading from 'UI/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from 'UI/Dialog/DialogToolbarButtonClose';
import DialogToolbarFillContent from 'UI/Dialog/DialogToolbarFillContent';
import DialogBody from 'UI/Dialog/DialogBody';
import DialogActionButton from 'UI/Dialog/DialogActionButton';
import { userService } from "Services/userService";
import { func } from "prop-types";

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogContext from "UI/DialogContext/DialogContext";

function UserSetPasswordDialog (props){
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const { record, validation, fields, recordId, doValidate } = props
  const { onFieldChange, onClose } = props; // HOC withFormController

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  const handleSetPassword = (evt) => {
    if (doValidate) {
      if(doValidate()) {
        userService.setPassword({userid: recordId, password_new: record.password}).then(resp => {
          if (resp.success) {
            snackbarContext.showNotification("Password successfully set!", "success");
            setTimeout(function () {
              dialogContext.hideDialog(2);
            }, 800)
          } else {
            snackbarContext.showNotification("Error setting password", "error");
          }

        })
      }
    }
  }

  const handleClose = (evt) => {
    close({dataChanged: false});
  }

  const close = (result) => {
    if (result.dataChanged) {
      onClose({dataChanged: result.dataChanged, action: result.action});
    }

    dialogContext.hideDialog(2);
  }

  return (
    <DraggableDialog
        open={true}
        maxWidth={"xs"}
        onClose={(evt) => handleClose(evt, dialogContext.hideDialog)}>
      <DialogHeader>
        <Toolbar variant="regular" disableGutters={true}>
          <DialogToolbarHeading>
            {t('titles.password_set')}
          </DialogToolbarHeading>
          <DialogToolbarFillContent/>
          <DialogToolbarButtonClose onClick={(evt) => handleClose(evt)} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
                record={record}
                validation={validation}
                fields={fields}
                fieldNames={["password", "password_confirm"]}
                onFieldChange={handleFieldChange}
                columns={1}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButton 
          variant="outlined" color="primary"
          onClick={(evt) => handleClose(evt)} 
          startIcon={<CloseIcon/>}
        >
          {t('buttons.cancel')}
        </DialogActionButton>
        <DialogActionButton 
          variant="contained" color="primary"
          onClick={(evt) => handleSetPassword(evt)}
          startIcon={<SaveIcon/>}
        >
          {t('buttons.change')}
        </DialogActionButton>
      </DialogActions>
    </DraggableDialog>
  )
}

export default withFormController(UserSetPasswordDialog);
