import React, { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import withSnackbar from "Components/withSnackbar";

//Material-UI Core Components
import FormButton from "UI/Form/FormButton";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";

// Custom Components
import DialogContext from "UI/DialogContext/DialogContext";
import { MapConfigContext } from "Components/MapConfigContext/MapConfigContext";
import MapAddLayerDialog from "Views/MapPage/MapAddLayerDialog";

export function LayersPane(props) {
  const { elementRef } = props;
  const { t } = useTranslation();
  const { showDialog } = useContext(DialogContext);
  const { addLayer } = useContext(MapConfigContext);

  const handleAddExternalLayer = (evt) => {
    evt.currentTarget.blur();
    showDialog(MapAddLayerDialog, {
      onClose: handleDialogResult,
    });
  };

  const handleDialogResult = (result) => {
    if (result.success) {
      console.log(result.data);
      addLayer(result.data);
      // showNotification("Sloj je uspješno odabran.", "success");
    } else if (result.error) {
      // showNotification("Došlo je do greške prilikom odabira sloja", "error");
    }
  };

  return (
    <Fragment>
      <div id="layers" className="layer-switcher" ref={elementRef}></div>
      <FormButton
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddExternalLayer}
      >
        Dodaj novi sloj
      </FormButton>
    </Fragment>
  );
}

export default LayersPane;
