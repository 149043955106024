import React, { useContext, useEffect, Fragment } from "react";
import OlLayerGroup from "ol/layer/Group";

import OlSourceOSM from "ol/source/OSM";
import OlSourceStamen from "ol/source/Stamen";
import OlSourceTileWMS from 'ol/source/TileWMS';

import OlCollection from "ol/Collection";

import Util from '../../Util';
import { MapContext } from "../../MapContext";
import { GroupLayerContext } from "./GroupLayerContext";

export default function GroupLayer(props) {

  const context = useContext(MapContext);

  const childLayers = [];

  let layer = undefined;

  const options = {
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    minZoom: undefined,
    maxZoom: undefined
  };

  const idKey = "id";
  const titleKey = "title";
  const foldKey = "fold";

  const events = {
    'change': undefined,
    'change:extent': undefined,
    'change:layers': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    'error': undefined,
    'postrender': undefined,
    'prerender': undefined,
    'propertychange': undefined
  };

  const hash = 'hash_' + props.children.map(x => x !== null ? x.key : '').join();

  useEffect(() => {
    // console.log('ue GroupLayer', props.id, hash);

    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    layer = new OlLayerGroup(definedOptions);
    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if (props.fold) {
      layer.set(foldKey, props.fold);
    }

    const validLayers = childLayers.filter(x => x !== null);

    layer.setLayers(new OlCollection(validLayers, true));

    if (context.map) {
      const mapLayers = context.map.getLayers();
      const mapLayer = mapLayers.getArray().find(x => x instanceof OlLayerGroup && x.get(idKey) === props.id);

      if (mapLayer) {
        console.log('remove layer', mapLayer, props.id);
        context.map.removeLayer(mapLayer);
      }
      console.log('add layer', layer, props.id);
      context.map.addLayer(layer);

    } else {
      context.initOptions.layers.push(layer)
    }

    let olEvents = Util.getEvents(events, props);
    for(let eventName in olEvents) {
      layer.on(eventName, olEvents[eventName]);
    }

  }, [
    hash
  ]);

  // console.log('r GroupLayer', hash, props.children);

  return(
    <GroupLayerContext.Provider value={{exists: true, childLayers: childLayers}}>
      {props.children}
    </GroupLayerContext.Provider>
  );
}
