import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

//Material-UI Icons
import ExploreIcon from "@material-ui/icons/Explore";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";

//Custom Components
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";
import TableButton from "UI/Table/TableButton";
import DialogContext from "UI/DialogContext/DialogContext";

import TagTableButton from "Components/TaggingControl/TagTableButton";
//import TagsMap from "Components/TaggingControl/TagsMap";
//import TagDialog from "Components/TaggingControl/TagDialog";

import model from "Models/tagByLink";
import dataController from "Lib/dataController";


import { tagsService } from "Services/tagsService"

function TaggingAddControl(props) {
  const { isRequired, title, linkId, add_linkId, onClose, groupsDenied = [], groupsAllowed = [], groupsFilterId = -1 } = props;

  const [tags, setTags] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagsLen, setSelectedTagsLen] = useState(0);

  const dialogContext = useContext(DialogContext);

  const { t } = useTranslation();

  const hasError = false;

  const dc = new dataController(model);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {

    if (groupsFilterId)  {
      tagsService.getTags(groupsFilterId, groupsDenied, groupsAllowed).then((grouped_tags) => {
        setTags(grouped_tags);
      })
    }
  };

  const handleSelectChip = (evt, tag) =>
  {
    if (tag) {
      const index = selectedTags.indexOf(tag.id);
      if (index > -1) {
        selectedTags.splice(index, 1);
        //setSelectedTags(selectedTags);
      } else {
        selectedTags.push(tag.id);
        //setSelectedTags(selectedTags);
      }
    }

    //this.forceUpdate();
    setSelectedTagsLen(selectedTags.length);
  };

  const handleTagAdd = (evt) => {
    if (add_linkId)  {
      tagsService.insTags(add_linkId, selectedTags).then(() => {
        onClose(evt,true);
      });
    }
  };

  const handleTagAddCancel = (evt) => {
    onClose(evt,false);
  };


  return (
    <FormControl fullWidth={true} error={hasError}>
      <FormLabel required={isRequired}>{title}</FormLabel>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Toolbar>
            <TagTableButton variant="outlined" startIcon={<CancelIcon />} onClick={handleTagAddCancel}>
              {t("buttons.cancel")}
            </TagTableButton>
           <TagTableButton
              disabled={selectedTagsLen < 1 ? true : false}
              variant={selectedTagsLen > 0 ? "contained" : "outlined"}
              startIcon={<AddIcon />}
              onClick={handleTagAdd}
              >
              {t("buttons.add") + (selectedTagsLen < 1 ? '' : ' ' + selectedTagsLen)}
            </TagTableButton>

          </Toolbar>
          <Table>
            <TableBody>
              {tags.map((group, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{group.label}</TableCell>
                    <TableCell>
                      {group.tags.map((tag, j) => {
                        const selected = selectedTags.indexOf(tag.id) > -1;
                        const hasPos = tag.tag_xy && tag.tag_xy !== "";
                        return (
                          <Chip
                            color="primary"
                            variant={!selected ? 'outlined' : 'default'}
                            icon={hasPos ? <LocationOnOutlinedIcon /> : undefined}
                            key={j}
                            label={tag.tag}
                            clickable={true}
                            onClick={(evt) => handleSelectChip(evt, tag)}
                          />
                        );
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </GridItem>
      </GridContainer>
    </FormControl>
  );
}

export default TaggingAddControl;
