import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import moment from "moment";
//import patchedevent from 'Libs/patchedevent';

//Material-UI Core Components
import withTheme from "@material-ui/core/styles/withTheme";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

//Material-UI Icons
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import MouseIcon from "@material-ui/icons/Mouse";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SyncIcon from "@material-ui/icons/Sync";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import TodayIcon from '@material-ui/icons/Today';

//Custom Components
import withSnackbar from "Components/withSnackbar";
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";
import Loader from "UI/Loader/Loader";

import ToolbarButton from "UI/Toolbar/ToolbarButton";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import ToolbarHeading from "UI/Toolbar/ToolbarHeading";
import ToolbarMenuButton from "UI/Toolbar/ToolbarMenuButton";

import schedulerDataController from "Components/Scheduler/schedulerDataController";
import SchedulerDrawer from "Components/Scheduler/SchedulerDrawer";
import SchedulerDrawerHeading from "Components/Scheduler/SchedulerDrawerHeading";
import SchedulerDrawerSwitch from "Components/Scheduler/SchedulerDrawerSwitch";
import SchedulerGrid from "Components/Scheduler/SchedulerGrid";
import SchedulerFilterForm from "Components/Scheduler/SchedulerFilterForm";
import PickerControl from "Controls/PickerControl";
import TextControl from "Controls/TextControl";

import DialogConsumer from "UI/DialogContext/DialogConsumer";
import CalendarDialog from "Views/CalendarPage/CalendarDialog";

import { formats } from "Lib/formats";
import model from "Models/calendar";
import modelSearch from "Models/search";
import dataController from "Lib/dataController";

let drag_offset = 0;
let mouse_down = false;

class SchedulerView extends React.Component {
  constructor(props) {
    super(props);
    console.log("scheduler is alive", props);

    this.resolutions = [1, 2, 3, 4, 6, 8, 12, 24];

    const thisWeekStart = moment().isoWeekday(1).startOf("day");

    this.state = {
      thisWeekStart: thisWeekStart,
      startDate: thisWeekStart,
      res_h: 2,
      dateTimeAnchorEl: null,
      date_under_mouse: null,
      icon_under_mouse: null,
      day_offset: 0,
      row_filter: false,
      userShow: {
        vozaci: true,
        vozila: true,
        voznje: true,
        ostalo: true
      },
      userFilters: {},
      sortType: 1,
      filterRecord: {},
      isLoading: false
    };

    this.dcSearch = new dataController(modelSearch);

    this.sdc = new schedulerDataController(thisWeekStart);
    this.dc = new dataController(model);

    //this.handleToolbarFilterChange = this.handleToolbarFilterChange.bind(this);
    this.handleOpenDateTime = this.handleOpenDateTime.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    this.handleSetUserFilter = this.handleSetUserFilter.bind(this);
    this.handleSetSort = this.handleSetSort.bind(this);
    this.handleSearchFilter = this.handleSearchFilter.bind(this);

    this.refreshData = this.refreshData.bind(this);

    this.change_startdate = this.change_startdate.bind(this);
    this.cell_enter = this.cell_enter.bind(this);
    this.cell_leave = this.cell_leave.bind(this);
    this.item_group_state = this.item_group_state.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    const { startDate, res_h } = this.state;

    this.setState({
      isLoading: true
    });
    this.sdc.GetData(startDate, res_h).then(resp => {
      //parse error?
    }).finally(() => {
      this.setState({
        isLoading: false
      })
    });
  }

  change_resolution(newpos, skip_event) {
    const { startDate } = this.state;

    // TODO: check bounds!
    if (drag_offset != 0) return;
    newpos = Math.max(0, Math.min(this.resolutions.length - 1, newpos));
    if (newpos == this.resolutions.indexOf(this.state.res_h)) return;

    const newResH = this.resolutions[newpos];

    //this.deattach_events();
    this.setState({ res_h: newResH });

    this.sdc.CheckData(startDate, newResH);
    // this.update_cell_width(this.resolutions[newpos]);
    // if (!skip_event) {
    //   this.vents.emit('blcksz:change', this.resolutions[newpos]);
    // }
    //setTimeout(() => { this.attach_events() }, 0);
  }

  change_startdate(newdate) {
    const { res_h }  = this.state;

    if (!newdate.isValid()) {
      return;
    }
    this.setState({ startDate: newdate });
    this.sdc.CheckData(newdate, res_h);
  }

  man_change_startdate(dir, step_weight) {
    const { startDate, res_h } = this.state;

    this.setState({ day_offset: 0 });

    let interval = 2;
    if (res_h <= 4) {
      interval = 2;
    } else if (res_h > 4 && res_h <= 8) {
      interval = 7;
    } else if (res_h > 8) {
      interval = 14;
    }

    let step = dir + dir * step_weight * (interval - 1);
    this.change_startdate(startDate.clone().add(step, "days"));
  }

  handleRefresh(evt) {
    this.refreshData();
  }

  handleOpenDateTime(evt) {
    this.setState({
      dateTimeAnchorEl: evt.currentTarget
    });
  }

  cell_enter(evt, day, div) {
    const { hideTime } = this.props;
    const { startDate, date_under_mouse } = this.state;

    let offset = day * 24 + div * this.state.res_h;
    let target = startDate.clone().add(offset, "hours");
    let end = Math.min(target.hours() + this.state.res_h, 24);
    let value = hideTime ? target.format("DD.MM.YYYY") : target.format("DD.MM.YYYY H") + "-" + end;
    if (value !== date_under_mouse) {
      this.setState({ date_under_mouse: value, icon_under_mouse: <MouseIcon /> });
    }
  }

  cell_leave(evt) {
    this.setState({ date_under_mouse: null, icon_under_mouse: null });
  }

  handleClick(item_id, showDialog) {
    // console.log(item_id);
    showDialog(CalendarDialog, {
      model: this.dc,
      mode: "update",
      form: "update",
      recordId: item_id,
      onClose: this.handleDialogClose
    });
  }

  item_group_state(item_id, state) {
    this.sdc.ChangeGroupState(item_id, state);
    this.forceUpdate();
  }

  handleSetUserFilter(filterRecord) {
    this.setState({
      userFilters: filterRecord
    });
  }
  // handleSetUserFilter(values, source) {
  //   this.setState((prevState) => {
  //     let filters = prevState.userFilters;
  //     if (values) {
  //       filters[source] = values;
  //     } else {
  //       delete filters[source];
  //     }
  //     return { userFilters: filters };
  //   });
  // }

  handleSetSort(value, source) {
    this.setState({
      sortType: value.value
    });
  }

  handleSearchFilter(value, source) {
    if (value.length > 0) {
      //this.handleSetUserFilter([{ value: value }], "search"); //same as picker format
    } else {
      this.handleSetUserFilter(null, "search");
    }
  }

  // handleToolbarFilterChange(name) {
  //   switch (name) {
  //     case 'Vozači':
  //       this.sdc.ToggleFilter('vozaci');
  //       break;
  //     case 'Vozila':
  //       this.sdc.ToggleFilter('vozila');
  //       break;
  //   }
  //   this.forceUpdate();
  //   this.setState(prevState => {
  //     return { [`checked${name}`]: !prevState[`checked${name}`] }
  //   })
  // }

  handleAdd(showDialog) {
    showDialog(CalendarDialog, {
      model: this.dc,
      mode: "insert",
      form: "insert",
      onClose: this.handleDialogClose
    });
  }

  handleDialogClose(result) {
    if (result.dataChanged) {
      this.refreshData();
    }
  }

  showPrikaz(item) {
    this.setState((prevState) => {
      const userShow = Object.assign({}, prevState.userShow);
      userShow[item] = !prevState.userShow[item];

      return {
        userShow: userShow
      };
    });
  }

  render() {
    const {
      dateTimeAnchorEl,
      subModels,
      userFilters,
      res_h,
      startDate,
      userShow,
      filterRecord,
      sortType,
      isLoading
    } = this.state;
    const { theme, hideTime, t } = this.props;

    //console.log('render');

    const curr_res = this.resolutions.indexOf(res_h);

    // TODO: icon under mouse update!!!
    const icon_under_mouse = this.state.icon_under_mouse ? this.state.icon_under_mouse : <CalendarTodayIcon />;
    const date_under_mouse = this.state.date_under_mouse
      ? this.state.date_under_mouse
      : startDate.format("DD.MM.YYYY");
    //this.calculate_date_range();

    // let rows = Array.apply( null, { length: 20 } ).map( Number.call, umber );
    const end_date = startDate.clone().add(10, "days"); //TODO: fix
    let [rows, conflicts] = this.sdc.GetRows(
      startDate,
      end_date,
      this.state.res_h,
      this.state.cell_width,
      this.state.grid_width,
      mouse_down,
      userFilters,
      userShow,
      hideTime,
      sortType
    );

    // const checkLista = ['Vozači', 'Vozila'];
    // const showLista = checkLista.map((item) => {
    //   return this.state['checked' + item] ? item : null;
    // })

    const fieldSort = {
      title: "Sort",
      source: "sort_id",
      ttoken: "common.sort",
      type: "picker",
      items: {
        values: [1/*, 2*/, 3, 4],
        labels: ["Dan, djelatnik, oprema"/*, "Objekt, dan, oprema"*/, "Djelatnik, dan, oprema", "Oprema, dan, djelatnik"]
      },
      multi: false,
      showHelper: false
    };

    const searchFilterValue = userFilters && userFilters["search"] ? userFilters["search"][0].value : "";

    return (
      <Fragment>
      <DialogConsumer>
        {({ showDialog }) => (
          <Card id="scheduler-component-xx" style={{ marginBottom: "0px" }}>
            <CardContent>
              <Toolbar variant="dense">
                <ToolbarButton
                  onClick={this.handleOpenDateTime}
                  style={{ width: 170, minWidth: 150 }}
                  startIcon={icon_under_mouse}
                >
                  {date_under_mouse}
                </ToolbarButton>
                <ToolbarButton onClick={(evt) => this.handleAdd(showDialog)}>
                  <AddIcon />
                  <Hidden mdDown>{t("calendar.new")}</Hidden>
                </ToolbarButton>
                <ToolbarButton onClick={this.handleRefresh}>
                  <SyncIcon />
                </ToolbarButton>

                {/* <ToolbarFilters
                handleChange={this.handleToolbarFilterChange.bind(this)}
                checked={showLista}
                items={checkLista}
              /> */}

                <ToolbarFillContent>
                  <ToolbarButton
                    onClick={() => {
                      this.man_change_startdate(-1, 1);
                    }}
                  >
                    <FirstPageIcon />
                  </ToolbarButton>
                  <ToolbarButton
                    onClick={() => {
                      this.man_change_startdate(-1, 0);
                    }}
                  >
                    <ArrowBackIcon />
                  </ToolbarButton>
                  <ToolbarButton
                    onClick={() => {
                      this.change_startdate(this.state.thisWeekStart);
                    }}
                  >
                    <TodayIcon />
                  </ToolbarButton>
                  <ToolbarButton
                    onClick={() => {
                      this.man_change_startdate(1, 0);
                    }}
                  >
                    <ArrowForwardIcon />
                  </ToolbarButton>
                  <ToolbarButton
                    onClick={() => {
                      this.man_change_startdate(1, 1);
                    }}
                  >
                    <LastPageIcon />
                  </ToolbarButton>
                </ToolbarFillContent>

                <ToolbarButton
                  onClick={() => this.change_resolution(curr_res + 1)}
                  disabled={curr_res == this.resolutions.length - 1}
                >
                  <ZoomOutIcon />
                </ToolbarButton>
                <ToolbarButton
                  onClick={() => this.change_resolution(curr_res - 1)}
                  disabled={curr_res < 1 ? true : false}
                >
                  <ZoomInIcon />
                </ToolbarButton>
                <ToolbarMenuButton
                  onSelect={(value) => {
                    this.change_resolution(value);
                  }}
                  items={this.resolutions}
                  selected={curr_res}
                >
                  <MenuIcon />
                </ToolbarMenuButton>
                <Popover
                  id={"manual-navi-controls"}
                  open={Boolean(dateTimeAnchorEl)}
                  onClose={() => {
                    this.setState({ dateTimeAnchorEl: null });
                  }}
                  anchorEl={dateTimeAnchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <Datetime
                    locale="hr"
                    utc={true}
                    value={startDate}
                    timeFormat={false}
                    onChange={this.change_startdate}
                    input={false}
                    open={true}
                  />
                </Popover>
              </Toolbar>
              <GridContainer>
                <GridItem xs={2}>
                  <SchedulerDrawer>
                    <SchedulerDrawerHeading>SORT</SchedulerDrawerHeading>
                    <Divider variant="middle" />
                    <PickerControl
                      field={fieldSort}
                      formMode="form"
                      controlMode="edit"
                      //model={modelVozilo}
                      allowClear={false}
                      allowNewRecord={false}
                      value={sortType}
                      onChange={this.handleSetSort}
                    />
                    <SchedulerDrawerHeading>Filteri</SchedulerDrawerHeading>
                    <Divider variant="middle" />
                    <SchedulerFilterForm model={this.dcSearch} form="scheduler" onFilterChange={this.handleSetUserFilter} />
                    <GridContainer>
                      {/*  <Form form="scheduler" model={this.dcSearch}>
                    <FormContent
                      fieldNames={this.dcSearch.getFormFieldsNames("scheduler")}
                      //record={filterRecord}
                      validation={undefined}
                      fields={this.dcSearch.getFormFields("scheduler")}
                      onFieldChange={this.handleSetUserFilter}
                      mode={"update"}
                      columns={1}
                    />
                  </Form> */}
                      {/*
                  <GridItem xs={12}>
                    <TextControl
                      label="Pretraži"
                      value={searchFilterValue}
                      formMode={"form"}
                      controlMode={"edit"}
                      onChange={this.handleSearchFilter}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                      <PickerControl
                        field={fieldDjelatnik}
                        formMode="form"
                        controlMode="edit"
                        //model={modelVozilo}
                        allowMultiSelect={true}
                        allowClear={false}
                        allowNewRecord={false}
                        onChange={this.handleSetUserFilter}
                      />

                  </GridItem>
                  <GridItem xs={12}>
                    {subModels ? (
                      <PickerControl
                        field={fieldVozac}
                        formMode="form"
                        controlMode="edit"
                        model={modelVozac}
                        allowMultiSelect={true}
                        allowClear={false}
                        allowNewRecord={false}
                        onChange={this.handleSetUserFilter}
                      />
                    ) : null}
                  </GridItem> */}
                    </GridContainer>
                  </SchedulerDrawer>
                </GridItem>
                <GridItem xs={10}>
                  <SchedulerGrid
                    startDate={startDate}
                    day_offset={this.state.day_offset}
                    row_height={50}
                    res_h={this.state.res_h}
                    records={rows}
                    conflicts={Array.isArray(conflicts) ? conflicts : []}
                    onCellEnter={this.cell_enter}
                    onCellLeave={this.cell_leave}
                    onToggleDisplay={this.item_group_state}
                    onItemClick={(id) => this.handleClick(id, showDialog)}
                    onUpdateCellWidth={(cw) => this.setState({ cell_width: cw })}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
          </Card>
        )}
      </DialogConsumer>
      <Loader open={isLoading} />
      </Fragment>
    );
  }
}

export default withTranslation()(withTheme(withSnackbar(SchedulerView)));
