import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import FormButton from "UI/Form/FormButton";

//Material-UI Icons
import SearchIcon from "@material-ui/icons/Search";
import CropFreeIcon from "@material-ui/icons/CropFree";
import CenterFocusStrongIcon from "@material-ui/icons/CenterFocusStrong";
import ClearIcon from "@material-ui/icons/Clear";
import BlockIcon from "@material-ui/icons/Block";

//Custom Components
import withFormController from "Components/withFormController";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import TaggingMapControl from "Components/TaggingControl/TaggingMapControl";
import { union, difference } from "Lib/setHelpers";
import { tagsService } from "Services/tagsService";

function SearchForm(props) {
  const { t } = useTranslation();

  const { onToggleDraw, onSubmit, searchWKT, isDrawing, onAddTag } = props;
  const { model, mode, form, record, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagPoints, setTagPoints] = useState({});
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const handleExpandedChange = () => {
    setExpanded(!expanded);
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(true), 100);
    });
  };

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit({
        ...record,
        vrsta_picker: record.vrsta_picker ? record.vrsta_picker.value : null,
      });
    }
    if (expanded) {
      handleExpandedChange().then(() => {
        openSidebar();
      });
    } else {
      openSidebar();
    }
  };

  const handleClearSearch = () => {
    const fieldNames = model.getFormFieldsNames();
    fieldNames.forEach((f) => {
      handleFieldChange(null, f);
    });
    setSelectedTags([]);
    setTagPoints({});
  };

  const openSidebar = () => {
    const sidebar = document.getElementsByClassName("sidebar-left")[0];
    if (sidebar && !sidebar.classList.contains("expanded")) {
      sidebar.classList.add("expanded");
    }
  };

  const handleDrawExtent = () => {
    onToggleDraw(true);
  };

  const handleClearExtent = () => {
    onToggleDraw(false);
  };

  const handleSelectTag = (evt, tag) => {
    const newSet = new Set(selectedTags);
    const tagId = tag.id;
    if (selectedTags.indexOf(tagId) >= 0) {
      setSelectedTags([...difference(newSet, new Set([tagId]))]);
    } else {
      setSelectedTags([...union(newSet, new Set([tagId]))]);
    }
    getTagPoints(tagId);
  };

  const getTagPoints = (tagId) => {
    if (tagPoints.hasOwnProperty(tagId)) {
      const refreshFlagNow = refreshFlag;
      setRefreshFlag(!refreshFlagNow);
      setTagPoints(
        Object.assign({}, tagPoints, { refreshFlag: !refreshFlagNow })
      );
    } else {
      tagsService.getPointsByTag(tagId).then((resp) => {
        setTagPoints(Object.assign({}, tagPoints, { [tagId]: resp.data }));
      });
    }
  };

  useEffect(() => {
    if (onFieldChange) {
      onFieldChange(searchWKT, "wkt");
    }
  }, [searchWKT]);

  useEffect(() => {
    handleFieldChange(selectedTags, "tag_id");
  }, [selectedTags]);

  useEffect(() => {
    onAddTag(tagPoints, selectedTags);
  }, [tagPoints]);

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: "update",
    subModels: subModels,
  };

  return (
    <GridContainer>
      <FormContent
        fieldNames={[
          "djelatnik_id",
          "oprema_id",
          "klijent_id",
          "usluga_id",
          "vrsta_picker",
        ]}
        columns={1}
        {...commonFields}
      />
      <GridItem xs={12}>
        <TaggingMapControl
          linkId={-1}
          onSelectTag={handleSelectTag}
          selectedTags={selectedTags}
          tagPoints={tagPoints}
          expanded={expanded}
          onExpanded={handleExpandedChange}
        />
      </GridItem>
      <FormContent
        fieldNames={["datum_od", "datum_do"]}
        columns={2}
        {...commonFields}
      />
      <GridItem xs={12}>
        <FormButton
          variant="outlined"
          startIcon={isDrawing ? <CenterFocusStrongIcon /> : <CropFreeIcon />}
          onClick={handleDrawExtent}
        >
          {t("buttons.draw_extent")}
        </FormButton>
        <FormButton
          variant="outlined"
          startIcon={<BlockIcon />}
          onClick={handleClearExtent}
        >
          {t("buttons.clear_extent")}
        </FormButton>
      </GridItem>
      <GridItem xs={12}>
        <FormButton
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={handleSubmit}
        >
          {t("buttons.search")}
        </FormButton>
        <FormButton
          variant="outlined"
          startIcon={<ClearIcon />}
          onClick={handleClearSearch}
        >
          {t("buttons.clear_search")}
        </FormButton>
      </GridItem>
    </GridContainer>
  );
}

export default withFormController(SearchForm);
