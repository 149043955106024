import React, { useContext, useEffect, useRef } from "react";
import { SidebarControlContext } from "./SidebarControlContext";

export default function SidebarTabs(props) {
  // const context = useContext(SidebarControlContext);
  // const tabsRef = useRef(null);

  // const element = document.createElement("div");
  // element.className = "sidebar-tabs";
  // tabsRef.current = element;

  // useEffect(() => {
  //   if (context.sidebar) {
  //     context.sidebar.appendChild(element);
  //   }
  // }, []);

  // return (
  //   <SidebarControlContext.Provider value={{
  //     sidebar: context.sidebar,
  //     tabs: tabsRef.current
  //   }}>
  //     {props.children}
  //   </SidebarControlContext.Provider>
  // );

  return (
    <div className="sidebar-tabs">
      {props.children}
    </div>
  )
}
