import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

//Material-UI Icons
import ExploreIcon from "@material-ui/icons/Explore";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LocationOffOutlinedIcon from '@material-ui/icons/LocationOffOutlined';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Custom Components
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";
import TableButton from "UI/Table/TableButton";

import TagTableButton from "Components/TaggingControl/TagTableButton";
//import TagsMap from "Components/TaggingControl/TagsMap";
//import TagDialog from "Components/TaggingControl/TagDialog";

import model from "Models/tag";
import dataController from "Lib/dataController";


import { tagsService } from "Services/tagsService"

function TaggingMapControl(props) {
  const { isRequired, title, linkId, add_linkId, onClose, onSelectTag, selectedTags, tagPoints, onExpanded, expanded } = props;

  const [tags, setTags] = useState([]);

  
  const [hadExpanded, setHadExpanded] = useState(false);

  const { t } = useTranslation();

  const hasError = false;

  const dc = new dataController(model);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
   if (linkId)  {
      tagsService.getTags(linkId).then((grouped_tags) => {
        setTags(grouped_tags);
      })
    }
  };


  useEffect(() => {
    const sidebar = document.getElementsByClassName("sidebar-left")[0];
    if (expanded) {
      if (sidebar && !sidebar.classList.contains('expanded')) {
        setHadExpanded(true);
        sidebar.classList.add('expanded');
      }
    } else {
      if (sidebar && sidebar.classList.contains('expanded') && hadExpanded) {
        setHadExpanded(false);
        sidebar.classList.remove('expanded');
      }  
    }
  }, [expanded]);

  const tagTitles = tags.map(g => (g.tags.map(t => (selectedTags.indexOf(t.id) >= 0 ? t.tag : null)))).flat().filter(f => f !== null);
  return (
    <FormControl fullWidth={true} error={hasError}>
    <Accordion 
      expanded={expanded}
      onChange={onExpanded}
      style={{borderBottom: "1px solid black", borderBottomRightRadius: 0, backgroundColor: "rgba(0,0,0,0.1)", borderBottomLeftRadius: 0}}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
      <FormLabel required={isRequired}>
        {t('tags.tags')}
        <Typography variant="caption" style={{paddingLeft: "10px"}}>
          {tagTitles.map((tt, i) => <u key={i} style={{padding: "0 3px", display: "inline-block"}}>{tt}</u>)}
        </Typography>
      </FormLabel>
      </AccordionSummary>
      <AccordionDetails>
      {/*<FormLabel required={isRequired}>{title}</FormLabel>*/}
      <GridContainer>
        <GridItem xs={12} sm={12}>
        {
          expanded || true ?
            <Table key={'table'}>
              <TableBody>
                {tags.map((group, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{group.label}</TableCell>
                      <TableCell>
                        {group.tags.map((tag, j) => {
                          const selected = selectedTags.indexOf(tag.id) > -1;
                          const hasPos = !tagPoints.hasOwnProperty(tag.id) || 
                               tagPoints[tag.id] 
                            && Array.isArray(tagPoints[tag.id]) 
                            && tagPoints[tag.id].some(t => t.hasOwnProperty("tag_xy"));

                          return (
                            <Chip
                              color="primary"
                              variant={!selected ? 'outlined' : 'default'}
                              icon={hasPos ? undefined : <LocationOffOutlinedIcon fontSize="small"/>}
                              key={j}
                              label={tag.tag}
                              clickable={true}
                              onClick={(evt) => onSelectTag(evt, tag)}
                            />
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          : null
        }
        </GridItem>
      </GridContainer>
      </AccordionDetails>
    </Accordion>
    </FormControl>
  );
}

export default TaggingMapControl;
