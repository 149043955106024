import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { DragAndDrop } from "ol/interaction";

export default function DragAndDropInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    formatConstructors: undefined,
    source: undefined,
    projection: undefined,
    target: undefined
  };

  const events = {
    addfeatures: undefined,
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, DragAndDrop, props, options, events),
    []
  );

  return null;
}
