import React, { Fragment } from 'react'
import PropTypes from 'prop-types';

//Material-UI Core Components
import ToolbarButton from 'UI/Toolbar/ToolbarButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


class ToolbarMenuButton extends React.Component {

  constructor(props) {
    super(props);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state= {
      anchorEl: null
    };
  }

  handleOpen(evt) {
    this.setState({anchorEl: evt.currentTarget});
  }

  handleClose(evt) {
    const { onSelect } = this.props;
    if (evt.currentTarget && evt.currentTarget.value !== undefined) {
      if (onSelect) {
        onSelect(evt.currentTarget.value);
      }
    }
    this.setState({anchorEl: null});
  }

  render() {
    const { anchorEl } = this.state;
    const { onSelect, items, selected, children, ...other} = this.props;

    const menuItems = items.map((item,i) =>
      <MenuItem key={i} value={i} onClick={this.handleClose} selected={i === selected}>{item}</MenuItem>
    );

    return (
      <Fragment>
        <ToolbarButton {...other} onClick={this.handleOpen}>
          {children}
        </ToolbarButton>
        <Menu
          id="toolbarmenu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {menuItems}
        </Menu>
      </Fragment>
    );
  }
}

ToolbarMenuButton.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func
}

export default ToolbarMenuButton;