import React, { Fragment, useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import CloseIcon from "@material-ui/icons/Close";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";

//Custom Components
import withSnackbar from "Components/withSnackbar";
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";
import PickerControl from "Controls/PickerControl";
import FormButton from "UI/Form/FormButton";
import dataController from "Lib/dataController";
import dataControllerSubModel from "Lib/dataControllerSubModel";
import picker from "Models/submodels/picker";
import Form from "Components/Form";
import SearchForm from "Components/MapSidebarPanes/SearchForm";
import model from "Models/search";
import ModelTable from "UI/Table/ModelTable";
import TableButton from "UI/Table/TableButton";
import Loader from "UI/Loader/Loader";

import { searchService } from "Services/searchService";

import DialogContext from "UI/DialogContext/DialogContext";
import MapConfigContext from "Components/MapConfigContext/MapConfigContext";

import TagDialog from "Views/TagsPage/TagDialog";
import DjelatnikDialog from "Views/DjelatniciPage/DjelatnikDialog"
import KlijentDialog from "Views/KlijentiPage/KlijentDialog"
import PonudaDialog from "Views/PonudePage/PonudaDialog";
import OpremaDialog from "Views/OpremaPage/OpremaDialog";
import UgovorDialog from "Views/UgovoriPage/UgovorDialog";
import ZahvatDialog from "Views/ZahvatiPage/ZahvatDialog";
import UslugaDialog from "Views/UslugePage/UslugaDialog";
import UslugaTimDialog from "Views/UslugeTimPage/UslugaTimDialog";
import CalendarDialog from "Views/CalendarPage/CalendarDialog";
import VanjskiCVDialog from "Views/VanjskiCVPage/VanjskiCVDialog";

import modelTag from "Models/tag";
import modelDjelatnik from "Models/djelatnik"
import modelKlijent from "Models/klijent";
import modelPonuda from "Models/ponuda";
import modelOprema from "Models/oprema";
import modelUgovor from "Models/ugovor";
import modelZahvat from "Models/zahvat";
import modelUsluge from "Models/usluge";
import modelUslugeTim from "Models/usluge_tim";
import modelCalendar from "Models/calendar";
import modelVanjskiCV from "Models/vanjski_cv";

function SearchPane(props) {
  const dc = new dataController(model);

  const dialogContext = useContext(DialogContext);
  const mapConfigContext = useContext(MapConfigContext);

  const { t } = useTranslation();

  const { onToggleDraw, searchWKT, isDrawing, onAddTag } = props;

  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recordsEdited, setRecordsEdited] = useState([]);
  const [newRecordId, setNewRecordId] = useState(-1);

  useEffect(() => {
    if (newRecordId !== -1) {
      setRecordsEdited([newRecordId, ...recordsEdited]);
    }
  }, [newRecordId])

  useEffect(() => {
    if (props.records.length) {
      setRecords(props.records[0] === null ? [] : props.records);
    }
  }, [JSON.stringify(props.records)]);


  const handleSumbit = (filters) => {
    setIsLoading(true);
    searchService.search(filters).then((resp) => {
      if (resp.success) {
        setRecords(resp.data);
        props.setFeatureRecords([]);
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const handleOpen = (evt, sel) => {
    let rowId;
    if (Array.isArray(sel)) {
      rowId = parseInt(sel[0])
    } else {
      rowId = parseInt(sel);
    }
    const record = records[rowId];
    const id = record.id;
    // const id = item.value;

    if (id > 20000 && id <= 29999) {
      dialogContext.showDialog(DjelatnikDialog, {
        model: new dataController(modelDjelatnik),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 30000 && id <= 39999) {
      dialogContext.showDialog(KlijentDialog, {
        model: new dataController(modelKlijent),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 50000 && id < 60000) {
      dialogContext.showDialog(OpremaDialog, {
        model: new dataController(modelOprema),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 60000 && id < 70000) {
      dialogContext.showDialog(VanjskiCVDialog, {
        model: new dataController(modelVanjskiCV),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 100000 && id < 200000) {
      dialogContext.showDialog(PonudaDialog, {
        model: new dataController(modelPonuda),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 200000 && id < 300000) {
      dialogContext.showDialog(UgovorDialog, {
        model: new dataController(modelUgovor),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 4000000 && id < 5000000) {
      dialogContext.showDialog(ZahvatDialog, {
        model: new dataController(modelZahvat),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 5000000 && id < 6000000) {
      dialogContext.showDialog(UslugaDialog, {
        model: new dataController(modelUsluge),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 20000000 && id < 30000000) {
      dialogContext.showDialog(UslugaTimDialog, {
        model: new dataController(modelUslugeTim),
        recordId : id,
        mode: "update",
        form: "search",
        submodelDefaultValues: {
          ugovor_id: -1
        }
      });
    } else if (id > 80000000 && id < 90000000) {
      dialogContext.showDialog(CalendarDialog, {
        model: new dataController(modelCalendar),
        recordId : id,
        mode: "update",
        form: "default"
      });
    }
  }

  const handleDialogClose = (retParams, id) => {
    const { dataChanged } = retParams;
    if (dataChanged) {
      //const newRecordsEdited = [id, ...recordsEdited];
      setNewRecordId(id);
    }
  }

  // Sorry for massacring this boy no iz nekog su razloga records undefined kad se iz table.jsx pozove funkcija
  // moguce da je neki bind u pitanju ali salju se lambda funkcije koje bindaju this tako da ne znam 
  const handleInlineOpen = (id) => {
    if (id > 20000 && id <= 29999) {
      dialogContext.showDialog(DjelatnikDialog, {
        model: new dataController(modelDjelatnik),
        recordId : id,
        mode: "update",
        form: "default",
        onClose: (retParams) => handleDialogClose(retParams, id)
      });
    } else if (id > 30000 && id <= 39999) {
      dialogContext.showDialog(KlijentDialog, {
        model: new dataController(modelKlijent),
        recordId : id,
        mode: "update",
        form: "default",
        onClose: (retParams) => handleDialogClose(retParams, id)
      });
    } else if (id > 50000 && id < 60000) {
      dialogContext.showDialog(OpremaDialog, {
        model: new dataController(modelOprema),
        recordId : id,
        mode: "update",
        form: "default",
        onClose: (retParams) => handleDialogClose(retParams, id)
      });
    } else if (id > 60000 && id < 70000) {
      dialogContext.showDialog(VanjskiCVDialog, {
        model: new dataController(modelVanjskiCV),
        recordId : id,
        mode: "update",
        form: "default"
      });
    } else if (id > 100000 && id < 200000) {
      dialogContext.showDialog(PonudaDialog, {
        model: new dataController(modelPonuda),
        recordId : id,
        mode: "update",
        form: "default",
        onClose: (retParams) => handleDialogClose(retParams, id)
      });
    } else if (id > 200000 && id < 300000) {
      dialogContext.showDialog(UgovorDialog, {
        model: new dataController(modelUgovor),
        recordId : id,
        mode: "update",
        form: "default",
        onClose: (retParams) => handleDialogClose(retParams, id)
      });
    } else if (id > 4000000 && id < 5000000) {
      dialogContext.showDialog(ZahvatDialog, {
        model: new dataController(modelZahvat),
        recordId : id,
        mode: "update",
        form: "default",
        onClose: (retParams) => handleDialogClose(retParams, id)
      });
    } else if (id > 5000000 && id < 6000000) {
      dialogContext.showDialog(UslugaDialog, {
        model: new dataController(modelUsluge),
        recordId : id,
        mode: "update",
        form: "default",
        onClose: (retParams) => handleDialogClose(retParams, id)
      });
    } else if (id > 20000000 && id < 30000000) {
      dialogContext.showDialog(UslugaTimDialog, {
        model: new dataController(modelUslugeTim),
        recordId : id,
        mode: "update",
        form: "search",
        submodelDefaultValues: {
          ugovor_id: -1
        },
        "wait": true
      });
    } else if (id > 80000000 && id < 90000000) {
      dialogContext.showDialog(CalendarDialog, {
        model: new dataController(modelCalendar),
        recordId : id,
        mode: "update",
        form: "default",
        onClose: (retParams) => handleDialogClose(retParams, id)
      });
    }
  }

  return (
    <Fragment>
      <SearchForm
        model={dc}
        mode={"insert"}
        form={"insert"}
        onToggleDraw={onToggleDraw}
        searchWKT={searchWKT}
        isDrawing={isDrawing}
        onSubmit={handleSumbit}
        onAddTag={onAddTag}
      />
      <ModelTable
        records={records || []}
        editedRecordIds={recordsEdited}
        dc={dc}
        allowColumnPicker={false}
        allowSelection="one"
        allowFilter={false}
        allowExport={true}
        allowSearchBar={false}
        inlineEdit={true}
        onEdit={(id) => handleInlineOpen(id)}
        customFnc={(id) => handleInlineOpen(id)}
        exportPonudeIds={true}
        disableStyles={true}
        allowAdd={false}
      >
        <TableButton variant="outlined" allow="one" onClick={handleOpen} startIcon={<OpenInBrowserIcon />}>
          {t("buttons.open")}
        </TableButton>
      </ModelTable>
      <Loader open={isLoading} />
    </Fragment>
  );
}

export default SearchPane;
