import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { DragRotateAndZoom } from "ol/interaction";

export default function DragRotateAndZoomInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    condition: undefined,
    duration: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, DragRotateAndZoom, props, options, events),
    []
  );

  return null;
}
