import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";

//Custom Components
import AppPage from "UI/AppPage/AppPage";
import Dashboard from "Views/HomePage/Dashboard";

function DashboardPage(props) {
  const { t } = useTranslation();

  return (
    <AppPage>
      <Box m={0}>
        <Dashboard />
      </Box>
    </AppPage>
  );
}

export default DashboardPage;
