import api from "Lib/api";
import { sifrarnici } from "Lib/sifrarnici";

//OpenLayers
import OlSourceOSM from "ol/source/OSM";
import OlSourceVector from "ol/source/Vector";
import OlSourceStamen from "ol/source/Stamen";
import OlSourceTileWMS from "ol/source/TileWMS";
import OlSourceImageWMS from "ol/source/ImageWMS";

import OlLayerTile from "ol/layer/Tile";
import OlLayerImage from "ol/layer/Image";
import OlLayerVector from "ol/layer/Vector";
import OlLayerGroup from "ol/layer/Group";

import OlCollection from "ol/Collection";

import { flatLayersArray } from "Lib/olHelpers";

export const mapService = {
  getBaseLayers,
  getLayers,
  getDefaultData
};

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

function getDefaultData() {
  const apiInstance = new api();
  return apiInstance.Call("map/defaults", "get").then((resp) => {
    if (resp && resp.data) {
      return resp.data;
    }
    return null;
  })
}

function getSampleBaseLayers() {
  const layerOSM =  new OlLayerTile({
    source: new OlSourceOSM()
  });
  layerOSM.set("baseLayer", true);

  const layerStamen = new OlLayerTile({
    source: new OlSourceStamen({
      layer: "watercolor"
    })
  });
  layerStamen.set("baseLayer", true);

  return Promise.resolve(
    new OlLayerGroup({
      layers: [layerStamen, layerOSM]
    })
  );
}

function getBaseLayers(t) {

  const apiInstance = new api();
  return apiInstance.Call("map/baselayers", "get").then((resp) => {
    if (resp.success) {
      const baselayerDefinitions = resp.data;
      const baselayers = prepareBaseLayers(baselayerDefinitions, t);
      return new OlLayerGroup({
          layers: baselayers,
        });
    } else {
      return new OlLayerGroup({
        layers: []
      });
    }
  });
}

function getLayers() {
  const apiInstance = new api();
  return apiInstance.Call("map/layers", "get").then((resp) => {
    if (resp.success) {
      const layerDefinitions = resp.data;
      const layers = prepareLayers(layerDefinitions);
      const coll = new OlCollection(layers);
      return coll;
    } else {
      return new OlCollection();
    }
  });
}

function prepareBaseLayers(defs, t) {
  const layers = [];
  let first = true;

  defs.forEach((def) => {
    const options = {
      source: getSource(def)
    };
    const layer = getLayer(def);
    layer.set("id", "base-" + def.code.toString());
    layer.set("title", t(def.ttoken));
    layer.set("type", "base");
    layer.set("baseLayer", true);
    layer.set("preview", layer.getPreview([2015935, 5257500], 2)); //projection: EPSG:3857, resolution
    // layer.set("preview", "http://www.culture.gouv.fr/Wave/image/memoire/2484/sap40_z0002136_v.jpg");

    if (first) {
      layer.setVisible(true);
      first = false;
    } else {
      layer.setVisible(false);
    }

    layers.push(layer);
  });

  return layers;
}

function getSource(def) {
  const layerTypes = sifrarnici.map_layer_types;

  switch (def.layer_type) {
    case layerTypes.OSM:
      return new OlSourceOSM();
    case layerTypes.Stamen:
      return new OlSourceStamen({
        layer: def.layer
      });
    case layerTypes.WMS:
      const options = {
        url: def.url,
        params: {
          LAYERS: def.layer,
          FORMAT: "image/png8"
        }
      };
      return new OlSourceTileWMS(options);
    case layerTypes.GeoServer:
      const imageWMSoptions = {
        url: gs_url,
        params: {
          LAYERS: def.layer,
          FORMAT: "image/png8"
        }
      };
      return new OlSourceImageWMS(imageWMSoptions);
    case layerTypes.Group:
      return new OlLayerGroup();
    default:
      return null;
  }
}

function getLayer(def) {
  const layerTypes = sifrarnici.map_layer_types;

  switch (def.layer_type) {
    case layerTypes.OSM:
    case layerTypes.Stamen:
    case layerTypes.WMS:
      return new OlLayerTile({
        source: getSource(def),
        visible: def.visible
      });
    case layerTypes.GeoServer:
      return new OlLayerImage({
        source: getSource(def),
        visible: def.visible
      });
    case layerTypes.Group:
      return new OlLayerGroup()
  }
};

function prepareLayers(defs) {
  const layers = [];

  defs.forEach((def) => {
    const layer = getLayer(def);
    layer.set("id", "app-" + def.code.toString());
    // layer.set("title", def.ttoken);
    layer.set("title", def.ttoken);
    layer.set("type", "app-defined");
    layer.set("layer", def.layer);
    layer.set("query", def.can_query);
    if (def.z_index) {
      layer.setZIndex(def.z_index);
    }

    if (def.parent_layer_code === null || def.parent_layer_code === undefined) {
      layers.push(layer);
    } else {
      const parentCode = def.parent_layer_code;
      const flatLayers = layers ? flatLayersArray(layers, 3) : [];
      const parentLayer = flatLayers.find((x) => x.get("id") === "app-" + parentCode);
      if (parentLayer) {
        parentLayer.setLayers(new OlCollection([...parentLayer.getLayers().getArray(), layer]));
      }
    }
  });

  return layers;
}
