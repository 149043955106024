import api from "Lib/api";

export const tagsService = {
  getTags,
  insTags,
  updTag,
  delTags,
  getPointsByTag
};

function getTags(linkId, groupsDenied = [], groupsAllowed = []) {
  const apiInstance = new api();

  return apiInstance.Call("tags-by-link/link/" + linkId, "get").then((resp) => {
    if (resp.success) {
      const tags = resp.data || [];
      const grouped_tags = [];

      tags.forEach((t) => {
        const { grupa_sort, grupa } = t;
        const groupId = "gr-" + grupa_sort;
        const groupName = grupa;
        // blacklist by group id
        if (linkId >= -1 && groupsDenied.length >= 0 && groupsDenied.indexOf(groupId) >= 0) {
          return null;
        }
        // blacklist by group name
        if (linkId >= -1 && groupsDenied.length >= 0 && groupsDenied.indexOf(groupName) >= 0) {
          return null;
        }
        // whitelist by group name
        if (linkId >= -1 && groupsAllowed.length > 0 && groupsAllowed.indexOf(groupName) === -1) {
          return null;
        }

        if (!grouped_tags.find((x) => x.id === groupId)) {
          grouped_tags.push({
            id: groupId,
            label: grupa,
            tags: []
          });
        }

        const group = grouped_tags.find((x) => x.id === groupId);
        group.tags.push(t);
      });

      return grouped_tags;
    } else {
      return Promise.reject(undefined);
    }
  });
}

function insTags(linkId, tagsList) {
  const apiInstance = new api();

  return apiInstance.Call("tags-by-link/link", "post", {
    link_id: linkId,
    tag_ids: tagsList.join()
  });
}

function updTag(tag, tagPoint, tagPoly = null) {
  const apiInstance = new api();

  return apiInstance.Call("tags-by-link/" + tag.id, "put", {
    link_id: tag.link_id,
    tag_id: tag.tag_id,
    tag_xy: tagPoint,
    tag_poly: tagPoly
  });
}

function delTags(linkId, tagsList) {
  const apiInstance = new api();

  return apiInstance.Call("tags-by-link/link", "delete", {
    link_id: linkId,
    tag_ids: tagsList.join()
  });
}


function getPointsByTag(tagId) {
  const apiInstance = new api();

  return apiInstance.Call("tags-by-link/tag/" + tagId, "get");
}