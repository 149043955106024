import React, { useContext, useEffect, useState } from "react";
import { MapContext } from "../../MapContext";

import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { getDistance } from "ol/sphere";
import { transform } from "ol/proj";

import ScaleRatioRoot from "Components/Map/Controls/ScaleRatio/ScaleRatioRoot";

function ScaleRatioControl(props) {
  const { ppi, viewOptions } = props;
  const mapContext = useContext(MapContext);

  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
      showScale();
  }, [viewOptions]);

  const handleFieldValue = function (value) {
    setFieldValue(value);
  };

  const setMap = function (map) {};

  const showScale = function () {
    const map = mapContext.map;
    if (map) {
        const view = map.getView();
        const proj = view.getProjection();
        const center = view.getCenter();
        let px = map.getPixelFromCoordinate(center);
        px[1] += 1;
        let coord = map.getCoordinateFromPixel(px);
        let d = getDistance(
            transform(center, proj, "EPSG:4326"),
            transform(coord, proj, "EPSG:4326")
        );
        d *= ppi / 0.254;
        handleFieldValue(formatScale(d*10));
    }
  };

  const formatScale = function (d) {
    if (d > 100) {
      d = Math.round(d / 100) * 100;
    } else {
      d = Math.round(d);
    }
    return "1:" + d.toString();
  };

  const setScale = function (value) {
    const map = mapContext.map;
    if (map && value) {
    const view = map.getView();
      let fac;
      if (value[1] === ":") {
        fac = value.split(":")[1];
        fac = parseInt(fac)/10;
    } else {
        return setScale(formatScale(value));
      }
      const d = getD();
      view.setResolution(view.getResolution() * (fac/d));
      // handleFieldValue(formatScale(fac));
    }
    showScale();
  };

  const getD = function() {
    const map = mapContext.map;
    if(map) {
        const view = map.getView();
        const proj = view.getProjection();
        const center = view.getCenter();
        let px = map.getPixelFromCoordinate(center);
        px[1] += 1;
        let coord = map.getCoordinateFromPixel(px);
        let d = getDistance(
          transform(center, proj, "EPSG:4326"),
          transform(coord, proj, "EPSG:4326")
        );
        d *= ppi / 0.254;
        return d;
    }
  }

  const handleEnterKey = function (evt) {
    if (evt.key === "Enter") {
      setScale(evt.target.value);
    }
  };

  return (
    <ScaleRatioRoot>
        <input
          value={fieldValue}
          onChange={(evt) => handleFieldValue(evt.target.value)}
          onKeyDown={(evt) => handleEnterKey(evt)}
        />
    </ScaleRatioRoot>
  );
}

export default ScaleRatioControl;
