import React from 'react'

//Material-UI Core Components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const customButtonStyles = makeStyles(theme => ({
  root: {
    marginLeft: 5,
    marginRight: 5,
    overflowY: "hidden"
  }})
);

function ToolbarButton(props) {
  const classes = customButtonStyles();

  return (
    <Button classes={classes} color="primary" variant="contained" size="small" {...props}>
      {props.children}
    </Button>
  );
}

export default ToolbarButton;