import React from 'react';
import moment from "moment";
import PropTypes from 'prop-types';

// Material-UI Core Components
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// Material-UI Icons
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import PersonIcon from '@material-ui/icons/Person';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import HelpIcon from '@material-ui/icons/Help';

// Custom components
import UngroupIcons from 'Components/Scheduler/UngroupIcons';
import SchedulerPicker from 'Components/Scheduler/SchedulerPicker';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(255, 0, 0, 1)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const errorColor = makeStyles(theme => ({
  root: {
    color: theme.palette.error.dark,
    fontWeight: "bold"
  }}));

const goodColor = makeStyles(theme => ({
  root: {
    color: "#000"
  }}));

function DisplayIcons(props) {
  const { recordId } = props;
  const { registration, driver, description, cooperant } = props.showDetails;

  const vozaci = [];
  const classes = goodColor();
  const classesConflict = errorColor();

  let vozaciConf = 0;
  let voziloConf = false;

  let voziloTTip;
  let vozacTTip;

  if(props.conflicts){
    props.conflicts.forEach(item => {
      if(item.type === 'vozilo'){
        voziloConf = true;
        voziloTTip = item.conflictIntervals.map((tTip, i) => {
          return(`${tTip.from.format('DD.MM HH:mm')} - ${tTip.to.format('DD.MM HH:mm')}`)
        })

      } else {
        vozaciConf++;
        vozacTTip = item.conflictIntervals.map((tTip, i) => {
          return(`${tTip.from.format('DD.MM HH:mm')} - ${tTip.to.format('DD.MM HH:mm')}`)
        })
      }
    })
  }
  let tempVozaciConf = vozaciConf;

  driver.content.forEach((item, i) => {
    if(item.id){
      const conflictCondition = (tempVozaciConf > 0) && item.id === props.conflictsActive.id && props.styleBasedConflict;
      vozaci.push(
        <div key={`div${i}`} style={{display: "inline-block"}}>
          {item.label === '?' ?
            <HelpIcon color="disabled" fontSize="small" key={'vozac-' + i}/>
            :
            <div>
              <PersonIcon fontSize="small" classes={ tempVozaciConf > 0 ? classesConflict : classes } key={`icon${i}`} onClick={tempVozaciConf-- > 0 ? () => props.onIconClick({trjobId: props.trjobId, id: item.id, timeSpan: props.timeSpan}) : null}/>
              {driver.state ?
                <div style={{float: "right"}} key={`text${i}`}>
                  <SchedulerPicker conflict={conflictCondition} label={item.label} modelRecord={props.modelRecord} modelPicker={props.modelVozac} fieldName={"vozac" + (i+1)} fieldValue={item.id} recordId={recordId} autoFocus={true}/>
                </div>
                : null
              }
            </div>
          }
        </div>
      );
    }
  })

  return(
      <div className="scheduler-span-icons">
        <div >
          <div className="scheduler-span-icons-inline">
            {
              props.icon === 'group' ?
                <KeyboardArrowUpIcon fontSize="small" color="primary" onClick={() => { props.handleHide(props.id, true) }} />
              : props.icon === 'ungroup' ?
                <KeyboardArrowDownIcon fontSize="small" color="primary" onClick={() => { props.handleHide(props.id, false) }} />
              : null
            }
          </div>

          { props.icon === 'ungroup' ?
            <UngroupIcons
              subjobs={props.subjobs}
              trjobId={props.trjobId}
              driverState={driver.state}
              registrationState={registration.state}
              allConflicts={props.allConflicts}
              classes={classes}
              classesConflict={classesConflict}
              conflictsActive={props.conflictsActive}
              onIconClick={props.onIconClick}
              modelVozilo={props.modelVozilo}
              modelVozac={props.modelVozac}
            />
            : null
          }

          { cooperant.content.id && props.subjobs.length === 0 ?
            <div className="scheduler-span-icons-inline">
              <AirportShuttleIcon fontSize="small" />
              {cooperant.state ?
                <span style={{float: "right"}}>&nbsp;{cooperant.content.label}&nbsp;</span>
                : null
              }
            </div>
            : null
          }

          { registration.content.id && !cooperant.content.id && props.subjobs.length === 0  ?
            <LightTooltip
              title={''/*
                voziloTTip && typeof voziloTTip == 'object' ?
                voziloTTip.map((item, i) => {
                  return(<div key={`vozilo${i}`}>{item}</div>)
                })
                : ''
              */}
              placement="top"
            >
              <div className="scheduler-span-icons-inline">
                <DirectionsBusIcon fontSize="small" classes={ voziloConf ? classesConflict : classes } onClick={voziloConf ? () => props.onIconClick({trjobId: props.trjobId, id: registration.content.id, timeSpan: props.timeSpan}) : null}/>
                {registration.state ?
                  <span style={{float: "right"}}>
                    <SchedulerPicker conflict={voziloConf && registration.content.id === props.conflictsActive.id && props.styleBasedConflict} label={registration.content.label} modelRecord={props.modelRecord} modelPicker={props.modelVozilo} fieldName={"vozilo"} fieldValue={registration.content.id} recordId={recordId} autoFocus={true}/>
                  </span>
                  : null
                }
              </div>
            </LightTooltip>
          : null}

          { vozaci.length !== 0 && !cooperant.content.id && props.subjobs.length === 0  ?
            <LightTooltip
              title={'' /*
                vozacTTip && typeof voziloTTip == 'object' ?
                vozacTTip.map((item, i) => {
                  return(<div key={`vozac${i}`}>{item}</div>)
                })
                : ''
              */}
              placement="top"
            >
              <div className="scheduler-span-icons-inline">
                {vozaci.map(person => person)}
              </div>
            </LightTooltip>
            : null
          }
          { description.state ?
            <div className="scheduler-span-icons-inline" style={{float: "right", diplay: "inline"}}>&nbsp;{description.content.label}&nbsp;</div>
            : null
          }
        </div>
        <div className="scheduler-span-icons-hover"></div>
      </div>
  )
}

DisplayIcons.propTypes = {
  modelVozilo: PropTypes.object,
  modelVozac: PropTypes.object,
  modelRecord: PropTypes.object,
  recordId: PropTypes.number,
  trjobId: PropTypes.number,
}

export default DisplayIcons