import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { Translate } from "ol/interaction";

export default function TranslateInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    features: undefined,
    layers: undefined,
    filter: undefined,
    hitTolerance: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined,
    translateend: undefined,
    translatestart: undefined,
    translating: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Translate, props, options, events),
    []
  );

  return null;
}
