import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import UserDialog from "Views/UsersPage/UserDialog";

import model from "Models/user";
import dataController from "Lib/dataController";

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

function UsersTable(props) {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const [records, setRecords ] = useState([]);
  const [notAuth, setNotAuth] = useState(false);
  const [tableControls, setTableControls] = useState(true);
  const [loading, setLoading] = useState(false);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }

  const handleAdd = (evt) => {
    dialogContext.showDialog(UserDialog, {
      model: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;

    dialogContext.showDialog(UserDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  };

  const handleTableEdit = (recordId) => {
    dialogContext.showDialog(UserDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    // dummyRecords();
    setLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      } else {
        const { error } = resp;
        if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
          setNotAuth(true);
        }
      }
      setLoading(false);
    });
  };

  return (
    <ModelTable records={records} dc={dc}
      allowSelection="one"
      allowFilter={true}
      allowExport={true}
      disableControls={!tableControls}
      inlineEdit={true}
      onEdit={handleTableEdit}
      notAuth={notAuth}
      initialPageSize={100}
      isLoading={loading}
      stickyHeader={true}
      onAdd={handleAdd}
      title="titles.users"
      addGender="m"
    />
  );
}

export default UsersTable;
