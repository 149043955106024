import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import TagDialog from "Views/TagsPage/TagDialog";
import TaggingSelectControl from "Components/TaggingControl/TaggingSelectControl"

import model from "Models/tag";
import dataController from "Lib/dataController";
import { union, difference } from "Lib/setHelpers";

function TagsTable(props) {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const [records, setRecords ] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState(null);
  const [tableControls, setTableControls] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [notAuth, setNotAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  useEffect(() => {
    filterRecords();
  }, [selectedTags]);

  const handleSelectGroup = (evt, group) => {
    const newGroups = new Set(selectedGroups);
    const groupId = group.id;
    const groupIdSet = new Set([groupId]);
    const deselected = selectedGroups.indexOf(groupId) >= 0;
    if (deselected) {
      setSelectedGroups([...difference(newGroups, groupIdSet)]);
    } else {
      setSelectedGroups([...union(newGroups, groupIdSet)]);
    }
    handleSelectMultiTags(group, deselected);
  }

  const handleDeselectGroup = (groupId) => {
    setSelectedGroups([...difference(new Set(selectedGroups), new Set([groupId]))]);
  }

  const handleSelectMultiTags = (group, deselected) => {
    const groupedTagIds = group && group.tags && Array.isArray(group.tags) ? group.tags.map((tag) => (tag.id)) : []
    const newTags = new Set(groupedTagIds);
    if (deselected) {
      setSelectedTags([...difference(new Set(selectedTags), newTags)]);
    } else {
      setSelectedTags([...union(new Set(selectedTags), newTags)]);
    }
  }

  const handleSelectTag = (evt, tag) => {
    const newTags = new Set(selectedTags);
    const tagId = tag.id;
    const deselected = selectedTags.indexOf(tagId) >= 0;
    if (deselected) {
      setSelectedTags([...difference(newTags, new Set([tagId]))]);
    } else {
      setSelectedTags([...union(newTags, new Set([tagId]))]);
    }
    const groupId = "gr-" + tag.grupa_sort;
    if (deselected && selectedGroups.indexOf(groupId) >= 0) {
      handleDeselectGroup(groupId)
    }
  }

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }

  const handleAdd = (evt) => {
    dialogContext.showDialog(TagDialog, {
      model: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;

    dialogContext.showDialog(TagDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  };

  const handleTableEdit = (recordId) => {
    dialogContext.showDialog(TagDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    // dummyRecords();
    setLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      } else {
        const { error } = resp;
        if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
          setNotAuth(true);
        }
      }
      setLoading(false);
    });
  };

  const filterRecords = () => {
    if (selectedTags.length === 0) {
      if (records.length) {
        setFilteredRecords(records);
      }
      return;
    }
    const fRecords = records.filter(r => {
      return selectedTags.indexOf(r.id) >= 0;
    });
    setFilteredRecords(fRecords);
  }

  const clearSelection = () => {
    setSelectedGroups([]);
    setSelectedTags([]);
  }

  return (
    <ModelTable records={filteredRecords || records || []}
      dc={dc}
      allowSelection="one"
      allowFilter={true}
      allowExport={true}
      disableControls={!tableControls}
      inlineEdit={true}
      onEdit={handleTableEdit}
      initialPageSize={100}
      notAuth={notAuth}
      isLoading={loading}
      stickyHeader={true}
      onAdd={handleAdd}
      title="titles.tags"
      addGender="f"
      PreComponent={        
        <TaggingSelectControl 
          groupSelect={true} 
          linkId={-1} 
          selectedTags={selectedTags} 
          onSelectTag={handleSelectTag} 
          selectedGroups={selectedGroups} 
          onSelectGroup={handleSelectGroup} 
          clearSelection={clearSelection}
        />
      }
    />
  );
}

export default TagsTable;
