import React from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";

//Material-UI Core Components
import withTheme from "@material-ui/core/styles/withTheme";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";

//Material-UI Icons
import ErrorIcon from "@material-ui/icons/Error";

//Custom Components
import DisplayIcons from "Components/Scheduler/DisplayIcons";
import SchedulerGridHeader from "Components/Scheduler/SchedulerGridHeader";
import SchedulerGridRow from "Components/Scheduler/SchedulerGridRow";
import SchedulerGridBar from "Components/Scheduler/SchedulerGridBar";

let drag_animation = null;

function DragComponent(props) {
  return (
    <Draggable handle={"#scheduler"}>
      <Paper {...props} />
    </Draggable>
  );
}

class SchedulerGrid extends React.Component {
  constructor(props) {
    super(props);

    this.drag = this.drag.bind(this);
    this.drag_start = this.drag_start.bind(this);
    this.drag_end = this.drag_end.bind(this);

    this.refreshSize = this.refreshSize.bind(this);

    this.drag_offset = 0;
    this.mouse_down = false;
    this.last_x = 0;
    this.day_classes = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    this.state = {
      grid_width: 0,
      grid_height: 0,
      cell_width: 15
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.refreshSize);
    //this.attach_events();

    this.refreshSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.refreshSize);
    this.deattach_events();
  }

  drag(evt) {
    if (this.mouse_down) {
      this.drag_offset += evt.clientX - this.last_x;
    }
    this.last_x = evt.clientX;
  }

  drag_start(evt) {
    const { cell_width } = this.props;

    console.log("drag start!");
    if (drag_animation != null) {
      clearTimeout(drag_animation);
    }
    this.last_x = evt.clientX;
    this.drag_offset = 0;
    this.mouse_down = true;
    drag_animation = setInterval(() => {
      let n_cells = 0;
      if (this.drag_offset > 0) {
        n_cells = Math.ceil(this.drag_offset / (cell_width * 2));
      } else {
        n_cells = Math.floor(this.drag_offset / cell_width);
      }
      this.drag_offset = 0;
      if (n_cells != 0) this.shift_cells(n_cells);
    }, 150);
  }

  drag_end() {
    const { cell_width } = this.state;

    console.log("drag_end");

    this.mouse_down = false;
    this.forceUpdate();
    if (drag_animation) {
      clearInterval(drag_animation);
      drag_animation = null;
    }
    let n_cells = Math.round(this.drag_offset / cell_width);
    this.drag_offset = 0;
    if (n_cells) this.shift_cells(n_cells);
    // console.log( 'drag end!!!', n_cells );
  }

  shift_cells(n_cells) {
    const { cell_width } = this.state;
    const { startDate, res_h, day_offset } = this.props;

    let res = Math.floor(24.0 / res_h);
    let len = cell_width * res;
    let days = Math.ceil(n_cells / res);
    let mask = 0;
    if (n_cells > 0) {
      let offset = n_cells - Math.floor(n_cells / res) * res;
      mask = -len * (offset / res);
    } else if (n_cells < 0) {
      let offset = Math.abs(n_cells) + days * res;
      mask = -len + len * (1 - offset / res);
      mask += day_offset;
      if (Math.abs(mask) > len) {
        days = -1;
        mask = 0;
      }
    }
    if (days) {
      this.change_startdate(startDate.clone().add(-days, "days"));
    }
    // set offset
    this.setState({ day_offset: mask });

    // console.log( n_cells, days, mask, n_cells > 0 );
  }

  range_move(evt) {
    const { cell_width } = this.state;
    const { res_h, onCellEnter } = this.props;

    let cell_offset = Math.floor((evt.clientX - 200) / cell_width);
    let divisions = Math.floor(24.0 / res_h);
    let days = Math.floor(cell_offset / divisions);
    let div = cell_offset - days * divisions;

    if (onCellEnter) {
      onCellEnter(evt, days, div);
    }
  }

  attach_events() {
    try {
      // this.refs.dateelements.addEventListener( 'wheel', this.scroll.bind( this ) );
      const headerElements = Array.from(this.refs.dateelements.getElementsByClassName("scheduler-header-day"));
      headerElements.forEach((itm) => {
        // TODO: drag evt for android is missing (https://www.kirupa.com/html5/drag.htm)
        itm.addEventListener("mousemove", this.drag);
        itm.addEventListener("mousedown", this.drag_start);
        itm.addEventListener("mouseup", this.drag_end);
      });
      this.refs.dateelements.addEventListener("mouseleave", this.drag_end);
    } catch (e) {
      console.error("Unable to attach scroll event to scheduler control!", e);
    }

    try {
      // this.refs.dateelements.addEventListener( 'wheel', this.scroll.bind( this ) );
      const cellElements = Array.from(this.refs.dragElements.getElementsByClassName("scheduler-cell"));
      cellElements.forEach((itm) => {
        // TODO: drag evt for android is missing (https://www.kirupa.com/html5/drag.htm)
        itm.addEventListener("mousemove", this.drag);
        itm.addEventListener("mousedown", this.drag_start);
        itm.addEventListener("mouseup", this.drag_end);
      });
      this.refs.dragElements.addEventListener("mouseleave", this.drag_end);
    } catch (e) {
      console.error("Unable to attach scroll event to scheduler control!", e);
    }
  }

  deattach_events() {
    try {
      // this.refs.dateelements.removeEventListener( 'wheel', this.scroll.bind( this ) );
      Array.from(this.refs.dateelements.getElementsByClassName("scheduler-header-day")).forEach((itm) => {
        // TODO: drag evt for android is missing
        itm.removeEventListener("mousemove", this.drag);
        itm.removeEventListener("mousedown", this.drag_start);
        itm.removeEventListener("mouseup", this.drag_end);
      });
      this.refs.dateelements.removeEventListener("mouseleave", this.drag_end);
    } catch (e) {
      console.error("Error deattaching scroll event from scheduler control!", e);
    }
  }

  get_date_format() {
    const { res_h } = this.props;

    switch (res_h) {
      case 1:
      case 2:
      case 3:
      case 4:
        return "DD.MM.YYYY";
      case 6:
        return "DD.MM.YY";
      case 8:
        return "DD.MM";
      case 12:
      case 24:
        return "DD";
      default:
        return "DD.MM";
    }
  }

  get_date_name(date) {
    const { res_h } = this.props;

    let names;
    if (res_h == 12 || res_h == 24) {
      names = ["N", "P", "U", "S", "Č", "P", "S"];
    } else if (res_h == 6 || res_h == 8) {
      names = ["NED", "PON", "UTO", "SRI", "ČET", "PET", "SUB"];
    } else {
      names = ["nedjelja", "ponedjeljak", "utorak", "srijeda", "četvrtak", "petak", "subota"];
    }

    return names[date.day()];
  }

  scheduler_days() {
    const { grid_width, cell_width } = this.state;
    const { res_h, startDate } = this.props;

    return Math.floor(grid_width / (cell_width * Math.floor(24.0 / res_h)));
  }

  update_cell_width(res) {
    const { grid_width, cell_width } = this.state;
    const { res_h } = this.props;

    let min_width = 0;
    if (res == 24) {
      min_width = 17;
    }

    let cw = Math.max(15, min_width); // assumed default

    // how many is missing for fill!?
    let n_cells = this.scheduler_days() * Math.floor(24.0 / res_h);
    let delta = grid_width - n_cells * cw;

    if (delta) {
      let v = Math.max(0, Math.min(Math.ceil(delta / n_cells), 5)); // again assumption of 15 px cell width so 1/3 increas will be ok
      cw += v;
    }
    this.setState({ cell_width: cw });
    if (this.props.onUpdateCellWidth) {
      this.props.onUpdateCellWidth(cw);
    }

    let days = Math.floor(grid_width / (cell_width * Math.floor(24.0 / res)));
    //this.data_provider.UpdateDateRange(startDate, days);
  }

  refreshSize() {
    const { grid_width, grid_height } = this.state;
    const { res_h, row_height } = this.props;

    // if (this.refs.gridcontainer) {
    //   let bbox = this.refs.gridcontainer.getBoundingClientRect();
    //   if (bbox.width != grid_width) {
    //     this.setState({ grid_width: bbox.width });

    //     this.update_cell_width(res_h);
    //     //TODO: check why timeout
    //     setTimeout(() => {
    //       this.update_cell_width(res_h);
    //     }, 0);
    //     // setTimeout(this.attach_events.bind(this), 0);
    //   }
    // }
    if (this.refs.scheduler) {
      let bbox = this.refs.scheduler.getBoundingClientRect();
      if (bbox.width != grid_width) {
        this.setState({ grid_width: bbox.width });

        this.update_cell_width(res_h);
        //TODO: check why timeout
        setTimeout(() => {
          this.update_cell_width(res_h);
        }, 0);
        // setTimeout(this.attach_events.bind(this), 0);
      }
      // let height = window.screen.height - bbox.top - this.row_height;
      let height = window.innerHeight - bbox.top - row_height - 50;
      if (height != grid_height) {
        this.setState({ grid_height: height });
      }
    }
  }

  handleDragStart(evt, obj) {}

  handleDrag(evt, obj) {}

  handleDragStop(evt, obj) {
    console.log(obj.deltaX, obj, evt);
  }

  item_group_state(item_id, state) {
    // this.sdc.ChangeGroupState(item_id, state);
    // this.forceUpdate();
  }

  render() {
    const { grid_height, grid_width, cell_width } = this.state;
    const { theme } = this.props;
    const { startDate, day_offset, row_height, res_h, records, conflicts } = this.props;
    const { onMouseMove, onCellEnter, onCellLeave, onItemClick, onToggleDisplay } = this.props;

    const divisions = Math.floor(24.0 / res_h);

    // console.log('render SchedulerGrid', this.props);

    const n_days = this.scheduler_days();
    const end_date = startDate.clone().add(n_days, "days");

    const n_rows = Math.floor(grid_height / row_height) + 1;

    let rows = records.map((x) => x);

    for (let i = records.length; i < n_rows; i++) {
      rows.push({
        id: -1,
        label: " ",
        icon: null
      });
    }

    let day_cells = new Array(n_days);
    for (let i = 0; i < n_days; i++) {
      let date = startDate.clone().add(i, "days");
      let conflict = false;
      let tooltipNum = 0;

      conflicts.forEach((item, j) => {
        if (date.format("MM.DD") >= item.from.format("MM.DD") && date.format("MM.DD") <= item.to.format("MM.DD")) {
          conflict = true;
          tooltipNum++;
        }
      });

      day_cells[i] = {
        day: i,
        day_lbl: date.format(this.get_date_format()),
        name: this.get_date_name(date),
        width: divisions * cell_width,
        day_class: "scheduler-header-day " + this.day_classes[date.day()],
        cells: Array.apply(null, { length: divisions }).map(Number.call, Number),
        conflict: conflict,
        tooltip: tooltipNum
      };
    }

    {
      /* <Draggable
        axis="x"
        handle=".handle"
        //defaultPosition={{x: 0, y: 0}}
        position={null}
        grid={[cell_width, row_height]}
        scale={1}
        onStart={this.handleDragStart}
        onDrag={this.handleDrag}
        onStop={this.handleDragStop}
      > */
    }
    return (
      <div id="scheduler" ref="scheduler">
        <SchedulerGridHeader
          day_offset = {day_offset}
          day_cells = {day_cells}
          row_height = {row_height}
          res_h = {res_h}
        />
        <div
          ref="dragElements"
          className="scheduler-scroll handle"
          style={{ maxHeight: `${grid_height}px`, overflowY: "auto" }}
        >
          {rows.map((r, i) => (
            <div className="scheduler-row scheduler-data" style={{ height: row_height }} key={i}>
              <div className="scheduler-row-gridwrapper" style={{ height: row_height }}>
                <SchedulerGridRow
                  day_offset={day_offset}
                  day_cells={day_cells}
                  cell_width={cell_width}
                  onCellEnter={onCellEnter}
                  onCellLeave={onCellLeave}
                />
                {r.id > -1 ? (
                  <SchedulerGridBar
                    r={r}
                    day_offset={day_offset}
                    grid_width={grid_width}
                    onClick={onItemClick}
                    onMouseMove={this.range_move.bind(this)}
                    onCellLeave={onCellLeave}
                  />
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

SchedulerGrid.propTypes = {
  day_offset: PropTypes.number,
  res_h: PropTypes.number,
  rows: PropTypes.array,
  row_height: PropTypes.number,
  onMouseMove: PropTypes.func,
  onCellEnter: PropTypes.func,
  onCellLeave: PropTypes.func,
  onItemClick: PropTypes.func,
  onToggleDisplay: PropTypes.func,
  records: PropTypes.array,
  conflicts: PropTypes.array
};

export default withTheme(SchedulerGrid);
