import React, { Fragment } from "react";
import Util from "../Util";

import ZoomControl from "./OpenLayers/ZoomControl";
import AttributionControl from "./OpenLayers/AttributionControl";
import RotateControl from "./OpenLayers/RotateControl";

export default function DefaultControls(props) {
  const options = {
    attribution: true,
    attributionOptions: undefined,
    rotate: true,
    rotateOptions: undefined,
    zoom: true,
    zoomOptions: undefined

  };

  const allOptions = Object.assign(options, props);
  const definedOptions = Util.getDefinedOptions(allOptions);

  return (
    <Fragment>
      {definedOptions.attribution === true ? (
        <AttributionControl {...definedOptions.attributionOptions} />
      ) : null}
      {definedOptions.rotate === true ? (
        <RotateControl {...definedOptions.rotateOptions} />
      ) : null}
      {definedOptions.zoom === true ? <ZoomControl {...definedOptions.zoomOptions} /> : null}
    </Fragment>
  );
}
