import OlLayerGroup from "ol/layer/Group";

export default function findLayer(map, id) {
  let layer = null;
  const idKey = 'id';

  if (map) {
    const mapRootLayers = map.getLayers().getArray();
    const mapLayer = mapRootLayers.find(x => x.get(idKey) === id);
    if (mapLayer) {
      return mapLayer;
    }

    mapRootLayers.filter( x => x instanceof OlLayerGroup).forEach(groupLayer => {
      const mapLayer = groupLayer.getLayers().getArray().find(x => x.get(idKey) === id);
      if (mapLayer) {
        layer = mapLayer;
      }
    });
  }

  return layer;
}