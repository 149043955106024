import React, { useState, useEffect, useRef } from "react";

import OlMap from "ol/Map";
import OlView from "ol/View";

import { MapContext } from "./MapContext";
import Util from "./Util";
import { Controls } from "./Controls";
import { Layers } from "./Layers";
import { Overlays } from "./Overlays";
import { Interactions } from "./Interactions";

import "ol-ext/dist/ol-ext.js";
import "ol-ext/dist/ol-ext.css";

export default function Map(props) {
  const mapElementRef = useRef();

  // we use useRef, as it provides us mutable object that persists between re-renders
  const map = useRef(null);
  const [ initialized, setInitialized] = useState(false);

  const initOptions = {
    controls: [],
    layers: [],
    overlays: [],
    interactions: []
  };

  const options = {
    controls: undefined,
    pixelRatio: undefined,
    interactions: undefined,
    keyboardEventTarget: undefined,
    layers: undefined,
    maxTilesLoading: undefined,
    moveTolerance: undefined,
    overlays: undefined,
    target: undefined,
    view: new OlView({ center: [0, 0], zoom: 3 })
  };

  const events = {
    change: undefined,
    "change:layerGroup": undefined,
    "change:size": undefined,
    "change:target": undefined,
    "change:view": undefined,
    click: undefined,
    dblclick: undefined,
    error: undefined,
    moveend: undefined,
    movestart: undefined,
    pointerdrag: undefined,
    pointermove: undefined,
    postcompose: undefined,
    postrender: undefined,
    precompose: undefined,
    propertychange: undefined,
    rendercomplete: undefined,
    singleclick: undefined
  };

  const { zoomToExtent, zoomToExtentPadding, changeZoom, getMap } = props;

  const controlsComp = Util.findChild(props.children, Controls);
  const layersComp = Util.findChild(props.children, Layers);
  const overlaysComp = Util.findChild(props.children, Overlays);
  const interactionsComp = Util.findChild(props.children, Interactions);

  useEffect(() => {
    if (!map.current) {
      let allOptions = Object.assign(options, props);
      let mapOptions = Util.getDefinedOptions(allOptions);
      !(mapOptions.view instanceof OlView) && (mapOptions.view = new OlView(options.view));

      if (controlsComp) {
        mapOptions.controls = initOptions.controls;
      } else {
        // do nothing, we leave controls prop undefined -> OpenLayers will assign default controls
      }
      mapOptions.layers = initOptions.layers;
      mapOptions.overlays = initOptions.overlays;

      if (interactionsComp) {
        mapOptions.interactions = initOptions.interactions;
      } else {
        // do nothing, we leave interactions prop undefined -> OpenLayers will assign defult interactions
      }

      let newMap = new OlMap(mapOptions);
      newMap.setTarget(mapElementRef.current);

      //regitster events
      let olEvents = Util.getEvents(events, props);
      for (let eventName in olEvents) {
        newMap.on(eventName, olEvents[eventName]);
      }

      //assign newMap to our ref object
      map.current = newMap;
      setInitialized(true);
      if (getMap) {
        getMap(newMap);
      }
      //console.log(newMap);
    }
  });

  useEffect(() => {
    //let padding = zoomToExtentPadding ? zoomToExtentPadding : [100, 100, 100, 600];
    if (map.current && zoomToExtent && changeZoom) {
      map.current.getView().fit(zoomToExtent, {
        size: map.current.getSize(),
        //padding: padding
      });
    }
  }, [zoomToExtent]);

  useEffect(() => {
    if (map.current && props.view) {
      map.current.getView().setCenter(props.view.center);
    }
  }, [props.view.center]);

  useEffect(() => {
    if (map.current && props.view) {
      map.current.getView().setZoom(props.view.zoom);
    }
  }, [props.view.zoom])

  return (
    <MapContext.Provider
      value={{
        map: map.current,
        initOptions: initOptions
      }}
    >
      <div className="map-container">
        <div id={props.id || 'testmap'} className="map openlayers-map sidebar-map" ref={mapElementRef}>
          {layersComp ? React.createElement(Layers, layersComp.props) : null}
          {controlsComp ? React.createElement(Controls, controlsComp.props) : null}
          {interactionsComp ? React.createElement(Interactions, interactionsComp.props) : null}
        </div>
        {overlaysComp ? React.createElement(Overlays, overlaysComp.props) : null}
      </div>
    </MapContext.Provider>
  );
}
