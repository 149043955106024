import React, { useContext, useRef, useEffect, useCallback } from "react";
import { registerOlControl, findControl } from "../helpers";
import Util from "../../Util";

import { MapContext } from "../../MapContext";
import { SidebarControlContext } from "./SidebarControlContext";

import Sidebar from "./ol5-sidebar";
import "./ol3-sidebar.css";

export default function SidebarControl(props) {
  const context = useContext(MapContext);
  const sidebarRef = useRef(null);

  // var element = document.createElement("div");
  // element.id = "sidebar";
  // element.className = "sidebar collapsed";

  // sidebarRef.current = element;

  useEffect(() => {
    // console.log("useEffect Sidebar", props);

    if (sidebarRef.current) {
      const options = {
        element: sidebarRef.current,
        position: "left",
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = Util.getDefinedOptions(allOptions);

      const events = {};

      let control = new Sidebar(definedOptions);

      if (props.id) {
        control.set("id", props.id);
      }

      if (context.map) {
        const mapControl = findControl(context.map, props.id, Sidebar);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
        context.map.addControl(control);
      } else {
        context.initOptions.controls.push(control);
      }

      let olEvents = Util.getEvents(events, props);
      for (let eventName in olEvents) {
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, Sidebar);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <div id="sidebar" className="sidebar collapsed" ref={sidebarRef}>
      <SidebarControlContext.Provider value={{ sidebar: sidebarRef.current }}>
        {props.children}
      </SidebarControlContext.Provider>
    </div>
  );
}
