import React, { Fragment } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

//Custom Components
import AppHeader from "UI/Header/AppHeader";
import Sidebar from "UI/Sidebar/Sidebar";
import Footer from "UI/Footer/Footer";

import menuRoutes from "Routes/menu";

import UserContext from "Components/UserContext/UserContext";
import UserConsumer from "Components/UserContext/UserConsumer";
import { authService } from "Services/authService";

import withRedirect from "Components/withRedirect";

const style = (theme) => ({
  content: {
    flexGrow: 1,
    marginTop: "48px !important",
    [theme.breakpoints.up("xl")]: {
      padding: props => props.noPadding ? 0 : theme.spacing(2)
    },
    [theme.breakpoints.down("lg")]: {
      padding: props => props.noPadding ? 0 : theme.spacing(1)
    }
  }
});

class AppPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    console.log("sidebar prevState", this.state.sidebarOpen);
    this.setState((prevState) => ({
      sidebarOpen: !prevState.sidebarOpen
    }));
  }

  render() {
    const { needRole } = this.props;
    const { redirect } = this.props; //HOC withRedirect
    const { classes } = this.props; //HOC withStyles
    const { isHomePage } = this.props;
    const { sidebarOpen } = this.state;

    let userContext = this.context;

    if (needRole && !userContext.hasAnyRole(needRole)) {
      console.warn("Tried to access forbidden page, Logging out...");
      //redirect("/");
      //return null;
    }
      return (
        <div id="page">
          <AppHeader menu={menuRoutes} sidebarOpen={sidebarOpen} toggleSidebar={this.toggleSidebar} />
          <Sidebar menu={menuRoutes} sidebarOpen={sidebarOpen} toggleSidebar={this.toggleSidebar} />
          <main id="content-wrapper" className={classes.content}>
            <div id="content">{this.props.children}</div>
          </main>
          {/*
            isHomePage ?
            <Footer />
            : null
          */}
        </div>
      );
    //else {
    //}
  }
}

AppPage.contextType = UserContext;

AppPage.defaultProps = {
  needRole: []
};

AppPage.propTyps = {
  needRole: PropTypes.array
};

export default withRedirect(withStyles(style)(AppPage));
