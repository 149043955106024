import React, { useContext, useEffect, useRef } from "react";
import { SidebarControlContext } from "./SidebarControlContext";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  }
}));

export default function SidebarContent(props) {
  const context = useContext(SidebarControlContext);
  const classes = useStyles();
  const contentRef = useRef(null);

  const element = document.createElement("div");
  element.className = "sidebar-content";

  contentRef.current = element;

  useEffect(() => {
    if (context.sidebar) {
      context.sidebar.appendChild(element);
    }
  }, []);

  // return (
  //   <SidebarControlContext.Provider value={{sidebar: context.sidebar, container: contentRef.current}}>
  //     {props.children}
  //   </SidebarControlContext.Provider>
  // );

  return (
     <div className={`${classes.root}` + " sidebar-content"}>
      {props.children}
    </div>
  )
}
