import React from 'react';

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tabs from '@material-ui/core/Tabs';

const customTabsStyles = makeStyles(theme => ({
  root: {
    paddingTop: "0px",
    position: "sticky",
    top: "0",
    zIndex: "9",
    borderBottom: `1px solid ${theme.palette.primary.main}`
  }}));

function DialogTabs(props)  {
  const { children } = props;
  const classes = customTabsStyles();

  return(
    <Tabs
      indicatorColor="secondary"
      textColor="secondary"
      variant="fullWidth"
      classes={classes} {...props} >
      {children}
    </Tabs>
  )
}

export default DialogTabs;