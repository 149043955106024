import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import { MousePosition } from "ol/control";

export default function MousePositionControl(props) {
  const context = useContext(MapContext);

  const options = {
    className: undefined,
    coordinateFormat: undefined,
    projection: undefined,
    render: undefined,
    target: undefined,
    undefinedHTML: undefined
  };

  const events = {
    change: undefined,
    "change:coordinateFormat": undefined,
    "change:projection": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, MousePosition, props, options, events), [
    props.className,
    props.coordinateFormat,
    props.projection,
    props.render,
    props.target,
    props.undefinedHTML
  ]);

  return null;
}
