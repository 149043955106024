import React from 'react'

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

const customButtonStyles =  makeStyles(theme => ({
  root: {
    marginLeft: 5,
    marginRight: 0
  }}));


function TagTableButton(props) {
  const classes = customButtonStyles();
  return (
    <Button classes={classes} color="secondary" size="small" {...props}>
      {props.children}
    </Button>
  );
}

export default TagTableButton;