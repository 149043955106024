import React, { Fragment } from 'react';
import PropTypes from "prop-types";

//Custom Components
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";

import formGenerator from "Lib/formGenerator";

const customBottomStyle = {
  alignSelf: "flex-end"
}

class FormContent extends React.Component {

  constructor(props) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);

    this.fg = new formGenerator();
  };

  handleFieldChange(value, source) {
    const { onFieldChange, onSubmit } = this.props;

    if(value && typeof value == 'object' && value.keyCode && value.keyCode == 13){
      if (onSubmit){
        onSubmit()
      } else {
        return
      }
    } else if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  getWidth(f) {
    const { columns } = this.props;

    if (f && f.width && f.width === 'full') {
      return 12;
    } else {
      switch (columns) {
        case 1:
          return 12;
        case 2:
          return 6;
        case 3:
          return 4;
        case 4:
          return 3;
        default:
          return 6;
      }
    }
  }

  render() {
    const { mode, record, fieldNames, validation, subModels, fields, showDialog } = this.props;

    const renderFields = fieldNames ? fieldNames.map(name => fields.find(f => f.source === name)) : [];

    let controls = {};
    switch (mode) {
      case 'insert':
      case 'update': {
        controls = this.fg.generateFormControls(renderFields, "form", "edit", record, validation, this.handleFieldChange, subModels, showDialog);
        break;
      }
      case 'view': {
        controls = this.fg.generateFormControls(renderFields, "form", "view", record, null, null, subModels, showDialog);
        break;
      }
    }

    renderFields.map(field => {
      field.control = controls.hasOwnProperty(field.source) ? controls[field.source] : "-"
    })

    return (
      <Fragment>
        {renderFields.map((f,i) =>
          <GridItem xs={12} sm={this.getWidth(f)} key={'grid-'+i} style={f.bottom ? customBottomStyle : null}>
          {f.control}
          </GridItem>
        )}
      </Fragment>
    );
  };
};

FormContent.defaultProps = {
  mode: "update",
  columns: 2,
  onClose: () => { }
}

FormContent.propTypes = {
  subModels: PropTypes.object
  , mode: PropTypes.oneOf(['view', 'insert', 'update'])
  , onFieldChange: PropTypes.func
  , onClose: PropTypes.func
  , fieldNames: PropTypes.arrayOf(PropTypes.string).isRequired
  , record: PropTypes.object.isRequired
  , validation: PropTypes.object
  , fields: PropTypes.arrayOf(PropTypes.object).isRequired
  , columns: PropTypes.oneOf([1, 2, 3, 4])
};

export default FormContent;