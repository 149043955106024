import React from 'react'

//Material-UI Core Components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const customTypographyStyles = makeStyles(theme => ({
  root: {
    marginLeft: 10
  }})
);

function SchedulerDrawerHeading(props) {
  const classes = customTypographyStyles();

  return (
    <Typography align="left" variant="h6" color="primary" classes={classes}>
      {props.children}
    </Typography>
  );
}

export default SchedulerDrawerHeading;