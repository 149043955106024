import React, { Fragment } from 'react'
import PropTypes from "prop-types";

//Material-UI Core Components
import withStyles from "@material-ui/core/styles/withStyles";
import Popover from '@material-ui/core/Popover';

//Custom Components
import PickerControl from 'Controls/PickerControl';

const style = {
  paper: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: 400,
    minWidth: 400
  },

  conflict: {
    color: "#FF0000",
    fontWeight: "bold"
  }
}

class SchedulerPicker extends React.Component {

  constructor(props) {
    super(props);

    this.handleShowPicker = this.handleShowPicker.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      open: false,
      anchorEl: null
    }
  }

  handleShowPicker(evt) {
    this.setState({
      open: true,
      anchorEl: evt.target
    })
  }

  handleChangeField(value, source) {
    const { modelRecord, recordId } = this.props;

    let record = modelRecord.getRecordObject(recordId);

//    record[modelRecord.id_attribute.source] = recordId;
    record[source] = value;


// TODO: brisanje vozaca i vozila kod odabiranja kooperanta

    modelRecord.UpdateRecord(recordId, record)
    .then(result => {
      if (result.success) {
        this.setState({
          open: false,
          anchorEl: null
        });
      }
    })
  }

  handleClose() {
    this.setState({
      open: false,
      anchorEl: null
    });
  }



  render() {
    const { open, anchorEl } = this.state;
    const { classes, label, modelRecord, modelPicker, fieldName, fieldValue, autoFocus, conflict } = this.props;

    const field = modelRecord ? modelRecord.getField(fieldName) : null;

    return (
      <Fragment>
        <div onClick={ modelPicker ? this.handleShowPicker: null} className={conflict ? classes.conflict : ''}>&nbsp;{label}&nbsp;</div>
        { modelPicker ?
        <Popover
          id={"singlepicker"}
          open={open}
          onClose={this.handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          classes = {{paper: classes.paper}}
        >
          <PickerControl field={field} formMode="form" controlMode="edit" model={modelPicker}
            allowClear={true} allowNewRecord={false} showHelper={false}
            onChange={this.handleChangeField}
            value={fieldValue}
            autoFocus={autoFocus}/>
        </Popover> : null }
      </Fragment>
    )
  }

}

SchedulerPicker.propTypes = {
  label: PropTypes.string,
  modelPicker: PropTypes.object,
  modelRecord: PropTypes.object,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.number,
  recordId: PropTypes.number,
  autoFocus: PropTypes.bool,
  conflict: PropTypes.bool,
}

export default withStyles(style)(SchedulerPicker);