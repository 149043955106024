import { authService } from 'Services/authService';

// The handleResponse function checks responses from the api to see if the request was unauthorised, forbidden or unsuccessful.

// If the response status is 401 Unauthorized or 403 Forbidden then the user is automatically logged out of the application,
// this handles if the user token is no longer valid for any reason. If the response contains an error
// then a rejected promise is returned that includes the error message, otherwise if the request was successful then the response data is returned as a JSON object.

export function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      //if ([401, 403].indexOf(response.status) !== -1) {
      //  // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      //  authService.logout();
      //  window.location.reload(true);
      //}

      //if (response.status === 401) {
      //  authService.logout();
      //  window.location.reload(true); 
      //} else 
      if (response.status === 400) {
        try {
          const errorStr = data.detail.replace(/"/gi, "---").replace(/'/gi, "\"").replace(/---/gi, "'");
          const error = JSON.parse(errorStr);
          return Promise.reject({ message: error.errmsg, errorCode: parseInt(error.errcode, 10) });
        } catch(e) {
          return Promise.reject({message: response.statusText, errorCode: response.status});
        }
      } else if ([401, 403].indexOf(response.status) !== -1) {
        return Promise.reject({message: "messages.not_authorized", errorCode: response.status});
      } else if (response.status === 404) {
        return Promise.reject({message: response.statusText, errorCode: response.status});
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject({ message: error, errorCode: data.errorCode});
    }

    return data;
  });
}