import React, { useState, useEffect } from "react";

//Material-UI Core Components
import Input from "@material-ui/core/Input";

//Material-UI Icons
import SearchIcon from "@material-ui/icons/Search"

//Custom Components
import useDebounce from "Components/useDebounce";

function TableSearchBar(props) {
  const { count, value, onChange } = props;

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const handleChange = function (evt) {
    setSearchTerm(evt.target.value || undefined);
  };

  // Here's where the API call happens
  // We use useEffect since this is an asynchronous action
  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (debouncedSearchTerm) {
        onChange(debouncedSearchTerm)
      } else {
        onChange(undefined);
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm]
  );

  const placeholder = `Search: ${count} records...`;

  return (
    <Input
      id="global-search"
      startAdornment={<SearchIcon/>}
      variant="filled"
      placeholder={placeholder}
      onChange={handleChange}
      //value={value || ""}
    />
  );
}

export default TableSearchBar;
