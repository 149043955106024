import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

//Material-UI Core Components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

//Custom Components
import withFormAuthController from 'Components/withFormAuthController';
import withRedirect from 'Components/withRedirect';
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import FormButton from "UI/Form/FormButton";
import UserConsumer from 'Components/UserContext/UserConsumer';
import { Role } from 'Lib/role';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleRegistration = this.handleRegistration.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFieldChange(value, source) {
    const { onFieldChange } = this.props; //HOC withFormAuthController

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  handleRegistration() {
    const { redirect } = this.props; //HOC withRedirect
    redirect("/register");
  }

  handlePasswordReset() {
    const { redirect } = this.props; //HOC withRedirect
    redirect("/password-reset-request");
  }

  handleSubmit(setUserData) {
    const { doLogin } = this.props; //HOC withFormAuthController
    const { redirect } = this.props; //HOC withRedirect

    if (doLogin) {
      doLogin().then(result => {
        if (result.success) {
          if (!result || !result.data) result = {data:{}};
          setUserData(result.data.data)
          redirect("/")
        } else if (result.validationPass === false) {
          //console.log(result.validation);
        }
      })
    }
  }

  render() {
    const { t, model, form  } = this.props;
    const { record, validation, fields, onFieldChange, dataChanged} = this.props; //HOC withFormAuthController

    let fieldNames = ["email", "password"];

    return (
      <UserConsumer>
        {(userContext) => (
          <Card>
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography variant="h5" component="h3">{t("titles.login")}</Typography>
                </GridItem>
                <FormContent
                  fieldNames={model.getFormFieldsNames(form)}
                  record={record}
                  validation={validation}
                  fields={fields}
                  onFieldChange={this.handleFieldChange}
                  onSubmit={() => this.handleSubmit(userContext.setUserData)}
                  mode={"insert"}
                  columns={1} />
              </GridContainer>
            </CardContent>
            <CardActions>
              <FormButton variant="contained" onClick={() => this.handleSubmit(userContext.setUserData)}>
                {t('buttons.login')}
              </FormButton>
              {/*
              <FormButton variant="text" onClick={this.handleRegistration}>
                {t('buttons.registration')}
              </FormButton>
              <FormButton variant="text" onClick={this.handlePasswordReset}>
                {t('buttons.password_reset')}
              </FormButton>
              */}
            </CardActions>
          </Card>
        )}
      </UserConsumer>
    )
  };
}

LoginForm.defaultProps = {
  shouldChangePassword: false
}

LoginForm.propTypes = {
  shouldChangePassword: PropTypes.bool
}

export default withTranslation()(withFormAuthController(withRedirect(LoginForm)));