import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { Snap } from "ol/interaction";

export default function SnapInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    feaures: undefined,
    edge: undefined,
    vertex: undefined,
    pixelTolerance: undefined,
    source: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Snap, props, options, events),
    []
  );

  return null;
}
