import React, { useContext, useEffect } from "react";
import { SidebarControlContext } from "./SidebarControlContext";

export default function SidebarHeading(props) {
  const { title } = props;

  const context = useContext(SidebarControlContext);

  const h1 = document.createElement("h1");
  h1.className = "sidebar-header";

  var t = document.createTextNode(title);

  const span = document.createElement("span");
  span.className = "sidebar-close";

  const i = document.createElement("i");
  i.className = "fas fa-caret-left";

  span.appendChild(i);
  h1.append(t);
  h1.appendChild(span);


  useEffect(() => {
    if (context.pane) {
      context.pane.appendChild(h1);
    }
  }, []);

  // return null;

  return (
    <h1 className="sidebar-header">
      {title}
      <span className="sidebar-close">
        <i className="fas fa-caret-left"></i>
      </span>
      <span className="sidebar-expand">
        <i className="fas fa-caret-right"></i>
      </span>
    </h1>
  );
}
