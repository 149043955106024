import React, { Fragment} from 'react'

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import Menu from '@material-ui/core/Menu';

//Material-UI Icons
import MenuIcon from '@material-ui/icons/Menu';

//Custom Components
import DialogToolbarButton from 'UI/Dialog/DialogToolbarButton';
import DialogToolbarMenuItem from 'UI/Dialog/DialogToolbarMenuItem';
import UserConsumer from "Components/UserContext/UserConsumer";


const customStyles =  makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    color: theme.palette.common.black
  }
}));


function DialogToolbarMenuButton(props) {
  const { needRole } = props;

  const [ anchorEl, setAnchorEl] = React.useState(null);
  const classes = customStyles();

  const handleClick = evt => {
    setAnchorEl(evt.currentTarget);
  }

  const handleClose = (evt, reason) => {
    setAnchorEl(null);
  }

  const hookAction = action => {
    setAnchorEl(null);
    action();
  }

  const buildMenuItem = (node, index) => {
    const { onClick, label, icon } = node.props;
    return <DialogToolbarMenuItem onClick={() => hookAction(onClick)} label={label} icon={icon} key={index}/>;
  }

  let menuItems = [];
  if (Array.isArray(props.children)) {
    props.children.forEach((child,i) => {
      if (child !== null) {
        menuItems.push(buildMenuItem(child, i));
      }
    })
  } else {
    if (props.children !== null) {
      menuItems.push(buildMenuItem(props.children, 1));
    }
  }

  return (
    <UserConsumer>
      {userContext => 
        needRole && !userContext.hasAnyRole(needRole) ? null : (
          <Fragment>
            <DialogToolbarButton onClick={handleClick}>
              <MenuIcon />
            </DialogToolbarButton>
            <Menu
              id="dialogt-toolbar-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              classes={classes}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
             {menuItems}
            </Menu>
          </Fragment>
        )
      }
    </UserConsumer>
  );
}

export default withTheme(DialogToolbarMenuButton);