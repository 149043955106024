import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Table from "UI/Table/Table";
import tableGenerator from "Lib/tableGenerator";
import dataControllerSubModel from "Lib/dataControllerSubModel";
import dataController from "Lib/dataController";
import { getHeader } from "Lib/tableHelpers";

import radio from "Models/submodels/radio";
import checkbox from "Models/submodels/checkbox";
import picker from "Models/submodels/picker";


function ModelTable(props) {
  const { t } = useTranslation();
  const [subModelsWithData, setSubModelsWithData] = useState([]);
  const [columnInfo, setColumnInfo] = useState({columns: [], hiddenColumnNames: []});
  const [data, setData] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);

  const {
    dc,
    viewName,
    records,
    children,
    allowColumnPicker,
    allowSelection,
    allowFilter,
    allowExport,
    allowSearchBar,
    allowInc,
    inlineEdit,
    disableControls,
    onSelectRecord,
    invalidRecordIndeces,
    editedRecordIds,
    selectedRowId,
    topAlignedSearch,
    searchFilter,
    tableName,
    sidebarpane,
    initialPageSize,
    onEdit,
    customFnc,
    exportPonudeIds,
    notAuth,
    isLoading,
    stickyHeader,
    exportFnc,
    disableStyles,
    PreButton,
    PreComponent,
    customPadding
  } = props;
  // header props
  const {
    displayHeader
  , title
  , allowAdd
  , onAdd
  , addGender
  } = props;

  const viewFields = dc.getViewFields(viewName);

  const src = dc.getSource();
  const identifier = src + viewName;

  const SelectColumnFilter = function({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    const { t } = useTranslation();
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (

      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">{t("common.all")}</option>
        {options.map((option, i) => ( option !== "" ?
          <option key={i} value={option}>
            {option}
          </option> : null
        ))}
      </select>
    );

  }

  const TextColumnFilter = function ({ column: { filterValue, setFilter, preFilteredRows, id} }) {
    return (
      <TextField

        onChange= {(e) => {
          setFilter(e.target.value || undefined);
        }}
      />
    );
  }

  const NoFilter = function() {
    return (null);
  }

  const compareDateTime = (rowA, rowB, columnId, desc) => {
    const divA = rowA.values[columnId];
    const divB = rowB.values[columnId];
    const momA = divA && divA.props && divA.props.value !== "" ? moment(divA.props.value) : null;
    const momB = divB && divB.props && divB.props.value !== "" ? moment(divB.props.value) : null;
    if (moment.isMoment(momA) && moment.isMoment(momB)) {
      if (momA.isAfter(momB)) {
        return 1;
      } else {
        return -1;
      }
    } else if (momA !== null && momB === null) {
      return -1;
    } else if (momA === null && momB !== null) {
      return 1;
    } else {
      return 1;
    }
  }

  const compareCurrency = (rowA, rowB, columnId, desc) => {
    const divA = rowA.values[columnId];
    const divB = rowB.values[columnId];
    const valA = divA && divA.props && divA.props.unformatedvalue !== "" ? parseInt(divA.props.unformatedvalue) : null;
    const valB = divB && divB.props && divB.props.unformatedvalue !== "" ? parseInt(divB.props.unformatedvalue) : null;
    // console.log(divA, divB)
    if (valA !== null && valB !== null) {
      return valA > valB ? 1 : -1;
    } else if (valA !== null && valB === null) {
      return -1;
    } else if (valA === null && valB !== null) {
      return 1;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    const fieldsWithModel = viewFields.filter((f) => f.subModel);
    const subModels = {};

    fieldsWithModel.forEach((f) => {
      const modelType =
        f.type === "picker"
          ? picker
          : f.type === "radio"
          ? radio
          : f.type === "checkbox"
          ? checkbox
          : f.type === "link"
          ? require(`Models/${f.subModel.name}`)
          : null;

      if (modelType !== null) {
        if (f.type === "link") {
          Object.assign(subModels, {
            [f.source]: new dataController(modelType, f)
          });
        } else {
          Object.assign(subModels, {
            [f.source]: new dataControllerSubModel(modelType, f)
          });
        }
      }
    });

    const getDataPromises = Object.keys(subModels).map((f) => subModels[f].GetData());
    Promise.all(getDataPromises).then((data) => {
      setSubModelsWithData(subModels);
    });
  }, []);

  useEffect(() => {
    const viewFields = dc.getViewFields(viewName);
    const columns = viewFields.map((f) => {
      const canSort = f.hasOwnProperty("sort") && f.sort === false ? false : true;
      const sortType = (f.type === "date" || f.type === "datetime")
        ? compareDateTime
        : f.type === 'currency'
        ? compareCurrency
        : "alphanumeric";
      let obj = {
        // Header: f.ttoken ? t(f.ttoken) : t(f.title),
        Header: () => getHeader(f, t),
        maxWidth: f.maxWidth,
        accessor: f.source,
        disableSortBy: !canSort,
        sortType: canSort && sortType,
        disableFilters: !f.filter,
        Cell: ({ value, trimString, row: { isSelected } }) => typeof value === "string" && !isSelected ? trimString(value) : value ? value : null,
        Filter: sidebarpane === undefined ? f.type === "text" ? TextColumnFilter
              : f.type === "radio" || f.type === "boolean" || f.type === "picker" || f.type === "active" ? SelectColumnFilter : NoFilter : NoFilter,
        filter: (arrValues, filterField, currValue) => {
          const [fField] = filterField;
          return arrValues.filter((field) => {
            return (f.type === "radio" || f.type === "boolean" || f.type === "picker" || f.type === "active") ?
              (field.original[fField] + "").toUpperCase() === currValue.toUpperCase()
            : (field.original[fField] + "").toUpperCase().indexOf(currValue.toUpperCase()) >= 0;
          });
        },
        padding: f.padding ? f.padding : "default"
      };

      return obj;
    }, );

    const hiddenColumnNames = dc.getHiddenViewFieldsNames(viewName);

    setColumnInfo({
      columns: columns,
      hiddenColumnNames: hiddenColumnNames
    })

  }, [viewName, t])

  useEffect(() => {
    const tg = new tableGenerator(t);
    const viewFields = dc.getViewFields(viewName);
    tg.generateDataRows(records, viewFields, subModelsWithData, customFnc)
    .then(data => {
      setData(data);
    })

  }, [viewName, records, t])

  const onSort = function(sortBy){
    setInitialSortBy(sortBy);
  }

  return (
    <Table
      data={data}
      topAlignedSearch={topAlignedSearch}
      records={records}
      columns={columnInfo.columns}
      hiddenColumnNames={columnInfo.hiddenColumnNames}
      allowColumnPicker={allowColumnPicker}
      allowSelection={allowSelection}
      allowFilter={allowFilter}
      allowExport={allowExport}
      allowSearchBar={allowSearchBar}
      allowInc={allowInc}
      disableControls={disableControls}
      onSelectRecord={onSelectRecord}
      invalidRecordIndeces={invalidRecordIndeces}
      notAuth={notAuth}
      isLoading={isLoading}
      editedRecordIds={editedRecordIds}
      selectedRowId={selectedRowId}
      initialSortBy={initialSortBy}
      onSort={onSort}
      dc={dc}
      tableName={dc.title}
      exportName={dc.title}
      initialPageSize={initialPageSize}
      onEdit={(id) => onEdit(id)}
      inlineEdit={inlineEdit}
      exportPonudeIds={exportPonudeIds}
      customFnc={customFnc}
      identifier={identifier}
      stickyHeader={stickyHeader}
      exportFnc={exportFnc}
      disableControls={disableControls}
      displayHeader={displayHeader}
      title={title}
      allowAdd={allowAdd}
      onAdd={onAdd}
      addGender={addGender}
      disableStyles={disableStyles}
      PreComponent={PreComponent}
      PreButton={PreButton}
      customPadding={customPadding}
    >
      {children}
    </Table>
  );
}

ModelTable.defaultProps = {
  viewName: "default",
  allowColumnPicker: true,
  allowSelection: "one",
  allowFilter: false,
  allowExport: false,
  stickyHeader: true,
  disableStyles: false
};

ModelTable.propTypes = {
  records: PropTypes.array,
  dc: PropTypes.object,
  viewName: PropTypes.string,
  allowSelection: PropTypes.oneOf(["none", "one", "many"]),
  allowColumnPicker: PropTypes.bool,
  allowFilter: PropTypes.bool,
  allowExport: PropTypes.bool,
  disableStyles: PropTypes.bool
};

export default ModelTable;
