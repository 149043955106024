import React, { Fragment, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import Loader from "UI/Loader/Loader";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import PonudaDialog from "Views/PonudePage/PonudaDialog";

import model from "Models/ponuda";
import dataController from "Lib/dataController";
import { exportTableToExcel } from "Lib/exportExcel";

const tagsByLink = {
  "Sektor": "klj_sektor",
  "Uloga DE": "klj_uloga_de",
  "Vrsta naručitelja": "klj_vrsta_narucitelja",
  "Lokacija": "klj_lokacija",
  "Ponude": "klj_ponude",
}

function PonudeTable(props) {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const [records, setRecords ] = useState([]);
  const [tableControls, setTableControls] = useState(true);
  const [notAuth, setNotAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleExport = (filteredRecords) => {
    const rObj = filteredRecords.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    const viewFields = dc.getViewFields("exportExcel");

    setOpenLoader(true);
    dc.GetData("tags-by-link/0").then(rTags => {
      const tags = rTags.data;
      dc.GetData("usluge/0").then(rUsluge => {
        const usluge = rUsluge.data;
        usluge.forEach(u => {
          if (rObj[u.ugovor_id]) {
            if (rObj[u.ugovor_id].usl_tags) {
              // rObj[u.ugovor_id].usl_tags.push(u.tag);
              rObj[u.ugovor_id].usl_tags += `;${u.tag} - ${u.odjel} ${u.grupa} ${u.opis}`;
            } else {
              // rObj[u.ugovor_id].usl_tags = [u.tag];
              rObj[u.ugovor_id].usl_tags = `${u.tag} - ${u.odjel} ${u.grupa} ${u.opis}`;
            }
            // if (rObj[u.usluga_id].usl_tags) {
            //   // rObj[u.usluga_id].usl_tags.push(u.tag);
            //   rObj[u.usluga_id].usl_tags += `${u.tag} - ${u.odjel} ${u.grupa} ${u.opis}; `;
            // } else {
            //   // rObj[u.usluga_id].usl_tags = [u.tag];
            //   rObj[u.usluga_id].usl_tags = `${u.tag} - ${u.odjel} ${u.grupa} ${u.opis}; `;
            // }
          }
        });
        tags.forEach(t => {
          if (rObj[t.link_id]) {
            if (rObj[t.link_id][ tagsByLink[t.grupa] ]) {
              rObj[t.link_id][ tagsByLink[t.grupa] ] += `;${t.tag}`;
            } else {
              rObj[t.link_id][ tagsByLink[t.grupa] ] = `${t.tag}`
            }
          }
        });
        console.log(viewFields)
        exportTableToExcel({
          records: Object.keys(rObj).map(k => rObj[k]),
          fields: viewFields,
          filename: "Ponude",
          t: t
        });
      }).finally(() => {
        setOpenLoader(false);
      })

    })
  }

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }

  const handleAdd = (evt) => {
    dialogContext.showDialog(PonudaDialog, {
      model: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;

    dialogContext.showDialog(PonudaDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  };

  const handleTableEdit = (recordId) => {
    dialogContext.showDialog(PonudaDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
      if (result.action === "insert" && result.id) {
        handleTableEdit(result.id);
      }
    }
  };

  const refreshRecords = () => {
    // dummyRecords();
    setLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      } else {
        const { error } = resp;
        if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
          setNotAuth(true);
        } 
      }
      setLoading(false);
    });
  };

  return (
    <Fragment>
      <ModelTable records={records || []} dc={dc} 
        allowSelection="one" 
        allowFilter={true} 
        allowExport={true} 
        disableControls={!tableControls} 
        inlineEdit={true} 
        onEdit={handleTableEdit} 
        notAuth={notAuth}
        isLoading={loading}
        exportFnc={handleExport}
        onAdd={handleAdd}
        title="titles.ponude"
        addGender="f"
      />
      <Loader open={openLoader} />
    </Fragment>
  );
}

export default PonudeTable;
