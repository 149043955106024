import EuroIcon from "@material-ui/icons/Euro";
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from "@material-ui/icons/Home";
import MapIcon from "@material-ui/icons/Map";
import GroupIcon from "@material-ui/icons/Group";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const menuRoutes = [
	{ path: "/", name: "Home", ttoken: "menu.home", icon: "home" },
	{ path: "/dashboard", name: "dashboard", ttoken: "menu.dashboard", icon: "dashboard" },
	{ path: "/map", name: "map", ttoken: "menu.map", icon: "travel_explore" },
  { path: "/users", name: "users", ttoken: "menu.users", icon: "account_circle" },
  { path: "/tags", name: "tags", ttoken: "menu.tags", icon: "local_offer" },
  { path: "/djelatnici", name: "djelatnici", ttoken: "menu.djelatnici", icon: "group" },
  { path: "/vanjski-cv", name: "vanjski-cv", ttoken: "menu.vanjski_cv", icon: "list_alt" },
  { path: "/oprema", name: "oprema", ttoken: "menu.oprema", icon: "emoji_transportation" },
  { path: "/klijenti", name: "klijenti", ttoken: "menu.klijenti", icon: "contact_phone" },
  { path: "/vanjski-zahvati", name: "Vanjski zahvati", ttoken: "menu.vanjski_zahvati", icon: "speaker_notes" },
  { path: "/ponude", name: "Ponude", ttoken: "menu.ponude", icon: "outbound" },
  { path: "/ugovori", name: "Ugovori", ttoken: "menu.ugovori", icon: "receipt_long" },
  { path: "/kalendar", name: "Kalendar", ttoken: "menu.kalendar", icon: "event" }
];

export default menuRoutes;
