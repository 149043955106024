import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { Select } from "ol/interaction";

export default function SelectInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    addCondition: undefined,
    condition: undefined,
    layers: undefined,
    style: undefined,
    removeCondition: undefined,
    toggleCondition: undefined,
    multi: undefined,
    features: undefined,
    filter: undefined,
    hitTolerance: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined,
    select: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Select, props, options, events),
    []
  );

  return null;
}
