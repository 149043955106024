import React from 'react'
import UserContext from 'Components/UserContext/UserContext';
import { Role } from 'Lib/role';

import { userService } from "Services/userService";
import api from "Lib/api";

const tagsByLink = {
  "Sektor": "klj_sektor",
  "Uloga DE": "klj_uloga_de",
  "Vrsta naručitelja": "klj_vrsta_narucitelja",
  "Lokacija": "klj_lokacija",
  "Područje Vanjski": "klj_podrucje_vanjski"
}

class UserProvider extends React.Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    this.getDataTags = this.getDataTags.bind(this);
    this.getDataUsluge = this.getDataUsluge.bind(this);

    this.fetchTags = this.fetchTags.bind(this);
    this.fetchUsluge = this.fetchUsluge.bind(this);

    this.setUserData = this.setUserData.bind(this);
    this.setUserSetting = this.setUserSetting.bind(this);
    this.setLayerVisible = this.setLayerVisible.bind(this);
    this.setMultipleLayerVisible = this.setMultipleLayerVisible.bind(this);

    this.state = {
      username: null,
      user: null,
      roleIds: [],
      roleNames: [],
      setUserData: this.setUserData,
      hasAnyRole: this.hasAnyRole,

      setUserSetting: this.setUserSetting,
      setLayerVisible: this.setLayerVisible,
      setMultipleLayerVisible: this.setMultipleLayerVisible,

      getRecent: this.getRecent,
      setRecent: this.setRecent,

      tagsCache: {},
      uslugeCache: {},
      gotData: false,
      getData: this.getData,
      getDataTags: this.getDataTags,
      getDataUsluge: this.getDataUsluge,
      fetchTags: this.fetchTags,
      fetchUsluge: this.fetchUsluge,
    };

    this.api = new api();
  }

  checkRoleName(code) {
    const role = Role.getRoleByCode(code)
    switch (role.id) {
      case "ADM_SYS":
      case "ADM_APP":
      case "ADM_REQ":
      case "RESEARCH":
      case "CLERK":
      case "USER":
      case "PUBLIC":
        return role.display
      default:
        return null;
    }
  }

  setUserData(data) {
    const {username, first_name, last_name, email, roles} = data;
    let roleIds;
    if (!Array.isArray(roles)) {
      roleIds = [roles];
    }
    let roleNames = roles ? roles.map(x => this.checkRoleName(x)).join(", ") : "";

    this.setState({
      username: username,
      firstName: first_name,
      lastName: last_name,
      fullName: first_name.concat(" ", last_name),
      email: email,
      user: data,
      roleIds: roleIds,
      roleNames: roleNames
    });

    this.getData();
  }

  hasAnyRole(needAnyRole) {
    if (needAnyRole === null || needAnyRole === undefined) {
      return true;
    } else if (!Array.isArray(needAnyRole)) {
      needAnyRole = [needAnyRole];
    } else if (needAnyRole.length === 0) {
      return true;
    }

    const roleCodes = needAnyRole.map(x => x.code);
    return this.roleIds.find(x => roleCodes.indexOf(x) >= 0) !== undefined;
  }

  setUserSetting(settings) {
    let currentSettings = {};

    this.setState(
      (prevState) => {
        currentSettings = prevState.userSettings || {};
        Object.keys(settings).forEach((key) => {
          currentSettings[key] = settings[key];
        });
        return {
          userSettings: currentSettings
        };
      },
      () => {
        userService.setUserSettings(currentSettings);
      }
    );
  }

  setLayerVisible(id, isVisible) {
    // console.log(id, isVisible);
    const userSettings = this.state.userSettings || {};
    const map_layer_visibility = userSettings.map_layer_visibility || {};

    const new_map_layer_visibility = Object.assign({}, map_layer_visibility);
    const oldValue = new_map_layer_visibility[id];
    // if (oldValue !== isVisible) {
      new_map_layer_visibility[id] = isVisible;

      this.setUserSetting({
        map_layer_visibility: new_map_layer_visibility
      });
    // }
  }

  setMultipleLayerVisible(obj) {
    // console.log(obj);

    const userSettings = this.state.userSettings || {};
    const map_layer_visibility = userSettings.map_layer_visibility || {};

    const new_map_layer_visibility = Object.assign({}, map_layer_visibility);
    Object.keys(obj).forEach((key) => {
      new_map_layer_visibility[key] = obj[key];
    });

    this.setUserSetting({
      map_layer_visibility: new_map_layer_visibility
    });
  }

  setRecent(key, identifier, details) {
    const recent = JSON.parse(localStorage.getItem(key));
    if(recent === null) {
      localStorage.setItem(key, JSON.stringify({[identifier]: details}));
    } else {
      recent[identifier] = details;
      localStorage.setItem(key, JSON.stringify(recent));
    }
  }

  getRecent(key, identifier, defaultDetail = {}) {
    const recent = JSON.parse(localStorage.getItem(key));
    if (recent === null || recent[identifier] === null || recent[identifier] === undefined) {
      this.setRecent(identifier, defaultDetail);
      return defaultDetail;
    } else {
      return recent[identifier];
    }
  }

  getDataUsluge(ugovorId) {
    this.api.Call(`usluge/ug/${ugovorId}`, "get")
      .then(resp => {
        if (resp.success && resp.data && Array.isArray(resp.data)) {
          let uslTags = "";
          resp.data.forEach((u, i) => {
            uslTags += `${i?';':''}${u.tag}`;
          });
          this.setState(prevState => {
            const { uslugeCache } = prevState;
            uslugeCache[ugovorId] = {
              usl_tags: uslTags
            }
            return {
              uslugeCache: uslugeCache,
              gotData: !prevState.gotData
            }
          });
        } else {
          if (resp.success && !resp.data) {
            this.setState(prevState => {
              const { uslugeCache } = prevState;
              delete uslugeCache[ugovorId];
              return { 
                uslugeCache: uslugeCache, 
                gotData: !prevState.gotData 
              };
            });
          }
        }
      }) 
  }

  getDataTags(ugovorId) {
    this.api.Call(`tags-by-link/link/${ugovorId}`, "get")
      .then(resp => {
        if (resp.success && resp.data && Array.isArray(resp.data)) {
          const rObj = {};
          resp.data.forEach(t => {
            if (rObj[ tagsByLink[t.grupa] ]) {
              rObj[ tagsByLink[t.grupa] ] += `;${t.tag}`;
            } else {
              rObj[ tagsByLink[t.grupa] ] = `${t.tag}`;
            }
          });
          this.setState(prevState => {
            const { tagsCache } = prevState;
            tagsCache[ugovorId] = rObj;
            return {
              tagsCache: tagsCache,
              gotData: !prevState.gotData
            }
          })
        } else if (resp.success && !resp.data) {
          this.setState(prevState => {
            const { tagsCache } = prevState;
            delete tagsCache[ugovorId];
            return {
              tagsCache: tagsCache,
              gotData: !prevState.gotData
            }
          });
        }
      })
  }

  getData() {
    this.api.Call("tags-by-link/0", "get")
      .then(resp => {
        if (resp.success && resp.data && Array.isArray(resp.data)) {
          const tagsCache = resp.data.reduce((rObj, t) => {
            if (rObj[t.link_id]) {
              if (rObj[t.link_id][ tagsByLink[t.grupa] ]) {
                rObj[t.link_id][ tagsByLink[t.grupa] ] += `;${t.tag}`;
              } else {
                rObj[t.link_id][ tagsByLink[t.grupa] ] = `${t.tag}`
              }
            } else {
              rObj[t.link_id] = {
                [ tagsByLink[t.grupa] ]: `${t.tag}`
              }
            }
            return rObj;
          }, {});
          this.setState({ tagsCache: tagsCache, gotData: !this.state.gotData });
        }
      });
    this.api.Call("usluge/0", "get")
      .then(resp => {
        if (resp.success && resp.data && Array.isArray(resp.data)) {
          const uslugeCache = resp.data.reduce((rObj, u) => {
            if (rObj[u.ugovor_id]) {
              if (rObj[u.ugovor_id].usl_tags) {
                rObj[u.ugovor_id].usl_tags += `;${u.tag}`;
              } else {
                rObj[u.ugovor_id].usl_tags = `${u.tag}`;
              }
            } else {
              rObj[u.ugovor_id] = {
                usl_tags: `${u.tag}`
              }
            }
            return rObj;
          }, {});
          this.setState({ uslugeCache: uslugeCache, gotData: !this.state.gotData });
        }
      });
  }

  fetchUsluge() {
    return this.state.uslugeCache;
  }

  fetchTags() {
    return this.state.tagsCache;
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>

    );
  }
}

export default UserProvider;