import React, { useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaw,
  faTools,
  faTag,
} from "@fortawesome/free-solid-svg-icons";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

//Custom Components
import withFormController from "Components/withFormController";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarHeadingSub from "UI/Dialog/DialogToolbarHeadingSub";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogTabs from "UI/Dialog/DialogTabs";
import DialogTab from "UI/Dialog/DialogTab";
import DialogTabPanel from "UI/Dialog/DialogTabPanel";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import FormContent from "Components/FormContent";
import TaggingControl from "Components/TaggingControl/TaggingControl";

import ZahvatiTable from "Views/ZahvatiPage/ZahvatiTable";
import TagsTable from "Views/TagsPage/TagsTable";
import UslugeTable from "Views/UslugePage/UslugeTable";
import UslugeTimTable from "Views/UslugeTimPage/UslugeTimTable";

function UgovorDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [refreshed, setRefreshed] = useState(true);

  const { model, mode, form, record, validation, fields, subModels } = props; //HOC withFormController
  const { doInsert, doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const headerString = useMemo(() => {
    const { datum_potpisa, ug_neto_iznos, predmet_ug } = record;
    return `${
      datum_potpisa ? datum_potpisa.format("YYYY-MM-DD") : ""
    } ${ug_neto_iznos} ${predmet_ug}`;
  }, [record]);

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert", id: result.id });
        } else if (result.error) {
          const msg =
            result.errorCode && result.errorCode === 23505
              ? "messages.duplicate"
              : "Greška prilikom dodavanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error", {
            record_type: "Ugovor",
          });
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          const msg = "Greška prilikom brisanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg =
            result.errorCode && result.errorCode === 23505
              ? "messages.duplicate"
              : "Greška prilikom dodavanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error", {
            record_type: "Ugovor",
          });
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    dialogContext.hideDialog();
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }
  };

  const refresh = () => {
    setRefreshed(false);
    setTimeout(() => setRefreshed(true), 10);
  };

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: mode,
    subModels: subModels,
  };

  const handleTabChange = (evt, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DraggableDialog
      open={true}
      maxWidth={"lg"}
      fixedHeight={true}
      onClose={handleClose}
    >
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="contract" icon_word="receipt_long" />
          <DialogToolbarHeading>{t("titles.ugovor")}</DialogToolbarHeading>
          {record.id ? (
            <DialogToolbarHeadingSub>
              (ID = {record.id})
            </DialogToolbarHeadingSub>
          ) : null}
          {record.predmet_ug ? (
            <DialogToolbarHeadingSub>
              {record.predmet_ug}
            </DialogToolbarHeadingSub>
          ) : null}
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <DialogTabs value={tabValue} onChange={handleTabChange}>
          <DialogTab
            value={0}
            label={t("ugovori.tab1")}
            icon={<FontAwesomeIcon icon={faFileAlt} size="lg" />}
            fields={["klijent_id", "datum_potpisa", "ug_neto_iznos"]}
            validation={validation}
          />
          <DialogTab
            disabled={mode === "insert"}
            value={1}
            label={t("ugovori.tab2")}
            //icon={<FontAwesomeIcon icon={faPaw} size="lg" />}
            icon={<Icon>design_services</Icon>}
            fields={[
              "stvarni_zavrsetak",
              "predmet_ug",
              "voditelj_id",
              "doc_path",
              "potvrda_path",
              "opis_aktivnosti",
              "napomena",
            ]}
            validation={validation}
          />
          <DialogTab
            disabled={mode === "insert"}
            value={2}
            icon={<FontAwesomeIcon icon={faTools} size="lg" />}
            label={t("ugovori.tab3")}
            validation={validation}
          />
          <DialogTab
            disabled={mode === "insert"}
            value={3}
            icon={<FontAwesomeIcon icon={faTag} size="lg" />}
            label={t("ugovori.tab4")}
            validation={validation}
          />
        </DialogTabs>

        <DialogTabPanel value={tabValue} index={0} key={0}>
          <Box m={2}>
            <GridContainer>
              <FormContent
                fieldNames={[
                  "klijent_id",
                  "tag",
                  "ug_neto_sveukupno",
                  "ug_neto_iznos",
                  "datum_potpisa",
                  "stvarni_zavrsetak",
                  "voditelj_id",
                  "kontakt_osoba",
                  "email_kontakt_o",
                  "tel_kontakt_o",
                ]}
                columns={3}
                {...commonFields}
              />
              <FormContent fieldNames={[]} columns={3} {...commonFields} />
              <FormContent
                fieldNames={[
                  "predmet_ug",
                  "predmet_ug_eng",
                  "doc_path",
                  "potvrda_path",
                  "ponuda_id",
                  "opis_aktivnosti",
                  "napomena",
                ]}
                columns={2}
                {...commonFields}
              />
            </GridContainer>
          </Box>
        </DialogTabPanel>

        <DialogTabPanel value={tabValue} index={1} key={1}>
          <ZahvatiTable
            level={2}
            ugovorId={record.id}
            headerString={headerString}
          />
        </DialogTabPanel>

        <DialogTabPanel value={tabValue} index={2} key={2}>
          <UslugeTable level={2} ugovorId={record.id} refreshData={refresh} />
          {refreshed ? <UslugeTimTable level={2} ugovorId={record.id} /> : null}
        </DialogTabPanel>

        <DialogTabPanel value={tabValue} index={3} key={3}>
          <Box m={2}>
            <TaggingControl
              mode={mode}
              linkId={record.id}
              groupsDenied={JSON.parse(
                process.env.REACT_TAGS_UGOVORI_BLACKLIST
              )}
              groupsFilterId={process.env.REACT_TAGS_UGOVORI_FILTER_ID}
            />
          </Box>
        </DialogTabPanel>
      </DialogBody>
      <DialogActions>
        {mode === "update" ? (
          <DialogActionButton
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={handleDelete}
          >
            {t("buttons.delete")}
          </DialogActionButton>
        ) : null}
        <DialogActionButton
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={handleClose}
        >
          {t("buttons.close")}
        </DialogActionButton>
        {mode === "insert" ? (
          <DialogActionButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleInsert}
          >
            {t("buttons.add")}
          </DialogActionButton>
        ) : mode === "update" ? (
          <DialogActionButton
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleUpdate}
          >
            {t("buttons.save")}
          </DialogActionButton>
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormController(UgovorDialog);
