import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { sifrarnici } from "Lib/sifrarnici";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import ScheduleIcon from "@material-ui/icons/Schedule";

//Custom Components
import withFormController from "Components/withFormController";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarHeadingSub from "UI/Dialog/DialogToolbarHeadingSub";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";

const pickerList = ["klijent_id", "ponuda_id", "ugovor_id"];
const pickerIntervals = {
  klijent_id: [30000, 40000],
  ponuda_id: [100000, 200000],
  ugovor_id: [200000, 300000],
  usluga_id: [5000000, 6000000]
};

const getInitialPickerField = (id) => {
  if (id > pickerIntervals["klijent_id"][0] && id < pickerIntervals["klijent_id"][1]) {
    return sifrarnici.calendar_picker.klijent_id;
  } else if (id > pickerIntervals["ponuda_id"][0] && id < pickerIntervals["ponuda_id"][1]) {
    return sifrarnici.calendar_picker.ponuda_id;
  } else if (id > pickerIntervals["ugovor_id"][0] && id < pickerIntervals["ugovor_id"][1]) {
    return sifrarnici.calendar_picker.ugovor_id;
  } else if (id > pickerIntervals["usluga_id"][0] && id < pickerIntervals["usluga_id"][1]) {
    return sifrarnici.calendar_picker.usluga_id;
  } else {
    return null;
  }
}

function CalendarDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { model, mode, form, record, validation, fields, subModels } = props; //HOC withFormController
  const { doInsert, doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController
  const { onClose } = props;
  const { level = 1 } = props;

  const [dataChanged, setDataChanged] = useState(false);
  const initialPickerField = mode === "update" && record.link_id ? getInitialPickerField(record.link_id) : null;
  const [pickerField, setPickerField] = useState(null);

  // We enter here only on mount when mode is update
  if (mode === "update" && pickerField === null && initialPickerField !== null) {
    pickerList.forEach(p => {
      const interval = pickerIntervals[p];
      if (interval[0] < record.link_id && record.link_id < interval[1]) {
        record[p] = record.link_id;
      } else {
        record[p] = null;
      }
    });
    record["lookup_picker"] = initialPickerField;
  }

  const handleFieldChange = (value, source) => {

    if (source === "lookup_picker") {
      const val = value && (value.value || value);
      setPickerField(val);
      const pField = getPickerField(val);
      if (pField === null) {
        if (onFieldChange) {
          onFieldChange(null, "link_id");
        }
      } else {
        const pValue = record[pField]
        if (pValue && onFieldChange) {
          onFieldChange(pValue && (pValue.value || pValue), "link_id");
        }
      }
    } else {
      const pField = getPickerField(pickerField);
      if (source === pField && onFieldChange) {
        onFieldChange(value && (value.value || value), "link_id");
      }
    }

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {

    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
        } else if (result.error) {
          const msg = "Greška prilikom dodavanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          const msg = "Greška prilikom brisanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }

  }

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg = "Greška prilikom ažuriranja podataka! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog((level && level === 2) ? 2 : 1);
  };

  const getPickerField = (pFieldVal) => {
    const keys = Object.keys(sifrarnici.calendar_picker);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (sifrarnici.calendar_picker[key] === pFieldVal) {
        if (key == 'none') {
          return null;
        }
        return key;
      }
    }
    return null;
  }

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: mode,
    subModels: subModels,
  }

  const pField = getPickerField(pickerField || initialPickerField);
  pickerList.forEach(p => {
    const currPicker = fields.find(f => f.source === p);
    //return;
    if (currPicker.source === pField) {
      currPicker.validation = { required: true };
    } else {
      currPicker.validation = { required: false };
    }
  })
  return (
    <DraggableDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="contact" icon={<ScheduleIcon />} />
          <DialogToolbarHeading>{t("titles.calendar")}</DialogToolbarHeading>
          {record.id ? <DialogToolbarHeadingSub>(ID = {record.id})</DialogToolbarHeadingSub> : null}
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={[
                "day1"
              , "day2"
              , "opis"
              , "usage_rate"
              , "djelatnik_id"
              , "oprema_id"
              ]}
              columns={2}
              {...commonFields}
            />
            <GridItem xs={6}>
              <GridContainer>
                <FormContent
                  fieldNames={["lookup_picker"]}
                  columns={1}
                  {...commonFields}
                />
              </GridContainer>
            </GridItem>
            {
              pField ?
              <GridItem xs={6}>
                <GridContainer>
                  <FormContent
                    fieldNames={[pField]}
                    columns={1}
                    {...commonFields}
                  />
                </GridContainer>
              </GridItem>
              : null
            }
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        { mode === "update" ? (
          <DialogActionButton variant="outlined" startIcon={<DeleteIcon />} onClick={handleDelete}>
            {t("buttons.delete")}
          </DialogActionButton>
        ) : null}
        <DialogActionButton variant="outlined" startIcon={<CloseIcon />} onClick={handleClose}>
          {t("buttons.close")}
        </DialogActionButton>
        {mode === "insert" ? (
          <DialogActionButton variant="contained" startIcon={<AddIcon />} onClick={handleInsert}>
            {t("buttons.add")}
          </DialogActionButton>
        ) : mode === "update" ? (
          <DialogActionButton variant="contained" startIcon={<SaveIcon />} onClick={handleUpdate}>
            {t("buttons.save")}
          </DialogActionButton>
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormController(CalendarDialog);
