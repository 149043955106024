import React from 'react';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

//Material-UI Core Components
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

//Material-UI Icons
import LockIcon from "@material-ui/icons/Lock";


/*** Option examples for the toolbar
**
**    const toolbarOptions_xx = [
**      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
**      ['blockquote', 'code-block'],
**      ['link'],
**
**      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
**      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
**      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
**      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
**      [{ 'direction': 'rtl' }],                         // text direction
**
**      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
**      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
**
**      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
**      [{ 'font': [] }],
**      [{ 'align': [] }],
**
**      ['clean']                                         // remove formatting button
**    ];
*/


const CustomToolbar = (props) => (
  <div id={`toolbar-${props.id}`} style={{display: "inline-block", maxWidth: "12%", height: "fit-content"}}>
    {props.toolbarOptions.indexOf('bold') !== -1 ?
      <button className="ql-bold" />
      : null
    }
    {
     props.toolbarOptions.indexOf('italic') !== -1 ?
      <button className="ql-italic" />
      : null
    }
    {
     props.toolbarOptions.indexOf('underline') !== -1 ?
      <button className="ql-underline" />
      : null
    }
  </div>
);

class RichTextControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.getLabel = this.getLabel.bind(this);
    this.getHelperText = this.getHelperText.bind(this);
    this.checkIfRequired = this.checkIfRequired.bind(this);



  }

  handleChange(value, delta, asd, editor){
    // console.log(value, delta, asd, editor.getText(value))
    // getText parses the text from the value which is html
    const { field } = this.props;
    const source = field ? field.source : null;
    // console.log(editor)
    if (this.props.onChange) {
      if (value === "<p><br></p>") {
        this.props.onChange(null, source);
      } else {
        this.props.onChange(value, source);
      }
    }
  }

  getLabel() {
    const { field, label, t } = this.props;

    return field.ttoken ? t(field.ttoken) : field.title;
  }

  getHelperText() {
    const { field, helperText, showHelper, t} = this.props;

    const txt = field && field.tooltip ? field.tooltip : (helperText ? helperText : (showHelper ? " " : null));
    return field.translate ? t(txt) : txt;
  }

  checkIfRequired() {
    const { field } = this.props;

    if (field) {
      return field.validation && field.validation.required;
    } else {
      return false;
    }
  }

  render() {
    const { field, formMode, controlMode, value, validation, multiline, rowsMax, isValueCalculated, type, t } = this.props;

    const isCustom = field.normal ? false : true;
    const isRequired = this.checkIfRequired();
    const hasValue = value !== undefined && value !== null;
    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || (field && field.readonly) || (field && field.type === "categorization");
    const isFocused = field && field.focus === true;

    const label = this.getLabel();
    const helperText = this.getHelperText();

    const toolbarOptions = field.styles;
    const id = label.split(' ')[0]

    return(
      <FormControl
        fullWidth
        error={hasError}
        required={isRequired}
      >
        <FormLabel>{label}</FormLabel>
        <div style={{display: "inline-block"}}>
          <ReactQuill
            value={value}
            readOnly={isReadOnly}
            onChange={this.handleChange}
            modules={{toolbar: isCustom ? {container: `#toolbar-${id}`} : toolbarOptions}}
            style={{width: "88%", maxWidth: "88%", height: "fit-content", display: "inline-block"}}
          />
          {isReadOnly ? <LockIcon/> : null }
          {isCustom ?
            <CustomToolbar toolbarOptions={toolbarOptions} id={id}/>
            : null
          }
        </div>
        <FormHelperText id={field.source + "-helper"}>
          { hasError ? validation.msg : helperText}
        </FormHelperText>
      </FormControl>
    )
  }
}

RichTextControl.defaultProps = {
  formMode: "form",
  controlMode: "edit",
  multiline: false,
  rowsMax: 5,
  isValueCalculated: false,
  showHelper: true
}

RichTextControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValueCalculated: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  showHelper: PropTypes.bool,
}

export default withTranslation()(RichTextControl);