import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//Material-UI Icons
import PersonIcon from "@material-ui/icons/Person"

//Custom Components
import withRedirect from "Components/withRedirect";
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import UserContext from "Components/UserContext/UserContext";

import { userService } from "Services/userService";

function ProfileMenu(props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { redirect } = props; //HOC withRedirect

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleUserDetails = () => {
    redirect("/profile");
  };

  const handleLogout = () => {
    userService.logout();
    redirect("/login");
  };

  const label = userContext && userContext.firstName ? userContext.firstName : undefined;
  // const label = "user";

  return (
    <HeaderMenuButton text={mdUp ? label : null} startIcon={<PersonIcon/>} showExpandIcon={mdUp ? true : false}>
      <HeaderMenuItem label={t("menu.profile")} onClick={handleUserDetails} />
      <HeaderMenuItem label={t("menu.logout")} onClick={handleLogout} />
    </HeaderMenuButton>
  );
}

export default withRedirect(ProfileMenu);
