import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";

//Material-UI Icons
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

//Custom Components
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogContext from "UI/DialogContext/DialogContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";

function NotAuthorizedDialog(props) {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();
  const { recordId } = props;

  const close = () => {
    dialogContext.hideDialog();
  };

  return (
    <DraggableDialog open={true} maxWidth={"sm"} onClose={close}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="error" color="error" icon={<ErrorIcon />} />
          {recordId ? <DialogToolbarHeading>(ID = {recordId})</DialogToolbarHeading> : null}
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={close} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <GridItem xs={12}>
              <Typography color="error" variant="h6">
                {t("messages.not_authorized")}
              </Typography>
            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButton variant="outlined" startIcon={<CloseIcon />} onClick={close}>
          {t("buttons.close")}
        </DialogActionButton>
      </DialogActions>
    </DraggableDialog>
  );
}

export default NotAuthorizedDialog;
