import React from "react";

function SchedulerGridRow(props) {

  const { day_offset, day_cells, cell_width } = props;
  const { onCellEnter, onCellLeave } = props;

  return (
    <div
      className="scheduler-grid"
      style={{ transform: "translateX(" + day_offset + "px)" }}
      onMouseLeave={onCellLeave}
    >
      {day_cells.map((d, di) => (
        <div className="scheduler-day" style={{ width: d.width + "px" }} key={di}>
          {d.cells.map((c, ci) => (
            <div
              className="scheduler-cell"
              style={{ width: cell_width + "px" }}
              onMouseEnter={(evt) => {
                onCellEnter(evt, d.day, c);
              }}
              key={ci}
            >
              .
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default SchedulerGridRow;
