import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import PersonIcon from "@material-ui/icons/Person";

//Custom Components
import HeaderButton from "UI/Header/HeaderButton";
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import ThemeSwitch from "UI/Header/ThemeSwitch";
import LanguageSelector from "UI/Header/LanguageSelector";

const makeAppBarStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center"
  },
  link: {
    textDecoration: "none",
    paddingLeft: 10,
    paddingRight: 10
  },
  titleLink: {
    color: "#fff",
    textDecoration: "none"
  },
  activeLink: {
    color: "#FFD33A",
    textDecoration: "underline"
  }
}));

const makeToolbarStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));


function PublicHeader(props) {
  const { t } = useTranslation();
  const appBarClasses = makeAppBarStyles();
  const toolbarClasses = makeToolbarStyles();

  return (
    <div id="header" className={appBarClasses.root}>
      <AppBar position="fixed" color="primary">
        <Toolbar className={toolbarClasses.root} variant="dense">
          <ToolbarFillContent>
            <Typography
              variant="h6"
              component="h2"
              className={appBarClasses.grow}
              style={{ color: "#fff", fontSize: "1.15rem" }}
            >
              <NavLink to="/" className={appBarClasses.titleLink}>
                {t("app_title")}
              </NavLink>
            </Typography>
          </ToolbarFillContent>
          {/*<ThemeSwitch />*/}
          <LanguageSelector />
        </Toolbar>
      </AppBar>
    </div>
  );
}

PublicHeader.propTypes = {
  menu: PropTypes.array
};

export default PublicHeader;
