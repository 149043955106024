import React from 'react';
import { useTranslation } from "react-i18next";

// Material-UI Core Components
import TextField from '@material-ui/core/TextField';

export default function NotAuthorizedControl(props) {
	const { t } = useTranslation();
	const { label, variant } = props;
	return(
		<TextField 
			fullWidth
			error={true}
			disabled={true}
			margin="none"
			label={label}
			variant={variant}
			value={t("messages.not_authorized")}
		/>
	);
}