import React, { Fragment } from "react";
import moment from "moment";
import numbro from "numbro";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { fieldFormat } from "Lib/fieldFormat";
import { formats } from "Lib/formats";

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

let tableGenerator = function (t) {
  this.t = t;
};

tableGenerator.prototype.generateDataRows = function (records, fields, subModels, customFnc = () => {}) {
  return Promise.resolve(
    Array.isArray(records) ? records.map((record) => {
      let rows = {};
      fields.forEach((field) => {
        rows[field.source] = this.generateDataCell(field, record, subModels[field.source], customFnc);
      });
      return rows;
    }) : []
  );
};

tableGenerator.prototype.generateDataCell = function (field, record, dc, customFnc) {
  const val = record[field.source];
  switch (field.type) {
    case "text":
      if (field.items) {
        const pos = field.items.values.indexOf(val);
        const lbl = field.items.labels[pos]
        return field.translate ? this.t(lbl) : lbl;
      } else if (field.format) {
        return fieldFormat(record, field.format, field.translate ? this.t : undefined);
      } else if (field.translate) {
        return this.t(val);
      } else {
        return val;
      }
    case "date":
      return (
        <div type="date" value={val ? moment.utc(val).toISOString() : ""}>
          {val ? moment.utc(val).local().format(formats.date) : "-"}
        </div>
      );
    case "datetime":
      return (
        <div type="datetime" value={val ? moment.utc(val).toISOString() : ""}>
          {val ? moment.utc(val).local().format(formats.datetime) : ""}
        </div>
      );
    case "currency":
      if (val && typeof val === "object") {
        console.log(field.source, val);
        if (val._value !== undefined && val._value !== null) {
          const num = numbro(val._value).format({ thousandSeparated: true, mantissa: 2 });
          return (
            <div align="right" unformatedvalue={val._value} type="currency">
              {num}
            </div>
          );
        }
        return null;
      } else if(val && typeof val === "number"){
        const num = numbro(val).format({ thousandSeparated: true, mantissa: 2 });
        return (
          <div unformatedvalue={val} type="currency">
            {num}
          </div>
        );
      } else {
        return null;
      }
    case "active":
      if (field.items) {
        const pos = field.items.values.indexOf(val);
        const lbl = field.items.labels[pos];
        return (
          <div type="active" value={val}>
            {field.translate ? this.t(lbl) : lbl}
          </div>
        );
      }
    case "boolean":
    case "radio":
      if (dc) {
        if (val === null || val === undefined) {
          return "";
        } else {
          const lbl = dc.records.find((x) => x.value === val).label
          return field.translate ? this.t(lbl) : lbl;
        }
      } else if (field.items) {
        const ind = field.items.values.indexOf(val);
        const lbl = field.items.labels[ind];
        return field.translate ? this.t(lbl) : lbl;
      } else {
        return val;
      }
    case "checkbox":
      if (Number.isInteger(val)) {
        if (dc) {
          const lbl = dc.records.find((x) => x.value == val).label;
          return field.translate ? this.t(lbl) : lbl;
        }
        const codes = val.toString(2).split("").reverse();
        let arr = codes.map((x, i) => field.items.labels[field.items.values.indexOf(Math.pow(2, i))]);
        if (field.translate) {
          arr = arr.map(x => this.t(x));
        }
        return arr.join(", ");
        // let valueLabel = "";
        // codes.forEach((el, i) => {
        //   if (el === "1") {
        //     const pos = field.items.values.indexOf(Math.pow(2, i));
        //     // valueArray.push( Math.pow(2, i) );
        //     valueLabel += this.t(field.items.labels[pos]) + "; ";
        //   }
        // });
        // return valueLabel;
      } else if (Array.isArray(val)) {
        if (dc) {
          let arr = val.map((x) => dc.records.find((f) => f.tag === x || f.tag === x.tag).opis);
          if (field.translate) {
            arr = arr.map(x => this.t(x));
          }
          return arr.join(", ");
        } else {
          return val.map((x) => x.tag).join(", ");
        }

        // return val.map(x => this.t(x.label)).join(', ');
      } else {
        return val;
      }
    case "picker":
      if (dc) {
        if (val === null || val === undefined) {
          return "";
        } else {
          const rec = dc.records.find((x) => x.id === (val.value || val));
          if (rec) {
            const lbl = dc.fieldRender ? rec[dc.fieldRender] : rec.opis;
            return field.translate ? this.t(lbl) : lbl;
          } else {
            return "";
          }
        }
      } else if (val && val.hasOwnProperty("value")) {
        return val.value;
      } else if (field.items) {
        const ind = field.items.values.indexOf(val);
        const lbl = field.items.labels[ind];
        return field.translate ? this.t(lbl) : lbl;
      } else {
        return val;
      }
    case "state":
      if (val === null) {
        return null
      } else {
        if (val) {
          return <i style={{ color: "#0F0" }} className="fas fa-check"></i>
        } else {
          return <i style={{ color: "#F00" }} className="fas fa-ban"></i>
        }
      }
    case "link":
      if (val) {
        return (
          <a href={field.use_prefix ? `${process.env.REACT_CV_PREFIX}${val}` : val} target="_blank" style={{color: "#b7c800"}}>
            <i className="fas fa-link"></i>
          </a>
        )
      } else {
        return ""
      }
    case "link-double":
      return field.link_fields.map((fld, i) => (
        record[fld.source] ?
          <Tooltip key={`${fld.source}-${i}`} 
            title={
              <div>
                <div>{this.t(fld.ttip)}</div>
                <div>
                  <i 
                    className="fas fa-copy" 
                    style={{ marginRight: "4px", cursor: "pointer", padding: "2px" }}
                    onClick={() => copyTextToClipboard(field.use_prefix ? `${process.env.REACT_CV_PREFIX}${record[fld.source]}` : record[fld.source])}
                  ></i>
                  {field.use_prefix ? `${process.env.REACT_CV_PREFIX}${record[fld.source]}` : record[fld.source]}</div>
              </div>
            }
            interactive
          >
            <a href={field.use_prefix ? `${process.env.REACT_CV_PREFIX}${record[fld.source]}` : record[fld.source]} target="_blank" style={{color: "#b7c800"}}>
              <i className="fas fa-link" style={{ marginRight: "4px" }}></i>
            </a>
          </Tooltip>
          :
          <Tooltip key={`${fld.source}-${i}`} title={this.t(fld.ttip)}>
            <i className="fas fa-times" style={{ marginRight: "9px" }}></i>
          </Tooltip>
      ))
    case "mail":
      if (val) {
        return (
          <a href={"mailto:" + val} style={{color: "#b7c800"}}>
            <i className="far fa-envelope"></i>
          </a>
        )
      }
    case "dialog_id":
      return (val
        // val ?
        // <IconButton size="small" onClick={() => customFnc(val)}>
        //   <FontAwesomeIcon icon={faEdit} size="sm" />
        // </IconButton>
        // : null
      );
    default:
      return val;
  }
};

export default tableGenerator;
