import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import VanjskiZahvatDialog from "Views/VanjskiZahvatiPage/VanjskiZahvatDialog";

import model from "Models/zahvat";
import dataController from "Lib/dataController";

function VanjskiZahvatiTable(props) {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();
  const { level = 1, ugovorId } = props;
  const [records, setRecords ] = useState([]);
  const [tableControls, setTableControls] = useState(true);
  const [notAuth, setNotAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }

  const handleAdd = (evt) => {
    dialogContext.showDialog(VanjskiZahvatDialog, {
      model: dc,
      mode: "insert",
      form: "vanjski_zahvati",
      onClose: handleDialogClose,
      level: level,
      ugovorId: ugovorId
    }, level);
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;

    dialogContext.showDialog(VanjskiZahvatDialog, {
      level: props.level,
      model: dc,
      mode: "update",
      form: "vanjski_zahvati",
      recordId: recordId,
      onClose: handleDialogClose,
      level: level,
      ugovorId: ugovorId
    }, level);
  };

  const handleTableEdit = (recordId) => {
    dialogContext.showDialog(VanjskiZahvatDialog, {
      model: dc,
      mode: "update",
      form: "vanjski_zahvati",
      recordId: recordId,
      onClose: handleDialogClose,
      level: level,
      ugovorId: ugovorId
    }, level);
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    // dummyRecords();
    setLoading(true);
    dc.GetData(dc.apiPath+'ug/'+'-1').then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      } else {
        const { error } = resp;
        if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
          setNotAuth(true);
        } 
      }
      setLoading(false);
    });
  };

  return (
    <ModelTable 
      viewName="vanjski_zahvati" 
      records={records || []} 
      dc={dc} 
      allowSelection="one" 
      allowFilter={true} 
      allowExport={true} 
      disableControls={!tableControls} 
      inlineEdit={true} 
      onEdit={handleTableEdit}
      notAuth={notAuth}
      isLoading={loading}
      onAdd={handleAdd}
      title="titles.vanjski_zahvati"
      addGender="m"
    />
  );
}

export default VanjskiZahvatiTable;
