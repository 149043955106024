
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// Material-UI Icons
import DashboardIcon from '@material-ui/icons/Dashboard';

//Material UI Core Components
import TableHeaderButton from "UI/Table/TableHeaderButton";
import Hidden from "@material-ui/core/Hidden";

function TableHeaderButtonFilter(props) {
  const { t } = useTranslation();
  const { handleExportPonudeIds, ...otherProps } = props;

  return (
    <TableHeaderButton 
      onClick={handleExportPonudeIds} 
      startIcon={<DashboardIcon />} 
      variant="outlined"
      {...otherProps}
    >
      <Hidden smDown>
        {t("buttons.export_search")}
      </Hidden>
    </TableHeaderButton>
  );
}

TableHeaderButtonFilter.propTypes = {
  handleExportPonudeIds: PropTypes.func
};

export default TableHeaderButtonFilter;
