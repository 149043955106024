import React from "react";
import { useTranslation } from "react-i18next";

import AppPage from "UI/AppPage/AppPage";
import SchedulerView2 from "Components/Scheduler/SchedulerView2";

import Box from "@material-ui/core/Box";

function CalendarPage(props) {
  const { t } = useTranslation();

  return (
    <AppPage>
      <Box m={0}>
        <SchedulerView2 hideTime={true}></SchedulerView2>
      </Box>
    </AppPage>
  );
}

export default CalendarPage;
