import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';


//Material-UI Icons
import LockIcon from "@material-ui/icons/Lock";

//Custom Components
import ThemeConsumer from "Components/ThemeContext/ThemeConsumer"
import withSnackbar from 'Components/withSnackbar';
import { border } from '@material-ui/system';
import { fieldFormat } from "Lib/fieldFormat";
import NotAuthorizedControl from "Controls/NotAuthorizedControl";

// TODO:
// replace this control with Autocomplete from Material-UI

const selectStyles = {
  container: (base, state, ...a) => {
    const inFormContainer = state.selectProps.inContainer === "form";

    const height = inFormContainer ? 56 : 32;
    const bkgColor = inFormContainer ? "rgba(0, 0, 0, 0.09)" : "rgba(255,255,255,0)";
    const bkgRadius = inFormContainer ? 4 : 0;
    return {
      ...base,
      minHeight: height,
      color: "rgba(0, 0, 0, 0.54)",
      backgroundColor: bkgColor,
      borderTopLeftRadius: bkgRadius,
      borderTopRightRadius: bkgRadius
    }
  },
  control: (base, state) => {
    const inFormContainer = state.selectProps.inContainer === "form";
    const marginTop = inFormContainer ? 16 : 0;
    const borderBottom = state.isFocused ? "2px solid rgb(25, 118, 210)" : "1px solid rgba(0, 0, 0, 0.87)";
    const borderBottomHover = state.isFocused ? "2px solid rgb(25, 118, 210)" : "2px solid black";
    return {
      ...base,
      marginTop: marginTop,
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: borderBottom,
      borderRadius: 0,
      boxShadow: 0,
      "&:hover": {
        borderBottom: borderBottomHover
      },
      backgroundColor: "rgba(255,255,255,0)"
    }
  },
  option: (base, state) => {
    const bckColor = state.isFocused ? state.selectProps.theme === "light" ? "rgba(150, 190, 240, 0.2)" : "rgba(69, 87, 110, 1)" : null;
    const color = state.selectProps.theme === "light" ? "#303030" : "rgba(255, 255, 255, 1)";
    return {
      ...base,
      backgroundColor: bckColor,
      color: color
    }
  },
  menuList: (base, state) => ({
      ...base,
      backgroundColor: state.selectProps.theme === "light" ? "rgba(255, 255, 255, 1)" : "#303030"
  }),
  menuPortal: (base, state) => ({
    ...base,
    zIndex: "9999"
  }),
  input: (base, state) => ({
      ...base,
      fontSize: "1rem",
       color: state.theme[0] === "l" ? "#303030" : "rgba(255, 255, 255, 1)"
  }),
  multiValueLabel: (base, state) => {
    const bckColor = state.selectProps.theme === "light" ? "rgba(150, 190, 240, 0.2)" : "rgba(69, 87, 110, 1)";
    const color = state.selectProps.theme === "light" ? "#303030" : "rgba(255, 255, 255, 1)";
    return {
      ...base,
      backgroundColor: bckColor,
      color: color,
      borderRadius: 4
    }
  },
  singleValue:  (base, state) => {
    const bckColor = state.selectProps.theme === "light" ? "rgba(150, 190, 240, 0.2)" : "rgba(69, 87, 110, 1)";
    const color = state.selectProps.theme === "light" ? "#303030" : "rgba(255, 255, 255, 1)";
    return {
      ...base,
      backgroundColor: bckColor,
      color: color,
      borderRadius: 4,
    }
  }
}

const labelStyles = {
  label: "transform: translate(12px, 20px) scale(1)"
}

class PickerControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.applyChange = this.applyChange.bind(this);

    this.getOption = this.getOption.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.getFilteredOptions = this.getFilteredOptions.bind(this);
    this.getLabel = this.getLabel.bind(this);

    const formMode = props.formMode ? props.formMode : "form";

    this.state = {
      isFocused: false,
      inputValue: '',
      inFormContainer: formMode === "form",
      inTableContainer: formMode === "table",
      options: [],
      renderOptions: [],
    }

    this.offset = 100;
  }

  componentDidMount(){
    this.getOptions();
  }

  componentDidUpdate(prevProps){
    const { options, renderOptions, inputValue } = this.state;
    if (prevProps.model !== this.props.model){
      this.getOptions();
    }
    if (inputValue.length === 0 && renderOptions.length === 0 && options.length > 0) {
      this.getFilteredOptions();
    }
    return null;
  }

  handleFocus(evt) {
    this.setState({ isFocused: true });
  }

  handleBlur(evt) {
    this.setState({ isFocused: false });
  }

  handleChange(option, actionType) {

    switch (actionType.action) {
      case "select-option":
      case "remove-value":
        this.applyChange(option);
        break;
      case "clear":
        this.applyChange(null);
        break;
      default:
        //console.log('action', actionType);
    }
  }

  handleInputChange(value){
    this.setState({ inputValue: value }, () => this.getFilteredOptions());
  }


  applyChange(option) {
    const { field, onChange } = this.props;

    if (onChange) {
      onChange(option, field.source);
    }
  }

  getLabel() {
    const { field, label, t } = this.props;

    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  }

  getOption(value) {
    const { field, model, t } = this.props;

    let intValue;
    if (typeof value === 'string') {
      intValue = value;
    } else if (value && value.hasOwnProperty('value') && value.hasOwnProperty('label')) {
      return value; //it's already option object
    } else if (value === null){
      return null
    } else {
      intValue = value;
    }

    if (field && field.subModel && model) {
      if (Array.isArray(value)) {
        return value.map(val => {
          if (val && val.hasOwnProperty('value') && val.hasOwnProperty('label')) {
            return val //it's already option object
          } else if(Number.isInteger(val)) {
            const record = model.getRecordObject(val);
            if (record) {
              return {
                label: fieldFormat(record, field.subModel.format, field.translate ? t : null),
                value: val,
                link: field.subModel.link ? record[field.subModel.link] : null
              }
            } else {
              return null;
            }
          }
        })
      } else {
        const record = model.getRecordObject(intValue);
        if (record) {
          return {
            label: fieldFormat(record, field.subModel.format, field.translate ? t : undefined),
            value: intValue,
            link: field.subModel.link ? record[field.subModel.link] : null
          }
        }
      }
    } else if (field && field.items && field.items.labels) {
      const index = field.items.values.indexOf(intValue);
      const lbl = field.items.labels[index];
      return {
        label: field.translate ? t(lbl) : lbl,
        value: intValue,
        link: null
      }
    } else {
      return null;
    }
  }

  getFilteredOptions(bottom = false) {
    const { options, renderOptions, inputValue } = this.state;
    const limit = bottom ? (renderOptions.length + this.offset) : this.offset;
    const retOptions = [];
    let cnt = 0;

    for (let i = 0; cnt < limit && i < options.length; ++i) {
      if (inputValue && options[i].label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        retOptions.push( options[i] );
        ++cnt;
      } else if (!inputValue) {
        retOptions.push( options[i] );
        ++cnt;
      }
    }

    this.setState({
      renderOptions: retOptions
    });
  }

  getOptions() {
    const { field, model } = this.props;

    if (field && field.subModel && model) {
      const idAttribute = model.fieldId.source;

      model.GetData()
        .then(resp => {
          this.setState({
            options: resp.data ? resp.data.map( (r, i) => this.getOption(r[idAttribute]) ) : []
          }, () => this.getFilteredOptions())
        })
    } else if (field && field.items && field.items.values)  {
      this.setState({
        options: field.items.values.map(val => this.getOption(val))
      }, () => this.getFilteredOptions())
    } else {
      return [];
    }
  }

  formatOptionLabel(optionsProps, contextProps) {
    const { inputValue } = contextProps;
    const { label } = optionsProps;
    if (inputValue === null || inputValue === undefined || inputValue.length === 0) {
      return (label === null || label === undefined || label.length === 0) ? "?" : label;
    } else {
      const strs = label.toLowerCase().split(inputValue);
      const strsLen = strs.length;
      let cnt = 0
      return strs.map((s, i) => {
        cnt += s.length;
        cnt += inputValue.length;
        return (
          <Fragment key={`${label}-${i}`}>
            {label.slice(cnt - s.length - inputValue.length, cnt - inputValue.length)}
            {
              i === strsLen - 1 ?
              null
              :
              <span style={{ backgroundColor: "#FDFD96", color: "#000" }}>{label.slice(cnt - inputValue.length, cnt)}</span>
            }
          </Fragment>
        )
      })
    }
  }

  render() {
    const { isFocused, options, renderOptions } = this.state;
    const {
      formMode,
      controlMode,
      field,
      value,
      validation,
      model,
      allowNewRecord,
      classes,
      allowMultiSelect,
      allowClear,
      // showHelper,
      autoFocus,
      t
    } = this.props;
    const isRequired = field && field.validation && field.validation.required;

    const option =  this.getOption(value);
    const notAuth = model === null || model === undefined ? false : model.notAuth;

    // TODO:
    // translations
    const loadingText = "Podaci se učitavaju...";
    const noDataText = field && field.external ? "Počnite tipkati za pretragu" : "Nema podataka";

    const hasValue = option !== undefined && option !== null && option !== '';
    const hasError = validation && validation.valid === false;

    const isReadOnly = controlMode === "view" || field.readonly;
    const isLoading = (model || field && field.items && field.items.values || field && field.external) ? false : true;
    const hideLabel = formMode === "table";
    const label = this.getLabel();

    let allowAdd = allowNewRecord;
    if (field.allowNewRecord !== undefined && field.allowNewRecord === false) {
      allowAdd = false;
    }
    const SelectComponent = allowAdd ? CreatableSelect : Select;

    const isMulti = field.multi && Array.isArray(option);
    let displayValue = "";
    if (isMulti){
      const labels = option.map(op => op ? op.label : "");
      displayValue = labels.join('; ');
    }

    const showHelper = field.hasOwnProperty('showHelper') && field.showHelper == false ? false : true;

    return (
      <ThemeConsumer>
        {(themeContext) => (


        notAuth ?
        <NotAuthorizedControl 
          label={label}
          variant={formMode === "form" ? "filled" : "standard"}
        />
        :
        isReadOnly ?
        <TextField
          margin="none"
          fullWidth
          error={hasError}
          label={hideLabel ? null : label}
          id={field.source}
          value={ isMulti ? displayValue : option ? option.label : ''}
          InputProps={{
            readOnly: isReadOnly,
            endAdornment:
              isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null
          }}
          helperText={hasError ? validation.msg : (field.tooltip ? field.tooltip : " ")}
          variant={formMode === "form" ? "filled" : "standard"}
        />
         :
      <FormControl margin="none" fullWidth error={hasError} variant="filled" >
        {formMode === "form" ? <InputLabel shrink={hasValue || isFocused} className={classes.label} required={isRequired}>{label}</InputLabel> : null }
        <SelectComponent
          value={allowMultiSelect || field && field.items && field.items.multi ? option.value : option}
          placeholder=""
          inContainer={formMode}
          autoFocus={autoFocus}

          options={renderOptions}
          formatOptionLabel={this.formatOptionLabel}
          noOptionsMessage={() => noDataText}

          isMulti = {allowMultiSelect || field && field.multi}
          isClearable={allowClear}
          theme={themeContext.theme}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          menuPosition="fixed"
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onMenuScrollToBottom={() => this.getFilteredOptions(true)}
          styles={selectStyles}
          isLoading={isLoading}
          loadingMessage={() => loadingText}
          //onCreateOption={allowAdd ? this.handleCreateOption : null}
          formatCreateLabel={allowAdd ? (inputValue) => { return 'Dodaj "' + inputValue + '"'; }: null}

          inputProps={{
            readOnly: isReadOnly,
            endAdornment: isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null
          }}
        />
        {showHelper ?
          <FormHelperText id={field.source + "-helper"}>
            {hasError ? validation.msg : (field.tooltip ? field.tooltip : " ")}
          </FormHelperText>
          : null }
      </FormControl>
      )}
      </ThemeConsumer>
    )
  }
}

PickerControl.defaultProps = {
  formMode: "form",
  controlMode: "edit",
  allowNewRecord: false,
  isLoading: true,
  allowMultiSelect: false,
  allowClear: true,
  showHelper: true,
  autoFocus: false
}

PickerControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array]),
  validation: PropTypes.object,
  model: PropTypes.object,
  allowNewRecord: PropTypes.bool,
  onChange: PropTypes.func,
  allowMultiSelect: PropTypes.bool,
  allowClear: PropTypes.bool,
  showHelper: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default withTranslation()(withStyles(labelStyles)(withSnackbar(PickerControl)));