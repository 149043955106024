import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import { ZoomSlider } from "ol/control";

export default function ZoomSliderControl(props) {
  const context = useContext(MapContext);

  const options = {
    className: undefined,
    duration: undefined,
    render: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, ZoomSlider, props, options, events), [
    props.className,
    props.duration,
    props.render
  ]);

  return null;
}
