import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faTag, faUserAlt } from "@fortawesome/free-solid-svg-icons";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Delete";

//Custom Components
import withFormController from "Components/withFormController";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarHeadingSub from "UI/Dialog/DialogToolbarHeadingSub";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogTabs from "UI/Dialog/DialogTabs";
import DialogTab from "UI/Dialog/DialogTab";
import DialogTabPanel from "UI/Dialog/DialogTabPanel";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";

import TaggingControl from "Components/TaggingControl/TaggingControl";
import ZivotopisTable from "Views/DjelatniciPage/ZivotopisTable";

function DjelatnikDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [dataChanged, setDataChanged] = useState(false);
  const [ tabValue, setTabValue] = useState(0);

  const { model, mode, form, record, validation, fields, subModels, } = props; //HOC withFormController
  const { doInsert, doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController


  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
        } else if (result.error) {
          const msg = result.errorCode && result.errorCode === 23505 ?
            ("messages.duplicate") :
            ("Greška prilikom dodavanja novog zapisa! " + result.error);
          snackbarContext.showNotification(msg, "error", {record_type: "Djelatnik"});
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          const msg = "Greška prilikom brisanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }

  }

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        console.log(result)
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg = result.errorCode && result.errorCode === 23505 ?
            ("messages.duplicate") :
            ("Greška prilikom dodavanja novog zapisa! " + result.error);
           console.log(msg)
          snackbarContext.showNotification(msg, "error", {record_type: "Djelatnik"});
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const handleTabChange = (evt, newValue) => {
    setTabValue(newValue);
  }

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: mode,
    subModels: subModels,
  }

  return (
    <DraggableDialog open={true} maxWidth={"xl"} fixedHeight={true} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="employee" icon={<PersonIcon />} />
          <DialogToolbarHeading>{t("titles.djelatnik")}</DialogToolbarHeading>
          {record.ime ? <DialogToolbarHeadingSub noMarginRight={true}>{record.ime}</DialogToolbarHeadingSub> : null}
          {record.prezime ? <DialogToolbarHeadingSub>{record.prezime}</DialogToolbarHeadingSub> : null}
          {record.id ? <DialogToolbarHeadingSub>(ID = {record.id})</DialogToolbarHeadingSub> : null}
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <DialogTabs
          value={tabValue}
          onChange={handleTabChange}
        >
          <DialogTab
            value={0}
            label={t("djelatnici.tab1")}
            icon={<FontAwesomeIcon icon={faUserAlt} size="lg" />}
            fields={[
              "tag","ime","prezime", "oib","rodjendan","iban"
            , "najvise_obrazovanje","titula","datum_diplome", "datum_zaposlenja","radno_mjesto"
            , "ulica_kbr","zip","mjesto"
            , "telefon","email","doc_path"
            , "napomena"
            ]}
            validation={validation}
          />
          <DialogTab
            disabled={mode === "insert"}
            value={1}
            label={t("djelatnici.tab2")}
            icon={<FontAwesomeIcon icon={faTag} size="lg" />}
            fields={[]}
            validation={validation}
          />
          <DialogTab
            disabled={mode === "insert"}
            value={2}
            label={t("djelatnici.tab3")}
            icon={<FontAwesomeIcon icon={faFileAlt} size="lg" />}
            validation={validation}
          />
        </DialogTabs>
        <DialogTabPanel value={tabValue} index={0} key={0}>
          <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={["tag","ime","prezime", "oib","rodjendan","iban"]}
              columns={3}
              {...commonFields}
            />
            <FormContent
              fieldNames={["najvise_obrazovanje","titula"]}
              columns={2}
              {...commonFields}
            />
            <FormContent
              fieldNames={["datum_diplome", "datum_zaposlenja","radno_mjesto"]}
              columns={3}
              {...commonFields}
            />
            <FormContent
              fieldNames={["ulica_kbr","zip","mjesto"]}
              columns={3}
              {...commonFields}
            />
            <FormContent
              fieldNames={["telefon","email","doc_path"]}
              columns={3}
              {...commonFields}
            />
            <FormContent
              fieldNames={["napomena"]}
              columns={1}
              {...commonFields}
            />
          </GridContainer>
        </Box>
      </DialogTabPanel>
      <DialogTabPanel value={tabValue} index={1} key={1}>
        <Box m={2}>
          <GridContainer>
            <TaggingControl linkId={record.id} title="Djelatnik ključne riječi" groupsDenied={JSON.parse(process.env.REACT_TAGS_DJELATNICI_BLACKLIST)} groupsFilterId={process.env.REACT_TAGS_DJELATNICI_FILTER_ID}/>
          </GridContainer>
        </Box>
      </DialogTabPanel>
      <DialogTabPanel value={tabValue} index={2} key={2}>
        <Box m={2}>
          <GridContainer>
            <GridItem xs={12}>
              <ZivotopisTable djelatnikId={record.id} level={2}/>
            </GridItem>
          </GridContainer>
        </Box>
      </DialogTabPanel>
      </DialogBody>
      <DialogActions>
        { mode === "update" ? (
          <DialogActionButton variant="outlined" startIcon={<DeleteIcon />} onClick={handleDelete}>
            {t("buttons.delete")}
          </DialogActionButton>
        ) : null}
        <DialogActionButton variant="outlined" startIcon={<CloseIcon />} onClick={handleClose}>
          {t("buttons.close")}
        </DialogActionButton>
        {mode === "insert" ? (
          <DialogActionButton variant="contained" startIcon={<AddIcon />} onClick={handleInsert}>
            {t("buttons.add")}
          </DialogActionButton>
        ) : mode === "update" ? (
          <DialogActionButton variant="contained" startIcon={<SaveIcon />} onClick={handleUpdate}>
            {t("buttons.save")}
          </DialogActionButton>
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormController(DjelatnikDialog);

