import MapPage from "Views/MapPage/MapPage";
import HomePage from "Views/HomePage/HomePage";
import UsersPage from "Views/UsersPage/UsersPage";
import LoginPage from "Views/Auth/LoginPage";
import Registration from "Views/Auth/RegistrationPage";
import PasswordResetRequestPage from "Views/Auth/PasswordResetRequestPage";
import PasswordResetPage from "Views/Auth/PasswordResetPage"

const publicRoutes = [
	{ path: "/login", name: "Login", component: LoginPage },
	{ path: "/register", name: "Register", component: Registration},
  { path: "/password-reset-request", name: "Password Reset Request", component: PasswordResetRequestPage },
  { path: "/password-reset", name: "Reset Password", component: PasswordResetPage }
];

export default publicRoutes