import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faTag } from "@fortawesome/free-solid-svg-icons";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EmojiTransportationSharpIcon from '@material-ui/icons/EmojiTransportationSharp';
import ListAltIcon from '@material-ui/icons/ListAlt';

//Custom Components
import withFormController from "Components/withFormController";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarHeadingSub from "UI/Dialog/DialogToolbarHeadingSub";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogTabs from "UI/Dialog/DialogTabs";
import DialogTab from "UI/Dialog/DialogTab";
import DialogTabPanel from "UI/Dialog/DialogTabPanel";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";

import TaggingControl from "Components/TaggingControl/TaggingControl";

function VanjskiCVDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [dataChanged, setDataChanged] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const { model, mode, form, record, validation, fields, subModels, } = props; //HOC withFormController
  const { doInsert, doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController
  const { level = 1 } = props;


  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert", id: result.id});
        } else if (result.error) {
          const msg = result.errorCode && result.errorCode === 23505 ?
            ("messages.duplicate") :
            ("Greška prilikom dodavanja novog zapisa! " + result.error);
          snackbarContext.showNotification(msg, "error", {record_type: "Oprema"});        
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          const msg = "Greška prilikom brisanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }

  }

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg = result.errorCode && result.errorCode === 23505 ?
            ("messages.duplicate") :
            ("Greška prilikom dodavanja novog zapisa! " + result.error);
          snackbarContext.showNotification(msg, "error", {record_type: "Oprema"});
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    dialogContext.hideDialog((level && level === 2) ? 2 : 1);
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }
  };

  const handleTabChange = (evt, newValue) => {
    setTabValue(newValue);
  }

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: mode,
    subModels: subModels,
  }

  return (
    <DraggableDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="contact" icon={<ListAltIcon />} />
          <DialogToolbarHeading>{t("titles.vanjski_cv")}</DialogToolbarHeading>
          {
            record.id ? 
              <DialogToolbarHeadingSub>
                {`ID = (${record.id}) ${record.naziv}`}
              </DialogToolbarHeadingSub> 
            : null
          }
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <DialogTabs
          value={tabValue}
          onChange={handleTabChange}
        >
          <DialogTab
            value={0}
            label={t("vanjski_cv.osn_podaci")}
            icon={<FontAwesomeIcon icon={faFileAlt} size="lg" />}
            fields={["naziv", "sprema", "godine_staza", "cv_wb_hr", "cv_wb_en", "cv_eu_hr", "cv_eu_en", "cv_o_hr", "cv_o_en", "cv_ep_hr", "cv_ep_en", "napomena"]}
            validation={validation}
          />
          <DialogTab
            disabled={mode === "insert"}
            value={1}
            icon={<FontAwesomeIcon icon={faTag} size="lg" />}
            label={t("vanjski_cv.tags")}
            validation={validation}
          />
        </DialogTabs>
        <DialogTabPanel value={tabValue} index={0} key={0}>
          <GridContainer>
            <FormContent
              fieldNames={["naziv", "sprema", "godine_staza"]}
              columns={3}
              {...commonFields}
            />
            <FormContent
              fieldNames={["cv_wb_hr", "cv_wb_en", "cv_eu_hr", "cv_eu_en", "cv_o_hr", "cv_o_en", "cv_ep_hr", "cv_ep_en"]}
              columns={2}
              {...commonFields}
            />
            <FormContent
              fieldNames={["napomena"]}
              columns={1}
              {...commonFields}
            />
          </GridContainer>
        </DialogTabPanel>
        <DialogTabPanel value={tabValue} index={1} key={1}>
          <GridContainer>
            <GridItem xs={12}>
              <TaggingControl mode={mode} linkId={record.id} title="Vanjski CV ključne riječi" groupsAllowed={JSON.parse(process.env.REACT_TAGS_VANJSKI_CV_WHITELIST)}  groupsFilterId={process.env.REACT_TAGS_VANJSKI_CV_FILTER_ID}/>
            </GridItem>
          </GridContainer>
        </DialogTabPanel>

      </DialogBody>
      <DialogActions>
        { mode === "update" ? (
          <DialogActionButton variant="outlined" startIcon={<DeleteIcon />} onClick={handleDelete}>
            {t("buttons.delete")}
          </DialogActionButton>
        ) : null}
        <DialogActionButton variant="outlined" startIcon={<CloseIcon />} onClick={handleClose}>
          {t("buttons.close")}
        </DialogActionButton>
        {mode === "insert" ? (
          <DialogActionButton variant="contained" startIcon={<AddIcon />} onClick={handleInsert}>
            {t("buttons.add")}
          </DialogActionButton>
        ) : mode === "update" ? (
          <DialogActionButton variant="contained" startIcon={<SaveIcon />} onClick={handleUpdate}>
            {t("buttons.save")}
          </DialogActionButton>
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormController(VanjskiCVDialog);
