import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material UI Core Components
import TableHeaderButton from "UI/Table/TableHeaderButton";
import Switch from "@material-ui/core/Switch";
import Hidden from "@material-ui/core/Hidden";

import FilterListIcon from "@material-ui/icons/FilterList";

function TableHeaderButtonFilter(props) {
  const { t } = useTranslation();
  const { filters, onToggleFilter, ...otherProps } = props;

  const isOn = filters ? true : false;

  return (
    <TableHeaderButton
      onClick={onToggleFilter}
      startIcon={<FilterListIcon />}
      endIcon={<Switch checked={isOn} size="small" color="secondary" />}
      variant={"outlined"}
      {...otherProps}
    >
      <Hidden smDown>
        {isOn ? t("buttons.filters-off") : t("buttons.filters-on")}
      </Hidden>
    </TableHeaderButton>
  );
}

TableHeaderButtonFilter.propTypes = {
  filters: PropTypes.bool,
  onToggleFilter: PropTypes.func
};

export default TableHeaderButtonFilter;
