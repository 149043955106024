import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material UI Core Components
import TableHeaderButton from "UI/Table/TableHeaderButton";
import Hidden from "@material-ui/core/Hidden";

//Material UI Icons
import CloudDownloadSharpIcon from "@material-ui/icons/CloudDownloadSharp";

//Custom Components
import { exportTableToExcel } from "Lib/exportExcel";

function TableHeaderButtonExport(props) {
  const { t } = useTranslation();
  const { dc, rows, records, tableName, exportFnc, ...otherProps } = props;

  const handleExport = function (evt) {
    let filteredRecords = new Array();
    for (let i = 0; i < rows.length; i++) {
      filteredRecords.push(records[parseInt(rows[i].id)]);
    }

    if (exportFnc && typeof exportFnc === 'function') {
      return exportFnc(filteredRecords)
    }

    const fields = dc.getViewFields("exportExcel");
    const obj = {
      records: filteredRecords,
      fields: fields,
      filename: tableName ? tableName : ( dc.filename ? dc.filename : ""),
      t: t
    };
    exportTableToExcel(obj);
  };

  return (
      <TableHeaderButton onClick={handleExport} startIcon={<CloudDownloadSharpIcon />} variant="outlined" {...otherProps}>
        <Hidden smDown>
          {t("buttons.export")}
        </Hidden>
      </TableHeaderButton>
  );
}

TableHeaderButtonExport.propTypes = {
  dc: PropTypes.object,
  records: PropTypes.array,
  fileName: PropTypes.string
};

export default TableHeaderButtonExport;
