import api from "Lib/api";

export const searchService = {
  link,
  search,
};

function search(filters) {
  const apiInstance = new api();

  const data = _prepareData(filters);

  return apiInstance.Call("search/", "post", data).then((resp) => {
    if (resp.success) {
      return resp;
    } else {
      return Promise.reject(resp);
    }
  });
}

function _prepareData(record) {
  let data = Object.assign({}, record);

  Object.keys(data).forEach(key => {
    if (key.includes("datum") && data[key] && data[key].length) {
      const sample = "YYYY-MM-DD";
      data[key] = data[key].slice(0, sample.length);
    }
    if (Array.isArray(data[key])) {
      data[key] = data[key].map(x => x.value || x)
    }
  });
  return data;
}

function link(properties) {
  const apiInstance = new api();
  // const data = properties
    // .map(p => ({ link_id: p.link_id ? p.link_id : -1 }))
    // .filter(f => f.link_id !== -1);
  return apiInstance.Call("search/link", "post", properties)
    .then(resp => {
      if (resp.success) {
        return resp;
      } else {
        Promise.reject(resp);
      }
    });
}