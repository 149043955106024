import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Divider from "@material-ui/core/Divider";
import FormButton from "UI/Form/FormButton";

//Material-UI Icons
import ClearIcon from "@material-ui/icons/Clear";

//Custom Components
import withFormController from "Components/withFormController";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";

function SchedulerFilterForm(props) {

  const { t } = useTranslation();

  const { onFilterChange } = props;
  const { model, mode, form, record, validation, fields, subModels } = props; //HOC withFormController
  const { onFieldChange } = props; //HOC withFormController
  const { doClear } = props; //HOC withFormController

  const handleFieldChange = (value, source) => {

    if (onFieldChange) {
      onFieldChange(value, source, (record) => {
        if (onFilterChange) {
          onFilterChange(record);
        }
      });
    }
  };

  const handleClear = (evt) => {
    if (doClear) {
      doClear().then(resp => {
        if (resp.success) {
          if (onFilterChange) {
            onFilterChange({});
          }
        }
      });
    }
  }

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: mode,
    subModels: subModels,
    columns: 1
  }

  return (
    <GridContainer>
      <FormContent
        fieldNames={["djelatnik_id", "oprema_id"]}
        {...commonFields}
      />
      <GridItem xs={12}>
        <Divider variant="middle" />
      </GridItem>
      <FormContent
        fieldNames={["klijent_id","ponuda_id","ugovor_id"]}
        {...commonFields}
      />
      <GridItem xs={12}>
      <FormButton
          variant="outlined"
          startIcon={<ClearIcon />}
          onClick={handleClear}
        >
          {t("buttons.clear")}
        </FormButton>
      </GridItem>
    </GridContainer>
  )

}

export default withFormController(SchedulerFilterForm);