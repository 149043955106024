import React, { useContext} from "react";

import UserContext from "Components/UserContext/UserContext";

import ImageLayer from "Components/Map/Layers/ImageLayer";

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

function GeoPortalGSLayer(props) {
  const { id, layer, ...otherProps } = props;
  const userContext = useContext(UserContext);

  const handleChangeLayerVisibile = (evt) => {
    const newValue = !evt.oldValue;
    const olLayer = evt.target;
    const id = olLayer.get('id');

    userContext.setLayerVisible(id, newValue);
  }

  const checkIfLayerVisible = () =>  {
    const userSettings = userContext.userSettings || {};
    const mapLayerVisibility = userSettings.map_layer_visibility || {};
    if (mapLayerVisibility.hasOwnProperty(id)) {
      return mapLayerVisibility[id];
    } else {
      return true;
    }
  }

  const visible = checkIfLayerVisible();

  return (
    <ImageLayer
      id={id}
      {...otherProps}
      visible={visible}
      //onChangeVisible={handleChangeLayerVisibile}
      gsLayer={layer}
      wms={{
        url: gs_url,
        params: {
          LAYERS: layer,
          FORMAT: "image/png8"
        }
      }}
    />
  );
}

export default GeoPortalGSLayer;
