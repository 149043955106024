import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";

import Icon from "@material-ui/core/Icon";

//Custom Components
import AppPage from "UI/AppPage/AppPage";
import Dashboard from "Views/HomePage/Dashboard";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import withRedirect from "Components/withRedirect";

//Images
import esfi from "Images/ESIF.gif";
import eu from "Images/eu.png";

const useStyles = makeStyles(theme => ({
  anchor: {
    color: theme && theme.palette && theme.palette.type === "dark" ? "#b7c800" : "#0000EE"
  },
  footer: {
    position: "fixed",
    bottom: 0
  }
}));

function HomePage(props) {
  const { t } = useTranslation();
  const { redirect } = props;

  const classes = useStyles();

  return (
    <AppPage isHomePage={true}>
      <Box m={2} style={{paddingBottom: "250px"}}>
        <GridContainer /*alignItems="center"*/ /*direction="column"*/ justify="space-between">
          <GridItem xs={12}>
            <Typography variant="h4">SUSTAV ZA UPRAVLJANJE PODACIMA KORISNIKA (CMS)</Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant="body1">
              Dobrodošli u CMS sustav tvrtke <a className={classes.anchor} href="https://dvokut-ecro.hr/">Dvokut-ECRO d.o.o.</a>, kojeg je izradila tvrtka <a className={classes.anchor} href="https://prehnit.hr/">Prehnit d.o.o.</a>.
              Izradu aplikacije je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.
              Osobni podaci koji se nalaze u sustavu dostupni su samo ovlaštenim osobama te se štite sukladno Općoj uredbi o zaštiti podataka (GDPR). 
              Za sva pitanja u vezi zaštite osobnih podataka možete se obratiti imenovanom Službeniku za zaštitu osobnih podataka na mail: 
              <a className={classes.anchor} href="mailto:tajana.uzelac@dvokut-ecro.hr">tajana.uzelac@dvokut-ecro.hr</a>.
              Voditelj obrade osobnih podataka je DVOKUT-ECRO d.o.o., pravna osoba.
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography variant="body1">CMS sustav sastoji se od sljedećih komponenti:</Typography>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/dashboard")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Dashboard"
                  avatar={<Icon>dashboard</Icon>}
                  subheader="Grafički prikaz odabranih podataka"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/map")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Karta"
                  avatar={<Icon>travel_explore</Icon>}
                  subheader="Prostorni prikaz, pretraga i vizualizacija zahvata u prostoru"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/users")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Korisnici"
                  avatar={<Icon>account_circle</Icon>}
                  subheader="Upravljanje korisničkim pravima (dostupno administratorima)"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/tags")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Ključne riječi"
                  avatar={<Icon>local_offer</Icon>}
                  subheader="Upravljanje ključnim riječima za pretrage (dostupno administratorima)"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/djelatnici")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Djelatnici"
                  avatar={<Icon>group</Icon>}
                  subheader="Upravljanje podacima djelatnika (samo za ovlaštene korisnike)"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/vanjski-cv")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Vanjski suradnici (CV)"
                  avatar={<Icon>group</Icon>}
                  subheader="Upravljanje podacima vanjskih suradnika (samo za ovlaštene korisnike)"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/oprema")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Oprema"
                  avatar={<Icon>emoji_transportation</Icon>}
                  subheader="Upravljanje podacima opreme"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/klijenti")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Klijenti"
                  avatar={<Icon>contact_phone</Icon>}
                  subheader="Upravljanje podacima klijenata"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/vanjski-zahvati")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Vanjski izvori"
                  avatar={<Icon>speaker_notes</Icon>}
                  subheader="Informacije o vanjskim izvorima"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/ponude")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Ponude"
                  avatar={<Icon>outbound</Icon>}
                  subheader="Informacije o ponudama"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/ugovori")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Ugovori"
                  avatar={<Icon>receipt_long</Icon>}
                  subheader="Informacije o ugovorima"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6} sm={6} md={4} lg={3}>
            <Link onClick={() => redirect("/kalendar")}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Kalendar"
                  avatar={<Icon>event</Icon>}
                  subheader="Pregled rasporeda"
                />
              </Card>
            </Link>
          </GridItem>
          <GridItem sm={6} md={4} lg={3}>
          </GridItem>
          <GridItem md={6} lg={2}>
            <img src={esfi} style={{display: "block", margin: "auto", height:"75px", paddingRight: "10px"}} />
          </GridItem>
          <GridItem md={6} lg={2}>
            <img src={eu} style={{display: "block", margin: "auto", height:"75px"}}/>
          </GridItem>
          <GridItem md={12} lg={8}>
          </GridItem>
        </GridContainer>
      </Box>
    </AppPage>
  );
}

export default withRedirect(HomePage);
