import React, { Fragment, useContext, useMemo } from "react";
// import ThemeContext from "Components/ThemeContext/ThemeContext";

import OlSourceTileWMS from "ol/source/TileWMS";
import OlSourceOSM from "ol/source/OSM";
import TileLayer from "Components/Map/Layers/TileLayer";

function GeoportalBaseLayer(props) {
  // const themeContext = useContext(ThemeContext);

  const source = () => {
    return new OlSourceOSM();
  }

  // const source = useMemo(() => {
  //   return new OlSourceTileWMS({
  //     url: process.env.REACT_APP_MAPPROXY_PATH,
  //     params: {
  //       LAYERS: themeContext.isThemeLight ? process.env.REACT_APP_MAPPROXY_LIGHT : process.env.REACT_APP_MAPPROXY_DARK,
  //       TILED: true
  //     },
  //     transition: 0
  //   });
  // }, [themeContext.isThemeLight]);

  // return <TileLayer key={themeContext.isThemeLight} {...props} source={source} />
  return <TileLayer {...props} source={source()} />
}

export default GeoportalBaseLayer;
