import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

//Custom Components
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import HeaderDropdown from "UI/Header/HeaderDropdown";
import HeaderButton from "UI/Header/HeaderButton";
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import ThemeSwitch from "UI/Header/ThemeSwitch";
import LanguageSelector from "UI/Header/LanguageSelector";
import ProfileMenu from "UI/Header/ProfileMenu";

const drawerWidth = 240;

const makeAppBarStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center"
  },
  link: {
    textDecoration: "none",
    paddingLeft: 10,
    paddingRight: 10
  },
  titleLink: {
    color: "#fff",
    textDecoration: "none"
  },
  activeLink: {
    color: "#FFD33A",
    textDecoration: "underline"
  }
}));

const makeToolbarStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      }
    }
  },
}));

function AppHeader(props) {
  const appBarClasses = makeAppBarStyles();
  const toolbarClasses = makeToolbarStyles();
  const { t } = useTranslation();
  const { menu, sidebarOpen, toggleSidebar } = props;
  const [appVersion, setAppVersion] = useState(null);

  useEffect(() => {
    fetch('/appver.txt')
    .then(response => response.text())
    .then(data => {
      if (data && data.startsWith('master')) {
        //setAppVersion(data);
      }
    })
    .catch(error => console.warn(error))
  }, []);

  return (
    <AppBar position="fixed" color="primary" className={`${appBarClasses.appBar} ${sidebarOpen ? appBarClasses.appBarShift : ""}`}>
      <Toolbar className={toolbarClasses.root} variant="dense" disableGutters={true}>
        {!sidebarOpen ? <HeaderMenuButton toggleSidebar={toggleSidebar} /> : null}
        <div className={appBarClasses.grow} />
          <Typography
            variant="h6"
            component="h2"
            className={appBarClasses.grow}
            style={{ color: "#fff", fontSize: "1.15rem" }}
          >
            <NavLink to="/" className={appBarClasses.titleLink}>
              {t("app_title")}
              {
                appVersion ? 
                <Fragment>
                  &nbsp;
                  ({appVersion.trim()})
                </Fragment>
                : null
              }
            </NavLink>
          </Typography>
          {
            appVersion ?
            <Typography
              variant="h6"
              component="h2"
              className={appBarClasses.grow}
              style={{ color: "#fff", fontSize: "1.15rem" }}
            >
              
            </Typography>
            : null
          }
          <div className={appBarClasses.grow} />
        {/* <ToolbarFillContent>
          <Typography variant="h6" component="h2" className={appBarClasses.grow} style={{ color: "#fff", fontSize: "1.15rem" }}>
            <NavLink to="/" className={appBarClasses.titleLink}>
              {t("app_title")}
            </NavLink>
          </Typography>
        </ToolbarFillContent> */}
        <ThemeSwitch />
        <LanguageSelector />
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}
// <div id="header" className={classes.root}>
// </div>

AppHeader.propTypes = {
  menu: PropTypes.array
};

export default AppHeader;
