import React from 'react';
import Draggable from 'react-draggable';
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

//Material-UI Core Components
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';

function DragComponent(props) {
  return (
    <Draggable handle={".dialogTitle"} cancel={".nodrag"}>
      <Paper {...props} />
    </Draggable>
  );
}

const customDialogStyles = makeStyles(theme => ({
  paper: {
    minHeight: '40vh',
    maxHeight: '90vh'
  }})
);

const fixedHeightStyles = makeStyles(theme => ({
  paper: {
    minHeight: '90vh',
    maxHeight: '90vh'
  }
}))

function DraggableDialog(props) {
  const { fixedHeight, ...rest } = props;
  const classes = fixedHeight ? fixedHeightStyles() : customDialogStyles();

  return(
    <Dialog
      classes={classes}
      PaperComponent={DragComponent}
      fullWidth={true}
      disableBackdropClick={true}
      {...rest}
      >
        {props.children}
    </Dialog>
  )
}

DraggableDialog.defaultProps = {
  handle: ".dialogTitle",
  fixedHeight: false
}

DraggableDialog.propTypes = {
  open: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  handle: PropTypes.string,
  onClose: PropTypes.func,
  fixedHeight: PropTypes.bool
}

export default DraggableDialog;