import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import Map from "Components/Map/Map";
import {
  Controls,
  DefaultControls,
  ZoomControl,
  ZoomToExtentControl,
  ScaleControl,
  ScaleLineControl,
  ScaleRatioControl,
  FullScreenControl,
  RotateControl,
  LayerSwitcher,
  ViewHistoryControl
} from "Components/Map/Controls";

import { Overlays, CardOverlay } from "Components/Map/Overlays";

import {
  SidebarControl,
  SidebarTabs,
  SidebarTabList,
  SidebarTabListItem,
  SidebarContent,
  SidebarPane,
  SidebarHeading
} from "Components/Map/Controls/Sidebar";

import InfoPane from "Components/MapSidebarPanes/InfoPane";
import LayersPane from "Components/MapSidebarPanes/LayersPane";
import SearchPane from "Components/MapSidebarPanes/SearchPane";

import DialogConsumer from "UI/DialogContext/DialogConsumer";

import { Layers, TileLayer, GroupLayer, VectorLayer } from "Components/Map/Layers";

import { Interactions, DefaultInteractions, DrawInteraction } from "Components/Map/Interactions";

import GeoportalBaseLayer from "Views/MapPage/GeoportalBaseLayer";
import GeoPortalGSLayer from "Views/MapPage/GeoPortalGSLayer";
import GeoPortalBaseLayerSwitcher from "Views/MapPage/GeoPortalBaseLayerSwitcher";
import GeoPortalLayerSwitcher from "Views/MapPage/GeoPortalLayerSwitcher";
import GSInfoCard from "Views/MapPage/GSInfoCard";

//OpenLayers
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection } from "ol/proj";
import OlSourceVector from "ol/source/Vector";
import OlLayerGroup from "ol/layer/Group";
import OlFormatWKT from "ol/format/WKT";
import OlFeature from "ol/Feature";
import OlCollection from "ol/Collection";
import { buffer as OlBuffer } from "ol/extent";

//Services
import { gsService } from "Services/gsService";
import { mapService } from "Services/mapService";
import { searchService } from "Services/searchService";

//Styles
import { drawStyle, tagsStyle } from "Components/mapStyles";

//Dialogs
import TagDialog from "Views/TagsPage/TagDialog";
import DjelatnikDialog from "Views/DjelatniciPage/DjelatnikDialog"
import KlijentDialog from "Views/KlijentiPage/KlijentDialog"
import PonudaDialog from "Views/PonudePage/PonudaDialog";
import OpremaDialog from "Views/OpremaPage/OpremaDialog";
import UgovorDialog from "Views/UgovoriPage/UgovorDialog";
import ZahvatDialog from "Views/ZahvatiPage/ZahvatDialog";
import UslugaDialog from "Views/UslugePage/UslugaDialog";
import UslugaTimDialog from "Views/UslugeTimPage/UslugaTimDialog";
import CalendarDialog from "Views/CalendarPage/CalendarDialog";

import modelTag from "Models/tag";
import modelDjelatnik from "Models/djelatnik"
import modelKlijent from "Models/klijent";
import modelPonuda from "Models/ponuda";
import modelOprema from "Models/oprema";
import modelUgovor from "Models/ugovor";
import modelZahvat from "Models/zahvat";
import modelUsluge from "Models/usluge";
import modelUslugeTim from "Models/usluge_tim";
import modelCalendar from "Models/calendar";
import dataController from "Lib/dataController";


class MainMap extends Component {
  constructor(props) {
    super(props);

    this.layerSwitcherElementRef = React.createRef();

    //define proj
    proj4.defs(
      "EPSG:3765",
      "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
    );
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    proj4.defs(
      "EPSG:3857",
      "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
    );
    OlRegister(proj4);

    this.htrs96 = OlGetProjection("EPSG:3765");
    this.wgs84 = OlGetProjection("EPSG:4326");
    this.wgs84PM = OlGetProjection("EPSG:3857");

    this.defaultViewCenter = [1731757, 5581737];
    // this.defaultExtent = [-90879, 4680674, 1045230, 5169330];
    //this.defaultExtent = [1042452.65, 5167448.19, 2626808.03, 5851603.54];
    //this.defaultExtent = [1120566.5218317308, 5289747.551950627, 2342947.478168269, 5873726.448049373]; // invisible Dubrovnik
    this.defaultExtent = [1030519.1565774428, 5322489.6431554565, 2379023.7432858474, 5736546.6070551695]; // visible Dubrovnik



    this.wkt = new OlFormatWKT();
    this.drawingSource = new OlSourceVector({});
    this.searchSource = new OlSourceVector({});
    this.tagsSource = new OlSourceVector({});


    this.handleClick = this.handleClick.bind(this);
    this.handleViewChangeCenter = this.handleViewChangeCenter.bind(this);
    this.showCardOverlay = this.showCardOverlay.bind(this);
    this.closeOverlays = this.closeOverlays.bind(this);

    this.handleToggleDraw = this.handleToggleDraw.bind(this);
    this.handleDrawStart = this.handleDrawStart.bind(this);
    this.handleDrawChange = this.handleDrawChange.bind(this);
    this.handleDrawEnd = this.handleDrawEnd.bind(this);

    this.handleAddTag = this.handleAddTag.bind(this);

    this.genFeature = this.genFeature.bind(this);
    this.fillSource = this.fillSource.bind(this);

    this.setViewCenter = this.setViewCenter.bind(this);
    this.previousView = this.previousView.bind(this);
    this.nextView = this.nextView.bind(this);

    this.openDataDialog = this.openDataDialog.bind(this);
    this.setFeatureRecords = this.setFeatureRecords.bind(this);

    this.state = {
      viewOptions: {
        center: this.defaultViewCenter,
        zoom: 8
        //extent: this.defaultExtent,
        //projection: this.wgs84PM,
        // minZoom: 8,
        // maxZoom: 18
      },
      layersCollection: undefined,
      cardOverlay: null,
      feature: undefined,
      draw_search: false,
      searchWKT: null,
      currentViews: [],
      removedViews: [],
      currentView: 0,
      featureRecords: [],
      forceRefreshCnt: 0
    };
  }

  componentDidMount() {
    const defaultView = {
      center: this.defaultViewCenter, 
      zoom: 8
    }
    this.setState({
      viewOptions: defaultView
    });

    this.getLayers();
  }

  getLayers() {
    mapService.getLayers().then((coll) => {
      this.setState({
        layersCollection: coll
      });
    });
  }

  openDataDialog(properties, showDialog) {
    // const { properties } = feature;
    const { link_id } = properties;

    if (link_id > 20000 && link_id <= 29999) {
      showDialog(DjelatnikDialog, {
        model: new dataController(modelDjelatnik),
        recordId : link_id,
        mode: "update",
        form: "default"
      });
    } else if (link_id > 30000 && link_id <= 39999) {
      showDialog(KlijentDialog, {
        model: new dataController(modelKlijent),
        recordId : link_id,
        mode: "update",
        form: "default"
      });
    } else if (link_id > 50000 && link_id < 60000) {
      showDialog(OpremaDialog, {
        model: new dataController(modelOprema),
        recordId : link_id,
        mode: "update",
        form: "default"
      });
    } else if (link_id > 100000 && link_id < 200000) {
      showDialog(PonudaDialog, {
        model: new dataController(modelPonuda),
        recordId : link_id,
        mode: "update",
        form: "default"
      });
    } else if (link_id > 200000 && link_id < 300000) {
      showDialog(UgovorDialog, {
        model: new dataController(modelUgovor),
        recordId : link_id,
        mode: "update",
        form: "default"
      });
    } else if (link_id > 4000000 && link_id < 5000000) {
      showDialog(ZahvatDialog, {
        model: new dataController(modelZahvat),
        recordId : link_id,
        mode: "update",
        form: "default"
      });
    } else if (link_id > 5000000 && link_id < 6000000) {
      showDialog(UslugaDialog, {
        model: new dataController(modelUsluge),
        recordId : link_id,
        mode: "update",
        form: "default"
      });
    } else if (link_id > 20000000 && link_id < 30000000) {
      showDialog(UslugaTimDialog, {
        model: new dataController(modelUslugeTim),
        recordId : link_id,
        mode: "update",
        form: "search",
        submodelDefaultValues: {
          ugovor_id: -1
        }
      });
    } else if (link_id > 80000000 && link_id < 90000000) {
      showDialog(CalendarDialog, {
        model: new dataController(modelCalendar),
        recordId : link_id,
        mode: "update",
        form: "default"
      });
    }
  }

  setFeatureRecords(properties) {
    // // get set of ids so we dont duplicate records in table
    // const featureIds = new Set(features.map(f => f && f.properties ? f.properties.link_id : -1));
    // // get only one instance of feature with that record
    // const featureRecords = features.map(f => {
    //   const id = f && f.properties ? f.properties.link_id : -1;
    //   if (id !== -1 && featureIds.has(id)) {
    //     featureIds.delete(id);
    //     return Object.assign({}, f.properties, { id: f.properties.link_id });
    //   } else {
    //     return null;
    //   }
    // }).filter(f => f !== null);

    const props = properties.map(p => Object.assign({}, p, { id: p.link_id }));
    const propIds = new Set(props.map(p => p.id).filter(id => id));
    const newProps = props
      .map(p => {
        if (propIds.delete(p.id)) {
          return p;
        } else {
          return null;
        }
      })
      .filter(p => p !== null);

    this.setState({
      featureRecords: newProps
    });
  }

  handleClick(evt, showDialog) {
    const { draw_search } = this.state;

    if (draw_search) {
      return;
    }

    gsService.getFeatureInfo(evt.map, evt.pixel).then((resp) => {
      if (resp && Object.keys(resp).length != 0) {
        const { user_tags = [], data_xl = [], data_lg = [], data_sm = [] } = resp;
        const features = [...user_tags, ...data_xl, ...data_lg, ...data_sm];
        // const featureIds = new Set(features.map(f => f && f.properties ? f.properties.link_id : -1));
        // featureIds.delete(-1);
        // const propIds = new Set();
        // const props = [];
        // features.forEach(f => {
        //   const { properties } = f;
        //   if (!properties || !properties.link_id) {
        //     return;
        //   }
        //   if (!propIds.has(properties.link_id)) {
        //     propIds.add(properties.link_id);
        //     props.push(properties);
        //   }
        // })
        const properties = features.map(f => f.properties);

        searchService.link(properties).then(resp => {
          // console.log(propIds, props)
          if (resp.success && resp.data && Array.isArray(resp.data)) {
            if (resp.data.length !== properties.length) {
              const { layersCollection } = this.state;
              this.setState(prevState => ({
                layersCollection: new OlCollection([])
              }), () => {
                this.setState({ layersCollection: layersCollection });
              });
            }
            const propIds = new Set(resp.data.map(p => p.link_id).filter(id => id));
            switch(propIds.size) {
              case 0:
                return;
              case 1:
                // this.setState({ featureRecords: [null] })
                this.openDataDialog(resp.data[0], showDialog);
                return;
              default:
                this.setFeatureRecords(resp.data);
                return;
            }
          }
        });
      }
    });
  }

  handleViewChangeCenter(evt) {
    const { currentViews, viewSwitchClicked, viewOptions } = this.state;

    if (viewSwitchClicked) {
      return;
    }

    if (evt && evt.map) {
      // // center_val might be used for showing center coordinates
      // const center = transform(evt.map.getView().getCenter(), "EPSG:3857", "EPSG:4326");
      // this.setState({ center_val: toStringHDMS(center, 0) });

      const newView = {
        center: evt.map.getView().getCenter(),
        zoom: evt.map.getView().getZoom()
      };
      this.setState({
        viewOptions: newView,
        currentViews: currentViews.concat(newView),
        currentView: currentViews.length,
        removedViews: []
      });
    }
  }

  handleAddTag(tagPointsCache, selectedTagIds) {
    const tagPoints = [];
    const tagPolys = [];
    selectedTagIds.forEach(tag => {
      if (tagPointsCache.hasOwnProperty(tag) && tagPointsCache[tag]) {
        tagPointsCache[tag].forEach(tPoint => {
          if (tPoint.hasOwnProperty("tag_xy") && tPoint["tag_xy"]) {
            tagPoints.push(tPoint["tag_xy"]);
          } else if (tPoint.hasOwnProperty("tag_poly") && tPoint["tag_poly"]) {
            tagPolys.push(tPoint["tag_poly"]);
          }
        })
      }
    });
    //const tagFeatures = tagPoints.map(tPoint => this.genFeature(tPoint));
    this.fillSource(this.tagsSource, [...tagPoints, ...tagPolys]);
  }

  genFeature(wkt) {
    if (wkt) {
      return new OlFeature({
        geometry: this.wkt.readGeometry(wkt, {
          dataProjection: this.htrs96,
          featureProjection: this.wgs84PM
        })
      });
    } else {
      return null;
    }
  }

  fillSource(source, wkts) {
    const { refreshSource } = this.state;
    source.clear();
    if (Array.isArray(wkts)) {
      wkts.forEach((wkt) => {
        let f = this.genFeature(wkt);
        source.addFeature(f);
      });
    } else if (wkts) {
      let f = this.genFeature(wkts);
      source.addFeature(f);
    }
    this.setState({refreshSource: !refreshSource});
  }


  showCardOverlay(type, data, ft) {
    const { feature } = this.state;

    //TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
    }

    if (type === "gsinfo") {
      if (Array.isArray(data.features) && data.features.length > 0) {
        const firstFeature = data.features[0];
        this.setState({
          cardOverlay: {
            type: type,
            data: {
              position: data.position,
              feature: firstFeature
            }
          },
          feature: ft
        });
      }
    }
  }

  closeOverlays() {
    this.setState({
      cardOverlay: null
    });
  }

  handleToggleDraw(isDrawing) {
    const { draw_search } = this.state;
    if (isDrawing === false) {
      this.drawingSource.clear();
      this.searchSource.clear();
      this.setState({
        searchWKT: null
      });
    }

    if (draw_search !== isDrawing) {
      //console.log("MAP: toggle draw", isDrawing);

      this.setState({
        draw_search: isDrawing
      });
    }
  }

  handleDrawStart(evt) {
    this.searchSource.getFeatures().length > 0 ? this.searchSource.clear() : null;
  }

  handleDrawChange(evt) {}

  handleDrawEnd(evt) {
    const wkt = new OlFormatWKT();

    const feature = evt.feature;

    this.searchSource.addFeature(feature);

    let wktResult = wkt.writeGeometry(feature.getGeometry(), {
      featureProjection: this.wgs84PM,
      dataProjection: this.htrs96,
      decimals: 0
    });
    this.setState({
      draw_search: false,
      searchWKT: wktResult
    });
  }

  getZoomExtent(source) {
    if (source.getFeatures().length > 0) {
      const extent = source.getExtent();
      if (extent) {

        /*
          Jadna budala koja se bude ovim bavila
          Prvo dohvati sidebar na karti i provjeri je li prosiren
          lrDist = udaljenost izmedu lijevog i desnog ruba extenta
          udDist = udaljenost izmedu gornjeg i donjeg ruba extenta
          d = max - ovo je potrebno jer ako gledamo i udD i lrD
            onda dolazi do problema kad su regresijski raporedeni
            po vertikalnom ili horizontalnom pravcu jer im je onda
            okomiti extent vrlo uzak
          modifier = ako je sidebar prosiren onda uzmi prvi array inace uzmi drugi...
            drugi array je 17% paddinga sa svake strane extenta
            prvi je udaljavanje lijeve strane karte za 1.5 sirine extenta
            padding gore i dolje za 10% i padding s desne strane za 20%
        */

        const sidebar = document.getElementsByClassName("sidebar-left")[0];
        const isExpanded = sidebar && sidebar.classList.contains('expanded');
        const lrDist = Math.abs(extent[0] - extent[2]);
        const udDist = Math.abs(extent[1] - extent[3]);
        const d = Math.max(lrDist, udDist)
        const modifier = isExpanded ? [-1.5, -0.10,0.2,0.1] : [-0.17, -0.17, 0.17, 0.17];
        return extent.map((ex, i) => {
          // ovaj tu macan na extent primjenjuje postotke paddinga + dodaje 2600 m lijevo ako je samo jedan feature
          return ex + modifier[i] * d + (isExpanded && !i && source.getFeatures().length === 1 ? -2600 : 0);
        });
      }
      return extent;
    }
  }

  setViewCenter(view) {
    this.setState({
      viewOptions: {
        center: view.center,
        zoom: view.zoom
      }
    });
    setTimeout(() => {
      this.setState({
        viewSwitchClicked: false
      });
    }, 100);
  }

  previousView() {
    const { currentViews, currentView, removedViews } = this.state;
    if (currentView === 0) {
      return;
    }
    this.setViewCenter(currentViews[currentView - 1]);
    removedViews.push(currentViews.pop());
    this.setState({
      viewSwitchClicked: true,
      currentView: currentView - 1,
      currentViews: currentViews,
      removedViews: removedViews
    });
  }

  nextView() {
    const { currentViews, currentView, removedViews } = this.state;
    if (removedViews.length === 0) {
      return;
    }
    this.setViewCenter(removedViews[removedViews.length - 1]);
    currentViews.push(removedViews.pop());
    this.setState({
      viewSwitchClicked: true,
      currentView: currentView + 1,
      currentViews: currentViews,
      removedViews: removedViews
    });
  }


  render() {
    const { t } = this.props;
    const { 
      cardOverlay
    , searchWKT
    , draw_search
    , layersCollection
    , viewOptions 
    , featureRecords
  } = this.state;

    const gsOverlay = cardOverlay && cardOverlay.type === "gsinfo" ? cardOverlay : undefined;
    const tagsExtent = this.tagsSource.getFeatures().length > 0 ? this.getZoomExtent(this.tagsSource) : null;

    //console.log("render map", draw_search);

    return (
      <DialogConsumer>
        {({ showDialog }) => (
          layersCollection ? (
            <Map
              height="800px"
              view={viewOptions}
              onClick={(evt) => this.handleClick(evt, showDialog)}
              onMoveend={this.handleViewChangeCenter}
              className="sidebar-map"
              id="main-map"
              zoomToExtentPadding={[100, 100, 100, 600]}
              zoomToExtent={this.defaultExtent}
            >
              <Controls>
                <ZoomControl zoomInTipLabel={t("map:controls.zoom_in")} zoomOutTipLabel={t("map:controls.zoom_out")} />
                <ZoomToExtentControl
                  id="zoom-extent-full"
                  extent={this.defaultExtent}
                  tipLabel={t("map:controls.zoom_to_extent")}
                  className="ol-zoom-extent ol-sidebar-sticky"
                />
                {
                  tagsExtent ?
                  <ZoomToExtentControl
                    id="zoom-extent-selected"
                    extent={OlBuffer(tagsExtent, 1000)}
                    tipLabel={t("map:controls.zoom_to_extent")}
                    className="ol-zoom-extent-selected ol-sidebar-sticky"
                  />
                  : null
                }
                {/*<ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} />*/}
                <ScaleRatioControl viewOptions={viewOptions} ppi={96} />
                <ScaleLineControl />
                <FullScreenControl tipLabel={t("map:controls.full_screen")} />
                <RotateControl />
                <GeoPortalBaseLayerSwitcher />
                {/* <GeoPortalLayerSwitcher elementRef={this.layerSwitcherElementRef} /> */}
                <ViewHistoryControl previousView={this.previousView} nextView={this.nextView} />
                <SidebarControl initialOpenId="search">
                  <SidebarTabs>
                    <SidebarTabList>
                      <SidebarTabListItem
                        id="info"
                        title={t("map:sidebar.info")}
                        icon={<i className="fas fa-info-circle"></i>}
                      />
                      <SidebarTabListItem
                        id="layers"
                        title={t("map:sidebar.layers")}
                        icon={<i className="fas fa-layer-group"></i>}
                      />
                      <SidebarTabListItem
                        id="search"
                        title={t("map:sidebar.search")}
                        icon={<i className="fas fa-search"></i>}
                      />
                    </SidebarTabList>
                  </SidebarTabs>
                  <SidebarContent>
                    <SidebarPane id="info">
                      <SidebarHeading title={t("map:sidebar.info")} />
                      <InfoPane />
                    </SidebarPane>
                    <SidebarPane id="layers">
                      <SidebarHeading title={t("map:sidebar.layers")} />
                      <LayerSwitcher ready={layersCollection ? true : false} />
                      {/* <LayersPane elementRef={this.layerSwitcherElementRef} /> */}
                    </SidebarPane>
                    <SidebarPane id="search">
                      <SidebarHeading title={t("map:sidebar.search")} />
                      <SearchPane 
                        onToggleDraw={this.handleToggleDraw} 
                        searchWKT={searchWKT} 
                        isDrawing={draw_search} 
                        onAddTag={this.handleAddTag}
                        records={featureRecords}
                        setFeatureRecords={this.setFeatureRecords}
                      />
                    </SidebarPane>
                  </SidebarContent>
                </SidebarControl>
              </Controls>
              {layersCollection ? (
                <Layers>
                  {layersCollection.getArray().map((layer, i) => {
                    if (layer instanceof OlLayerGroup) {
                      return (
                        <GroupLayer key={layer.get("id") + "-" + i} id={layer.get("id")} title={t(layer.get("title"))}>
                          {layer
                            .getLayers()
                            .getArray()
                            .map((childLayer, ci) => {
                              return (
                                <GeoPortalGSLayer
                                  key={ci}
                                  id={childLayer.get("id")}
                                  title={t(childLayer.get("title"))}
                                  layer={childLayer.get("layer")}
                                  query={childLayer.get("query")}
                                  zIndex={childLayer.get("zIndex")}
                                />
                              );
                            })}
                        </GroupLayer>
                      );
                    } else {
                      return (
                        <GeoPortalGSLayer
                          key={i}
                          id={layer.get("id")}
                          title={t(layer.get("title"))}
                          layer={layer.get("layer")}
                          query={layer.get("query")}
                          zIndex={layer.get("zIndex")}
                        />
                      );
                    }
                  })}
                  <VectorLayer id="search" source={this.searchSource} zIndex={11} style={drawStyle} />
                  <VectorLayer id="tags" source={this.tagsSource} zIndex={100} style={tagsStyle} />
                </Layers>
              ) : null}
              <Overlays>
                <CardOverlay
                  id="gsinfo-overlay"
                  position={gsOverlay ? gsOverlay.data.position : undefined}
                  autoPan={false}
                  onClose={this.closeOverlays}
                >
                  {gsOverlay ? <GSInfoCard featureData={gsOverlay.data} onClose={this.closeOverlays} /> : null}
                </CardOverlay>
              </Overlays>
              <Interactions>
                <DefaultInteractions doubleClickZoom={false} />
                {this.state.draw_search ? (
                  <DrawInteraction
                    source={this.drawingSource}
                    type="Polygon"
                    onChange={this.handleDrawChange}
                    onDrawstart={this.handleDrawStart}
                    onDrawend={this.handleDrawEnd}
                  />
                ) : null}
              </Interactions>
            </Map>
          ) : null
        )}
      </DialogConsumer>
    );
  }
}

export default withTranslation()(MainMap);
