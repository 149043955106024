import React from 'react';
import { withTranslation } from 'react-i18next';
import { CSVReader } from 'react-papaparse';

import { Role } from 'Lib/role';

// Material UI Core
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Toolbar from "@material-ui/core/Toolbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// Material UI Icons
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import DescriptionIcon from '@material-ui/icons/Description';

// Custom Components
import withSnackbar from "Components/withSnackbar";
import appValidator from 'Lib/appValidator';
import DialogConsumer from 'UI/DialogContext/DialogConsumer';
import DraggableDialog from 'UI/Dialog/DraggableDialog';
import DialogBody from 'UI/Dialog/DialogBody';
import DialogHeader from 'UI/Dialog/DialogHeader';
import DialogToolbarHeading from 'UI/Dialog/DialogToolbarHeading';
import DialogToolbarButton from 'UI/Dialog/DialogToolbarButton';
import DialogToolbarFillContent from 'UI/Dialog/DialogToolbarFillContent';
import DialogActionButton from 'UI/Dialog/DialogActionButton';
import FileControl from 'Controls/FileControl';
import ModelTable from 'UI/Table/ModelTable';
import TableButton from "UI/Table/TableButton";
import FormButton from "UI/Form/FormButton";
import moment from 'moment';


class ImportCSVDialog extends React.Component {
  constructor(props) {
    super(props)
    this.fileInput = React.createRef()

		this.handleReadCSV = this.handleReadCSV.bind(this);
		this.handleOnError = this.handleOnError.bind(this);
		this.handleImportOffer = this.handleImportOffer.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectRecord = this.handleSelectRecord.bind(this);

    this.validator = new appValidator(props.t);

		this.state = {
      viewName: "import",
      formName: "default",
			records: [],
      invalidRecordIndeces: [],
      isLoading: false,
      selectedIds: [],
      dataChanged: false
		}
  }

  handleSelectRecord(rowId, checked) {
    const { selectedIds } = this.state;
    let newSelectedIds = Array.from(selectedIds)
    const currIndex = selectedIds.indexOf(rowId);
    if (rowId === -1) {
      newSelectedIds = [];
      if (checked) {
        for (let i = 0; i < this.state.records.length; ++i) {
          newSelectedIds.push(i);
        }
      }
    } else if (currIndex >= 0) {
      newSelectedIds.splice(currIndex, 1);
    } else {
      newSelectedIds.push(rowId);
    }
    this.setState({
      selectedIds: newSelectedIds
    });
  }

  handleReadCSV(data) {
    const newRecords = data.data.map((r, i) => {
      return {
        ...r,
        state: null,
        rbr: i + 1
      }
    });
  	this.setState({
  		records: newRecords
  	})
    if (this.fileInput && this.fileInput.current && this.fileInput.current.value) {
      this.fileInput.current.value = null;
    }
  }

  handleDelete(evt, sel) {
    const { records } = this.state;
    const newRecords = [];
    const indices = sel.map(s => parseInt(s, 10));

    records.forEach((r, i) => {
      if (indices.indexOf(i) >= 0) {
        return;
      }
      newRecords.push(Object.assign({}, r));
    })
    this.setState({ records: newRecords });
  }

  setRecordsState(successRecords, failRecords) {
    const { records } = this.state;
    const newRecords = Array.from(records);
    successRecords.forEach(success => {
      newRecords[success]['state'] = true;
    });
    failRecords.forEach(fail => {
      newRecords[fail]['state'] = false;
    });
    this.setState({
      records: newRecords
    });
  }

  handleSubmit(evt, hideDialog) {
    const { model, onClose, t } = this.props;
    const { showNotification } = this.props;
    const { records, selectedIds } = this.state;

    if (records.length > 0) {
      this.setState({ isLoading: true });
      const rPromises = [];
      const successInputs = [];
      const failInputs = [];
      selectedIds.forEach(id => {
        //rPromises.push(model.InsertRecord(r));
        const currRecord = Object.assign({}, records[id]);
        if (!currRecord.state) {
          rPromises.push(model.InsertRecord(currRecord)
            .then(resp => {
              console.log(resp)
              if (resp.success) {
                successInputs.push(id);
              } else {
                failInputs.push(id);
              }
              return Promise.resolve();
            })
            .catch(err => {
              console.log(err)
              failInputs.push(id);
              return Promise.resolve();
            }));
        }
      });

      Promise.all(rPromises).then(() => {
        if (failInputs.length > 0) {
          showNotification("Greška pri pohrani podataka!", "error");
        }
        if (successInputs.length > 0) {
          this.setState({ dataChanged: true });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false }, () => this.setRecordsState(successInputs, failInputs));
      });
    }
  }

  handleOnError(err, file, inputElem, reason) {
    console.log(err)
    console.log(reason)
  }

  handleImportOffer() {
    this.fileInput.current.click()
  }

	handleClose(evt, hideDialog){
    const { dataChanged } = this.state;
    const { onClose } = this.props;
    if (onClose) {
      onClose({
        dataChanged: true,
        action: "insert"
      });
    }
		if (hideDialog){
			hideDialog();
		}
	}

	render(){
		const { t, model } = this.props;
		const { records, invalidRecordIndeces, viewName, isLoading } = this.state;

		return(
			<DialogConsumer>
				{({ showDialog, hideDialog }) => (
					<DraggableDialog
            open={true}
            maxWidth={"lg"}
            onClose={(evt) => this.handleClose(evt, hideDialog)}
					>
            <DialogHeader>
              <Toolbar variant="dense" disableGutters={true}>
                <DialogToolbarHeading>
                  {t("titles.csv_import")}
                </DialogToolbarHeading>
                <DialogToolbarFillContent />
                <DialogToolbarButton onClick={(evt) => this.handleClose(evt, hideDialog)}>
                  <CloseIcon />
                </DialogToolbarButton>
              </Toolbar>
            </DialogHeader>
						<DialogBody>
							<Box m={2}>
                <Toolbar disableGutters={true}>
  				        <CSVReader
  				          onFileLoaded={this.handleReadCSV}
  				          inputRef={this.fileInput}
  				          style={{display: 'none'}}
  				          onError={this.handleOnError}
  				          configOptions={{
  				          	header: true,
  				          	dynamicTyping: true,
  				          	skipEmptyLines: true
  				          }}
  				        />
                  <div style={{flexGrow: 1}} />
                  <FormButton
                    color="primary"
                    variant="contained"
                    startIcon={<DescriptionIcon />}
                    onClick={this.handleImportOffer}
                  >
                    {t("buttons.csv_browse")}
                  </FormButton>
  				        {/*<button onClick={this.handleImportOffer}>Import</button>*/}
                </Toolbar>
				      	<ModelTable
					      	dc={model}
					      	allowSelection="many"
					      	records={records}
                  invalidRecordIndeces={invalidRecordIndeces}
                  viewName= {viewName}
                  markRequired={true}
                  onSelectRecord={this.handleSelectRecord}
				      	>
                  <TableButton
                  	variant="outlined"
                  	allow="many"
                  	onClick={(evt, sel) => this.handleDelete(evt, sel)}
                  >
                    {t('buttons.delete')}
                  </TableButton>
				      	</ModelTable>
                <Backdrop
                      open={isLoading}
                      style={{
                        zIndex: 99999,
                        color: "#fff"
                      }}
                    >
                      <CircularProgress />
                    </Backdrop>
							</Box>
						</DialogBody>
						<DialogActions>
							<DialogActionButton variant="outlined" onClick={(evt) => this.handleClose(evt, hideDialog)} startIcon={<CloseIcon />}>
                {t('buttons.close')}
							</DialogActionButton>
							<DialogActionButton variant="contained" onClick={(evt) => this.handleSubmit(evt, hideDialog)} startIcon={<SendIcon />} disabled={records.length === 0}>
                {t('buttons.send_tag')}
							</DialogActionButton>
						</DialogActions>
					</DraggableDialog>
				)}
			</DialogConsumer>
		)
	}
}

export default withTranslation()(withSnackbar(ImportCSVDialog));