import React, { Fragment, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import Checkbox from "@material-ui/core/Checkbox";
import TreeItem from "@material-ui/lab/TreeItem";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import OlLayerGroup from "ol/layer/Group";

import { MapContext } from "Components/Map/MapContext";
import UserContext from "Components/UserContext/UserContext";

import { findLayer } from "Components/Map/Layers";

function LayerSwitcherTreeItem(props) {
  const { layer } = props;

  const { t } = useTranslation();

  const mapContext = useContext(MapContext);
  const userContext = useContext(UserContext);

  const handleChange = (evt) => {
    const id = layer.get("id");
    if (mapContext.map) {
      const l = findLayer(mapContext.map, id);

      const isGroup = l instanceof OlLayerGroup;
      const isVisible = l.getVisible();
      if (isGroup) {
        const childLayers = layer.getLayers().getArray();

        const childsVisible = childLayers ? childLayers.filter((x) => x.getVisible() === true) : [];
        const childsHidden = childLayers ? childLayers.filter((x) => x.getVisible() === false) : [];

        const groupChecked = childsVisible.length > 0;
        const groupIndeterminate = childsVisible.length > 0 && childsHidden.length > 0;

        let newVisible = null;

        if (groupIndeterminate) {
          newVisible = true;
        } else {
          newVisible = groupChecked ? false : true;
        }

        const newObj = {};

        if (newVisible === true) {
          childsHidden.forEach(l => {
            const lid = l.get('id');
            l.setVisible(true);
            newObj[lid] = true;
          })
        } else {
          childsVisible.forEach(l => {
            const lid = l.get('id');
            l.setVisible(false);
            newObj[lid] = false;
          })
        }

        userContext.setMultipleLayerVisible(newObj);

      } else {
        l.setVisible(!isVisible);
        userContext.setLayerVisible(id, !isVisible);
      }
    }
  };

  const id = layer.get("id");
  const title = layer.get("title");
  const visible = layer.getVisible();

  const show = title && title.length > 0;
  if (!show) {
    return null;
  }

  const isGroupLayer = layer instanceof OlLayerGroup;
  const childLayers = isGroupLayer ? layer.getLayers().getArray() : null;

  const childsVisible = childLayers ? childLayers.filter((x) => x.getVisible() === true).length : null;
  const childsHidden = childLayers ? childLayers.filter((x) => x.getVisible() === false).length : null;

  const groupChecked = childsVisible > 0;
  const groupIndeterminate = childsVisible > 0 && childsHidden > 0;

  return isGroupLayer ? (
    <TreeItem
      nodeId={id}
      label={t(title)}
      icon={
        groupIndeterminate ? (
          <IndeterminateCheckBoxIcon color="secondary" />
        ) : groupChecked ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <CheckBoxOutlineBlankIcon color="secondary" />
        )
      }
      onIconClick={handleChange}
      onLabelClick={handleChange}
    >
      {childLayers.map((l,i) => {
        return <LayerSwitcherTreeItem key={i} layer={l} />;
      })}
    </TreeItem>
  ) : (
    <TreeItem
      nodeId={id}
      label={t(title)}
      icon={visible ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon color="secondary" />}
      onIconClick={handleChange}
      onLabelClick={handleChange}
    />
  );
}

export default LayerSwitcherTreeItem;
