import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";

import AppPage from "UI/AppPage/AppPage";
import ZahvatiTable from "Views/ZahvatiPage/ZahvatiTable";

function ZahvatiPage(props) {
  const { t } = useTranslation();

  return (
    <AppPage>
      <Box m={2}>
        <ZahvatiTable ugid={0} level={1}/>
      </Box>
    </AppPage>
  );
}

export default ZahvatiPage;
