import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { Extent } from "ol/interaction";

export default function ExtentInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    extent: undefined,
    boxStyle: undefined,
    pixelTolerance: undefined,
    pointerStyle: undefined,
    wrapX: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    extendchanged: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Extent, props, options, events),
    []
  );

  return null;
}
