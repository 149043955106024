import moment from "moment";
import { sifrarnici } from "Lib/sifrarnici";

let appValidator = function(t) {
  this.t = t;
};

//Common Functions

appValidator.prototype.validateModel = function (record, fields) {
  const validation = {};
  fields.forEach((field) => {
    validation[field.source] = this.validateField(record, field);
  });
  //console.log(validation);
  return validation;
};

appValidator.prototype.validateField = function (record, field) {
  const fieldValidation = { valid: true, msg: "" };
  const isRequired = field.validation && field.validation.required;
  const isEmail =
    field.validation &&
    field.validation.hasOwnProperty("regex") &&
    field.validation.regex === "email";
  const isTelefon =
    field.validation &&
    field.validation.hasOwnProperty("regex") &&
    field.validation.regex === "tel";
  const isOIB =
    field.validation &&
    field.validation.hasOwnProperty("regex") &&
    field.validation.regex === "oib";
  const isDate =
    field.type === "date" &&
    field.validation &&
    field.validation.hasOwnProperty("maxDate");
  const hasCharLimit =
    field.validation && field.validation.hasOwnProperty("maxLength");
  const maxLength = hasCharLimit ? field.validation.maxLength : null;
  const hasMinCharsRequired =
    field.validation && field.validation.hasOwnProperty("minLength");
  const value =
    record && record.hasOwnProperty(field.source)
      ? record[field.source]
      : null;
  const minLength = hasMinCharsRequired ? field.validation.minLength : null;
  let hasValue = value !== undefined && value !== null && value !== "";
  if (Array.isArray(value) && value.length === 0) {
    hasValue = false;
  }

  const hasMinValue = field.validation && field.validation.hasOwnProperty("minValue");
  const minValue = hasMinValue ? field.validation.minValue : null;
  const hasMaxValue = field.validation && field.validation.hasOwnProperty("maxValue");
  const maxValue = hasMaxValue ? field.validation.maxValue : null;

  if (isRequired && !hasValue) {
    fieldValidation.valid = false;
    fieldValidation.msg = this.t("validation.required");
  } else if (isEmail && hasValue && this.checkRegexEmail(value) === false) {
    fieldValidation.valid = false;
    fieldValidation.msg = this.t("validation.emailformat");
  } else if (isTelefon && hasValue && this.checkRegexTel(value) === false) {
    fieldValidation.valid = false;
    fieldValidation.msg = this.t("validation.telformat");
  } else if (isOIB && hasValue && this.checkRegexOIB(value) === false) {
    fieldValidation.valid = false;
    fieldValidation.msg = this.t("validation.oibformat");
  } else if (hasCharLimit && hasValue && value.length > maxLength) {
    fieldValidation.valid = false;
    fieldValidation.msg = this.t("validation.max_char", { max_char: maxLength.toString(), curr_char: value.length });
  } else if (hasMinCharsRequired && hasValue && value.length < minLength) {
    fieldValidation.valid = false;
    fieldValidation.msg = this.t("validation.min_char", { min_char: minLength.toString(), curr_char: value.length });
  } else if ((hasMinValue || hasMaxValue) && hasValue) {
    const floatValue = typeof value === "string" ? parseFloat(value.replace(',','.')) : value;
    if (hasMinValue && floatValue < minValue) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.min_value", { min_value: minValue.toString() });
    }
    if (hasMaxValue && floatValue > maxValue) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t("validation.max_value", { max_value: maxValue.toString() });
    }
  } else if (isDate && moment(value).isAfter(moment(new Date()).add(field.validation.maxDate, 'days'), 'day') ) {
    fieldValidation.valid = false;
    fieldValidation.msg = this.t("validation.max_date", { date: moment(new Date()).add(field.validation.maxDate, 'days').format("DD.MM.YYYY.") });
  }
  //console.log(field, fieldValidation)
  return fieldValidation;
};


// old validateModel fnc
// replaced by validateModel and validateField fncs
appValidator.prototype.__validateModel = function(record, fields) {
  let validation = {};

  fields.forEach(field => {
    let fieldValidaton = { valid: true, msg: "" };
    const isRequired = field.validation && field.validation.required;
    const isEmail =
      field.validation &&
      field.validation.hasOwnProperty("regex") &&
      field.validation.regex === "email";
    const isTelefon =
      field.validation &&
      field.validation.hasOwnProperty("regex") &&
      field.validation.regex === "tel";
    const isOIB =
      field.validation &&
      field.validation.hasOwnProperty("regex") &&
      field.validation.regex === "oib";
    const isDate =
      field.type === "date" &&
      field.validation &&
      field.validation.hasOwnProperty("maxDate");
    const hasCharLimit =
      field.validation && field.validation.hasOwnProperty("maxLength");
    const maxLength = hasCharLimit ? field.validation.maxLength : null;
    const hasMinCharsRequired =
      field.validation && field.validation.hasOwnProperty("minLength");
    const value =
      record && record.hasOwnProperty(field.source)
        ? record[field.source]
        : null;
    const minLength = hasMinCharsRequired ? field.validation.minLength : null;
    let hasValue = value !== undefined && value !== null && value !== "";
    if (Array.isArray(value) && value.length === 0) {
      hasValue = false;
    }

    const hasMinValue = field.validation && field.validation.hasOwnProperty("minValue");
    const minValue = hasMinValue ? field.validation.minValue : null;
    const hasMaxValue = field.validation && field.validation.hasOwnProperty("maxValue");
    const maxValue = hasMaxValue ? field.validation.maxValue : null;

    if (isRequired && !hasValue) {
      fieldValidaton.valid = false;
      fieldValidaton.msg = this.t("validation.required");
    } else if (isEmail && hasValue && this.checkRegexEmail(value) === false) {
      fieldValidaton.valid = false;
      fieldValidaton.msg = this.t("validation.emailformat");
    } else if (isTelefon && hasValue && this.checkRegexTel(value) === false) {
      fieldValidaton.valid = false;
      fieldValidaton.msg = this.t("validation.telformat");
    } else if (isOIB && hasValue && this.checkRegexOIB(value) === false) {
      fieldValidaton.valid = false;
      fieldValidaton.msg = this.t("validation.oibformat");
    } else if (hasCharLimit && hasValue && value.length > maxLength) {
      fieldValidaton.valid = false;
      fieldValidaton.msg = this.t("validation.max_char", { max_char: maxLength.toString(), curr_char: value.length });
    } else if (hasMinCharsRequired && hasValue && value.length < minLength) {
      fieldValidaton.valid = false;
      fieldValidaton.msg = this.t("validation.min_char", { min_char: minLength.toString(), curr_char: value.length });
    } else if ((hasMinValue || hasMaxValue) && hasValue) {
      const floatValue = typeof value === "string" ? parseFloat(value.replace(',','.')) : value;
      if (hasMinValue && floatValue < minValue) {
        fieldValidaton.valid = false;
        fieldValidaton.msg = this.t("validation.min_value", { min_value: minValue.toString() });
      }
      if (hasMaxValue && floatValue > maxValue) {
        fieldValidaton.valid = false;
        fieldValidaton.msg = this.t("validation.max_value", { max_value: maxValue.toString() });
      }
    } else if (isDate && moment(value).isAfter(moment(new Date()).add(field.validation.maxDate, 'days'), 'day') ) {
      fieldValidaton.valid = false;
      fieldValidaton.msg = this.t("validation.max_date", { date: moment(new Date()).add(field.validation.maxDate, 'days').format("DD.MM.YYYY.") });
    }

    validation[field.source] = fieldValidaton;
  });

  return validation;
};

appValidator.prototype.checkRegexEmail = function(value) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const matches = value.match(re);
  return matches != null ? true : false;
};

appValidator.prototype.checkRegexTel = function(value) {
  const re = /^\+[0-9]{11,15}$/;
  const matches = value.match(re);
  return matches != null ? true : false;
};

appValidator.prototype.checkRegexOIB = function(value) {
  const re = /^[0-9]{11}$/;
  const matches = value.match(re);
  return matches != null ? true : false;
};

appValidator.prototype.updateValidationDatumOdDo = function(validation, record, fStart, fEnd) {

  const startDate = record && record.hasOwnProperty(fStart) ? record[fStart] : null;
  const endDate = record && record.hasOwnProperty(fEnd) ? record[fEnd] : null;

  if (startDate && endDate && startDate > endDate) {
    validation[fStart] = {
      valid: false,
      msg: this.t("validation.date_begin")
    };
    validation[fEnd] = {
      valid: false,
      msg: this.t("validation.date_end")
    };
  }

  return validation;
}

appValidator.prototype.mergeValidation = function(
  modelValidation,
  customValidation
) {
  let mergedValidation = modelValidation || {};
  if (customValidation !== undefined && customValidation !== null) {
    Object.keys(customValidation).forEach(key => {
      if (mergedValidation.hasOwnProperty(key)) {
        mergedValidation[key] = customValidation[key];
      } else {
        mergedValidation.push(key, customValidation[key]);
      }
    });
  }
  return mergedValidation;
};

appValidator.prototype.checkIfValid = function(validation) {
  const validatonFailed = Object.keys(validation)
    .map(key => validation[key].valid)
    .some(x => x === false);
  const isValid = !validatonFailed;
  return isValid;
};

appValidator.prototype.checkIfRecordChanged = function(record, originalRecord) {
  const isChanged = Object.keys(record).some(key =>
    this.checkIfFieldChanged(record[key], originalRecord[key])
  );
  return isChanged;
};

appValidator.prototype.checkIfFieldChanged = function(a, b) {
  if (a === null && b === null) {
    return false;
  } else if (moment.isMoment(a) && moment.isMoment(b)) {
    if (!a.isValid() && !b.isValid()) {
      return false;
    } else {
      return !a.isSame(b);
    }
  } else {
    return this.getValue(a) !== this.getValue(b);
  }
};

appValidator.prototype.getValue = function(x) {
  if (x === null || x === undefined) {
    return null;
  } else if (moment.isMoment(x)) {
    return x.isValid() ? x : null;
  } else if (x.value && typeof x.value === "function") {
    return x.value(); //numbro
  } else if (x.value) {
    return x.value; //option
  } else {
    return x; //other
  }
};

//Custom Validators



appValidator.prototype.msgRequired = function(t) {
  return {
    valid: false,
    msg: t("validation.required")
  };
}

appValidator.prototype.validatePasswordSet = function (record, t) {
  const fNewPass = "password";
  const fNewPassConfirm = "password_confirm";

  const newPass = record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
  const newPassConfirm = record && record.hasOwnProperty(fNewPassConfirm) ? record[fNewPassConfirm] : null;

  let validation = {};

  const isComplexPassword = function (password) {
    if (password.length < 8) {
      return false;
    }

    var matchedCase = new Array();
    matchedCase.push("[A-Z]"); // Uppercase Alpabates
    matchedCase.push("[0-9]"); // Numbers
    matchedCase.push("[a-z]"); // Lowercase Alphabates

    var ctr = 0;
    for (var i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(password)) {
        ctr++;
      }
    }

    return ctr == 3 ? true : false;
  };

  if (newPass && newPass.length > 0 && isComplexPassword(newPass) === false) {
    validation[fNewPass] = {
      valid: false,
      msg: t("validation.password_complexity")
    };
  }

  if (newPass !== newPassConfirm) {
    validation[fNewPassConfirm] = {
      valid: false,
      msg: t("validation.password_confirm_mismatch")
    };
  }

  return validation;
};


appValidator.prototype.isComplexPassword = function(password) {
  if (password.length < 8) {
    return false;
  }

  var matchedCase = new Array();
  matchedCase.push("[A-Z]"); // Uppercase Letters
  matchedCase.push("[0-9]"); // Numbers
  matchedCase.push("[a-z]"); // Lowercase Letters

  var ctr = 0;
  for (var i = 0; i < matchedCase.length; i++) {
    if (new RegExp(matchedCase[i]).test(password)) {
      ctr++;
    }
  }

  return ctr == 3 ? true : false;
};

appValidator.prototype.validatePasswordReset = function(record) {
  const fNewPass = "password_new";
  const fNewPassConfirm = "password_new_confirm";

  const newPass =
    record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
  const newPassConfirm =
    record && record.hasOwnProperty(fNewPassConfirm)
      ? record[fNewPassConfirm]
      : null;

  let validation = {};

  if (newPass && newPass.length > 0 && this.isComplexPassword(newPass) === false) {
    validation[fNewPass] = {
      valid: false,
      msg: "Lozinka treba sadržavati barem 8 znakova, 1 malo slovo, 1 veliko slovo i 1 znamenku"
    };
  }

  if (newPass !== newPassConfirm) {
    validation[fNewPassConfirm] = {
      valid: false,
      msg: "Potvrda lozinke se ne podudara s novom lozinkom!"
    };
  }

  return validation;
};

appValidator.prototype.validatePasswordChange = function(record) {
  const fOldPass = "password";
  const fNewPass = "password_new";
  const fNewPassConfirm = "password_new_confirm";

  const oldPass =
    record && record.hasOwnProperty(fOldPass) ? record[fOldPass] : null;
  const newPass =
    record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
  const newPassConfirm =
    record && record.hasOwnProperty(fNewPassConfirm)
      ? record[fNewPassConfirm]
      : null;

  let validation = {};

  if (newPass && newPass.length > 0 && appValidator.prototype.isComplexPassword(newPass) === false) {
    validation[fNewPass] = {
      valid: false,
      msg: "Lozinka treba sadržavati barem 8 znakova, 1 malo slovo, 1 veliko slovo i 1 znamenku"
    };
  }

  if (newPass && newPass.length > 0 && oldPass === newPass) {
    validation[fNewPass] = {
      valid: false,
      msg: "Nova lozinka ne može biti jednaka staroj!"
    };
  }

  if (newPass !== newPassConfirm) {
    validation[fNewPassConfirm] = {
      valid: false,
      msg: "Potvrda lozinke se ne podudara s novom lozinkom!"
    };
  }

  return validation;
};

appValidator.prototype.isComplexPassword = function(password) {
  if (password.length < 8) {
    return false;
  }

  var matchedCase = new Array();
  matchedCase.push("[A-Z]"); // Uppercase Alpabates
  matchedCase.push("[0-9]"); // Numbers
  matchedCase.push("[a-z]"); // Lowercase Alphabates

  var ctr = 0;
  for (var i = 0; i < matchedCase.length; i++) {
    if (new RegExp(matchedCase[i]).test(password)) {
      ctr++;
    }
  }

  return ctr == 3 ? true: false;
};

appValidator.prototype.calendarValidator = function(record, t) {
  const fLookupPicker = "lookup_picker";
  const vLookupPicker = record && record.hasOwnProperty(fLookupPicker) ? (record[fLookupPicker].value || record[fLookupPicker]) : null;
  console.log(vLookupPicker);
  if (vLookupPicker === null || vLookupPicker === undefined || vLookupPicker == sifrarnici.calendar_picker.none) {
    return {
      [fLookupPicker]: {
        valid: true,
        msg: ""
      }
    };    
  }

  const pickers = Object.keys(sifrarnici.calendar_picker);
  const fCurrPicker = pickers.find(f => sifrarnici.calendar_picker[f] === vLookupPicker)
  const vCurrPicker = record && record.hasOwnProperty(fCurrPicker) ? (record[fCurrPicker] && (record[fCurrPicker].value || record[fCurrPicker])) : null;
  console.log(fCurrPicker, vCurrPicker)
  let hasValue = vCurrPicker !== undefined && vCurrPicker !== null && vCurrPicker !== "";
  if (Array.isArray(vCurrPicker) && vCurrPicker.length === 0) {
    hasValue = false;
  }

  const fieldValidaton = {
    valid: true,
    msg: ""
  }

  if (!hasValue) {
    fieldValidaton.valid = false;
    fieldValidaton.msg = t("validation.required");
  };
  return {
    [fCurrPicker]: fieldValidaton
  };
}


export default appValidator;
