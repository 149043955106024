import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material UI Core Components
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ViewColumnIcon from "@material-ui/icons/ViewColumn";

//Custom Components
import TableHeaderButton from "UI/Table/TableHeaderButton";

function TableHeaderButtonColumnPicker(props) {
  const { t } = useTranslation();
  const { columns, hiddenColumnNames, onChange, setDefault, ...otherProps } = props;

  const [menuState, setMenuState] = useState({ open: false, anchorEl: null });

  const handleOpen = (evt) => {
    setMenuState({ open: true, anchorEl: evt.currentTarget });
  };

  const handleToggle = (evt, source) => {
    const isVisible = !hiddenColumnNames.includes(source);
    const newState = !isVisible;

    if (onChange) {
      onChange(source, newState);
    }
  };

  const handleClose = (evt, reason) => {
    setMenuState({ open: false, anchorEl: null });
  };

  return (
    <>
      <TableHeaderButton onClick={handleOpen} startIcon={<ViewColumnIcon />} variant={"outlined"} {...otherProps}>
        <Hidden smDown>
          {t("buttons.toggle_columns")}
        </Hidden>
      </TableHeaderButton>
      <Menu
        open={menuState.open}
        onClose={handleClose}
        anchorEl={menuState.anchorEl}
        transformOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
      >
        {
          [
            <MenuItem value={0} key={0} onClick={setDefault}>
              <Button size="small" startIcon={<ClearAllIcon fontSize="small" />}>
              <Typography variant="caption" style={{textTransform: "uppercase", fontWeight: 600}}>
                {t("buttons.default_columns")}
              </Typography>
              </Button>
            </MenuItem>
          ].concat(
          columns.map((c, i) => {
            return c.accessor && c.Header ? (
              <MenuItem value={i + 1} onClick={(evt) => handleToggle(evt, c.accessor)} key={i + 1}>
                <Switch size="small" color="secondary" checked={!hiddenColumnNames.includes(c.accessor)} value={i} />
                <Typography variant="caption" style={{textTransform: "uppercase", fontWeight: 600}}>
                {
                  c.Header && typeof c.Header === 'function' ?
                    c.Header()
                    : c.Header
                }
                </Typography>
              </MenuItem>
            ) : null;
          }))
        }
      </Menu>
    </>
  );
}

TableHeaderButtonColumnPicker.propTypes = {
  columns: PropTypes.array,
  hiddenColumnNames: PropTypes.array,
  onChange: PropTypes.func
};

export default TableHeaderButtonColumnPicker;
