import React, { useState, useEffect, useContext } from "react";

import { LayerSwitcher } from "Components/Map/Controls";
import { mapService } from "Services/mapService";
import { MapContext } from "Components/Map/MapContext";

function GeoPortalLayerSwitcher(props) {
  const [ready, setReady] = useState(false);
  const [layersGroup, setLayersGroup] = useState(undefined);
  const context = useContext(MapContext);
  const { elementRef } = props;

  useEffect(() => {
    if (context.map) {
      mapService.getLayers().then((olGroup) => {
        if (olGroup) {
          const mapLayers = context.map.getLayers();
          const mapLayersCount = mapLayers.getArray().length;
          mapLayers.insertAt(mapLayersCount,olGroup);
        }

        setLayersGroup(olGroup);
      });
    }
  }, [context.map]);

  useEffect(() => {
    if (!ready) {
      if (context.map &&
        elementRef && elementRef.current &&
        layersGroup && layersGroup.getLayers && layersGroup.getLayers().getArray().length > 0) {
          setReady(true);
        }
    }
  }, [context.map, elementRef, layersGroup])

  //console.log('render LS?', ready, context.map, elementRef, layersGroup);


  return ready ? <LayerSwitcher targetPanel={elementRef.current} /> : null;
}

export default GeoPortalLayerSwitcher;
