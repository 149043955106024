import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import KlijentDialog from "Views/KlijentiPage/KlijentDialog";

import model from "Models/klijent";
import dataController from "Lib/dataController";

function KlijentiTable(props) {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const [records, setRecords ] = useState([]);
  const [tableControls, setTableControls] = useState(true);
  const [notAuth, setNotAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  const dc = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }

  const handleAdd = (evt) => {
    dialogContext.showDialog(KlijentDialog, {
      model: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;

    dialogContext.showDialog(KlijentDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  };

  const handleTableEdit = (recordId) => {
    dialogContext.showDialog(KlijentDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    // dummyRecords();
    setLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success) {
        setRecords(resp.data)
      } else {
        const { error } = resp;
        if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
          setNotAuth(true);
        } 
      }
      setLoading(false);
    });
  };

  return (
    <ModelTable records={records || []} dc={dc} 
      allowSelection="one" 
      allowFilter={true} 
      allowExport={true} 
      disableControls={!tableControls} 
      inlineEdit={true} 
      onEdit={handleTableEdit} 
      notAuth={notAuth}
      isLoading={loading}
      onAdd={handleAdd}
      title="titles.klijenti"
      addGender="m"
    />
  );
}

export default KlijentiTable;
