import React from "react";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';

//Custom Components
import PublicPage from "UI/PublicPage/PublicPage";
import LoginForm from "Views/Auth/LoginForm";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";

import model from "Models/user";
import dataController from "Lib/dataController";

import esfi from "Images/ESIF.gif";
import eu from "Images/eu.png";


function LoginPage() {

  const dc = new dataController(model)

  return (
    <PublicPage>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Container maxWidth={"sm"}>
            <Typography variant="body1">
              Dobrodošli u CMS sustav tvrtke <a href="https://dvokut-ecro.hr/">Dvokut-ECRO d.o.o.</a>, kojeg je izradila tvrtka <a href="https://prehnit.hr/">Prehnit d.o.o.</a>.
              Izradu aplikacije je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.
            </Typography>
          </Container>
        </GridItem>
        <GridItem xs={12}>
            <Container maxWidth={"sm"}>
          <Box m={2}>
              <LoginForm model={dc} form="login" />
          </Box>
            </Container>
        </GridItem>
        <GridItem xs={12}>
          <Container maxWidth={"sm"}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <img src={esfi} style={{display: "block", margin: "auto", height:"75px", paddingRight: "10px"}} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <img src={eu} style={{display: "block", margin: "auto", height:"75px"}}/>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <a href="https://dvokut-ecro.hr/" target="_blank">
                <img src="https://dvokut-ecro.hr/wp-content/uploads/2016/04/DVK-logo2.png" style={{display: "block", margin: "auto", height:"75px"}}/>
                </a>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <a href="https://prehnit.hr/" target="_blank">
                <img src="https://prehnit.hr/wp-content/uploads/2017/06/Prehnit-logoFINb-e1498738348726.png" style={{display: "block", margin: "auto", height:"75px"}}/>
                </a>
              </GridItem>
            </GridContainer>
          </Container>
        </GridItem>


      </GridContainer>
    </PublicPage>
  )
}

export default LoginPage;