import React, { Fragment, useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

//Material UI Core Components
import TableContainer from "@material-ui/core/TableContainer";
import MaterialUITable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Switch from '@material-ui/core/Switch';

//Material UI Icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DashboardIcon from '@material-ui/icons/Dashboard';
import FilterListIcon from "@material-ui/icons/FilterList";
import CloudDownloadSharpIcon from "@material-ui/icons/CloudDownloadSharp";

//Custom Components
import IncrementControl from "Controls/IncrementControl";
import FormButton from "UI/Form/FormButton";
import TableCheckbox from "UI/Table/TableCheckbox";
import TableRadio from "UI/Table/TableRadio";
import TableSearchBar from "UI/Table/TableSearchBar";
import TableHeaderButton from "UI/Table/TableHeaderButton";
import TableRowNotAuthorized from "UI/Table/TableRowNotAuthorized";
import TableHeaderButtonExport from "UI/Table/HeaderButtons/TableHeaderButtonExport";
import TableHeaderButtonFilter from "UI/Table/HeaderButtons/TableHeaderButtonFilter";
import TableHeaderButtonFilterGraph from "UI/Table/HeaderButtons/TableHeaderButtonFilterGraph";
import TableHeaderButtonColumnPicker from "UI/Table/HeaderButtons/TableHeaderButtonColumnPicker";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";

import { useTable, useRowSelect, useSortBy, useFilters, useGlobalFilter, usePagination } from "react-table";
import withRedirect from "Components/withRedirect";
import MapConfigContext from "Components/MapConfigContext/MapConfigContext";
import UserContext from "Components/UserContext/UserContext";

function Table(props) {
  // Use the state and functions returned from useTable to build your UI

  const mapConfigContext = useContext(MapConfigContext);
  const userContext = useContext(UserContext);

  const [rowId, setRowId] = useState(-1);
  const [pageCurrent, setPageCurrent] = useState(0);
  const [recordsNumber, setRecordsNumber] = useState(0);
  const [firstEntry, setFirstEntry] = useState(false);
  const [filters, setFilters] = useState(false);
  const [noRender, setNoRender] = useState("edit");
  const [inc, setInc] = useState(50);

  const { disableControls } = props;
  const [tableControls, setTableControls] = useState(!disableControls);

  const { t } = useTranslation();
  const classes = useStyles();

  const {
    records,
    columns,
    data,
    hiddenColumnNames,
    children,
    allowColumnPicker,
    allowSelection,
    allowFilter,
    allowExport,
    allowSearchBar,
    allowInc,
    inlineEdit,
    // disableControls,
    onSelectRecord,
    invalidRecordIndeces,
    notAuth,
    isLoading,
    editedRecordIds,
    selectedRowId,
    onSort,
    dc,
    tableName,
    exportName,
    identifier,
    initialPageSize,
    onEdit,
    exportPonudeIds,
    customFnc,
    exportFnc,
    stickyHeader,
    disableStyles,
    PreButton,
    PreComponent,
    customPadding
  } = props;
  // header props
  const {
    displayHeader
  , title
  , allowAdd
  , onAdd
  , addGender
  } = props;

  const { redirect } = props;

  const selectOnlyOne = function (evt, value, onChange, toggleAllRowsSelected) {
    toggleAllRowsSelected(false);
    onChange(evt, value);
  };

  const handleRowClick = function (evt, toggleRowSelected, toggleAllRowsSelected, recordId, rowIndex) {
    switch (allowSelection) {
      case "none":
        return;
      case "one":
        if (recordId !== rowId) {
          setRowId(recordId);
          if (onSelectRecord) {
            onSelectRecord(recordId);
          }
          toggleAllRowsSelected(false);
          toggleRowSelected();
        }
        return;
      case "many":
        if (onSelectRecord) {
          onSelectRecord(rowIndex);
        }
        toggleRowSelected();
        break;
    }
  };

  const handleHeaderCheckboxClick = (onChange, evt, checked) => {
    if (onSelectRecord) {
      onSelectRecord(-1, checked);
    }
    onChange(evt, checked);
  }

  const handleToggleFilter = function (evt) {
    setFilters(!filters);
    if (filters === true) {
      setAllFilters([]);
    }
  };

  const handleExportPonudeIds = () => {
    mapConfigContext.setFilteredData(records, true);
    redirect("/dashboard")
  }

  const userDefinedColumnVisibility = userContext.getRecent("columns", identifier, []);

  const userHiddenFieldNames = userDefinedColumnVisibility.filter(x => x.hidden === true).map(x => x.source);
  const userVisibleFieldNames = userDefinedColumnVisibility.filter(x => x.hidden === false).map(x => x.source);
  const uniqueHiddenFieldNames = [...new Set(userHiddenFieldNames.concat(hiddenColumnNames))];

  const finalHiddenColumnNames = uniqueHiddenFieldNames.filter(x => {
    if (userVisibleFieldNames.indexOf(x) >= 0) {
      return false;
    } else {
      return true;
    }
  });

  const {
    getTableProps,
    toggleAllRowsSelected,
    headerGroups,
    rows,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    setAllFilters,
    setHiddenColumns,
    state: { selectedRowIds, globalFilter, sortBy, pageIndex, pageSize, hiddenColumns }
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: finalHiddenColumnNames,
        pageSize: initialPageSize ? initialPageSize : 10
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (instance) => {
      instance.allColumns.push((columns) => {
        switch (allowSelection) {
          case "none":
            return [...columns];
          case "one":
          case "many":
            return [
              ...columns,
              // {
              //   id: "selection",
              //   Header: ({ getToggleAllRowsSelectedProps }) => {
              //     const { onChange } = getToggleAllRowsSelectedProps();
              //     return (
              //       <div>
              //         {allowSelection === "many" ? (
              //           <TableCheckbox {...getToggleAllRowsSelectedProps()} onChange={(evt, checked) => handleHeaderCheckboxClick(onChange, evt, checked)}  title={"Toggle"} />
              //         ) : null}
              //       </div>
              //     );
              //   },
              //   Cell: ({ row, toggleAllRowsSelected }) => {
              //     const { onChange, checked, indeterminate, style } = row.getToggleRowSelectedProps();

              //     switch (allowSelection) {
              //       case "one":
              //         return (
              //           <div>
              //             <TableRadio
              //               // onClick={(evt, val) => { selectOnlyOne(evt, val, onChange, toggleAllRowsSelected) }}
              //               checked={checked}
              //               title={"Toggle"}
              //               style={style}
              //             />
              //           </div>
              //         );
              //       case "many":
              //       default:
              //         return (
              //           <div>
              //             <TableCheckbox {...row.getToggleRowSelectedProps()} title={"Toggle"} />
              //           </div>
              //         );
              //     }
              //   }
              // }, 
              {
                id: "edit",
                Header: () => t("buttons.edit"),
                Cell: (cellProps) => (
                  <div style={{display: "inline"}}>
                    <IconButton size="small" onClick={(evt) => onEdit(cellProps.row.original.id)}>
                      <EditIcon fontSize="small"/>
                    </IconButton>
                    {
                      cellProps.row.original.id2 && customFnc && typeof customFnc === "function" ?
                      <IconButton size="small" onClick={(evt) => customFnc(cellProps.row.original.id2)}>
                        <FontAwesomeIcon icon={faEdit} size="sm" />
                      </IconButton>
                      : null
                    }
                  </div>
                )
            }];
          default:
            return [...columns];
        }
      });
    }
  );

  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
  }, [onSort, sortBy]);

  useEffect(() => {
    if (inlineEdit) {
      setNoRender("selection");
    } else {
      setNoRender("edit");
    }
  }, [inlineEdit]);

  const arrSelectedRowsIds = Object.keys(selectedRowIds).filter((x) => selectedRowIds[x] === true);

  console.log;

  if (selectedRowId === -1 && selectedRowId !== rowId) {
    setRowId(-1);
  }
  if (!firstEntry && rows.length !== recordsNumber) {
    setRecordsNumber(rows.length);
    setFirstEntry(true);
  }

  if (pageIndex !== pageCurrent) {
    if (rows.length !== recordsNumber) {
      gotoPage(0);
      setPageCurrent(0);
      setRecordsNumber(rows.length);
    } else {
      gotoPage(pageCurrent);
      setRecordsNumber(rows.length);
    }
  }

  if (Math.floor(rows.length / pageSize) < pageCurrent || Math.floor(rows.length / pageSize) < pageIndex) {
    return null;
  }

  const setDefaultHidden = () => {
    const hiddenCols = dc.getHiddenViewFieldsNames("default");
    const allCols = dc.getViewFieldsNames("default");

    const stateCols = allCols.map(c => ({ source: c, hidden: hiddenCols.indexOf(c) >= 0 }))
    userContext.setRecent("columns", identifier, stateCols);
    setHiddenColumns(stateCols.filter(f => f.hidden).map(f => f.source));
  }

  const handleToggleColumnVisibility = useCallback((source, isVisible) => {
    let _columns = userContext.getRecent("columns", identifier, []);
    const _item = _columns.find(x => x.source === source);

    let newHidden;
    if (isVisible) {
      newHidden = hiddenColumns.filter(x => x !== source);
      if (_item) {
        _item.hidden = false;
      } else {
        _columns.push({ source: source, hidden: false });
      }
    } else {
      newHidden = hiddenColumns.concat([source]);
      if (!_item) {
        _columns.push({ source: source, hidden: true });
      } else {
        _item.hidden = true;
      }
    }

    const cleanArray = [...new Set(newHidden)];

    userContext.setRecent("columns", identifier, _columns);
    setHiddenColumns(cleanArray);
  }, [hiddenColumns]);

  const toggleTableControls = () => {
    setTableControls(!tableControls);
  }

  const trimString = (v) => {
    if (v.length > inc) {
      return v.slice(0, Math.max(1, inc)).trim() + "...";
    }
    return v;
  }

  // Render the UI for your table
  return (
    <Fragment>
    <Card 
      className={classes.card} 
      elevation={disableStyles ? 0 : 1}
      style={disableStyles ? { backgroundColor: "#eaeee2" } : {}}
     >
      <CardContent className={classes.cardContent}>
        { PreComponent ? PreComponent : null }
        {
          displayHeader ?
          <Toolbar disableGutters={true} variant="dense">
            <Typography variant="h5" component="h3">
              {t(title)}
            </Typography>
            <ToolbarFillContent />
            {
              page.length > 0 ?
              <TablePagination
                component="div"
                classes={{ toolbar: classes.toolbar }}
                onChangePage={(evt, page) => setPageCurrent(page)}
                page={pageIndex}
                rowsPerPage={pageSize}
                count={rows.length}
                onChangeRowsPerPage={(evt) => {
                  setPageCurrent(0);
                  setPageSize(evt.target.value);
                }}
                labelRowsPerPage={t("table.rows")}
                backIconButtonProps={{size: "small"}}
                nextIconButtonProps={{size: "small"}}
              />
              : null
            }
            <Switch
              checked={tableControls}
              onChange={toggleTableControls}
              size="small"
            />
            { PreButton ? PreButton : null }
            {allowAdd ?
              <FormButton variant="contained" size="small" onClick={onAdd} color="primary" startIcon={<AddIcon />}>
                {t("buttons.new_" + addGender)}
              </FormButton>
            : null}
          </Toolbar>
          : null
        }
        <Box m={0} className={classes.tableBox}>
      {
        tableControls ?
        <Toolbar variant="dense" disableGutters={true}>
          {allowSearchBar !== undefined && allowSearchBar === false ? null :
          <TableSearchBar
            count={preGlobalFilteredRows ? preGlobalFilteredRows.length : 0}
            value={globalFilter}
            onChange={setGlobalFilter}
          /> }
          <ToolbarFillContent />
          {allowInc ? 
            <IncrementControl value={inc} onChange={setInc} />
            : null}
          {allowColumnPicker ?
            <TableHeaderButtonColumnPicker columns={columns} setDefault={setDefaultHidden} hiddenColumnNames={hiddenColumns} onChange={handleToggleColumnVisibility} />
          : null}
          {allowFilter ? (
            <TableHeaderButtonFilter filters={filters} onToggleFilter={handleToggleFilter} />
          ) : null}
          {exportPonudeIds ?
            <TableHeaderButtonFilterGraph handleExportPonudeIds={handleExportPonudeIds} />
            : null}
          {allowExport ? (
            <TableHeaderButtonExport dc={dc} rows={rows} records={records} tableName={tableName} exportFnc={exportFnc}/>
          ) : null}
        </Toolbar>
        : null
      }
      <TableContainer style={{flexGrow: 1}}>
        <MaterialUITable {...getTableProps()} /*padding="none"*/ size="small" stickyHeader={stickyHeader}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, c) => {
                  const { onClick, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps({ title: undefined }));
                  if (column.id === noRender) {
                    return null;
                  }
                  headerProps.style = Object.assign({}, headerProps.style, { padding: `4px ${3*customPadding}px 4px ${2*customPadding}px` })
                  return (
                    <TableCell style={{ maxWidth: "100px" }} size="small" padding={column.id === "selection" || column.id === "edit" ? "checkbox" : "default"} {...headerProps}>
                      {/*<TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? "desc" : "asc"}
                        onClick={onClick}
                      >
                        <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 800 }}>{column.render("Header")}</Typography>
                      </TableSortLabel>*/}
                      {column.canSort ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                          onClick={onClick}
                        >
                          <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 800 }}>{column.render("Header")}</Typography>
                        </TableSortLabel>
                      ) : (
                        <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 800 }}>{column.render("Header")}</Typography>
                      )}
                      {filters && column.canFilter ? (
                        <div className="table-filter" key={c + "-filter"}>
                          {column.render("Filter")}
                        </div>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {
              isLoading ?
              <TableRow>
                <TableCell colSpan={headerGroups && headerGroups.length && headerGroups[0] && headerGroups[0].headers ? headerGroups[0].headers.length : 1}>
                  <LinearProgress  />
                </TableCell>
              </TableRow>
              :
              notAuth ?
              <TableRowNotAuthorized cols={headerGroups && headerGroups.length && headerGroups[0] && headerGroups[0].headers ? headerGroups[0].headers.length : 1}/>
              :
              page.map((row, i) => {
                prepareRow(row);
                const isItemSelected = selectedRowIds[pageIndex * pageSize + i] === true || rowId === row.original.id;
                if (rowId === row.original.id && arrSelectedRowsIds.length === 0) {
                  arrSelectedRowsIds.push(row.id);
                  row.toggleRowSelected();
                }
                const invalidRecord = Array.isArray(invalidRecordIndeces) && invalidRecordIndeces.indexOf(i) >= 0;

                return (
                  <TableRow
                    hover={true}
                    selected={isItemSelected}
                    role="check"
                    aria-checked={isItemSelected}
                    {...row.getRowProps()}
                    onClick={(evt) => handleRowClick(evt, row.toggleRowSelected, toggleAllRowsSelected, row.original.id, i)}
                    style={{ backgroundColor: invalidRecord ? "#F88" : "FFF" }}
                  >
                    {row.cells.map((cell, j) => {
                      //if (cell.column)
                      if(cell && cell.column && cell.column.id === noRender) {
                        return null;
                      }
                      {/*const currHeader = cell && cell.column;*/}
                      const cellStyle = 
                        {/*currHeader && currHeader.maxWidth ? 
                          {style: {maxWidth: currHeader.maxWidth, minWidth: currHeader.minWidth}} 
                          :*/};
                      if (customPadding !== -1) {
                        cellStyle.style = { padding: `4px ${3*customPadding}px 4px ${2*customPadding}px` };
                      }
                      //const cellStyle = {style:{}}
                      const isRowEdited = cell.column.id === "edit" && editedRecordIds && editedRecordIds.length > 0 && editedRecordIds.indexOf(row.original.id) >= 0;
                      return (
                        <TableCell 
                          size="small" 
                          {...cell.getCellProps()} 
                          {...cellStyle} 
                          className={isRowEdited ? "row-edited" : ""}
                        >
                          {cell.render("Cell", { trimString })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>


        </MaterialUITable>
      </TableContainer>
       {
          disableControls || rows.length <= pageSize ?
          null :
          null
        }
      {
        inlineEdit ?
        null :
        <Toolbar variant="dense" disableGutters={true}>
          <div style={{ flexGrow: 1 }} />
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, { selection: arrSelectedRowsIds });
          })}
        </Toolbar>
      }
    </Box>
      </CardContent>
    </Card>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    padding: theme.spacing(1)
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    padding: `${theme.spacing(0)}px !important`
  },
  tableBox: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "auto"
  },
  toolbar: {
    minHeight: theme.spacing(5)
  }
}));

Table.defaultProps = {
  displayHeader: true,
  allowAdd: true,
  allowInc: true,
  addGender: "m",
  disableControls: false,
  customPadding: 2
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  allowSelection: PropTypes.oneOf(["none", "one", "many"]),
  displayHeader: PropTypes.bool,
  title: PropTypes.string,
  allowAdd: PropTypes.bool,
  allowInc: PropTypes.bool,
  onAdd: PropTypes.func,
  addGender: PropTypes.oneOf(["m", "f", "n"]),
  customPadding: PropTypes.number
};

export default withRedirect(Table);
