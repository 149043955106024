import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import { Attribution } from "ol/control";

export default function AttributionControl(props) {
  const context = useContext(MapContext);

  const options = {
    className: undefined,
    target: undefined,
    collapsible: undefined,
    collapsed: undefined,
    tipLabel: undefined,
    label: undefined,
    collapseLabel: undefined,
    render: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, Attribution, props, options, events), [
    props.className,
    props.target,
    props.collapsible,
    props.collapsed,
    props.tipLabel,
    props.label,
    props.collapseLabel,
    props.render
  ]);

  return null;
}
