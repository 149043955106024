import React, { useEffect, useContext, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MapContext } from "../../MapContext";
import { BarControlContext } from "../OpenLayersExt/Bar/BarControlContext";
import { registerOlControl, findControl } from "../helpers";
import classNames from "classnames";

import Overlay from 'ol/Overlay';

import Tooltip from "ol-ext/overlay/Tooltip";
import Button from "ol-ext/control/Button";
import Toggle from "ol-ext/control/Toggle";
import Bar from "ol-ext/control/Bar";
import "ol-ext/control/Bar.css";

import { Draw as OlInteractionDraw } from "ol/interaction";


import Util from "../../Util";

export default function MeasureControls(props) {

  const context = useContext(MapContext);
  const { t } = useTranslation();

  let tooltipRef = useRef(null);

  const { position, measureVectorSource } = props;

  const options = {
    terget: undefined
  };

  const events = {
    "change:active": undefined,
    "change:disabled": undefined,
  };

  const getMidPoint = (coordArray) => {
    const len = coordArray.length - 1;
    let coordX = 0, coordY = 0;
    coordArray.slice(0, len).forEach((coordPair) => {
      coordX += coordPair[0];
      coordY += coordPair[1];
    });

    return [coordX / len, coordY / len];
  }

  const saveFeature = (drawEvent, tooltip) => {
    if (drawEvent && drawEvent.feature && drawEvent.feature.getGeometry() && drawEvent.feature.getGeometry().getLastCoordinate()) {
      const measureValDiv = document.getElementById("measure-overlay");
      measureValDiv.innerHTML = tooltip.prevHTML;
      measureValDiv.style.display = "block";
      measureValDiv.style.minWidth = "150px";

      let coordinates = drawEvent.feature.getGeometry().getLastCoordinate();
      if (drawEvent.feature.getGeometry().getType() === "Polygon") {
        coordinates = getMidPoint(drawEvent.feature.getGeometry().getCoordinates()[0]);
      }
      const measureVal = new Overlay ({
        position: coordinates,
        positioning: 'center-left',
        element: measureValDiv
      });
      context.map.addOverlay(measureVal);
    }
    
    if (props.onAddFeature && drawEvent.type === "drawend" && drawEvent.feature) {
      props.onAddFeature(drawEvent.feature);
    }
    tooltip.removeFeature.bind(tooltip)(drawEvent);
  }

  useEffect(() => {
    if (context.map) {
      tooltipRef.current = new Tooltip({
        positioning: 'center-left'
      });
      context.map.addOverlay(tooltipRef.current);
    }
  }, [ context.map ])

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    const drawLineInteraction = new OlInteractionDraw({ type: 'LineString' })
    const drawAreaInteraction = new OlInteractionDraw({ type: 'Polygon' })

    const isInSidebar = props.target !== undefined;

    const mLineIconElement = document.createElement('i');
    mLineIconElement.className = classNames('fas', 'fa-ruler',{'fa-2x': isInSidebar});
    mLineIconElement.dataToggle = "tooltip";
    mLineIconElement.title = t("map.length");

    const mAreaIconElement = document.createElement('i');
    mAreaIconElement.className = classNames('fas', 'fa-draw-polygon',{'fa-2x': isInSidebar});
    mAreaIconElement.dataToggle = "tooltip";
    mAreaIconElement.title = t("map.area");

    let control = new Bar({
      ...definedOptions,
      className: classNames({"ol-bar-sidebar": isInSidebar}),
      controls:[
        new Bar({
          toggleOne: true,
          controls: [
            new Toggle({
              id: "measure-line",
              className: classNames("ol-measure-line", {"ol-thumb-control": isInSidebar}),
              html: mLineIconElement.outerHTML,
              interaction: drawLineInteraction
            }),
            new Toggle({
              id: "measure-area",
              className: classNames("ol-measure-area", {"ol-thumb-control": isInSidebar}),
              html: mAreaIconElement.outerHTML,
              interaction: drawAreaInteraction
            }),
          ],
        })
      ]
    })

    control.setPosition(position || "top");

    if (props.id) {
      control.set("id", props.id);
    }

    if (context.map) {
      const tooltip = tooltipRef.current;
      // Set feature on drawstart
      drawLineInteraction.on('drawstart', tooltip.setFeature.bind(tooltip));
      // Remove feature on finish
      drawLineInteraction.on(['change:active','drawend'], (drawEvent) => saveFeature(drawEvent, tooltip));

      // Set feature on drawstart
      drawAreaInteraction.on('drawstart', tooltip.setFeature.bind(tooltip));
      // Remove feature on finish
      drawAreaInteraction.on(['change:active','drawend'], (drawEvent) => saveFeature(drawEvent, tooltip));

      // console.log('map add')
      const mapControl = findControl(context.map, props.id, Bar);
      if (mapControl) {
        context.map.removeControl(mapControl);
        // console.log("control removed", Button);
      }
      context.map.addControl(control);
      // console.log("control added", Button);
    } else {
      // console.log('initOptions add')
      context.initOptions.controls.push(control);
    }

    let olEvents = Util.getEvents(events, props);
    for (let eventName in olEvents) {
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, Bar);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    };
  }, [
    props.lng
    // props.className,
    // props.title,
    // props.html,
    // props.interaction,
    // props.active,
    // props.disable,
    // props.bar,
    // props.autoActive,
    // props.onToggle,
  ]);

  return null;

  // return (
  //   <ToggleControl
  //     id = "measure"
  //     className="ol-measure"
  //     title={tooltip}
  //     html='<i class="fas fa-ruler-combined"></i>'
  //   >
  //     <MeasureLineControl />
  //     <MeasureAreaControl />
  //   </ToggleControl>
  // );
}
