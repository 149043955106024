import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Material-UI Core Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

//Custom Components
import withFormAuthController from "Components/withFormAuthController";
import withRedirect from "Components/withRedirect";
import withSnackbar from "Components/withSnackbar";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import FormButton from "UI/Form/FormButton";
import {withStyles} from "@material-ui/core/styles";

// const PasswordResetCard = withStyles((theme) => ({
//   root: {
//     borderRadius: "0.5rem",
//     backgroundColor: theme.overrides.MuiCard.root.backgroundColor,
//     color: "#000"
//   }
// }))(Card);

class PasswordResetRequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleFieldChange(value, source) {
    const { onFieldChange } = this.props; //HOC withFormAuthController

    if (onFieldChange) {
      onFieldChange(value, source);
      switch (source) {
        case "username":
          onFieldChange(null, "email")
          break;
        case "email":
          onFieldChange(null, "username")
          break;
        }
    }
  }

  handleSubmit() {
    const { onCompleted } = this.props;
    const { doSendPasswordResetRequest } = this.props; //HOC withFormAuthController
    const { showNotification } = this.props; //HOC withSnackbar

    if (doSendPasswordResetRequest) {
      doSendPasswordResetRequest().then(result => {
        if (result.success) {
          if (onCompleted) {
            onCompleted({success: true});
          }
          showNotification("Succesful", "success")
        } else if (result.validationPass === false) {
          showNotification(result.validation, "error")
        } else {
          if (onCompleted) {
            onCompleted({ success: false});
          }
          showNotification("Unsuccesful", "error")
          //showNotification(result.error, 'error');
        }
      });
    }
  }

  handleCancel() {
    const { redirect } = this.props; //HOC withRedirect
    redirect("/login");
  }

  render() {
    const { t, form } = this.props;
    const {
      record,
      validation,
      fields,
      onFieldChange,
      dataChanged,
      model
    } = this.props; //HOC withFormAuthController

    return (
      <Card>
        <CardContent>
          <GridContainer>
            <GridItem xs={12}>
              <Typography variant="h5" component="h3">
                {t("titles.password_reset_request")}
              </Typography>
            </GridItem>
            <FormContent
              fieldNames={model.getFormFieldsNames(form)}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={this.handleFieldChange}
              onSubmit={() => this.handleSubmit()}
              mode={"insert"}
              columns={1}
            />
          </GridContainer>
        </CardContent>
        <CardActions>
          <FormButton variant="contained" onClick={this.handleSubmit}>
            {t("buttons.send")}
          </FormButton>
          <FormButton variant="text" onClick={this.handleCancel}>
            {t("buttons.cancel")}
          </FormButton>
        </CardActions>
      </Card>
    );
  }
}

export default withTranslation()(
  withFormAuthController(withRedirect(withSnackbar(PasswordResetRequestForm)))
);
