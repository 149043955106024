import React from "react";

//Materual-UI Icons
import CloseIcon from "@material-ui/icons/Close";

//Custom Components
import CardActionButton from "UI/Card/CardActionButton";

function CardActionButtonClose(props) {
  return (
    <CardActionButton color="inherit" aria-label="close" aria-controls="close" {...props}>
      <CloseIcon />
    </CardActionButton>
  );
}

export default CardActionButtonClose;
