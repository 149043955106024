import Util from "../Util";

function findInteraction(map, Interaction) {
  return map
    .getInteractions()
    .getArray()
    .find(x => x instanceof Interaction);
}

function registerOlInteraction(context, Interaction, props, options, events) {
  let allOptions = Object.assign(options, props);
  let definedOptions = Util.getDefinedOptions(allOptions);

  let interaction = new Interaction(definedOptions);
  if (context.map) {
    const mapInteraction = findInteraction(context.map, Interaction);
    if (mapInteraction) {
      context.map.removeInteraction(mapInteraction);
    }
    context.map.addInteraction(interaction);
  } else {
    context.initOptions.interactions.push(interaction);
  }

  let olEvents = Util.getEvents(events, props);
  for (let eventName in olEvents) {
    interaction.on(eventName, olEvents[eventName]);
  }

  return () => {
    //happens on umount
    if (context.map) {
      const mapInteraction = context.map.getInteractions().getArray().find(x => x instanceof Interaction);
      if (mapInteraction) {
        context.map.removeInteraction(mapInteraction);
      }
    }
  };
}

export { registerOlInteraction };
