export const sifrarnici = {
	map_layer_types: {
    OSM: "OSM",
    Stamen: "STAMEN",
    WMS: "WMS",
    GeoServer: "GEOSERVER"
  },
  calendar_picker: {
		klijent_id: 1,
		ponuda_id: 2,
		ugovor_id: 4,
		usluga_id: 8,
    none: 16
  }
}