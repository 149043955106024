import React from 'react'
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

//Custom Components
import GridContainer from 'UI/Grid/GridContainer';
import GridItem from 'UI/Grid/GridItem';

//Images
import esfi from "Images/ESIF.png";
import eu from "Images/eu.png";

const style = {
  toolbar: {
    borderTop: "2px solid #000",
    backgroundColor: "#fff",
    minHeight: 100,
    zIndex: 9999
  },
  center: {
    color: "#000",
    textAlign: "center",
    justifyContent: "center"
  }
}

const getFooterStyle = (page) => {
  const h = window.innerHeight;
  const w = window.innerWidth;
  //1280 , 960
  if (page === "public" && h < 700) {
    return {
      position: "absolute",
      top: w <= 960 ? "480px" : w <= 1280 ? "560px" : "600px",
      color: "#000"
    }
  } else {
    return {
      color: "#000"
    }
  }
}

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { refresh: true };
    window.onresize = () => this.setState((prevState) => ({ refresh: !prevState.refresh }));
  }
  render() {
    const { classes, t, i18n, page } = this.props;

    const textVariant = "caption";
    const version = process.env.REACT_APP_APPVERSION;
    const language = i18n.language;
    const currentYear = new Date().getFullYear();
    const isPublic = page === "public";
    const esfiSize = {
      xs: 6,
      sm: 6, 
      md: isPublic ? 3 : 6,
      lg: page === "public" ? 2 : 2
    }

    const euSize = {
      xs: 6,
      sm: 6, 
      md: isPublic ? 2 : 6,
      lg: page === "public" ? 1 : 2
    }

    const textSize = {
      xs: 12,
      sm: 12, 
      md: 12,
      lg: page === "public" ? 6 : 8
    }

    return (
      <footer id="footer" style={getFooterStyle(page)}>
        <Toolbar disableGutters={false} className={classes.toolbar}>
          <GridContainer spacing={0}>
            <GridItem {...esfiSize}>
              <img src={esfi} style={{display: "block", margin: "auto", height:"75px", paddingRight: "10px"}} />
            </GridItem>
            <GridItem {...euSize}>
              <img src={eu} style={{display: "block", margin: "auto", height:"75px"}}/>
            </GridItem>
            {
              page === "public" ?
              <GridItem {...euSize}>
                <a href="https://dvokut-ecro.hr/" target="_blank">
                <img src="https://dvokut-ecro.hr/wp-content/uploads/2016/04/DVK-logo2.png" style={{display: "block", margin: "auto", height:"75px"}}/>
                </a>
              </GridItem>
              : null
            }
            {
              page === "public" ?
              <GridItem {...esfiSize}>
                <a href="https://prehnit.hr/" target="_blank">
                <img src="https://prehnit.hr/wp-content/uploads/2017/06/Prehnit-logoFINb-e1498738348726.png" style={{display: "block", margin: "auto", height:"75px"}}/>
                </a>
              </GridItem>
              : null
            }
            <GridItem {...textSize}>

              {/*page === "public" ? <br /> : null*/}
              {
                page === "public" ?
                <Typography variant="body1">
                  Dobrodošli u CMS sustav tvrtke <a href="https://dvokut-ecro.hr/">Dvokut-ECRO d.o.o.</a>, kojeg je izradila tvrtka <a href="https://prehnit.hr/">Prehnit d.o.o.</a>.
                </Typography>
                : null
              }                
              {/*page === "public" ? <br /> : null*/}
              <Typography variant={page === "public" ? "body1" : "caption"}>
                Izradu aplikacije je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.
              </Typography>
              {page === "public" ? <br /> : null}
              
              {
                page === "public" ?
                null :
                <Typography variant="caption">
                  Osobni podaci koji se nalaze u sustavu dostupni su samo ovlaštenim osobama te se štite sukladno Općoj uredbi o zaštiti podataka (GDPR). 
                  Za sva pitanja u vezi zaštite osobnih podataka možete se obratiti imenovanom Službeniku za zaštitu osobnih podataka na mail: <a href="mailto:tajana.uzelac@dvokut-ecro.hr">tajana.uzelac@dvokut-ecro.hr</a>.
                  Voditelj obrade osobnih podataka je DVOKUT-ECRO d.o.o., pravna osoba.
                </Typography>
              }
            </GridItem>
          </GridContainer>
        </Toolbar>
      </footer>
    );
  }
}

export default withTranslation()(withStyles(style)(Footer));