import Util from "../Util";

function findControl(map, id, Control) {
  if (id) {
    return map.getControls().getArray().find(x => x.get("id") === id);
  } else {
    return map.getControls().getArray().find(x => x instanceof Control);
  }
}

function registerOlControl(context, Control, props, options, events) {
  let allOptions = Object.assign(options, props);
  let definedOptions = Util.getDefinedOptions(allOptions);

  let control = new Control(definedOptions);

  if(props.id) {
    control.set("id", props.id)
  }

  if (context.map) {
    const mapControl = findControl(context.map, props.id, Control);
    if (mapControl) {
      context.map.removeControl(mapControl);
      // console.log('control removed', Control);
    }
    // TODO: Piero
    // This is a hack and I know it, but it works for now
    if (props && props.className && control && control.element && control.element.classList) {
      control.element.classList.add(props.className);
    }
    context.map.addControl(control);
    // console.log('control added', Control);
  } else {
    context.initOptions.controls.push(control);
  }

  let olEvents = Util.getEvents(events, props);
  for (let eventName in olEvents) {
    control.on(eventName, olEvents[eventName]);
  }

  return () => {
    if (context.map) {
      const mapControl = findControl(context.map, Control);
      if (mapControl) {
        context.map.removeControl(mapControl);
      }
    }
  }
}

// function updateOlControl(context, Control, props, options, events) {
//   // console.log(Control, props, options);
//   let allOptions = Object.assign(options, props);
//   let definedOptions = Util.getDefinedOptions(allOptions);

//   let control = new Control(definedOptions);
//   if (context.map) {
//     const mapControl = findControl(context.map, Control);
//     if (mapControl) {
//       // context.updateMap({type: "removeControl", control: mapControl});
//       context.map.removeControl(mapControl);
//     }
//     // context.updateMap({type: "addControl", control: control})
//     context.map.addControl(control);
//   } else {
//     context.initOptions.controls.push(control);
//   }

//   let olEvents = Util.getEvents(events, props);
//   for (let eventName in olEvents) {
//     control.on(eventName, olEvents[eventName]);
//   }
// }

export { registerOlControl, findControl };
