import React, { Fragment, useContext, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import Loader from "UI/Loader/Loader";
import ModelTable from "UI/Table/ModelTable";
import DialogContext from "UI/DialogContext/DialogContext";
import UserContext from "Components/UserContext/UserContext";
import UgovorDialog from "Views/UgovoriPage/UgovorDialog";

import model from "Models/ugovor";
import dataController from "Lib/dataController";
import { exportTableToExcel } from "Lib/exportExcel";

const tagsByLink = {
  "Sektor": "klj_sektor",
  "Uloga DE": "klj_uloga_de",
  "Vrsta naručitelja": "klj_vrsta_narucitelja",
  "Lokacija": "klj_lokacija",
}

const tagsByLinkKeys = ["klj_sektor", "klj_uloga_de", "klj_vrsta_narucitelja", "klj_lokacija"];

function UgovoriTable(props) {
  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [records, setRecords ] = useState([]);
  const [notAuth, setNotAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);

  const dc = new dataController(model);  

  useEffect(() => {
    refreshRecords();
  }, []);

  useEffect(() => {
    refreshRecords();
  }, [userContext.gotData]);

  const handleExport = (filteredRecords) => {
    const rObj = filteredRecords.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    const viewFields = dc.getViewFields("exportExcel");
    setOpenLoader(true);

    dc.GetData("tags-by-link/0").then(rTags => {
      const tags = rTags.data;
      dc.GetData("usluge/0").then(rUsluge => {
        const usluge = rUsluge.data;
        usluge.forEach(u => {
          if (rObj[u.ugovor_id]) {
            if (rObj[u.ugovor_id].usl_tags) {
              // rObj[u.ugovor_id].usl_tags.push(u.tag);
              rObj[u.ugovor_id].usl_tags += `;${u.tag}`;
            } else {
              // rObj[u.ugovor_id].usl_tags = [u.tag];
              rObj[u.ugovor_id].usl_tags = `${u.tag}`;
            }
          }
        });
        tags.forEach(t => {
          if (rObj[t.link_id]) {
            if (rObj[t.link_id][ tagsByLink[t.grupa] ]) {
              rObj[t.link_id][ tagsByLink[t.grupa] ] += `;${t.tag}`;
            } else {
              rObj[t.link_id][ tagsByLink[t.grupa] ] = `${t.tag}`
            }
          }
        });
        exportTableToExcel({
          records: Object.keys(rObj).map(k => rObj[k]),
          fields: viewFields,
          filename: "Ugovori",
          t: t
        });
      })
      .finally(() => {
        setOpenLoader(false);
      })
    })
  }

  const handleRemoveTableControls = () => {
    setTableControls(!tableControls);
  }

  const handleAdd = (evt) => {
    dialogContext.showDialog(UgovorDialog, {
      model: dc,
      mode: "insert",
      form: "insert",
      onClose: handleDialogClose
    });
  };

  const handleEdit = (evt, sel) => {
    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;

    dialogContext.showDialog(UgovorDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: handleDialogClose
    });
  };

  const handleTableEdit = (recordId) => {
    dialogContext.showDialog(UgovorDialog, {
      model: dc,
      mode: "update",
      form: "update",
      recordId: recordId,
      onClose: (r) => handleDialogClose(r)
    });
  }

  const handleDialogClose = (result) => {
    if (result.dataChanged) {
      refreshRecords(true);
      if (result.action === "insert" && result.id) {
        handleTableEdit(result.id);
      }
    }
  };

  const mapRecords = (rs) => {
    const tc = userContext.fetchTags();
    const uc = userContext.fetchUsluge();
    return rs.map(r => {
      if (tc[r.id]) {
        tagsByLinkKeys.forEach(k => {
          if (tc[r.id][k]) {
            r[k] = tc[r.id][k];
          } else {
            r[k] = "";
          }
        });
      }
      if (uc[r.id]) {
        r.usl_tags = uc[r.id].usl_tags;
      } else {
        r.usl_tags = "";
      }
      return r;
    });
  };

  const refreshRecords = (force = false) => {
    if (force || records.length === 0) {
      setLoading(true);
      dc.GetData().then((resp) => {
        if (resp.success && resp.data && Array.isArray(resp.data)) {
          setRecords(mapRecords(resp.data));
        } else {
          const { error } = resp;
          if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
            setNotAuth(true);
          } 
        }
        setLoading(false);
      });
    } else {
      setRecords(mapRecords(records));
    }
  };

  return (
    <Fragment>
      <ModelTable records={records || []} dc={dc} 
        allowSelection="one" 
        allowFilter={true} 
        allowExport={true} 
        inlineEdit={true} 
        onEdit={handleTableEdit} 
        notAuth={notAuth}
        isLoading={loading}
        exportFnc={handleExport}
        stickyHeader={true}
        title={"titles.ugovori"}
        onAdd={handleAdd}
      />
      <Loader open={openLoader} />
    </Fragment>
  );
}

export default UgovoriTable;