import React, { useState, useEffect, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

// Material UI Core
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

// Material-UI Icons
import CloseIcon from "@material-ui/icons/Close";

// Custom Components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import BarChart from "UI/Charts/BarChart";
import Graph from "UI/Charts/Graph";
import FormButton from "UI/Form/FormButton";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";

import modelPonuda from "Models/ponuda";
import modelUgovor from "Models/ugovor";
import dataController from "Lib/dataController";

import MapConfigContext from "Components/MapConfigContext/MapConfigContext";
import ThemeContext from "Components/ThemeContext/ThemeContext";

//class Dashboard extends React.Component {
export default function Dashboard(props) {

  const { t } = useTranslation();
  const [numData, setNumData] = useState({});
  const [valData, setValData] = useState({});
  const [notAuth, setNotAuth] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dcPonuda = new dataController(modelPonuda);
  const dcUgovor = new dataController(modelUgovor);
  const mapConfigContext = useContext(MapConfigContext);
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    refreshRecords();
  }, [hasFilters]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [window.innerWidth]);

  const refreshRecords = () => {
    // dummyRecords();
    dcPonuda.GetData().then((respPonuda) => {
      dcUgovor.GetData().then((respUgovor) => {
        if (respUgovor.success || respPonuda.success) {
          const dataObj = {};
          let maxYear = 0, minYear = Infinity;
          const filteredData = mapConfigContext.getFilteredData();

          if (respPonuda.success && Array.isArray(respPonuda.data)) {
            respPonuda.data.forEach(pRecord => {
              const { id, datum, neto_iznos } = pRecord;
              const year = datum ? parseInt(datum.format('YYYY'), 10) : 0;
              if (year === 0) {
                return;
              }
              if (year > maxYear) {
                maxYear = year;
              }
              if (year < minYear) {
                minYear = year;
              }
              if (filteredData === null || filteredData.indexOf(id) >= 0) {
                if (dataObj.hasOwnProperty(year)) {
                  dataObj[year]['pNum']++;
                  dataObj[year]['pVal'] += neto_iznos;
                } else {
                  dataObj[year] = {
                    pNum: 1,
                    pVal: +neto_iznos,
                    uNum: 0,
                    uVal: 0
                  }
                }
              }
            })
          }

          if (respUgovor.success && Array.isArray(respUgovor.data)) {
            respUgovor.data.forEach(uRecord => {
              const { id, datum_potpisa, ug_neto_iznos } = uRecord;
              const year = datum_potpisa ? parseInt(datum_potpisa.format('YYYY'), 10) : 0;
              if (year === 0) {
                return;
              }
              if (year > maxYear) {
                maxYear = year;
              }
              if (year < minYear) {
                minYear = year;
              }
              if (filteredData === null || filteredData.indexOf(id) >= 0) {
                if (dataObj.hasOwnProperty(year)) {
                  dataObj[year]['uNum']++;
                  dataObj[year]['uVal'] += ug_neto_iznos;
                } else {
                  dataObj[year] = {
                    uNum: 1,
                    uVal: +ug_neto_iznos
                  }
                }
              }
            })
          }
          // series[0] => ponude, series[1] => ugovori
          const numData = {labels: [], series: [[], []]}
          const valData = {labels: [], series: [[], []]}
          for (let i = minYear; i <= maxYear; ++i) {
            numData['labels'].push(i);
            valData['labels'].push(i);
            numData['series'][0].push(
              dataObj.hasOwnProperty(i) && dataObj[i].hasOwnProperty("pNum") && dataObj[i].pNum ? 
                dataObj[i]['pNum'] 
                : 0
            );
            numData['series'][1].push(
              dataObj.hasOwnProperty(i) && dataObj[i].hasOwnProperty("uNum") && dataObj[i].uNum ? 
                dataObj[i]['uNum'] 
                : 0
            );
            valData['series'][0].push(
              dataObj.hasOwnProperty(i) && dataObj[i].hasOwnProperty("pVal") && dataObj[i].pVal ? 
                parseInt(dataObj[i]['pVal'], 10) 
                : 0
            );
            valData['series'][1].push(
              dataObj.hasOwnProperty(i) && dataObj[i].hasOwnProperty("uVal") && dataObj[i].uVal ? 
                parseInt(dataObj[i]['uVal'], 10) 
                : 0
            );
          }
          //setRecords(resp.data)
          setValData(numData);
          setNumData(valData);
          setHasFilters(filteredData !== null);

        } else {
          const { error } = resp;
          if (error && error.hasOwnProperty("errorCode") && error.errorCode === 403) {
            setNotAuth(true);
          } 
        }
      });
    });
  };

  const handleClearFilters = () => {
    mapConfigContext.setFilteredData(null, false);
    setHasFilters(false);
  }

	return (
    <Card>
      <CardContent>
  			<GridContainer>
        {
          hasFilters ?
          <GridItem xs={12}>
            <Toolbar disableGutters={true}>
              <FormButton variant="contained" onClick={handleClearFilters} color="primary" startIcon={<CloseIcon />}>
                {t("buttons.remove_filters")}
              </FormButton>
            </Toolbar>
          </GridItem>
          : null
        }
  				<GridItem xs={12}>
            <Card style={{height: '100%'}}>
              {/*<CardHeader title={t("dashboard.ponude_num")} style={{ textAlign: "center" }} />*/}
              <CardHeader 
                title={
                  <Fragment>{t("common.num")}
                    &nbsp;
                    <span style={{color: "#0466ad"}}>{t("titles.dash_ponuda")}</span>
                    {"/"}
                    <span style={{color: "#FF7F00"}}>{t("titles.dash_ugovor")}</span>
                  </Fragment>
                } 
                style={{ textAlign: "center" }} 
              />
              <CardContent>
                {
                  true || false ?
                  <BarChart themeContext={themeContext} data={valData} color={"#0466ad"} colors={["#0466ad", "#FF7F00"]} />
                  : null
                }
              </CardContent>
            </Card>
          </GridItem>
  				<GridItem xs={12}>
            <Card style={{height: '100%'}}>
              <CardHeader 
                title={
                  <Fragment>{t("common.val")}
                    &nbsp;
                    <span style={{color: "#0466ad"}}>{t("titles.dash_ponuda")}</span>
                    {"/"}
                    <span style={{color: "#FF7F00"}}>{t("titles.dash_ugovor")}</span>
                  </Fragment>
                } 
                style={{ textAlign: "center" }} 
              />
              <CardContent>
                {
                  true || false ?
                  <BarChart themeContext={themeContext} data={numData} color={"#0466ad"} colors={["#0466ad", "#FF7F00"]} />
                  : null
                }
              </CardContent>
            </Card>
          </GridItem>
        </GridContainer>
      </CardContent>
    </Card> 
		);
}