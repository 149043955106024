import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { sifrarnici } from "Lib/sifrarnici";

//Material-UI Core Components
import { makeStyles, withTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";

const customCheckboxStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 15,
    paddingRight: 15,
    // color: theme.palette.common.white,
    "&$checked": {
      // color: theme.palette.common.white,
    }
  },
  checked: {}
}));

class CheckboxControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleToggleAll = this.handleToggleAll.bind(this);
    this.getItems = this.getItems.bind(this);
    this.getItem = this.getItem.bind(this);
    this.getValues = this.getValues.bind(this);
  }

  componentDidMount() {
    this.getItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const { value, onChange, field } = this.props;
    const tempValue = [];

    if (prevProps.dc !== this.props.dc) {
      this.getItems();
    } else if (prevProps.tag !== this.props.tag) {
      this.getItems();
    }

    if (prevState.items !== this.state.items) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          tempValue.push(this.getItem(v));
        });
      } else if (Number.isInteger(value)) {
        const tempValueArray = this.getValues();
        tempValueArray.forEach((v) => {
          tempValue.push(this.getItem(v));
        });
      }
    }

    return null;
  }

  handleChange(evt) {
    const { field, onChange, value } = this.props;
    const itemValue = evt.target.value;
    const checked = evt.target.checked;
    const item = this.getItem(itemValue);

    const tempValue = this.getValues().map((v) => this.getItem(v));

    if (checked) {
      tempValue.push(item);
    } else {
      tempValue.forEach((v, i) => {
        if (v.tag == itemValue) {
          tempValue.splice(i, 1);
        }
      });
    }

    const source = field ? field.source : null;

    if (onChange) {
      onChange(tempValue, source);
    }
  }

  handleToggleAll(evt){
    const { field, onChange, value } = this.props;
    const { items } = this.state;
    const checked = evt.target.checked;

    const source = field ? field.source : null;

    if (checked) {
      if (onChange) {
        onChange(items, source);
      }

    } else {
      if (onChange) {
        onChange([], source);
      }

    }
  }

  getItems() {
    const { field, t, dc } = this.props;

    if (field && field.subModel && dc ){
      const idAttribute = dc.fieldId.source;
      dc.GetData()
        .then(resp => {
         if (dc.filter) {
            const items = this.filter(resp.data);
            this.setState({
              items: items
            })
          } else {
            this.setState({
              items: resp.data ? resp.data : []
            })
          }
        });
    } else if (field && field.items) {
      let items = [];
      field.items.labels.forEach((label, i) => {
        items.push({
          label: field.translate ? t(label) : label
          , value: field.items.values[i]})
      });
      this.setState({
        items: items
      });
    } else {
      this.setState({ items: [] });
    }
  }

  filter(items) {
    const { dc } = this.props;
    if (dc.filter) {
      return items.filter((x) => dc.filter.find((f) => f === x.tag));
    } else {
      return items;
    }
  }

  getItem(value) {
    // const items = this.getItems()
    const { items } = this.state;
    return items.find((x) => x.tag === parseInt(value, 10));
  }

  getValues() {
    const { value } = this.props;
    let valueArray = [];
    let valueCopy = value;

    if (Array.isArray(valueCopy) && valueCopy.length >= 0) {
      valueCopy.forEach((el) => {
        if (typeof el === "object" && el.tag) {
          valueArray.push(el.tag);
        } else {
          valueArray.push(el);
        }
      });
    } else if (Number.isInteger(valueCopy) && valueCopy >= 0) {
      // toString(2) turns integer to binary string
      // split is used so we can reverse the list
      // reverse is used so the position of the bit {i} is equal to the power of 2 it represents

      valueCopy = valueCopy.toString(2).split("").reverse();
      valueCopy.forEach((el, i) => {
        if (el === "1") {
          valueArray.push(Math.pow(2, i));
        }
      });
    }

    return valueArray;
  }

  render() {
    const { field, t, value, validation, formMode, controlMode, onChange } = this.props;
    const { items } = this.state;
    // const classes = customCheckboxStyles();

    // const items = this.getItems();

    const hasValue = value !== undefined && value !== null;
    const isRequired = field && field.validation && field.validation.required ? true : false;
    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || (field && field.readonly);
    const title = field.ttoken ? t(field.ttoken) : t(field.title);

    const valueArray = this.getValues();

    const showToggleAll = (field && field.showToggleAll) || this.props.showToggleAll;
    const allChecked = valueArray.length === items.length;
    const someChecked = valueArray.length > 0 && !allChecked;

    return (
      <FormControl error={hasError}>
        <FormLabel required={isRequired}>{title}</FormLabel>
        <FormGroup
          row={field.row}
          value={hasValue ? value.tag : ""}
          // onChange={this.handleChange}
          aria-label={t(this.props.title)}
        >
          {showToggleAll ? (
            <FormControlLabel control={<Checkbox
              checked={allChecked}
              indeterminate={someChecked}
              color="secondary"
              onChange={this.handleToggleAll}
            />} value={-1} label={"Toggle All"} disabled={isReadOnly} />
          ) : null}
          {items.map((item, i) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={valueArray.indexOf(item.tag) >= 0}
                  //onChange={this.handleChange(field.items.values[i])}
                  color="secondary"
                  //classes={classes}
                  onChange={this.handleChange}
                  key={i}
                />
              }
              value={item.tag}
              label={field && field.translate ? t(item.opis) : item.opis}
              key={i}
              disabled={isReadOnly || item.disabled}
            />
          ))}
        </FormGroup>
        <FormHelperText id={field.source + "-helper"}>
          {hasError ? validation.msg : field.tooltip ? field.tooltip : " "}
        </FormHelperText>
      </FormControl>
    );
  }
}

CheckboxControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  validation: PropTypes.object,
  onChange: PropTypes.func
};

export default withTranslation()(withTheme(CheckboxControl));
