import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCalculator } from '@fortawesome/free-solid-svg-icons'

//Material-UI Core Components
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

//Material-UI Icons
import LockIcon from "@material-ui/icons/Lock";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class TextControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.getLabel = this.getLabel.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getHelperText = this.getHelperText.bind(this);
    this.checkIfRequired = this.checkIfRequired.bind(this);

    this.state = {
      showPassword: false,
      isFocused: false
    }
  }

  handleChange(evt){
    const { field } = this.props;

    // There is regex in replace because without global parameter g replace replaces only the first occurence
    const value = (field && field.mask) ? evt.currentTarget.value.replace(/ /g, '') : evt.currentTarget.value;
    const source = field ? field.source : null;

    if (this.props.onChange) {
      this.props.onChange(value, source);
    }
  }

  handleFocus(evt) {
    this.setState({ isFocused: true });
  }

  handleBlur(evt) {
    this.setState({ isFocused: false });
  }

  getLabel() {
    const { field, label, t } = this.props;

    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  }

  getValue(value){
    const { field, t } = this.props;

    if (field && field.items && field.items.values && field.items.labels){
      const pos = value ? field.items.values.indexOf(value) : field.items.values.indexOf( field.items.default );
      const lbl = field.items.labels[pos];
      return field.translate ? t(lbl) : lbl;
    } else {
      return (value !== undefined && value !== null) ? value : ''
    }
  }

  getHelperText() {
    const { field, helperText, showHelper, t } = this.props;

    return field && field.tooltip ? t(field.tooltip) : (helperText ? helperText : (showHelper ? " " : null));
  }

  checkIfRequired() {
    const { field } = this.props;

    if (field) {
      return field.validation && field.validation.required;
    } else {
      return false;
    }
  }

  handleClickShowPassword(){
    this.setState(prevState => {
      return {showPassword: !prevState.showPassword}
    })
  };

  handleMouseDownPassword(event){
    event.preventDefault();
  };

  render() {
    const { field, formMode, controlMode, value, validation, multiline, rows, rowsMax, isValueCalculated, type, t } = this.props;
    const { showPassword, isFocused } = this.state;

    const isRequired = this.checkIfRequired();
    const hasValue = value !== undefined && value !== null && value !== '' ? true : false;
    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || (field && field.readonly) || (field && field.type === "categorization");
    const isAutoFocused = field && field.focus === true;

    const label = this.getLabel();
    const helperText = this.getHelperText();
    const hasPlaceholder = field && field.placeholder ? true : false;

    const displayValue = this.getValue(value);

    const endAdornmentIcons = [];
    if (isValueCalculated) {
      endAdornmentIcons.push( <FontAwesomeIcon icon={faCalculator} color="grey" /> );
    }
    if (isReadOnly) {
      endAdornmentIcons.push( <LockIcon style={{ fontSize: 20 }} color="disabled" />  );
    }

    const InputProps = {
        readOnly: isReadOnly,
        endAdornment: isValueCalculated ? <FontAwesomeIcon icon={faCalculator} color="grey" /> :
                      isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> :
                      type === 'password' ?
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword.bind(this)}
                          onMouseDown={this.handleMouseDownPassword}
                          tabIndex={-1}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        : null
      }

    return(
      <TextField
        margin="none"
        fullWidth
        required={isRequired}
        error={hasError}
        label={ formMode === "form" ? label : null}
        value={displayValue}
        placeholder={hasPlaceholder ? field.placeholder : ''}
        onChange={this.handleChange}
        onKeyDown={(evt) => {if (evt.keyCode == 13){this.props.onChange(evt, field ? field.source : null)}}}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        InputProps={InputProps}
        InputLabelProps={{shrink: hasValue || isFocused || isAutoFocused || displayValue !== ''}}
        variant={formMode === "form" ? "filled" : "standard"}
        helperText={hasError ? validation.msg : helperText}
        autoFocus={isAutoFocused}
        type={type && !showPassword ? type : "text"}
        color="secondary"
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      />
    )
  }
}

TextControl.defaultProps = {
  formMode: "form",
  controlMode: "edit",
  multiline: false,
  rowsMax: 5,
  isValueCalculated: false,
  showHelper: true
}

TextControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValueCalculated: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  showHelper: PropTypes.bool,
}


export default withTranslation()(TextControl);