import React from 'react'
import PropTypes from 'prop-types';
import Chartist from 'chartist'
import ChartistGraph from "react-chartist";
import { withTranslation } from 'react-i18next';

import withStyles from "@material-ui/core/styles/withStyles";

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.isEdge = window.navigator.userAgent.toLowerCase().indexOf("edge") >= 0;
    this.isMozilla = window.navigator.userAgent.toLowerCase().indexOf("firefox") >= 0;
    this.getBarDistance = this.getBarDistance.bind(this);
    this.curr = -1;
    this.state = {
      theme: props.themeContext.theme,
      fillColor: props.themeContext.theme === "light" ? "black" : "white",
    }
  }

  componentDidMount() {
    const { themeContext } = this.props;

  }

  componentDidUpdate() {
    const { themeContext } = this.props;
    const { theme } = this.state;
    if(theme !== themeContext.theme) {
      this.setState({
        theme: themeContext.theme,
        fillColor: themeContext.theme === "light" ? "black" : "white",
      });
    }
  }

  getAttributes(data) {
    const { fillColor } = this.state;

    const barVerticalCenter = data.y2 - 5;
    const barHorizontalCenter = this.isMozilla || this.isEdge ? data.x1 : (data.x1 + (data.element.width() * .5));

    return {
      x: barHorizontalCenter,
      y: barVerticalCenter,
      "text-anchor": "middle",
      style: "font-family: 'proxima-nova-alt', Helvetica, Arial, sans-serif; font-size: 12px; fill: "+fillColor+""
    };
  }

  parseLabel(label) {
    const l = parseInt(label, 10);
    if (l >= 1000000000) {
      return Math.round(l / 1000000000) + "G";
    } else if (l >= 1000000) {
      return Math.round(l / 1000000) + "M";
    } else if (l >= 1000) {
      return Math.round(l / 1000) + "k";
    } else {
      return l === 0 ? "" : l;
    }
  }

  chartListener() {
    const { color, colors } = this.props;

    return {
      draw: function (data) {
        if (data.index === 0 && data.type === 'bar') {
          this.curr++
        }
        //const seriesLen = this.props.data && this.props.data.series ? this.props.data.series.length : 1;
        const currColor = colors[this.curr % 2];
        if (data.type === 'bar') {
          data.element.attr({ style: 'stroke: ' + currColor + '; stroke-width: ' + Math.floor(1 / data.series.length * 40) + '%'});
          
          const labelText = this.parseLabel(data.series[data.index])
          const label = new Chartist.Svg("text");
          label.text(labelText);
          
          const attributes = this.getAttributes(data);
          label.attr(attributes);

          // add the new custom text label to the bar
          data.group.append(label);

          // Get the total path length in order to use for dash array animation
          let pathLength = -1;
          if (!this.isEdge) {
            pathLength = data.element._node.getTotalLength();
          }


          // Set a dasharray that matches the path length as prerequisite to animate dashoffset
          if (!this.isEdge) {
            data.element.attr({
              'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
            });
          }

          // Create animation definition while also assigning an ID to the animation for later sync usage
          var animationDefinition = {
            'stroke-dashoffset': {
              id: 'anim' + data.index,
              dur: 200,
              begin: 200 * data.index,
              from: pathLength + 'px',
              to: '0px',
              easing: Chartist.Svg.Easing.easeOutQuint,
              // We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
              fill: 'freeze'
            }
          };

          // We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });

          // We can't use guided mode as the animations need to rely on setting begin manually
          // See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
          data.element.animate(animationDefinition, false);

        } else if (data.type === 'label') {
          data.element._node.setAttribute('style', 'font-size: 2em; fill:' + color);
        }
      }.bind(this)
    }
  }

  getBarDistance() {
    const { data } = this.props;
    const dataLen = data && data.series && data.series.length ? data.series[0].length : 0;
    if (dataLen === 0) {
      return 20; // default
    }
    const windowWidth = window.innerWidth;
    const sidebarW = 50;
    const boxMargin = 2 * 16;
    const paperPadding = 2 * 16;
    const chartWidth = windowWidth - sidebarW - boxMargin - 2 * paperPadding; // 2 * paperPad because the chart is also padded

    const barWidth = Math.floor(1 / dataLen * 40);
    const tickWidth = Math.floor(chartWidth / dataLen);
    /*
      we want three empty spaces:
      1. between start border of the tick and first bar
      2. between first and second bar
      3. between second bar and end border of the tick
    */
    
    const barDist = Math.floor((tickWidth - 2 * barWidth) / 3)
    return barDist;
  }

  render() {
    const { value, data, t, themeContext } = this.props;
    const barDist = this.getBarDistance();
    const options = {
      // this one is to set the distance between bars if there is two of them in one tick
      seriesBarDistance: barDist,
      height: 250,
      axisX: {
        labelInterpolationFnc: (label, i, labelList) => t(label)
      },
      axisY: {
        onlyInteger: true,
        labelInterpolationFnc: (label) => this.parseLabel(label)
      },
      classNames: {
        label: themeContext.theme === "light" ? "ct-label ct-label-light" : "ct-label ct-label-dark"
      }
    };

    return (
      <ChartistGraph
        type="Bar"
        data={data}
        options={options}
        listener={this.chartListener()}
      />
    );
  }

}

BarChart.propTypes = {
  value: PropTypes.number
}

export default withTranslation()(BarChart);