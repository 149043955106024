import React, { useContext } from "react";

//Material-UI Core Components
import Switch from "@material-ui/core/Switch";

//Custom Components
import ThemeContext from "Components/ThemeContext/ThemeContext";

function ThemeSwitch() {

  const themeContext = useContext(ThemeContext);

  const handleThemeChange = (evt) => {
    let newTheme = themeContext.theme === "light" ? "dark" : "light";

    themeContext.setTheme(newTheme);
  }

  const checked = themeContext.theme === "dark";

  return (
    <Switch
      checked={checked}
      onChange={handleThemeChange}
    />
  )

}

export default ThemeSwitch;