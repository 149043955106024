import Interactions from "./Interactions";
import DefaultInteractions from "./DefaultInteractions";

//OpenLayers Interaction Wrappers
import DoubleClickZoomInteraction from "./OpenLayers/DoubleClickZoomInteraction";
import DragAndDropInteraction from "./OpenLayers/DragAndDropInteraction";
import DragBoxInteraction from "./OpenLayers/DragBoxInteraction";
import DragPanInteraction from "./OpenLayers/DragPanInteraction";
import DragRotateInteraction from "./OpenLayers/DragRotateInteraction";
import DragRotateAndZoomInteraction from "./OpenLayers/DragRotateAndZoomInteraction";
import DragZoomInteraction from "./OpenLayers/DragZoomInteraction";
import DrawInteraction from "./OpenLayers/DrawInteraction";
import ExtentInteraction from "./OpenLayers/ExtentInteraction";
import KeyboardPanInteraction from "./OpenLayers/KeyboardPanInteraction";
import KeyboardZoomInteraction from "./OpenLayers/KeyboardZoomInteraction";
import ModifyInteraction from "./OpenLayers/ModifyInteraction";
import MouseWheelZoomInteraction from "./OpenLayers/MouseWheelZoomInteraction";
import PinchRotateInteraction from "./OpenLayers/PinchRotateInteraction";
import PinchZoomInteraction from "./OpenLayers/PinchZoomInteraction";
import PointerInteraction from "./OpenLayers/PointerInteraction";
import SelectInteraction from "./OpenLayers/SelectInteraction";
import SnapInteraction from "./OpenLayers/SnapInteraction";
import TranslateInteraction from "./OpenLayers/TranslateInteraction";

//Custom Interactions
//...

export {
  Interactions,
  DefaultInteractions,

  DoubleClickZoomInteraction,
  DragAndDropInteraction,
  DragBoxInteraction,
  DragPanInteraction,
  DragRotateInteraction,
  DragRotateAndZoomInteraction,
  DragZoomInteraction,
  DrawInteraction,
  ExtentInteraction,
  KeyboardPanInteraction,
  KeyboardZoomInteraction,
  ModifyInteraction,
  MouseWheelZoomInteraction,
  PinchRotateInteraction,
  PinchZoomInteraction,
  PointerInteraction,
  SelectInteraction,
  SnapInteraction,
  TranslateInteraction
};