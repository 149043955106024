import React from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";

//Custom Components
import UserConsumer from "Components/UserContext/UserConsumer";

const customButtonStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.black,
    marginLeft: 0,
    marginRight: 10
  }
}));

function FormButton(props) {
  const { needRole, ...rest } = props;
  const classes = customButtonStyles();

  return (
    <UserConsumer>
      {userContext =>
        needRole && !userContext.hasAnyRole(needRole) ? null : (
          <Button classes={classes} color="secondary" size="medium" {...rest}>
            {props.children}
          </Button>
        )
      }
    </UserConsumer>
  );
}

FormButton.propTypes = {
  needRole: PropTypes.array
};

export default FormButton;
