export function fieldFormat(obj, format, t) {
  let keys = Object.keys(obj);

  if (format) {
    let label = format;
    if (keys.length > 0) {
      keys.forEach((k) => {
        let re = new RegExp('{' + k + '}');
        label = label.replace(re, obj[k] != null ? obj[k]: '');
      })
      return t ? t(label) : label;
    } else {
      return null;
    }
  } else {
    return keys.map(x => t ? t(obj[x]) : obj[x]).join(', ');
  }
}