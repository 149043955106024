import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faPaw, faTools, faTag } from "@fortawesome/free-solid-svg-icons";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

//Custom Components
import withFormController from "Components/withFormController";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarHeadingSub from "UI/Dialog/DialogToolbarHeadingSub";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogTabs from "UI/Dialog/DialogTabs";
import DialogTab from "UI/Dialog/DialogTab";
import DialogTabPanel from "UI/Dialog/DialogTabPanel";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import { common } from "@material-ui/core/colors";

import TaggingControl from "Components/TaggingControl/TaggingControl";
import UslugeTable from "Views/UslugePage/UslugeTable";

function PonudaDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [dataChanged, setDataChanged] = useState(false);
  const [ tabValue, setTabValue] = useState(0);

  const { model, mode, form, record, validation, fields, subModels, } = props; //HOC withFormController
  const { doInsert, doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController

  const handleTabChange = (evt, newValue) => {
    setTabValue(newValue);
  }

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert", id: result.id });
        } else if (result.error) {
          const msg = result.errorCode && result.errorCode === 23505 ?
            ("messages.duplicate") :
            ("Greška prilikom dodavanja novog zapisa! " + result.error);
          snackbarContext.showNotification(msg, "error", {record_type: "Ponuda"});
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          const msg = "Greška prilikom brisanja novog zapisa! " + result.error;
          snackbarContext.showNotification(msg, "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }

  }

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          const msg = result.errorCode && result.errorCode === 23505 ?
            ("messages.duplicate") :
            ("Greška prilikom dodavanja novog zapisa! " + result.error);
          snackbarContext.showNotification(msg, "error", {record_type: "Ponuda"});
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    dialogContext.hideDialog();
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }
  };

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: mode,
    subModels: subModels,
  }

  return (
    <DraggableDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="offer" icon_word="outbound"/>
          <DialogToolbarHeading>{t("titles.ponuda")}</DialogToolbarHeading>
          {record.id ? <DialogToolbarHeadingSub>(ID = {record.id})</DialogToolbarHeadingSub> : null}
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <DialogTabs
          value={tabValue}
          onChange={handleTabChange}
        >
        <DialogTab
          value={0}
          label={t("ponude.tab1")}
          icon={<FontAwesomeIcon icon={faFileAlt} size="lg" />}
          fields={["klijent_id", "datum_potpisa", "ug_neto_iznos"]}
          validation={validation}
        />
        <DialogTab
          disabled={mode === "insert"}
          value={1}
          icon={<FontAwesomeIcon icon={faTools} size="lg" />}
          label={t("ponude.tab2")}
          validation={validation}
        />
        </DialogTabs>
        <DialogTabPanel value={tabValue} index={0} key={0}>
          <Box m={2}>
            <GridContainer>
              <FormContent
                fieldNames={["klijent_id", "datum", "neto_iznos"]}
                columns={3}
                {...commonFields}
              />
              <FormContent
                fieldNames={["predmet"]}
                columns={1}
                {...commonFields}
              />
              <FormContent
                fieldNames={["tag", "doc_path", "voditelj_id"]}
                columns={3}
                {...commonFields}
              />
              <GridItem xs={12}>
                <TaggingControl mode={mode} linkId={record.id} title="Ponuda ključne riječi" groupsDenied={JSON.parse(process.env.REACT_TAGS_PONUDE_BLACKLIST)} groupsFilterId={process.env.REACT_TAGS_PONUDE_FILTER_ID}/>
              </GridItem>
              <FormContent
                fieldNames={["napomena"]}
                columns={2}
                {...commonFields}
              />
            </GridContainer>
          </Box>
        </DialogTabPanel>
        <DialogTabPanel value={tabValue} index={1} key={1}>
          <UslugeTable level={2} ugovorId={record.id} />
        </DialogTabPanel>
      </DialogBody>
      <DialogActions>
        { mode === "update" ? (
          <DialogActionButton variant="outlined" startIcon={<DeleteIcon />} onClick={handleDelete}>
            {t("buttons.delete")}
          </DialogActionButton>
        ) : null}
        <DialogActionButton variant="outlined" startIcon={<CloseIcon />} onClick={handleClose}>
          {t("buttons.close")}
        </DialogActionButton>
        {mode === "insert" ? (
          <DialogActionButton variant="contained" startIcon={<AddIcon />} onClick={handleInsert}>
            {t("buttons.add")}
          </DialogActionButton>
        ) : mode === "update" ? (
          <DialogActionButton variant="contained" startIcon={<SaveIcon />} onClick={handleUpdate}>
            {t("buttons.save")}
          </DialogActionButton>
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormController(PonudaDialog);
