import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import IconButton from '@material-ui/core/IconButton';

//Material-UI Icons
import ExploreIcon from "@material-ui/icons/Explore";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LocationOffOutlinedIcon from '@material-ui/icons/LocationOffOutlined';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Custom Components
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";
import TableButton from "UI/Table/TableButton";

import TagTableButton from "Components/TaggingControl/TagTableButton";
//import TagsMap from "Components/TaggingControl/TagsMap";
//import TagDialog from "Components/TaggingControl/TagDialog";

import model from "Models/tag";
import dataController from "Lib/dataController";


import { tagsService } from "Services/tagsService"

function TaggingSelectControl(props) {
  const { isRequired, title, linkId, add_linkId, onClose, onSelectTag, selectedTags, groupSelect, onSelectGroup, selectedGroups, clearSelection } = props;


  const [tags, setTags] = useState([]);

  const [expanded, setExpanded] = useState(false);
  const [hadExpanded, setHadExpanded] = useState(false);
  //const [selectedGroups, setSelectedGroups] = useState([]);

  const { t } = useTranslation();

  const hasError = false;

  const dc = new dataController(model);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
   if (linkId)  {
      tagsService.getTags(linkId).then((grouped_tags) => {
        setTags(grouped_tags);
      })
    }
  };

  const handleExpandedChange = () => {
    setExpanded(!expanded);
  }

  useEffect(() => {
    const sidebar = document.getElementsByClassName("sidebar-left")[0];
    if (expanded) {
      if (sidebar && !sidebar.classList.contains('expanded')) {
        setHadExpanded(true);
        sidebar.classList.add('expanded');
      }
    } else {
      if (sidebar && sidebar.classList.contains('expanded') && hadExpanded) {
        setHadExpanded(false);
        sidebar.classList.remove('expanded');
      }  
    }
  }, [expanded]);

  const tagTitles = tags.map(g => (g.tags.map(t => (selectedTags.indexOf(t.id) >= 0 ? t.tag : null)))).flat().filter(f => f !== null);
  return (
    <Box m={0} style={{ maxHeight: "40%", minHeight: expanded ? "40%" : "50px", overflowY: "auto" }}>
      <FormControl fullWidth={true} error={hasError}>
        <Accordion 
          expanded={expanded}
          onChange={handleExpandedChange}
          style={{borderBottom: "1px solid black", borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
          <FormLabel required={isRequired}>
            {t('tags.tags')} filter
            <Typography variant="caption" style={{paddingLeft: "10px"}}>
              {tagTitles.map((tt, i) => <u key={i} style={{padding: "0 3px", display: "inline-block"}}>{tt}</u>)}
            </Typography>
          </FormLabel>
          </AccordionSummary>
          <AccordionDetails>
          <GridContainer>
            <GridItem xs={12} sm={12}>
            {
              expanded || true ?
                <Table key={'table'}>
                  <TableBody>
                    {tags.map((group, i) => {
                      const groupSelected = selectedGroups.indexOf(group.id) >= 0;
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            {
                              groupSelect ?
                                <Chip
                                  color={groupSelected ? "secondary" : "primary"}
                                  variant={!groupSelected ? 'outlined' : 'default'}
                                  key={i}
                                  label={group.label}
                                  clickable={true}
                                  onClick={(evt) => onSelectGroup(evt, group)}
                                />
                              : group.label
                            }
                          </TableCell>
                          <TableCell>
                            {group.tags.map((tag, j) => {
                              const selected = selectedTags.indexOf(tag.id) > -1;
                              return (
                                <Chip
                                  color={selected || groupSelected ? "secondary" : "primary"}
                                  variant={!selected && !groupSelected ? 'outlined' : 'default'}
                                  key={j}
                                  label={tag.tag}
                                  clickable={true}
                                  onClick={(evt) => onSelectTag(evt, tag)}
                                />
                              );
                            })}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              : null
            }
            {
              selectedGroups.length || selectedTags.length ?
              <Button size="small" onClick={(evt) => {evt.preventDefault(); clearSelection();}} startIcon={<DeleteIcon fontSize="small"/>}>
                {t("buttons.delete_sel")}
              </Button>
              : null
            }
            </GridItem>
          </GridContainer>
          </AccordionDetails>
        </Accordion>
      </FormControl>
    </Box>
  );
}

export default TaggingSelectControl;
